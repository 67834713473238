import React from 'react'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'

/** Material UI import section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import FileIcon from '@material-ui/icons/AttachFile'
import DeleteIcon from '@material-ui/icons/Clear'

function FileContent(props) {
	const { files } = props

	const deleteFile = (fileId) => {
		if (props.onRemoveFile) {
			props.onRemoveFile(fileId)
		}
	}

	return files.map((file) => (
		<Card style={{ marginBottom: '.5em' }} key={uuidv4()}>
			<CardHeader
				avatar={
					<Avatar>
						<FileIcon />
					</Avatar>
				}
				action={
					<IconButton
						aria-label='settings'
						size='small'
						color='primary'
						onClick={() => deleteFile(file.id)}
					>
						<DeleteIcon style={{ color: '#D92550' }} />
					</IconButton>
				}
				title={
					file.attachment
						? `${file.attachment.name} ${file.attachment.size} KB`
						: ''
				}
				subheader={
					file.existFile === true ? (
						<Typography
							align='justify'
							variant='subtitle2'
							color='error'
						>
							Ya existe un documento con este nombre. Favor de
							cambiar el nombre.
						</Typography>
					) : null
				}
			/>
		</Card>
	))
}

FileContent.propTypes = {
    files: PropTypes.array
}

export default FileContent
