import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import uuidv4 from 'uuid/v4'
import PropTypes from 'prop-types'
import Select from 'react-select'

/** Material UI */
import { Grid, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core'

/** Custom component import section */
import LateralActionPanel from '../../common/LateralActionPanel'
import TextBox from '../../common/TextBox'
import NotificationDays from './NotificationDays'
import Toaster from '../../common/Toaster'
import DropDownZone from '../../common/DropDownZone'
import FileContent from '../../common/Dropzone/FileContent'

/** Helpers import section */
import DateSelector from '../../common/DateSelector'
import moment from 'moment'
import {
	COLOR_DANGER_NORMAL,
	COLOR_GRAY,
	COLOR_INFO_NORMAL
} from '../../../resources/constants/Colors'
import Dialog from '../../common/Dialog'
import { confirmUserPassword } from '../../../store/session/SessionActions'
import { removeDocumentInBackend } from '../../../store/operatingDocuments/DocumentActions'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
import { EOS_TYPE } from '../../../store/helpers/AppConsts'
import { removeCustomerDocumentInBackend } from '../../../store/customers/CustomerActions'
import { getOptions } from '../../../store/helpers/CustomerHelper'

const EditDocumentPanel = (props) => {
	const [documentModel, setDocumentModel] = useState(props.documentModel)
	const [passwordConfirmation, setPasswordConfirmation] = useState(null)
	const [expirationDate, setExpirationDate] = useState(
		documentModel ? documentModel.expirationDate : null
	)
	const [notificationValue, setNotificationValue] = useState(
		documentModel ? documentModel.notificationDays : null
	)
	const [notificationUnit, setNotificationUnit] = useState('D')
	const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
	const [showHelperPassword, setShowHelperPassword] = useState(false)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [notificationRequired, setNotificationRequired] = useState(
		documentModel ? documentModel.notificationDays > 0 : false
	)
	const [pdfFile, setPdfFile] = useState([])
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	let allCustomers = getOptions(props.customers ? props.customers : [])

	const dispatch = useDispatch()

	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setDocumentModel({
			...documentModel,
			[name]: value
		})
	}

	const canBeSaved = () => {
		return true
	}

	const onNotificationChange = (event) => {
		let { value, name } = event.target

		if (name === 'notification' && value.length > 0) {
			let regexNotification = /^([0-9]+$)/
			if (!regexNotification.test(value)) return
		}
		setNotificationValue(value)
	}

	const onNotificationRequired = (event) => {
		let { checked } = event.target
		setNotificationRequired(checked)
		onNotificationChange({ target: { value: 0, name: 'notification' } })
	}

	const onUnitsChange = (event) => {
		let { value } = event.target
		setNotificationUnit(value)
	}

	/**
	 * On seelct customer
	 */
	const onSelectCustomer = (customer) => {
		setSelectedCustomer(customer)
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileId) => {
		let index = pdfFile.findIndex((attachment) => attachment.id === fileId)
		if (index !== -1) {
			let newListFiles = pdfFile.filter((file) => {
				return file.id !== fileId
			})
			setPdfFile(newListFiles)
		}
	}

	/**Exist document add with name */
	const onExisteDocumentWithSameName = (fileName) => {
		let existDocument = pdfFile.find((u) => u.fileName === fileName)
		return existDocument ? true : false
	}

	const onSaveButtonClick = () => {
		if (props.onUpdateSupplierDocument) {
			let formatExpirationDate = null
			if (expirationDate !== null) {
				formatExpirationDate = moment(expirationDate).format()
			}
			let notificationDays = calculateDays()
			props.onUpdateSupplierDocument(pdfFile, {
				...documentModel,
				expirationDate: formatExpirationDate,
				notificationDays: notificationDays,
				customer: selectedCustomer
			})
		} else {
			console.warn("Callback 'onUpdateSupplierDocument' not defined!")
		}
	}

	const getExpirationDate = () => {
		let formatExpirationDate = null

		if (expirationDate !== null) {
			formatExpirationDate = moment(expirationDate).format()
		}
		return formatExpirationDate
	}

	const calculateDays = () => {
		if (notificationUnit === 'W') return notificationValue * 7
		return notificationValue
	}

	const removeDocument = () => {
		let resultResponse = false
		dispatch(confirmUserPassword(passwordConfirmation))
			.then((result) => {
				resultResponse = result.Success
				if (resultResponse) {
					dispatch(
						props.documentType === EOS_TYPE
							? removeDocumentInBackend(documentModel)
							: removeCustomerDocumentInBackend(documentModel)
					).finally(() => {
						setShowHelperPassword(false)
						setShowConfirmationDelete(false)
						setToasterOptions({
							open: true,
							message: 'Se ha eliminado el documento correctamente',
							variant: 'success'
						})
						setTimeout(() => {
							props.onClose()
						}, 2000)
					})
				} else {
					setShowHelperPassword(true)
				}
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message:
						'Se generó un error al eliminar el documento. Intentelo nuevamente por favor',
					variant: 'error'
				})
			})
	}

	const getModalBody = () => {
		return (
			<Grid container spacing={24} style={{ margin: '1em 0' }}>
				<Grid item xs={12}>
					<Typography variant='h6'>
						{`Por favor ingrese su contraseña para eliminar el documento.`}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextBox
						dataCy='input-password'
						active
						autoComplete='off'
						type='password'
						margin='dense'
						style={{ minWidth: 400, maxWidth: 400 }}
						label='Contraseña'
						onChange={(event) => setPasswordConfirmation(event.target.value)}
						helperText={
							showHelperPassword ? (
								<strong style={{ color: COLOR_DANGER_NORMAL }}>
									{'Contraseña incorrecta, por favor verifique.'}
								</strong>
							) : (
								''
							)
						}
					/>
				</Grid>
			</Grid>
		)
	}

	const getActionsModalRemoveDocument = () => {
		let isDisabled =
			isNullOrEmpty(passwordConfirmation) || passwordConfirmation.length < 3 || props.isSaving
		return (
			<Grid container spacing={2} justifyContent='flex-end' alignItems='center'>
				<Grid item xs={3}>
					<Button
						data-cy='btn-delete-document-footer'
						disabled={isDisabled}
						variant='contained'
						color='primary'
						onClick={() => removeDocument()}
						style={{
							backgroundColor: isDisabled ? COLOR_GRAY : COLOR_DANGER_NORMAL,
							color: '#fff'
						}}
					>
						{'Eliminar'}
					</Button>
				</Grid>
			</Grid>
		)
	}

	const getFooter = () => {
		return (
			<Button
				data-cy='btn-delete-document'
				disabled={props.isSaving}
				label='Eliminar'
				fullWidth={true}
				margin='dense'
				variant='outlined'
				name='delete'
				style={{
					backgroundColor: props.isSaving ? COLOR_GRAY : COLOR_DANGER_NORMAL,
					color: '#fff'
				}}
				onClick={() => setShowConfirmationDelete(true)}
			>
				{'Eliminar'}
			</Button>
		)
	}
	return (
		<LateralActionPanel
			title='Editar documento'
			show={props.open}
			onClose={props.onClose}
			isSaving={props.isSaving}
			canBeSaved={!canBeSaved()}
			onSaveItem={onSaveButtonClick}
			onCancel={props.onClose}
			footer={getFooter()}
		>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
						{'Cliente'}
					</Typography>
					<Select
						placeholder={'Seleccionar cliente'}
						options={allCustomers}
						onChange={onSelectCustomer}
						value={selectedCustomer}
						styles={{
							menu: (base) => ({ ...base, zIndex: 31 })
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<br />
					<TextBox
						active={true}
						label='Número de documento'
						fullWidth={true}
						margin='dense'
						name='numberDocument'
						type='number'
						onChange={(event) => {
							onPropertyChange(event)
						}}
						value={documentModel.numberDocument}
					/>
					<br /> <br />
				</Grid>
				<Grid item xs={12}>
					<TextBox
						dataCy='input-name'
						active={true}
						label='Nombre del documento'
						fullWidth={true}
						margin='dense'
						name='fileName'
						onChange={(event) => {
							onPropertyChange(event)
						}}
						value={documentModel.fileName}
					/>
				</Grid>
				<Grid item xs={12}>
					<DateSelector
						dataCy='data-picker-expiration'
						label='Seleccione fecha de vencimiento'
						onChange={(event) => {
							setExpirationDate(event.target.value)
						}}
						value={getExpirationDate()}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel
						labelPlacement='start'
						data-cy='check-notification'
						control={
							<Checkbox
								disabled={!expirationDate}
								onChange={(event) => onNotificationRequired(event)}
								checked={notificationRequired}
								color='primary'
							/>
						}
						label='¿Desea notificación?'
					/>
				</Grid>
				{expirationDate && notificationRequired && (
					<NotificationDays
						onUnitsChange={onUnitsChange}
						notificationValue={notificationValue}
						onNotificationChange={onNotificationChange}
						notificationUnit={notificationUnit}
					/>
				)}

				<Grid item xs={12}>
					<TextBox
						dataCy='input-description'
						multiline
						active={true}
						label='Descripción'
						fullWidth={true}
						margin='dense'
						name='description'
						onChange={(event) => {
							onPropertyChange(event)
						}}
						value={documentModel.description}
					/>
				</Grid>

				<Grid item xs={12}>
					<br />
					<DropDownZone
						datacy='drop-zone-documents-operating'
						multiple={false}
						accept={['.pdf', '.xlsx', '.eml', '.pps', '.ppsx', '.ppt', '.pptx']}
						message={
							"Seleccione los archivo(s) con extensión ('.pdf', '.xlsx', '.eml', '.pps', '.ppsx', '.ppt', '.pptx')"
						}
						onFilesReceived={(files) => {
							if (files.length > 0) {
								const filesAdded = files.map((file) => {
									let existFile = onExisteDocumentWithSameName(file.name)
									return {
										id: uuidv4(),
										attachment: file,
										existFile: existFile
									}
								})
								setPdfFile(pdfFile.concat(filesAdded))
							}
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					{pdfFile.length > 0 && (
						<FileContent files={pdfFile} onRemoveFile={onRemoveFile} />
					)}
				</Grid>
			</Grid>
			<Toaster {...toasterOptions} onClose={() => setToasterOptions({ open: false })} />
			<Dialog
				open={showConfirmationDelete}
				isSaving={props.isSaving}
				onClose={() => {
					setShowConfirmationDelete(false)
					props.onClose()
				}}
				style={{ maxWidth: 400 }}
				header={'Eliminar documento'}
				children={getModalBody()}
				actions={getActionsModalRemoveDocument()}
			/>
		</LateralActionPanel>
	)
}

EditDocumentPanel.propTypes = {
	documentModel: PropTypes.object.isRequired
}

export default EditDocumentPanel
