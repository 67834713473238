/** Imports section */
import axios from 'axios';

class IdentityWebApiClient {
    searchSupplierRequest = null;
    /**
     * End point to get the supplier data by code
     *
     * @static
     * @param {string} code
     * @param {object} config
     * @returns
     * @memberof IdentityWebApiClient
     */
    static searchSupplierByCode(code, config) {
        if (this.searchSupplierRequest != null) {
            this.searchSupplierRequest.cancel();
        }
        let cancelToken = axios.CancelToken;
        this.searchSupplierRequest = cancelToken.source();
        config.cancelToken = this.searchSupplierRequest.token;

        let endpoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${code}`;
        return axios.get(endpoint, config)
        .then(result => {
            let supplierData = result.data;
            this.searchSupplierRequest = null;
            let foundSupplier = null;
            if (supplierData) {
                foundSupplier = {
                    token: supplierData.token,
                    code: supplierData.code,
                    name: supplierData.name,
                    fullName: supplierData.full_name,
                    taxId: supplierData.rfc
                }
            }
            return Promise.resolve(foundSupplier);
        })
        .catch(error => {
            console.error('Error fetching supplier', error)
            return Promise.resolve(null);
        });
    }

    /**
     * Fetch supplier data by token.
     *
     * @static
     * @param {*} token
     * @param {*} config
     * @returns
     * @memberof IdentityWebApiClient
     */
    static fetchSupplierByToken(token, config) {
        let endpoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${token}`;
        return axios.get(endpoint, config);
    }

}

export default IdentityWebApiClient;