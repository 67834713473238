import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';

/** Material UI import section */
import {
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';

/** Custom components import section */
import Layout from '../layout/Layout';
import Loader from '../common/Loader';
/** Actions import section */
import {
    loadRequestDate
} from '../../store/dispositionRequests/DispositionRequestsActions';

/** Resources import section */
import { COLOR_PRIMARY_NORMAL } from '../../resources/constants/Colors';
import Calendar from './components/Calendar'

const Index = props => {
    const classes = useCalendarStyles();

    const dispatch = useDispatch();
    const allEvents = useSelector(state => state.dispositionRequests.get('events'), shallowEqual);
    const [isLoadingRequestsDate, setIsLoadingRequestsDate] = useState(true);
    const loggedUser = useSelector(state => state.session.get('loggedUser'), shallowEqual);
    /** Load all initial customers into application store */
    useEffect(() => {
        let customerToken = "";
        if (loggedUser.get('customer')) {
            customerToken = loggedUser.get('customer').get('token');
        }
        let supplierToken = "";
        if (loggedUser.get('supplier')) {
            supplierToken = loggedUser.get('supplier').get('token');
        }
        dispatch(loadRequestDate(customerToken, supplierToken)).finally(() => setIsLoadingRequestsDate(false));
    }, [dispatch, loggedUser])

    return (
        <Layout >
            <div>
                <Typography variant="subtitle1" className={classes.title} >
                    <strong> CALENDARIO DE ENTREGAS</strong>
                </Typography>
                <Paper className={classes.root}>
                    {
                        {
                            true: <Loader />,
                            false: <Calendar
                                events={allEvents}
                            />
                        }[isLoadingRequestsDate]
                    }
                </Paper>
            </div>

        </Layout>
    );
}

export default withRouter(Index);

const useCalendarStyles = makeStyles(({
    root: {
        marginTop: 10,
        width: "1200px",
        padding: "10px",
        maxHeight: `calc(100vh - ${135}px)`,
        overflowY: `auto`,
    },
    title: {
        marginTop: "5px",

        color: COLOR_PRIMARY_NORMAL
    }
}))
