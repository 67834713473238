import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const CircularProgressBar = (props) => {
    const {value, color} = props;
    return (
        <CircularProgressbar
            value={value}
            text={`${value}%`}
            styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: 'butt',
                textSize: '16px',
                pathTransitionDuration: 0.5,
                transition: 'stroke-dashoffset 0.5s ease 0s',
                pathColor: color? color: `rgba(62, 152, 199, ${90 / 100})`,
                textColor: color,
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
            })}
        />
    )
}

export default CircularProgressBar
