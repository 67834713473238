import React, { useState } from 'react';
import PropTypes from 'prop-types';

/** Material UI import section */
import { makeStyles } from '@material-ui/core/styles'
import {
    ClickAwayListener,
    Divider,
    Popper,
    Fade,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tab,
    Tabs,
    Typography,
    ListItemIcon,
    Tooltip
} from '@material-ui/core';
/** Resources import section */
import {
    COLOR_PRIMARY_LIGHT,
    COLOR_PRIMARY_NORMAL,
} from '../../resources/constants/Colors';
import { isUndefined } from 'util';

import PdfWarningIcon from '../../resources/images/pdfWarning.png';
import PdfDangerIcon from '../../resources/images/pdfDanger.png';
import PdfSuccessIcon from '../../resources/images/pdfSuccess.png';

/** Resources import section */
const useStyle = makeStyles((theme) => ({
    popperRoot: {
        marginLeft: "65vw",
        marginTop: "3.5em",
    },
    paperRoot: {
        marginTop: "13px",
        width: '350px',
        maxHeight: 320,
        overflowY: 'hidden'
    }
}))

export const getStatus = (status) => {
    let optionSmall = {
        height: "20px",
        width: "20px",
        marginTop: "-1px"
    }
    if (status === 3) {
        return (
            <Tooltip title={<Typography variant="caption">Vencido</Typography>} color="secondary">
                <img src={PdfDangerIcon} style={optionSmall} alt='Pdf PdfDangerIcon' />
            </Tooltip>
        )
    }
    if (status === 2) {
        return (
            <Tooltip title={<Typography variant="caption">Próximo a vencer</Typography>} color="secondary">
                <img src={PdfWarningIcon} style={optionSmall} alt='Pdf PdfWarningIcon' />
            </Tooltip>)
    }
    if (status === 1) {
        return (
            <Tooltip title={<Typography variant="caption">Vencimiento a largo plazo</Typography>} color="secondary">
                <img src={PdfSuccessIcon} style={optionSmall} alt='Pdf PdfSuccessIcon' />
            </Tooltip>
        )
    }
}

/**
 * Funcion to show option from adminsitration 
 * @param {*} props 
 */
const Notifications = props => {
    let classes = useStyle();
    let isOpen = props.anchorEl && (props.anchorEl.id === 'notification' || props.anchorEl.id === 'notification-arrow');
    return (
        <Popper open={isOpen} anchorEl={props.anchorEl} transition
            placement={'bottom'}
            className={classes.popperRoot}
            disablePortal
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={50}>
                    <ClickAwayListener onClickAway={props.onClickAway}>
                        <Paper className={classes.paperRoot}>
                            <NotificationTabs
                                {...props}
                                {...props.notificationData}
                            />
                        </Paper>
                    </ClickAwayListener>
                </Fade>
            )}
        </Popper>
    )
}

export default Notifications;


const useNotificationStyles = makeStyles((theme) => ({
    tabs: {
        height: 20
    },
    tab: {
        height: 20,
        width: 50
    }
}))

/**
 * Presentional componet to show the suppliers on tabs
 * 
 * @param {*} props 
 */
const NotificationTabs = props => {
    const classes = useNotificationStyles();
    const [selectedTab, onTabChange] = useState("EOS");


    const handleChange = (event, newValue) => {
        if (onTabChange) {
            onTabChange(newValue);
        }
        else {
            console.warn('Callback onTabChange not defined')
        }
    };

    return (
        <React.Fragment>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="fullWidth"
                className={classes.tabs}
            >
                <Tab
                    value={NotificationTabTypes.EOS}
                    label="EOS"
                    {...getTabProps(NotificationTabTypes.EOS)}
                    className={classes.tab}
                />
                <Tab
                    value={NotificationTabTypes.CUSTOMERS}
                    label="Clientes"
                    {...getTabProps(NotificationTabTypes.CUSTOMERS)}
                    className={classes.tab}
                />
            </Tabs>
            <Divider />
            <TabPanel
                onClickAway={props.onClickAway}
                history={props.history}
                value={selectedTab}
                index={NotificationTabTypes.EOS}
                documents={props.eosDocuments}
            />
            <TabPanel
                {...props}
                value={selectedTab}
                index={NotificationTabTypes.CUSTOMERS}
                documents={props.customerDocuments}
            />
        </React.Fragment>
    );
}

const NotificationTabTypes = {
    EOS: "EOS",
    SUPPLIERS: "SUPPLIERES",
    CUSTOMERS: "CUSTOMERS",
}

/**
 * Presentional component to show a tab panel
 * 
 * @param {*} props 
 */
const TabPanel = props => {
    const classes = useTabsPanel();
    const { children, value, index, ...other } = props;

    const onOptionDocumentsClick = (customer, status) => {
        if (isUndefined(customer) || customer === null)
            props.history.push(`/documents?pageNumber=1&status=${status}`);
        else
            props.history.push(`/customers/${customer ? customer.token : ""}?activeTab=1`);
        props.onClickAway();
    }

    const renderRow = (item) => {
        const { token, fileName, customer, status } = item;
        return (
            <ListItem
                key={token}
                dense
                divider
                button
                className={classes.outlinedRoot}
                onClick={() => onOptionDocumentsClick(customer, status)}
            >
                <ListItemIcon className={classes.outlinedRoot} >
                    {getStatus(status)}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography noWrap >{fileName}
                        </Typography>
                    }
                    secondary={customer && <Typography noWrap >{customer.name}</Typography>}
                />
            </ListItem >
        );
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            <List dense style={{ maxHeight: 200, overflowY: 'auto' }}>
                {props.documents.map(doc => renderRow(doc))}
            </List>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

/** Resources import section */
const useTabsPanel = makeStyles((theme) => ({
    outlinedRoot: {
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_LIGHT,
            color: COLOR_PRIMARY_NORMAL,
            borderColor: COLOR_PRIMARY_NORMAL
        },
    },
    optionSmall: {
        height: "25px",
        width: "25px",
        marginTop: "-1px"
    },
}))

const getTabProps = index => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
    };
}