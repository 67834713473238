import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { PositionPlacement } from '../../resources/constants/AppConstants'

function CustomCheckBox(props) {
	const {
		name,
		value,
		disabled,
		onChange,
		checked,
		color,
		label,
		labelplacement
	} = props
	return (
		<FormControlLabel
			labelPlacement={labelplacement}
			control={
				<Checkbox
					name={name}
					value={value}
					disabled={disabled}
					onChange={(event) => onChange(event)}
					checked={checked}
					color={color}
					{...props}
				/>
			}
			label={label}
		/>
	)
}

CustomCheckBox.propTypes = {
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
	color: PropTypes.string,
	label: PropTypes.node,
	labelplacement: PropTypes.oneOf([
		PositionPlacement.START,
		PositionPlacement.END,
		PositionPlacement.TOP,
		PositionPlacement.BOTTOM
	])
}

CustomCheckBox.defaultProps = {
	name: 'default check value',
	value: 0,
	disabled: false,
	onChange: (event) => console.debug('Checked: ', event.target.value),
	checked: false,
	color: 'primary',
	label: '',
	labelplacement: PositionPlacement.END,
}

export default CustomCheckBox
