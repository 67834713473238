import { createSelector } from 'reselect'
import { toSafeObject, toSafeArray } from '../../store/helpers/ImmutableHelper'
import { sortBy } from '../../store/helpers/SortingHelper'

const getAllCustomers = (state) => state.customers.get('allCustomers')

export const getAllCustomersSelector = createSelector([getAllCustomers], (customers) =>
	!!customers ? toSafeArray(customers) : []
)
/**
 * Create a selector for internal sort criteria
 */
export const queryParametersSelector = createSelector(
	(state) => state.users.get('internalQueryParameters'),
	(internalQueryParameters) => toSafeObject(internalQueryParameters)
)
/**
 * Create a selector for the filtered and sorted customer
 */
export const customerSelector = createSelector(
	(state) => state.customers.get('allCustomers'),
	(state) => state.customers.get('customerQueryParameters'),
	(customers, queryParameters) => {
		let queryObject = toSafeObject(queryParameters)

		const getFilteredCustomer = (customer) => {
			if (queryObject && queryObject.query) {
				let fixedQuery = queryObject.query.trim().toLowerCase()
				return customer.filter((x) => {
					return (
						x.code
							.trim()
							.toLowerCase()
							.startsWith(fixedQuery) ||
						x.name
							.trim()
							.toLowerCase()
							.includes(fixedQuery) ||
						x.fullCustomer
							.trim()
							.toLowerCase()
							.includes(fixedQuery) ||
						x.taxId
							.trim()
							.toLowerCase()
							.includes(fixedQuery)
					)
				})
			} else return customer
		}

		const getSortedCustomer = (customer) => {
			if (queryObject && queryObject.sortBy) {
				return sortBy(customer, queryObject.sortBy, queryObject.sortDirection)
			} else return customer
		}

		let result = toSafeArray(customers)
		result = getFilteredCustomer(result)
		result = getSortedCustomer(result)
		return result
	}
)

export const getAlertSettingSelector = createSelector(
	(state) => state.customers.get('alertSetting'),
	(alertSetting) => toSafeObject(alertSetting)
)
