import React from 'react'
import propTypes from 'prop-types'
import { AppBar, Toolbar, makeStyles } from '@material-ui/core'

/**
 * Layout header component
 */
const ApplicationHeader = (props) => {
	const classes = useAuthorizedToolbarStyles()

	return (
		<AppBar position='static'>
			<Toolbar className={classes.toolbar}>
				<div className={classes.root}>
					<div className={classes.leftContainer}>
						{props.leftToolbar}
					</div>
					<div className={classes.rightContainer}>
						{props.rightToolbar}
					</div>
				</div>
			</Toolbar>
		</AppBar>
	)
}
ApplicationHeader.propTypes = {
	/**
	 * Displays a toolbar in the left side of the header. You can pass a simple or composed component
	 */
	leftToolbar: propTypes.element,

	/**
	 * Displays a toolbar in the right side of the header. You can pass a simple or composed component
	 */
	rightToolbar: propTypes.element
}
/**
 * Authorized toolbar styles
 */
const useAuthorizedToolbarStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	leftContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	appLogo: {
		height: 40,
		marginRight: '10px'
	},
	toolbar: {
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.up('sm')]: {
			height: '64px',
		},
		[theme.breakpoints.up('xs')]: {
			height: '64px',
		},
		[theme.breakpoints.up('md')]: {
			height: '64px',
		},
		[theme.breakpoints.up('lg')]: {
			height: '64px',
		},
		[theme.breakpoints.up('xl')]: {
			height: 'xl',
		},
	},
	leftToobarContainer: {
		display: 'flex'
	},
	rightToobarContainer: {
		display: 'flex',
		alignItems: 'center'
	},
}))

export default ApplicationHeader
