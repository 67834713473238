/**
 * Determines id a customer require the act number into the template.
 * 
 * @param {string} customerCode 
 */
export const isCustomerKof = customerCode => {
    if (customerCode === 'KOF') return true;
    if (customerCode === '1210') return true;
    return false;
}

/**Get options for filter selectors */
export const getOptions=(data)=>{
    return data.map(option => getSelectOptionData(option));
}

/**Transform option for used in filter selector */
const getSelectOptionData = (option) => (
    {
        value: option.token,
        label: `${option.fullCustomer}`,
    }
);

/**Get options for filter selectors */
export const getOptionsUsers=(data)=>{
    return data.map(option => getSelectOptionUser(option));
}

/**Transform option for used in filter selector */
const getSelectOptionUser = (option) => (
    {
        value: option.email,
        label: `${option.name}`,
    }
);