import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom'

/** Material UI import section */
import {
    makeStyles,
    Avatar,
    Box,
    CardActions,
    Typography
} from '@material-ui/core';
import CustomersIcon from '@material-ui/icons/Business';

/** Custom components import section */
import MemoryPager from '../common/DataList/MemoryPager';
import Layout from '../layout/Layout';
import AddItemButton from '../common/AddItemButton';
import Loader from '../common/Loader';
import Toaster from '../common/Toaster';
import CustomersTable from './components/CustomersTable';
import AddCustomerPanel from './components/AddCustomerPanel';
import NoDataMessage from '../common/NoDataMessage';
import { AddBtnFake } from '../common/FakeButtons';

import {
    customerSelector,
} from '../../store/customers/CustomerSelectors'

/** Actions import section */
import {
    loadAllCustomers,
    createCustomer,
    activeCustomer,
    setCustomerQueryParameters
} from '../../store/customers/CustomerActions';

/** Resources import section */
import { COLOR_SECONDARY_NORMAL, COLOR_LIGHT_GRAY } from '../../resources/constants/Colors';
import NoCustomersImage from '../../resources/images/noCustomers.png';
import NoResultsFound from '../../resources/images/searchEmpty.png';

const useCustomerStyles = makeStyles(({
    root: {
        marginTop: 10,
        minWidth: "90vw",
    }
}))

const Index = props => {
    const classes = useCustomerStyles();
    let location = useLocation()
    let history = useHistory()
    //Configuration pagination table
    const [pageNumber, setPageNumber] = useState(1);

    const [pagination, setPagination] = useState({ pageSize: 10, total: 0 });
    const [selectedCustomerToken, setSelectedCustomerToken] = useState(null);
    const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
    // const [customers, setLocalCustomers] = useState([]);
    const customers = useSelector(customerSelector)
    const [customerPaginated, setCustomerPaginated] = useState([]);


    const [showAddCustomerPanel, setShowAddCustomerPanel] = useState(false);
    const [query, setQuery] = useState(null);
    const [searchBoxActive, setSearchBoxActive] = useState(false);
    const [sortConfig, setSortConfig] = useState({ by: 'name', criteria: 'asc' })
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    const dispatch = useDispatch();
    const isUpdatingCustomerStatus = useSelector(state => state.customers.get('isUpdatingCustomerStatus'));
    const isSavingCustomer = useSelector(state => state.customers.get('isSavingCustomer'));
    const allCustomers = useSelector(state => state.customers.get('allCustomers'), shallowEqual);

    // const allFilteredCustomers = useSearch(query, ['code', 'name'], allCustomers.toJS());
    // const allSortedCustomers = useSort(sortConfig, allFilteredCustomers);

    /** Load all initial customers into application store */
    useEffect(() => {
        const queryParameters = queryString.parse(location.search)
        if (!!queryParameters.pageNumber) {
            setPageNumber(queryParameters.pageNumber)
        }
        let newQuery = queryParameters.query ? queryParameters.query : '';
        if (!!newQuery) {
            setQuery(newQuery);
            setSearchBoxActive(true);
        }

        let newSortConfig = {}
        if (!!queryParameters.sortBy) newSortConfig.by = queryParameters.sortBy;
        if (!!queryParameters.sortDirection) newSortConfig.criteria = queryParameters.sortDirection;
        setSortConfig(newSortConfig)

        dispatch(loadAllCustomers(queryParameters)).finally(() => setIsLoadingCustomers(false));
    }, [dispatch, props.match.url, location.search])

    useEffect(() => {
        let customersPerPage = 10;
        let allTotal = customers.length;
        const indexOfLastUser = pageNumber * customersPerPage;
        let indexOfFirtsUser = indexOfLastUser - customersPerPage;
        let currentUsers = customers.slice(indexOfFirtsUser, indexOfLastUser)
        setCustomerPaginated(currentUsers)
        setPagination({ pageSize: customersPerPage, total: allTotal });
    }, [customers, pagination.total, pageNumber])

    const onActiveCustomer = (activeOrDisable, customer) => {
        setSelectedCustomerToken(customer.token)
        dispatch(activeCustomer(activeOrDisable, customer))
            .then(() => {
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al actualizar la informacion del cliente.", variant: 'error' });
            })
    }

    const onAddCustomerClick = () => {
        setShowAddCustomerPanel(true);
    }


    const onCreateCustomer = customer => {
        dispatch(createCustomer(customer))
            // .then(() => {
            //     return dispatch(filterUsers(query, sortConfig));
            // })
            .then(allUsers => {
                // setLocalUsers(allUsers.toJS());
                setShowAddCustomerPanel(false);
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al agregar el cliente", variant: 'error' });
            })
    }

    const onCustomerClick = user => {
        props.history.push(`/customers/${user.id}`);
    }

    const onShowCustomerUsersClick = customerId => {
        props.history.push(`/customers/${customerId}`);
    }

/**
 * Update sourt criteria
 */
const onSortingCriteriaChange = (sortBy, sortCriteria) => {
    const queryParams = queryString.parse(location.search)
    queryParams.sortBy = sortBy
    queryParams.sortDirection = sortCriteria
    history.push({ search: queryString.stringify(queryParams) })
    dispatch(setCustomerQueryParameters(queryParams))
}

const onSearchBoxChange = (searchQuery) => {
    const queryParams = queryString.parse(location.search)
    if (searchQuery) {
        queryParams.query = searchQuery
        setQuery(searchQuery);
    }
    else delete queryParams.query
    history.push({ search: queryString.stringify(queryParams) })
    dispatch(setCustomerQueryParameters(queryParams))
}

/**
* On change page number
*/
const onChangePageNumber = (page) => {
    setPageNumber(page)
    const queryParams = queryString.parse(location.search)
    queryParams.pageNumber = page
    history.push({ search: queryString.stringify(queryParams) })
}

    const onToggleSearchStatus = active => {
        setSearchBoxActive(active);
    }

    const getNoDataMessasge = () => {
        let message, image;
        if (allCustomers.size > 0 && customerPaginated.length === 0) {
            message = (
                <Typography>
                    No se encontraron resultados para esta búsqueda.
                </Typography>
            );
            image = NoResultsFound;
        }
        else {
            message = (
                <Typography>
                    Para agregar un nuevo cliente da clic en el boton "Agregar" <AddBtnFake/> y captura los datos solicitados.
                </Typography>
            ); 
            image = NoCustomersImage;
        }
        return (
            <NoDataMessage
                message={message}
                image={image}
            />
        );
    }

    return (
        <Layout
            searchBoxOptions={{
                enabled: true,
                placeholder: "Buscar clientes",
                value: query,
                onChange: onSearchBoxChange,
                active: searchBoxActive,
                onToggleStatus: onToggleSearchStatus
            }}
        >
            <div className={classes.root}>
                {/** Customers header */}
                <CustomersHeader
                    onAddCustomerButtonClick={onAddCustomerClick}
                />
                {/** Customers table */}
                {
                    {
                        true: <Loader />,
                        false: <CustomersTable
                            customers={customerPaginated}
                            onCustomerRowClick={onCustomerClick}
                            isUpdatingCustomerStatus={isUpdatingCustomerStatus}
                            sortConfig={sortConfig}
                            onSortingCriteriaChange={onSortingCriteriaChange}
                            onShowCustomerUsersButtonClick={onShowCustomerUsersClick}
                            onActiveCustomer={onActiveCustomer}
                            isSavingCustomer={isSavingCustomer}
                            selectedCustomerToken={selectedCustomerToken}
                            noDataMessage={getNoDataMessasge()}
                        />
                    }[isLoadingCustomers]
                }

                <CardActions>
                    <MemoryPager
                        pageNumber={pageNumber - 1}
                        pageSize={pagination.pageSize}
                        count={pagination.total}
                        onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
                    />
                </CardActions>
            </div>
            {/** Add customer panel */}
            {
                showAddCustomerPanel &&
                <AddCustomerPanel
                    open={showAddCustomerPanel}
                    isSaving={isSavingCustomer}
                    onClose={() => setShowAddCustomerPanel(false)}
                    onCreateCustomer={onCreateCustomer}
                />
            }
            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    {...toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </Layout>
    );
}

export default withRouter(Index);

const useHeaderStyles = makeStyles(({
    root: {
        marginTop: -20,
        marginBottom: 0
    },
    icon: {
        // marginRight: 10
        color: COLOR_SECONDARY_NORMAL
    },
    avatar: {
        marginTop: 8,
    },
    buttonAddUser: {
        marginTop: 3,
    },
    title: {
        marginTop: 6,
        marginLeft: 8,
        color: COLOR_SECONDARY_NORMAL
    }
}))

const CustomersHeader = props => {
    const classes = useHeaderStyles();

    return (
        <Box
            className={classes.root}
            display="flex"
            p={1}
            alignItems="center"
            justifyContent="flex-start"
        >
            <Box
                className={classes.avatar}
                display="flex"
                width="100%"
            >
                <Avatar style={{ color: COLOR_SECONDARY_NORMAL, backgroundColor: COLOR_LIGHT_GRAY, fontSize: "14px", height: "35px", width: "35px" }}>
                    <CustomersIcon className={classes.icon} />
                </Avatar>
                <Typography variant="button" className={classes.title}><strong>CLIENTES</strong></Typography>
            </Box>
            <Box
                className={classes.buttonAddUser}
                p={1}
                flexShrink={0}
            >
                <AddItemButton
                    title={"Agregar cliente"}
                    onClick={props.onAddCustomerButtonClick}
                />
            </Box>
        </Box>
    );
}
