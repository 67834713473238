import React from 'react'
import propTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, makeStyles, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import ValidatedTextField from '../common/ValidatedTextField'
import Alert from '@material-ui/lab/Alert'
import repareLogo from '../../resources/images/logoEOS.png'
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountBox';
import PasswordIcon from '@material-ui/icons/Lock';

/**
 * Login view component
 * @param {*} props
 * @returns
 */
const LoginView = (props) => {
	const {
		open,
		onClose,
		onLogin,
		isValidatingPassword,
		error,
		errorMessage
	} = props
	const {
		formState: { errors },
		handleSubmit,
		control,
	} = useForm()

	let classes = useStyle()
	return (
		<Dialog
			open={open}
			onClose={onClose}
			disableBackdropClick={!isValidatingPassword}
			disableEscapeKeyDown={!isValidatingPassword}
		>
					<form onSubmit={handleSubmit(onLogin)} className={classes.root}>
						<DialogTitle
							id='alert-dialog-title'
							className={classes.dialogTitle}
						>
							<img src={repareLogo} alt='reparte-logo' />
							<br />
							<Typography>Ingresa tus credenciales</Typography>
						</DialogTitle>
						<DialogContent>
							<ValidatedTextField
								autoFocus
								name='userName'
								label='Usuario'
								rules={{ required: 'Requerido' }}
								control={control}
								errors={errors}
								defaultValue=''
								startAdornment={
									<InputAdornment position='start'>
										<AccountCircle />
									</InputAdornment>
								}
							/>
							<div className={classes.separator} />
							<ValidatedTextField
								name='password'
								label='Clave de acceso'
								margin='dense'
								rules={{ required: 'Requerido' }}
								control={control}
								errors={errors}
								type='password'
								defaultValue=''
								className={classes.separator}
								startAdornment={
									<InputAdornment position='start'>
										<PasswordIcon />
									</InputAdornment>
								}
							/>
							<div className={classes.separator} />
							<div className={classes.errorMessage}>
								{error && (
									<Alert severity='error'>{errorMessage}</Alert>
								)}
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								type='submit'
								fullWidth
								color='secondary'
								variant='contained'
								disabled={!isValidatingPassword}
							>
								{isValidatingPassword ? 'INGRESAR' : 'VALIDANDO...' }
							</Button>
						</DialogActions>
						<div className={classes.separator} />
					</form>
		</Dialog>
	)
}
LoginView.propTypes = {
	open: propTypes.bool.isRequired,
	error: propTypes.bool.isRequired,
	errorMessage: propTypes.string,
	isValidatingPassword: propTypes.bool.isRequired,
}
LoginView.defaultProps = {
	open: true,
	error: false,
	errorMessage: '',
	isValidatingPassword: false,
}

let useStyle = makeStyles((theme) => ({
	root: {
		width: '350px',
	},
	separator: {
		marginBottom: '20px',
	},
	errorMessage: {
		height: '50px',
	},
	dialogTitle: {
		textAlign: 'center',
	},
}))

export default LoginView
