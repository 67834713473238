import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters
} from '../helpers/RequestHelper'

export const SET_ALL_REPORTS = 'SET_ALL_REPORTS'
export const ADD_REPORT = 'ADD_REPORT'
export const REMOVE_REPORT = 'REMOVE_REPORT'

const setAllReports = (reports) => {
	return {
		type: SET_ALL_REPORTS,
		reports
	}
}

export const loadReports = (queryParameters) => {
	return function(dispatch, getState) {
		const user = getState().oidc.user
		const config = getAuthorizedRequestConfigurationUser(user)
        const command = queryParameters;
		const endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}reports/all`
		return axios
			.post(endPoint, command, config)
			.then(({data}) => {
				dispatch(setAllReports(data))
			})
			.catch((error) => {
				console.error('Error loading all reports', error)
				return Promise.reject()
			})
	}
}

const addReport = (report) => {
	return {
		type: ADD_REPORT,
		report
	}
}

export const addReportForCustomer = (report, files) => {
	return function(dispatch, getState) {
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUserParameters(loggedUser)
		let command = {
			CustomerToken: report.customer && report.customer.value,
			Comments: report.comments
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}reports`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.post(endPoint, data, config)
			.then(({status, data}) => {
				if (status === 200) {
                    dispatch(addReport(data))
				}
			})
			.catch((error) => {
				console.error('Error adding report', error)
				return Promise.reject()
			})
	}
}

const removeReport = (token) => {
	return {
		type: REMOVE_REPORT,
		token
	}
}

export const removeRequestFromBackEnd = (token) => {
    return function(dispatch, getState){
        const user = getState().oidc.user;
        const config = getAuthorizedRequestConfigurationUser(user)
        const endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}reports/${token}`;
        return axios.delete(endPoint, config)
            .then((response) => {
				dispatch(removeReport(token))
            })
            .catch((error) => {
                console.error("🚀 ~ file: DispositionRequestsActions.js ~ Error removing report", error)
            })
    }
} 
