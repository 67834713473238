import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

/** Material UI import section */
import { makeStyles, Box, Fab, Tooltip, Typography, CardActions, Chip } from '@material-ui/core'
import FileIcon from '@material-ui/icons/InsertDriveFile'

/** Custom components import section */
import Layout from '../layout/Layout'
import Loader from '../common/Loader'
import Toaster from '../common/Toaster'
import NoDataMessage from '../common/NoDataMessage'
import { AddDocFake } from '../common/FakeButtons'
import AddDocumentsPanel from './components/AddDocumentsPanel'
import EditDocumentPanel from './components/EditDocumentPanel'
import DocumentsTable from './components/DocumentsTable'
import MemoryPager from '../common/DataList/MemoryPager'

/** Redux store import section */
import {
	loadAllDocuments,
	uploadEosDocuments,
	updateEosDocument
} from '../../store/operatingDocuments/DocumentActions'
import { loadAllCustomers } from '../../store/customers/CustomerActions'
import { getAllDocumentsSelector } from '../../store/operatingDocuments/DocumentSelectors'
import { getAllCustomersSelector } from '../../store/customers/CustomerSelectors'

/** Helpers import section */
import {
	getCustomFilters,
	parsePaginationFromQueryString,
	parseStatusValues,
	parseQuickLookupValue,
	parseSortingFromQueryString
} from '../../store/helpers/UrlHelper'
import { EOS_TYPE } from '../../store/helpers/AppConsts'
import { isUserInRole } from '../../store/helpers/UserHelper'
import { AppRoles } from '../../resources/constants/AppConstants'

/** Resources import section */
import {
	COLOR_SECONDARY_NORMAL,
	COLOR_INFO_NORMAL,
	COLOR_DANGER_NORMAL,
	COLOR_SUCCESS_NORMAL,
	COLOR_WARNING_NORMAL,
	COLOR_SUCCESS_PRESSED,
	COLOR_WARNING_PRESSED,
	COLOR_DANGER_PRESSED
} from '../../resources/constants/Colors'
import LogoHelp from '../../resources/images/logoHelp.png'
import NoResultsFound from '../../resources/images/searchEmpty.png'
import { searchByFilters, searchByQuery, sortDataSet } from '../../store/helpers/ArrayHelper'

const useSupplierStyles = makeStyles({
	root: {
		// marginTop: 10,
		minWidth: '90vw',
		height: `calc(100vh - ${200}px)`,
		overflowY: 'auto'
	},
	header: {
		marginTop: 10
	}
})

const Index = (props) => {
	const classes = useSupplierStyles()
	//Configuration pagination table
	const [pagination, setPagination] = useState({ pageSize: 20, total: 0 })

	const [isLoadingDocuments, setIsLoadingDocuments] = useState(true)
	const [documents, setLocalDocuments] = useState([])
	const [selectedDocumentToken, setSelectedDocumentToken] = useState(null)
	const [searchBoxActive, setSearchBoxActive] = useState(false)
	const [showAddDocumentsPanel, setShowAddDocumentsPanel] = useState(false)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [editDocumentPanelOptions, setEditDocumentPanelOptions] = useState({
		open: false,
		documentModel: null
	})

	/** Connect with store */
	const dispatch = useDispatch()
	const loggedUser = useSelector((state) => state.session.get('loggedUser'))
	const allDocuments = useSelector((state) => getAllDocumentsSelector(state))
	const allCustomers = useSelector((state) => getAllCustomersSelector(state))
	const isSavingDocument = useSelector((state) => state.documents.get('isSavingDocument'))

	const { pageNumber } = parsePaginationFromQueryString(props.history.location.search, {
		pageNumber: 1
	})
	const { sortBy, sortDirection } = parseSortingFromQueryString(props.history.location.search)
	const query = parseQuickLookupValue(props.history.location.search)
	const selectedStatus = parseStatusValues(props.history.location.search)
	const activeFilterOption = { isActive: selectedStatus.includes('1') }
	const toExpireFilterOption = { isActive: selectedStatus.includes('2') }
	const expiredFilterOption = { isActive: selectedStatus.includes('3') }

	useEffect(() => {
		dispatch(loadAllCustomers())
	}, [dispatch])

	/** Load all initial suppliers into application store */
	useEffect(() => {
		let customerToken = ''
		if (loggedUser.get('customer')) {
			customerToken = loggedUser.get('customer').get('token')
		}
		dispatch(loadAllDocuments(customerToken)).finally(() => setIsLoadingDocuments(false))
	}, [dispatch, props.match.url, loggedUser])

	useEffect(() => {
		let documentsToShow = [...allDocuments]
		const search = props.history.location.search
		if (search) {
			documentsToShow = searchByQuery(
				query,
				['creationDate', 'expirationDate', 'fileName', 'description'],
				allDocuments
			)
			const filters = getCustomFilters(props.history.location.search)
            console.log(`🚀 ~ file: Index.js ~ line 130 ~ useEffect ~ filters`, filters);
			documentsToShow = searchByFilters(filters, documentsToShow)
			documentsToShow = sortDataSet({ by: sortBy, criteria: sortDirection }, documentsToShow)
		}
		const itemsPerPage = 10
		const indexOfLastUser = pageNumber * itemsPerPage
		const indexOfFirtsUser = indexOfLastUser - itemsPerPage
		const documentsInPage = documentsToShow.slice(indexOfFirtsUser, indexOfLastUser)

		setLocalDocuments(documentsInPage)
		setPagination({ pageSize: itemsPerPage, total: documentsToShow.length })
	}, [allDocuments, pageNumber, sortBy, sortDirection, query, props.history.location.search])

	/**
	 * Handle the page number change in the query string
	 */
	const onChangePageNumber = (page) => {
		const actualParameters = queryString.parse(window.location.search)
		actualParameters.pageNumber = page
		let updatedQueryString = queryString.stringify(actualParameters)
		props.history.push(`${props.location.pathname}?${updatedQueryString}`)
	}

	const onAddDocumentsClick = () => {
		setShowAddDocumentsPanel(true)
	}

	const onCancelAddDocumentsClick = () => {
		setShowAddDocumentsPanel(false)
	}

	const onToggleSearchStatus = (active) => {
		setSearchBoxActive(active)
	}

	/**
	 * Handle changing text on serch box
	 * @param {*} searchValue
	 */
	const onSearchBoxChange = (searchValue) => {
		const actualParameters = queryString.parse(window.location.search)
		if (searchValue) {
			actualParameters.query = searchValue
		} else {
			delete actualParameters.query
		}
		actualParameters.pageNumber = 1
		let updatedQueryString = queryString.stringify(actualParameters)
		props.history.push(`${props.location.pathname}?${updatedQueryString}`)
	}

	const onAddDocuments = (files, data) => {
		dispatch(uploadEosDocuments(files, data))
			.then(() => {
				setShowAddDocumentsPanel(false)
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Error al cargar los documento',
					variant: 'error'
				})
			})
	}

	const onEditDocumentClick = (item) => {
		let documentModel = documents.find((a) => a.token === item.token)
		setEditDocumentPanelOptions({ open: true, documentModel })
	}

	const onDownloadDocumentClick = (event) => {
		let documentToken = event.currentTarget.id
		setSelectedDocumentToken(documentToken)
		let urlPdf = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/${documentToken}/download`
		window.open(urlPdf, '_blank', 'titlebar=no,width=800,height=800,location=no,resizable=yes')
	}

	const onCloseDocumentClick = () => {
		setEditDocumentPanelOptions({ open: false, documentModel: null })
	}

	const onUpdateDocument = (files, documentModel) => {
		dispatch(updateEosDocument(files, documentModel))
			.then(() => {
				setEditDocumentPanelOptions({ open: false, documentModel: null })
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Error al editar el documento',
					variant: 'error'
				})
			})
	}

	const onSortingCriteriaChange = (sortBy, sortCriteria) => {
		const actualParameters = queryString.parse(window.location.search)
		actualParameters.sortBy = sortBy
		actualParameters.sortDirection = sortCriteria
		const updatedQueryString = queryString.stringify(actualParameters)
		props.history.push(`${props.location.pathname}?${updatedQueryString}`)
	}

	const filterStatus = (event) => {
		const status = event.currentTarget.id
		const statusEnabled = selectedStatus.includes(status)
		const isFilterActive = (status === '1'
			? activeFilterOption
			: status === '2'
			? toExpireFilterOption
			: expiredFilterOption
		).isActive
		const actualParameters = queryString.parse(window.location.search)
		const updatedStatus = statusEnabled
			? selectedStatus.filter((s) => s !== status)
			: selectedStatus.concat(status)
		if (updatedStatus.length > 0) {
			actualParameters.status = updatedStatus.toString()
		} else {
			delete actualParameters.status
		}
		console.log(`🚀 ~ file: Index.js ~ line 234 ~ filterStatus`, {
			status,
			isFilterActive,
			statusEnabled,
			actualParameters,
			selectedStatus,
			updatedStatus
		})
		// let selectedStatus = []
		// if (actualParameters.status) selectedStatus = actualParameters.status.split(',')
		// if (isFilterActive) {
		// 	let statusIndex = selectedStatus.findIndex((currentFilter) => currentFilter === status)
		// 	if (selectedStatus.length > 0 && statusIndex < 0) {
		// 		selectedStatus.push(status)
		// 	} else {
		// 		selectedStatus.splice(statusIndex, 1)
		// 	}
		// } else {
		// 	selectedStatus.push(status)
		// }

		// if (selectedStatus.length > 0) actualParameters.status = selectedStatus.toString()
		// else delete actualParameters.status
		// status === '1'
		// 	? setActiveFilterOption({ isActive: !isFilterActive })
		// 	: status === '2'
		// 	? setToExpireFilterOption({ isActive: !isFilterActive })
		// 	: setExpiredFilterOption({ isActive: !isFilterActive })

		actualParameters.pageNumber = 1

		let updatedQueryString = queryString.stringify(actualParameters)
		props.history.push(`${props.location.pathname}?${updatedQueryString}`)
	}

	const getNoDataMessasge = () => {
		let message, image
		if (allDocuments.size > 0 && documents.length === 0) {
			message = <Typography>No se encontraron resultados para esta búsqueda.</Typography>
			image = NoResultsFound
		} else {
			message = (
				<Typography>
					Para agregar un nuevo documento da clic en el boton "Agregar" <AddDocFake /> y
					captura los datos solicitados.
				</Typography>
			)
			image = LogoHelp
		}
		return <NoDataMessage message={message} image={image} />
	}

	return (
		<Layout
			searchBoxOptions={{
				enabled: true,
				placeholder: 'Buscar documentos',
				value: query,
				onChange: onSearchBoxChange,
				active: searchBoxActive,
				onToggleStatus: onToggleSearchStatus
			}}
		>
			<div className={classes.header}>
				<DocumentsHeader
					isValitToAddDocument={
						isUserInRole(AppRoles.SALES_MANAGER, loggedUser.get('roles').toJS()) ||
						isUserInRole(
							AppRoles.ROLE_DOCUMENTS_OPERATIVES,
							loggedUser.get('roles').toJS()
						)
					}
					filterStatus={filterStatus}
					activeFilterOption={activeFilterOption}
					toExpireFilterOption={toExpireFilterOption}
					expiredFilterOption={expiredFilterOption}
					openAddFilesPanel={onAddDocumentsClick}
				/>
				<div className={classes.root}>
					{
						{
							true: <Loader />,
							false: (
								<DocumentsTable
									documents={documents}
									onViewPdfButtonClick={onDownloadDocumentClick}
									onUserRowClick={onEditDocumentClick}
									isSavingDocument={isSavingDocument}
									selectedDocumentToken={selectedDocumentToken}
									noDataMessage={getNoDataMessasge()}
									onSortingCriteriaChange={onSortingCriteriaChange}
									sortConfig={{ by: sortBy, criteria: sortDirection }}
									isValitToUpdate={
										isUserInRole(
											AppRoles.SALES_MANAGER,
											loggedUser.get('roles').toJS()
										) ||
										isUserInRole(
											AppRoles.ROLE_DOCUMENTS_OPERATIVES,
											loggedUser.get('roles').toJS()
										)
									}
								/>
							)
						}[isLoadingDocuments]
					}
				</div>
				<CardActions>
					<MemoryPager
						pageNumber={pageNumber - 1}
						pageSize={pagination.pageSize}
						count={pagination.total}
						onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
					/>
				</CardActions>
				{/** Add documents panel */}
				{showAddDocumentsPanel && (
					<AddDocumentsPanel
						open={showAddDocumentsPanel}
						onClose={onCancelAddDocumentsClick}
						documents={allDocuments}
						onFileReceived={onAddDocuments}
						isSaving={isSavingDocument}
						customers={allCustomers}
					/>
				)}
				{editDocumentPanelOptions.open && (
					<EditDocumentPanel
						documents={allDocuments}
						open={editDocumentPanelOptions.open}
						documentModel={editDocumentPanelOptions.documentModel}
						onClose={onCloseDocumentClick}
						onUpdateSupplierDocument={onUpdateDocument}
						isSaving={isSavingDocument}
						documentType={EOS_TYPE}
						customers={allCustomers}
					/>
				)}

				{/** Toaster */}
				{toasterOptions.open && (
					<Toaster
						{...toasterOptions}
						onClose={() => setToasterOptions({ open: false })}
					/>
				)}
			</div>
		</Layout>
	)
}

export default withRouter(Index)

const useHeaderStyles = makeStyles({
	root: {
		marginTop: -20,
		marginBottom: 0
	},
	icon: {
		color: COLOR_SECONDARY_NORMAL
	},
	avatar: {
		marginTop: 8
	},
	buttonAddUser: {
		marginTop: 3
	},
	title: {
		marginTop: 6,
		color: COLOR_SECONDARY_NORMAL
	}
})

const DocumentsHeader = (props) => {
	const classes = useHeaderStyles()

	return (
		<Box
			className={classes.root}
			display='flex'
			p={1}
			alignItems='center'
			justifyContent='flex-start'
		>
			<Box className={classes.avatar} display='flex' width='100%'>
				<Typography variant='button' className={classes.title}>
					<strong>DOCUMENTOS OPERATIVOS</strong>
				</Typography>
				<Chip
					id={'1'}
					size='small'
					label='Vigentes'
					style={{
						marginLeft: '10px',
						backgroundColor: props.activeFilterOption.isActive
							? COLOR_SUCCESS_PRESSED
							: COLOR_SUCCESS_NORMAL,
						color: '#FFFFFF',
						textTransform: 'uppercase'
					}}
					onClick={props.filterStatus}
				/>

				<Chip
					id={'2'}
					size='small'
					label='Por vencer'
					style={{
						marginLeft: '10px',
						backgroundColor: props.toExpireFilterOption.isActive
							? COLOR_WARNING_PRESSED
							: COLOR_WARNING_NORMAL,
						color: '#FFFFFF',
						textTransform: 'uppercase'
					}}
					onClick={props.filterStatus}
				/>

				<Chip
					id={'3'}
					size='small'
					label='Vencidos'
					style={{
						marginLeft: '10px',
						backgroundColor: props.expiredFilterOption.isActive
							? COLOR_DANGER_PRESSED
							: COLOR_DANGER_NORMAL,
						color: '#FFFFFF',
						textTransform: 'uppercase'
					}}
					onClick={props.filterStatus}
				/>
			</Box>

			{props.isValitToAddDocument && (
				<Box className={classes.buttonAddUser} p={1} flexShrink={0}>
					<Tooltip
						title={<Typography variant='caption'>Agregar documentos</Typography>}
						color='secondary'
					>
						<Fab
							data-cy='btn-add-documents-operating'
							size='small'
							style={{ backgroundColor: COLOR_INFO_NORMAL }}
							onClick={props.openAddFilesPanel}
						>
							<FileIcon className={classes.buttonIcon} />
						</Fab>
					</Tooltip>
				</Box>
			)}
		</Box>
	)
}
