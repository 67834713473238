import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


/** Resources import section */
/**
 *
 *
 * @param {*} props
 * @returns
 */
const CropImageSelector = props => {
    const classes = useStyle();
    // Define local state
    const [crop, setCrop] = useState(null);

    /**
 * On change crop
 *
 * @param {*} crop
 * @param {*} pixelCrop
 * @memberof CropForm
 */
    const onChangeCrop=(crop)=> {
        setCrop(crop)
    }

    const onImageLoaded = (image) => {
        let initialCrop = makeAspectCrop({
            x: 0,
            y: 0,
            aspect: 1,
            width: 400
        }, image.naturalWidth / image.naturalHeight);
        setCrop(initialCrop);
    }


    return (
        <div className={classes.root}>
            <ReactCrop
                src={props.fileBase64}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onChange = {onChangeCrop}
            >

            </ReactCrop>

        </div>
    );
}

let useStyle = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        minHeight: "300px"
    }
}))

export default withRouter(CropImageSelector);