import axios from 'axios'

import { getAuthorizedRequestConfigurationUser } from '../helpers/RequestHelper'
import { combineUsers } from '../helpers/UserHelper'
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED"
export const SET_LOGGED_USER = "SET_LOGGED_USER"
export const IS_LOADING_USER_PROFILE = "IS_LOADING_USER_PROFILE"
export const IS_SAVING_USER = "IS_SAVING_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CLEAN_VIEW_USER_PROFILE = "CLEAN_VIEW_USER_PROFILE";
export const ADD_ROLE_PROFILE = "ADD_ROLE_PROFILE";
export const DELETE_ROLE_PROFILE = "DELETE_ROLE_PROFILE";
export const EXECUTING_LOGOUT = "EXECUTING_LOGOUT";
export const SET_VALIDATING_PASSWORD = "SET_VALIDATING_PASSWORD";
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const EXECUTE_LOGOUT = 'EXECUTE_LOGOUT'

const setLoggedProfile = (user) => {
    return {
        type: SET_LOGGED_USER,
        user
    }
}

const setIsLoadingUser = status => {
    return {
        type: IS_LOADING_USER_PROFILE,
        status
    }
}

export const loadUserProfile = () => {
    return function (dispatch, getState) {
        dispatch(setIsLoadingUser(true))
        let user = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(user);


        let userId = user.profile.sub;
        let identityEndPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/${userId}`;
        let finalDispositionEndPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/${userId}`;

        return axios.all([
            axios.get(identityEndPoint),
            axios.get(finalDispositionEndPoint, config)
        ])
            .then(([identityResponse, finalDispositionResponse]) => {
                let loggedUser = combineUsers(identityResponse.data, finalDispositionResponse.data);
                dispatch(setLoggedProfile(loggedUser))
            })
            .catch(error => {
                console.error('Error loading logger user profile', error)
                dispatch(setIsLoadingUser(false))
                return Promise.resolve(null);
            });
    }
}

const setIsSavingUser = status => {
    return {
        type: IS_SAVING_USER,
        status
    }
}

const updateUserProfile = user => {
    return {
        type: UPDATE_USER_PROFILE,
        user
    }
}

export const uploadLogoUser = (userId, fileBase64) => {
    return function (dispatch, getState) {
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/${userId}/avatar`
        let data = { fileBase64 }
        let userDataFromIdentity = null;

        return axios.put(endPoint, data, null)
            .then((result) => {
                userDataFromIdentity = result.data;
                let user = getState().oidc.user;
                let config = getAuthorizedRequestConfigurationUser(user)
                let endPoint = `${process.env.REACT_APP_INVOICE_BACKEND_URL}/api/users/${userId}/profile`;
                return axios.get(endPoint, config)
            })
            .then((serverResponse) => {
                let updateUser = null;
                if (serverResponse.data) {
                    updateUser = {
                        id: userDataFromIdentity.id,
                        userName: userDataFromIdentity.user_name,
                        name: userDataFromIdentity.full_name,
                        email: userDataFromIdentity.email,
                        userType: serverResponse.data.userType,
                        avatarUrl: userDataFromIdentity.avatar_url,
                        roles: serverResponse.data.roles,
                        chainId: serverResponse.data.chainId,
                    }
                }
                dispatch(setIsSavingUser(false));
                dispatch(updateUserProfile(updateUser))
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error uploading user avatar", error)
                return Promise.reject()
            })
    }
}

export const executeLogoutCommand = () => {
    return {
        type: EXECUTE_LOGOUT
    }
}


function setValidatingPassword(status) {
    return {
        type: SET_VALIDATING_PASSWORD,
        status
    }
}

const setAccessToken = (accessToken, userProfile) => ({
	type: SET_ACCESS_TOKEN,
	accessToken,
	userProfile,
})

export const confirmUserPassword = password => {
    return function (dispatch, getState) {
        dispatch(setValidatingPassword(true));
        // Send to the back end
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/credentialsconfirm`;
        let userToken = getState().oidc.user.profile.sub;
        let data = {
            UserId: userToken,
            UserPassword: password
        }
        return axios.post(endPoint, data)
            .then(serverResponse => {
                dispatch(setValidatingPassword(false));
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                console.error("Error on validating password", error);
                dispatch(setValidatingPassword(false))
                return Promise.reject('500');
            })
    }
}

/***
 * Execute login
 */
 export const executeLoging = (userName, password) => {
	return async (dispatch, getState) => {
		try {
			dispatch(setValidatingPassword(true))
			let accessTokenResult = await getUserAccessToken(userName, password)
			let userProfileResult = await getUserProfile(accessTokenResult.data.access_token)
			dispatch(setAccessToken(accessTokenResult.data, userProfileResult.data))
		} catch (error) {
			if (error.response && error.response.status === 400) {
				console.error("Invalid user's credentials")
				return Promise.reject('Credenciales de usuario inválidas')
			} else {
				console.error('Error connecting with identity server')
				return Promise.reject('Error de conexión con el servidor de identidades')
			}
		} finally {
			dispatch(setValidatingPassword(false))
		}
	}
}

/**
 * Execute logout
 */
 export const executeLogout = () => {
	return (dispatch, getState) => {
		dispatch(executeLogoutCommand())
		return Promise.resolve()
	}
}

const getUserAccessToken = (userName, password) => {
	const encodePassword = (pass) => encodeURIComponent(pass)

	let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}identity/connect/token`
	let body = `grant_type=password&username=${userName}&password=${encodePassword(
		password
	)}&scope=openid+profile+userprofile`
	let headers = {
		'Content-type': 'application/x-www-form-urlencoded',
		Authorization: 'Basic YXBzeXMuZWZlbXNhLnByZXN1cHVlc3Rvcy5yZXNvdXJjZW93bmVydXNlcjpCbHVlamF5MDQyMSM=',
	}
	return axios.post(endPoint, body, { headers })
}

const getUserProfile = (accessToken) => {
	let identityUserInfoEndpoint = `${process.env.REACT_APP_IDENTITY_SERVER}identity/connect/userinfo`
	let userInfoHeaders = {
		headers: { Authorization: `Bearer ${accessToken}` },
	}
	return axios.get(identityUserInfoEndpoint, userInfoHeaders)
}
