export const AppRoles = {
    ADMINISTRATOR: 'Administrator',
    SALES_MANAGER: 'Sales',
    ROLE_SERVICE_INTERNAL: 'ServicesInternal',
    ROLE_DOCUMENTS_OPERATIVES: 'DocumentsOperatives',
    CUSTOMER_ADMINISTRATOR: 'CustomerAdministrator',
    CUSTOMER_SERVICE_APPLICANT: "ServiceApplicant",
    CUSTOMER_GOVERNMENT: "ServiceGovernment",
    CUSTOMER_VISUALIZATOR: "ServiceVisualizator",
    CUSTOMER_VISUALIZATOR_NOTIFICATOR: "ServiceVisualizatorNotificator",
    CARRIER: "Carrier"
}

export const UserTypes = {
    ACTIVE_DIRECTORY: 'ActiveDirectoryUser',
    NON_ACTIVE_DIRECTORY: 'NonActiveDirectoryUser'
}

export const SupplierTypes = {
    CARRIER: 1,
    DISPOSITION: 2
}

export const PositionPlacement = {
    END: 'end',
    START: 'start',
    TOP: 'top',
    BOTTOM: 'bottom'
}

export const CustomerCodes = {
    KOF_CODE: '1210',
    BONAFONT_CODE: '1975',
    HEINEKEN_CODE: '1467',
    PEÑAFIEL_CODE: '6364',
    IMBERA_CODE: '15188',
    RED_BULL_CODE: '8174',
    DANONE_CODE: '4824',
}

export const DispositionRequestStatus = {
    REQUESTED: 1,
    CONFIRMED: 2,
    PROGRAMMED: 3,
    DELIVERED_TO_PLANT: 4,
    IN_PROCESS: 5,
    FINISHED: 6,
    CANCEL: 7,
    LIBERATED: 8
}