/** Redux section imports */
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from './session/oidcReducer';
import sessionReducer from './session/SessionReducer';
import userReducer from './users/UserReducer';
import customerReducer from './customers/CustomerReducer';
import supplierReducer from './suppliers/SupplierReducer';
import documentsReducer from './operatingDocuments/DocumentReducer';
import reportsReducer from './reports/ReportReducer';
import dispositionRequestsReducer from './dispositionRequests/DispositionRequestsReducer';
import branchofficeReducer from './branchoffice/BranchOfficeReducer';

export default combineReducers({
    oidc: oidcReducer,
    session: sessionReducer,
    users: userReducer,
    customers: customerReducer,
    suppliers: supplierReducer,
    documents: documentsReducer,
    reports: reportsReducer,
    dispositionRequests: dispositionRequestsReducer,
    branchoffices: branchofficeReducer
 });
 