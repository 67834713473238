/**
 * Get the header for an authorized request 
 * @param {*} user 
 */
export const getAuthorizedRequestConfigurationUser = user => {
    if(user){
        let configuration = {
            headers:{
                "Accept": "application/json", 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`
            }
        }
        return configuration;
    }
}

/**
 * Get the header for an authorized request 
 * and add parameters like object
 * this is used to send data to delete request
 * @export
 * @param {*} user
 * @param {*} params
 * @returns
 */
export function getAuthorizedRequestConfigurationUserParameters(user, data) {
    if (user) {
        let configuration = {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${user.access_token}`,
            },
            data
        }
        return configuration;
    }
}