export const RequestStatus = {
	PENDING_AUTH: 0,
	REQUESTED: 1,
	CONFIRMED: 2,
	SCHEDULED: 3,
	DELIVERED: 4,
	IN_PROCESS: 5,
	COMPLETED: 6,
	CANCEL: 7,
	LIBERATED: 8
}

const allStatus = [
	{ label: 'Por autorizar', key: 0 },
	{ label: 'Solicitada', key: 1 },
	{ label: 'Confirmada ', key: 2 },
	{ label: 'Programada', key: 3 },
	{ label: 'Entregada a planta', key: 4 },
	{ label: 'En proceso', key: 5 },
	{ label: 'Por terminar', key: 6 },
	{ label: 'Cancelada', key: 7 },
	{ label: 'Terminada', key: 8 }
]

/***
 * Function to get all status
 */
export const getStatusRequest = () => {
	return allStatus
}

/**
 * Function to get description of status of a disposition request by id
 * @param {*} statusId
 */
export function getDispositionRequestStatusDescription(statusId) {
	const status = allStatus.find(({ key }) => key === statusId)
	return status ? status.label : 'Desconocido'
}

export const CollectionDateStatus = {
	SEND_TO_CARRIER: 1,
	ACCEPTED_BY_CARRIER: 2,
	ACCEPTED: 3
}

/**
 * Function to get status from disposition item
 * @param {*} statusId
 */
export function getDispositionItemStatus(statusId) {
	switch (Number(statusId)) {
		case 1:
			return 'Abierta'
		case 2:
			return 'Cerrada'
		default:
			return '-'
	}
}
