import { fromJS } from 'immutable';
import * as dispositionRequestActions from './DispositionRequestsActions';

let intialDispositionRequestsState = fromJS({
    //list (index) of requests data
    isLoadingRequests: true,
    dispositionRequests: [],
    pagination: {
        pageNumber: 1,
        total: 1,
        pageSize: 20
    },
    query: "",
 
    //List of equipments data
    isLoadingEquipments: false,
    equipmentsList: [],
    errorsList: [],

    //Single request data
    hastRequests: false,
    isLoadingRequest: true,
    isSavingRequest: true,
    dispositionRequest: null,
    events: [],
    isVerifingDocumentSap: false,
    isDownloadingSbai:false,
    isDonwloadReport:false,
    isLoadingSbais: false,
    sbaiRequests: [],
    sbaipagination: {
        pageNumber: 1,
        total: 1,
        pageSize: 20
    },

})

const dispositionRequestsReducer = (state = intialDispositionRequestsState, action) => {
    switch (action.type) {
        case dispositionRequestActions.IS_LOADING_REQUESTS: {
            return state.merge({
                isLoadingRequests: action.isLoadingRequests
            })
        }
        case dispositionRequestActions.SET_ALL_REQUESTS: {
            let actualPagination = state.get('pagination').toJS()
            actualPagination.pageNumber = action.pagination.pageNumber
            actualPagination.pageSize = action.pagination.pageSize
            return state.merge({
                dispositionRequests: fromJS(action.requests),
                pagination: fromJS(actualPagination),
                query: action.query,
                isLoadingRequests: false, 
                hastRequests: fromJS(action.hastRequest)
            });
        }
        case dispositionRequestActions.IS_LOADING_ALL_SBAIS:{
            return state.merge({
                isLoadingSbais: action.isLoadingSbais
            }) 
        }
        case dispositionRequestActions.SET_ALL_SBAIS: {
            return state.merge({
                sbaiRequests: fromJS(action.sbais),
                sbaipagination: fromJS(action.pagination),
                query: action.query,
                isLoadingSbais: false, 
            })
        }
        case dispositionRequestActions.IS_LOADING_EQUIPMENTS: {
            return state.merge({
                isLoadingEquipments: action.isLoadingEquipments
            })
        }
        case dispositionRequestActions.SET_EQUIPMENTS_LIST: {
            let equipmentsList = fromJS(action.equipmentsList);
            return state.set('equipmentsList', equipmentsList);
        }
        case dispositionRequestActions.SET_ERRORS_LIST: {
            let errorsList = fromJS(action.errorsList);
            return state.set('errorsList', errorsList);
        }
        case dispositionRequestActions.IS_LOADING_REQUEST: {
            return state.merge({
                isLoadingRequest: action.isLoadingRequest
            })
        }
        case dispositionRequestActions.IS_SAVING_REQUEST: {
            return state.merge({
                isSavingRequest: action.isSavingRequest
            })
        }
        case dispositionRequestActions.ADD_REQUEST: {
            let request = fromJS(action.dispositionRequest);
            return state.updateIn(['dispositionRequests'], allRequest => reverseRequests(allRequest, request))
                .set('isSavingRequest', false).set('hastRequests', true);
        }
        case dispositionRequestActions.SET_REQUEST: {
            return state.merge({
                dispositionRequest: fromJS(action.dispositionRequest),
                isLoadingRequest: false,
                isSavingRequest: false
            })
        }
        case dispositionRequestActions.CHANGE_PAGE_NUMBER: {
            return state.setIn(['pagination', 'pageNumber'], action.pageNumber);
        }
        case dispositionRequestActions.UPDATE_REQUEST_DATE:{
            let requestToUpdate = fromJS(action.request);
            return state.updateIn(['dispositionRequests'], allRequest => updateRequest(allRequest, requestToUpdate))
            .set('isSavingRequest', false)
            .set('dispositionRequest', requestToUpdate);
        }
        case dispositionRequestActions.SET_EVENTS_REQUEST_DATES:{
            let events = fromJS(action.events);
            return state.set('events', events);
        }
        case dispositionRequestActions.UPDATE_STATUS_REQUEST:{
            let updateStatus = fromJS(action.status);
            let token = action.token;
            return state.updateIn(['dispositionRequests'], allDispositionRequests => updateRequestStatus(allDispositionRequests, updateStatus, token))
            .setIn(["dispositionRequest", "status"], updateStatus);
        }
        case dispositionRequestActions.ADD_EVIDENCE_IN_RECEPTION:{
            let evidencesToUpdate = fromJS(action.evidences);
            return state.updateIn(['dispositionRequest', 'receptions'], (receptions) => {
                let index = receptions.findIndex(reception => {
                    return reception.get('receptionFolio') === action.receptionFolio
                });
                if (index >= 0) {
                    return receptions.setIn([index,"evidences"], evidencesToUpdate);
                }
                return receptions;
            });
        }
        case dispositionRequestActions.IS_VERIFY_EXIST_DOCUMENT_SAP:{
            return state.merge({
                isVerifingDocumentSap: action.isVerifingDocumentSap
            })
        }
        case dispositionRequestActions.ADD_DOCUMENT_TRAZABILITY_SAP:{
            return state.updateIn(['dispositionRequests'], allRequest => updateRequestDocument(allRequest, action.documentSap, action.folioRequest))
            .set('isSavingRequest', false);
        }
        case dispositionRequestActions.IS_DONWLOAD_REPORT_SBAI:{
            return state.merge({
                isDownloadingSbai: action.isDownloadingSbai
            })
        }
        case dispositionRequestActions.IS_DONWLOAD_REPORT_DATE:{
            return state.merge({
                isDonwloadReport: action.isDownloadingReport
            })
        }
        case dispositionRequestActions.ADD_COORDINATOR_EVIDENCE:{
            let evidencesToUpdate = fromJS(action.evidences);
            return state.updateIn(['dispositionRequests'], allRequest => updateCoordinatorEvidences(allRequest, evidencesToUpdate, action.token))
            .setIn(['dispositionRequest', 'coordinatorEvidences'], evidencesToUpdate)
        }
        case dispositionRequestActions.SET_TOTAL_ALL_REQUEST:{
            return state.setIn(['pagination', 'total'], fromJS(action.pagination.total)).set('hastRequests', fromJS(action.hastRequest));;
        }
        case dispositionRequestActions.ADD_RECILLECTION_IN_REQUEST: {
            let recollection = fromJS(action.recollection);
            return state.updateIn(['dispositionRequest',  'recollectionRequests'], recolections => recolections.push(recollection))
                .set('isSavingRequest', false);
        }
        case dispositionRequestActions.UPDATE_RECOLLECTION_DATE:{
            let recollection = fromJS(action.recollection);
            return state.updateIn(['dispositionRequest','recollectionRequests'], (allRecolections) =>updateRecolectionDate(allRecolections, action.recollection.token, recollection));
		}
        case dispositionRequestActions.UPDATE_ADD_CONTAINS:{
            let updateContaintManifest = fromJS(action.containtManifest);
            return state.updateIn(['dispositionRequests'], allDispositionRequests => updateRequestContainerManifest(allDispositionRequests, updateContaintManifest, action.token))
            .setIn(["dispositionRequest", "containsManifestFile"], updateContaintManifest);
        }
        default: {
            return state;
        }
    }
}


const updateRecolectionDate = (allRecolections, token, recollection) => {
    let indexOfRecolection = allRecolections.findIndex(e => e.get('token') === token);
    if (indexOfRecolection >= 0) {
        return allRecolections.setIn([indexOfRecolection], recollection);
    }
    return allRecolections;
}

const reverseRequests = (allRequests, request) => {
    allRequests = allRequests.push(request);
    allRequests = allRequests.reverse(rd => rd.get('folio') === request.get("folio"));
    return allRequests;
}

const updateRequestDocument = (allRequest, document, folioRequest) => {
    let indexOf = allRequest.findIndex(request => request.get('folio') === folioRequest);
    if (indexOf >= 0) {
        return allRequest.setIn([indexOf,"documentSAP"], document);
    }
    return allRequest;
}

const updateRequest = (allRequest, requestToUpdate) => {
    let indexOf = allRequest.findIndex(request => request.get('token') === requestToUpdate.get('token'));
    if (indexOf >= 0) {
        return allRequest.set(indexOf, requestToUpdate);
    }
    return allRequest;
}

const updateRequestStatus = (allRequest, status, requestToken) => {
    let indexOf = allRequest.findIndex(request => request.get('token') === requestToken);
    if (indexOf >= 0) {
        return allRequest.setIn([indexOf,"estatus"], status);
    }
    return allRequest;
}

const updateRequestContainerManifest = (allRequest, containsManifestFile, requestToken) => {
    let indexOf = allRequest.findIndex(request => request.get('token') === requestToken);
    if (indexOf >= 0) {
        return allRequest.setIn([indexOf,"containsManifestFile"], containsManifestFile);
    }
    return allRequest;
}

const updateCoordinatorEvidences = (allRequest, evidences, requestToken) => {
    let indexOf = allRequest.findIndex(request => request.get('token') === requestToken);
    if (indexOf >= 0) {
        return allRequest.setIn([indexOf,"coordinatorEvidences"], evidences);
    }
    return allRequest;
}

export default dispositionRequestsReducer;