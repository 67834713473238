/** React import section */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { CallbackComponent } from 'redux-oidc'

/** Material UI import section */
import { makeStyles, Grid, Typography } from '@material-ui/core'

import Loader from 'react-spinners/CircleLoader'

/** Helpers import section */
import userManager from '../../store/helpers/UserManager'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		height: '100vh'
	},
	message: {
		color: theme.palette.text.hint
	}
}))

const defaultColor = 'rgba(0, 0, 0, 0.38)'

const CallbackPage = (props) => {
	const classes = useStyles()

	const redirectToHome = (params) => {
		let homeUrl = '/'
		props.history.push(homeUrl)
	}

	return (
		<CallbackComponent
			userManager={userManager}
			successCallback={redirectToHome}
			errorCallback={(error) => {
				console.error(error)
			}}
		>
			<Grid
				container
				spacing={3}
				className={classes.root}
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				<Grid item>
					<Loader
						sizeUnit={'px'}
						size={100}
						margin={'2px'}
						color={defaultColor}
						loading={true}
					/>
				</Grid>
				<Grid item>
					<Typography
						className={classes.message}
						variant='subtitle1'
						style={{ color: defaultColor }}
						gutterBottom
					>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant='h6'>{'Cargando...'}</Typography>
							</Grid>
						</Grid>
					</Typography>
				</Grid>
			</Grid>
		</CallbackComponent>
	)
}

export default withRouter(CallbackPage)
