import React from 'react';
import PropTypes from 'prop-types';

/** Material UI import section */
import {
    makeStyles,
    Box,
    Paper,
    Typography,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    IconButton,
    Tooltip,
    Fab
} from '@material-ui/core';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DownloadIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';

/** Helpers import section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';
import DocumentsTable from './DocumentsTable';

/** Resources import section */
import PdfWarningIcon from '../../../resources/images/pdfWarning.png';
import PdfDangerIcon from '../../../resources/images/pdfDanger.png';
import PdfGrayIcon from '../../../resources/images/pdfGray.png';
import {
    COLOR_INFO_NORMAL,
    COLOR_WHITE,
    COLOR_GRAY,
    COLOR_ALTERNATE_NORMAL,
    COLOR_FOCUS_NORMAL
} from '../../../resources/constants/Colors';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 10,
    }
}));

/**
 * Presentional componet to show the suppliers operational documents 
 * 
 * @param {*} props 
 */
const OperatingDocumentsStack = props => {
    const classes = useStyles();

    const getHeader = () => {
        if (props.showHeader) {
            return (
                <Box display="flex" p={1} alignItems="center" style={{ width: '100%', paddingTop: 0 }}>
                    <Box p={1} flexGrow={1}>
                        <Typography variant="button" className={classes.title}><strong>DOCUMENTOS OPERATIVOS</strong></Typography>
                    </Box>
                    {!props.disabledAdd &&
                        <Box p={1}>
                            <Tooltip title={<Typography variant="caption">Agregar documentos</Typography>} color="secondary">
                                <Fab
                                    size="small"
                                    style={{ backgroundColor: COLOR_INFO_NORMAL }}
                                    onClick={props.openAddFilesPanel}
                                >
                                    <FileIcon className={classes.buttonIcon} />
                                </Fab>
                            </Tooltip>
                        </Box>}
                </Box>
            );
        }
        return null;
    }

    return (
        <Paper
            className={classes.root}
        >
            {getHeader()}

            <DocumentsTable
                documents={props.documents}
                onViewPdfButtonClick={props.onDownloadDocumentClick}
                onUserRowClick={props.onEditDocumentClick}
                noDataMessage={props.noDataMessage}
            />

        </Paper>
    );
}

OperatingDocumentsStack.propTypes = {
    showHeader: PropTypes.bool,
    documents: PropTypes.array.isRequired,
    openAddFilesPanel: PropTypes.func.isRequired,
    onEditDocumentClick: PropTypes.func.isRequired,
    onDownloadDocumentClick: PropTypes.func.isRequired
};

OperatingDocumentsStack.defaultProps = {
    showHeader: true,
    documents: []
}

export default OperatingDocumentsStack;


const useDocumentStyles = makeStyles(theme => ({
    card: {
    },
    cardHeader: {
        margin: 0,
        padding: '2px 5px 2px 5px',
        height: 20,
        backgroundColor: COLOR_GRAY,
        color: COLOR_WHITE
    },
    cardContent: {
        paddingTop: 10,
        paddingBottom: 0,
        textAlign: 'center'
    },
    image: {
        height: 75,
        width: 75
    },
    cardActions: {
        padding: '2px 5px 5px 5px',
        textAlign: 'center'
    },
    downloadButton: {
        backgroundColor: COLOR_ALTERNATE_NORMAL,
        color: COLOR_WHITE,
        width: 25,
        height: 25
    },
    editButton: {
        backgroundColor: COLOR_FOCUS_NORMAL,
        color: COLOR_WHITE,
        width: 25,
        height: 25,
        marginLeft: 5
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardDescription: {
        padding: theme.spacing(1),
        '&:last-child': {
            padding: theme.spacing(1)
        }
    }
}));

/**
 * Presentional component to show an operating document.
 * @param {object} props 
 */
const Document = props => {
    const classes = useDocumentStyles();

    const handleEditButtonClick = event => {
        if (props.onEditButtonClick) {
            props.onEditButtonClick(event)
        }
    }

    const handleDownloadButtonClick = event => {
        if (props.onDownloadButtonClick) {
            props.onDownloadButtonClick(event)
        }
    }

    const getImage = () => {
        const { expirationDate, notificationDate } = props;
        let nowMoment = moment();
        if (!isNullOrEmpty(expirationDate)) {
            let expirationMoment = moment(expirationDate);
            if (expirationMoment.isBefore(nowMoment)) {
                return PdfGrayIcon;
            }
        }
        if (!isNullOrEmpty(notificationDate)) {
            let notificationMoment = moment(notificationDate);
            if (notificationMoment.isBefore(nowMoment)) {
                return PdfDangerIcon;
            }
        }
        return PdfWarningIcon;
    }

    return (
        <Card
            key={props.token}
            className={classes.card}
        >
            <CardHeader
                className={classes.cardHeader}
                title={
                    <Typography variant="overline" display="block" noWrap>
                        {props.fileName}
                    </Typography>
                }
            />
            <CardContent className={classes.cardContent}>
                <img src={getImage()} className={classes.image} alt='jsx-a11y/img-redundant-alt' />
            </CardContent>
            <CardActions
                className={classes.cardActions}
                disableSpacing
            >
                <IconButton
                    size="small"
                    id={props.token}
                    className={classes.downloadButton}
                    onClick={handleDownloadButtonClick}
                >
                    <DownloadIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    size="small"
                    id={props.token}
                    className={classes.editButton}
                    onClick={handleEditButtonClick}
                >
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </CardActions>
        </Card>
    );
}

Document.propTypes = {
    token: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    description: PropTypes.string,
    onEditButtonClick: PropTypes.func.isRequired
};

Document.defaultProps = {
    fileName: 'Archivo',
    description: ''
}