import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

/** Material UI import section */
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CalendarIcon from '@material-ui/icons/EventNote'

/** Resources import section */
import {
	COLOR_PRIMARY_NORMAL,
	COLOR_INFO_NORMAL
} from '../../../resources/constants/Colors'
import RecollectionRequest from './RecollectionRequest'

/**
 * Component recollections list
 * @param {*} props
 */
function RecollectionComponetTab(props) {
	const {
		classes,
		recollections,
		programmeDate,
		branch,
		onOpenRecollectionDialog,
		canBeEdit
	} = props

	/**
	 * Render recolection list
	 */
	return (
		<List className={classes.contentTab}>
			{recollections.map((item) => (
				<RecollectionRequest
					key={item.token}
					editionEnabled={canBeEdit}
					branchOffice={branch}
					onEditButtonClick={onOpenRecollectionDialog}
					{...item}
				/>
			))}
			{recollections.length === 0 && (
				<ListItem divider className={classes.listContent}>
					<ListItemAvatar>
						<Avatar style={{ background: COLOR_INFO_NORMAL }}>
							<CalendarIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Typography component='span' variant='subtitle2'>
								<strong>{'RECOLECCIÓN DE MATERIAL'}</strong>
							</Typography>
						}
						secondary={
							<>
								{programmeDate && (
									<Typography
										component='span'
										variant='subtitle2'
									>
										<strong>
											{'Fecha de recolección: '}
										</strong>
										{programmeDate
											? moment(programmeDate).format(
													'DD/MM/YYYY'
											  )
											: ''}
										<br />
									</Typography>
								)}
								{!programmeDate && (
									<Typography
										component='span'
										variant='subtitle2'
									>
										<strong>
											{'Sin fecha de recolección'}
										</strong>
										{''}
										<br />
									</Typography>
								)}
								{programmeDate && (
									<Typography
										component='span'
										variant='subtitle2'
									>
										<strong>{'Sucursal: '}</strong>
										{branch}
									</Typography>
								)}
							</>
						}
					/>
				</ListItem>
			)}
		</List>
	)
}

RecollectionComponetTab.propTypes = {
	recollections: PropTypes.array,
	programmeDate: PropTypes.string,
}

RecollectionComponetTab.defaultProps = {
	recollections: [],
	programmeDate: '',
}

const useStyles = () => ({
	contentTab: {
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '55vh'
	},
	listContent: {
		borderLeft: `3px solid ${COLOR_PRIMARY_NORMAL}`,
		margin: '10px'
	}
})

export default withStyles(useStyles)(RecollectionComponetTab)
