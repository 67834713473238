/** Colors constants */

/** Color White */
export const COLOR_WHITE = "#FFFFFF";

/** Color Primary Azul */
export const COLOR_PRIMARY_NORMAL = "#545CD8";
export const COLOR_PRIMARY_PRESSED = "#2C35C0";
export const COLOR_PRIMARY_LIGHT = "#E0F3FF";

/** Color Secondary Gris */
export const COLOR_SECONDARY_NORMAL = "#6C757D";
export const COLOR_SECONDARY_PRESSED = "#4E555B";
export const COLOR_GRAY = "#A4AFB7";
export const COLOR_LIGHT_GRAY = "#EEEEEE";

/** Color Success Verde */
export const COLOR_SUCCESS_NORMAL = "#009245";
export const COLOR_SUCCESS_PRESSED = "#004521";
export const COLOR_SUCCESS= "#3AC47D";
export const COLOR_EVIDENCES= "#3DB39E";

/** Color Info Azul claro */
export const COLOR_INFO_NORMAL = "#30B1FF";
export const COLOR_INFO_PRESSED = "#0095EF";

/** Color Warning Amarillo */
export const COLOR_WARNING_NORMAL = "#CECC1F";
export const COLOR_WARNING_PRESSED = "#81800A";

/** Color Danger Rojo */
export const COLOR_DANGER_NORMAL = "#C1272D";
export const COLOR_DANGER_PRESSED = "#740C10";

/** Color Focus Marron */
export const COLOR_FOCUS_NORMAL = "#444054";
export const COLOR_FOCUS_PRESSED = "#272430";

/** Color Alternate Morado */
export const COLOR_ALTERNATE_NORMAL = "#83588A";
export const COLOR_ALTERNATE_PRESSED = "#5E3F63";
export const COLOR_ALTERNATE_HOVER = "#464A97";
export const COLOR_ALTERNATE_LIGHTER =  "#B6C4FF";

/** Color Light */
export const COLOR_LIGTH_NORMAL = "##F8F7F7";
export const COLOR_LIGTH_PRESSED = "#CECECE";

/** Color Dark */
export const COLOR_DARK_NORMAL = "#343A40";
export const COLOR_DARK_PRESSED = "#171A1D";

/**Color yellow */
export const COLOR_YELLOW_NORMAL = "#F7B924";
export const COLOR_YELLOW_PRESSED = "#D49808";

