import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'

/** Material UI import section */
import { Grid, FormGroup, Typography, Divider, Chip, CardContent, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Custom components import section */
import LateralActionPanel from '../../common/LateralActionPanel'
import CustomCheckBox from '../../common/CustomCheckBox'
import TextBox from '../../common/TextBox'
import DropDownZone from '../../common/DropDownZone'
import FileContent from '../../common/Dropzone/FileContent'
import Toaster from '../../common/Toaster'

/** Redux import section */
import { useDispatch, useSelector } from 'react-redux'
import {
	uploadEquipmentsList,
	addDispositionRequest,
	removeEquipmentsList,
	setErrors
} from '../../../store/dispositionRequests/DispositionRequestsActions'
import { getBranchoffices } from '../../../store/branchoffice/BranchOfficeAction'

/** Resources import section */
import {
	COLOR_INFO_NORMAL,
	COLOR_PRIMARY_NORMAL,
	COLOR_DANGER_NORMAL,
	COLOR_INFO_PRESSED
} from '../../../resources/constants/Colors'
import { SERVICE_INTERNAL } from '../../../store/helpers/AppConsts'
import { getExtension } from '../../../store/helpers/FileHelper'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
import { CustomerCodes, PositionPlacement } from '../../../resources/constants/AppConstants'
import { isCustomerKof, getOptions, getOptionsUsers } from '../../../store/helpers/CustomerHelper'
import CommonTemplate from '../../../resources/templates/template_common.xlsx'
import KofTemplate from '../../../resources/templates/template_propimex.xlsm'
import BonafontTemplate from '../../../resources/templates/template_bonafont.xlsx'
import HeinekenTemplate from '../../../resources/templates/template_heineken.xlsx'
import ImberaTemplate from '../../../resources/templates/template_imbera.xlsx'
import PeñafielTemplate from '../../../resources/templates/template_peñafiel.xlsx'
import GeneralTemplate from '../../../resources/templates/template_general.xlsx'
import {
	getEquipmentsSelector,
	getErrorsSelector
} from '../../../store/dispositionRequests/DispositionRequestsSelectors'
import ValidationErrors from './ValidationErrors'

/** Define component's styles */
const useStyles = makeStyles({
	gridItem: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important'
	},
	scrapContainer: {
		display: 'flex',
		flexDirection: 'column'
	}
})

function RequestPanel(props) {
	/** Destructuting properties */
	const { isValidToAddInternal, customers, userManagers } = props

	/** Defines local state */
	const [requestData, setRequestData] = useState(props.requestData)
	const [pdfFile, setPdfFile] = useState([])
	const [scrapFile, setScrapFile] = useState(null)
	const [filesAdded, setFilesAdded] = useState([])
	const [isSavingRequest, setIsSavingRequest] = useState(false)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [selectedBranch, setSelectedCranch] = useState(null)
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [otherBranch, setOtherBranch] = useState(false)
	const [selectedBranchManager, setSelectedBranchManager] = useState(null)
	const [allBranchs, setAllBranchs] = useState([])

	/** Connect with global store */
	const dispatch = useDispatch()
	const loggedUser = useSelector((state) => state.session.get('loggedUser'))
	const isLoadingEquipments = useSelector((state) => state.dispositionRequests.get('isLoadingEquipments'))
	const equipmentsList = useSelector((state) => getEquipmentsSelector(state))
	const errorsList = useSelector((state) => getErrorsSelector(state))
	const isLoadingBranchs = useSelector((state) => state.branchoffices.get('isLoadingbranchs'))

	const classes = useStyles()

	const allCustomers = getOptions(customers)
	const allUserManagers = getOptionsUsers(userManagers, loggedUser)

	/** Load branch  */
	useEffect(() => {
		dispatch(getBranchoffices()).then((branchoffices) => {
			const brachOptions = branchoffices.map((c) => ({
				value: c.code,
				label: c.description
			}))
			setAllBranchs(brachOptions)
		})
	}, [dispatch])

	useEffect(() => {
		if (filesAdded.length > 0) {
			dispatch(
				uploadEquipmentsList(
					filesAdded,
					loggedUser.toJS().customer ? loggedUser.toJS().customer.token : SERVICE_INTERNAL
				)
			)
				.then(({ actNumber, hasEquipments, hasErrors }) => {
					setPdfFile(filesAdded)
					setRequestData((prevState) => ({
						...prevState,
						actNumber: ''
					}))
					if (isNullOrEmpty(actNumber) && isCustomerKof(loggedUser.getIn(['customer', 'code']))) {
						setToasterOptions({
							open: true,
							message: 'El archivo no contiene el número de acta requerido',
							variant: 'error'
						})
					} else if (!hasEquipments) {
						setToasterOptions({
							open: true,
							message: 'El archivo no contiene información de equipos',
							variant: 'error'
						})
					} else if (hasErrors) {
						setToasterOptions({
							open: true,
							message:
								'El archivo contiene algunos errores, revise el panel lateral para ver mas detalles.',
							variant: 'error'
						})
					}
				})
				.catch((error) => {
					setToasterOptions({
						open: true,
						message: !isNullOrEmpty(error)
							? error
							: 'Se generó un error al carga el listado de solicitudes',
						variant: 'warning'
					})
				})
		}
		if (filesAdded.length === 0 && pdfFile.length > 0) {
			dispatch(removeEquipmentsList()).then(() => {
				setPdfFile([])
				setRequestData((prevState) => ({
					...prevState,
					actNumber: ''
				}))
			})
		}
	}, [dispatch, filesAdded, loggedUser, pdfFile.length])

	const canBeSaved = () => {
		if (!requestData) return false
		if (!selectedBranch) return false
		if (isValidToAddInternal && !selectedCustomer) return false
		if (!selectedBranchManager) return false
		if (isNullOrEmpty(requestData.serviceType) || requestData.serviceType === '0') return false
		if (
			isNullOrEmpty(requestData.street) ||
			isNullOrEmpty(requestData.numberStreet) ||
			isNullOrEmpty(requestData.suburb) ||
			isNullOrEmpty(requestData.zipCode) ||
			isNullOrEmpty(requestData.location) ||
			isNullOrEmpty(requestData.town)
		)
			return false
		if (requestData.scrapContained && !scrapFile) return false
		if (equipmentsList.length <= 0) return false
		if (errorsList.length > 0) return false
		return true
	}

	const onClose = () => {
		setSelectedCranch(null)
		dispatch(removeEquipmentsList())
			.then(() => {
				setPdfFile([])
				setRequestData(null)
			})
			.finally(() => props.onClose())
	}

	const onSaveButtonClick = () => {
		setIsSavingRequest(true)
		dispatch(
			addDispositionRequest(
				requestData,
				selectedBranch,
				filesAdded,
				loggedUser.toJS(),
				selectedCustomer,
				selectedBranchManager,
				scrapFile
			)
		)
			.then(() => {
				setIsSavingRequest(false)
				onClose()
			})
			.catch((error) => {
				setIsSavingRequest(false)
				setToasterOptions({
					open: true,
					message: 'El archivo contiene información con series ya agregadas',
					variant: 'warning'
				})
			})
	}

	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	const onPropertyCheckChange = (event) => {
		let { name, checked, value } = event.target
		let addressProps
		if (value === '1' && checked) {
			addressProps = {
				street: 'AV. TOMAS ALBA EDISON',
				numberStreet: '66-2',
				suburb: 'ZONA INDUSTRIAL VALLE DE ORO',
				location: 'SAN JUAN',
				town: 'QUERÉTARO, MÉXICO',
				zipCode: '76803'
			}
		} else {
			addressProps = {
				street: 'AV. TOMAS ALBA EDISON',
				numberStreet: '66-2',
				suburb: 'ZONA INDUSTRIAL VALLE DE ORO',
				location: 'SAN JUAN',
				town: 'QUERÉTARO, MÉXICO',
				zipCode: '76803'
			}
		}
		setRequestData({
			...requestData,
			[name]: checked ? value : 0,
			...addressProps
		})
	}

	const onScrapContainedChange = (event) => {
		const { name, checked } = event.target
		setRequestData({
			...requestData,
			[name]: checked
		})
		if (!checked) {
			setScrapFile(null)
		}
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileId) => {
		let index = filesAdded.findIndex((attachment) => attachment.id === fileId)
		if (index !== -1) {
			let newListFiles = filesAdded.filter((file) => {
				return file.id !== fileId
			})
			setFilesAdded(newListFiles)
		}
		dispatch(setErrors([]))
	}

	const getTemplate = (customerCode) => {
		switch (customerCode) {
			case CustomerCodes.KOF_CODE:
				return KofTemplate
			case CustomerCodes.BONAFONT_CODE:
				return BonafontTemplate
			case CustomerCodes.HEINEKEN_CODE:
				return HeinekenTemplate
			case CustomerCodes.PEÑAFIEL_CODE:
				return PeñafielTemplate
			case CustomerCodes.IMBERA_CODE:
				return ImberaTemplate
			case SERVICE_INTERNAL:
				return GeneralTemplate
			default:
				return CommonTemplate
		}
	}

	function renderTextBox(name, label, value, disabled, active, datacy) {
		return (
			<TextBox
				disabled={disabled}
				active={active}
				inactiveTitlePosition={PositionPlacement.TOP}
				label={label}
				fullWidth={true}
				margin='dense'
				name={name}
				onChange={(event) => onPropertyChange(event)}
				value={value ? value[name] : ''}
			/>
		)
	}

	/**
	 * On seelct branch
	 */
	const onBranchOfficeChange = (branch) => {
		setSelectedCranch(branch)
		if (branch && branch.value === '0000') {
			setOtherBranch(true)
		} else {
			setOtherBranch(false)
		}
	}

	/**
	 * On seelct customer
	 */
	const onSelectCustomer = (customer) => {
		setSelectedCustomer(customer)
	}

	/**
	 * On seelct branch manager
	 */
	const onSelectBranchManager = (branchManager) => {
		setSelectedBranchManager(branchManager)
	}

	const sendToEos = requestData && requestData['serviceType'] === '1'
	const sendToOtherAddress = requestData && requestData['serviceType'] === '2'
	return (
		<LateralActionPanel
			title={props.title}
			show={props.open}
			onClose={onClose}
			isSaving={isSavingRequest}
			canBeSaved={!canBeSaved()}
			onSaveItem={onSaveButtonClick}
			onCancel={onClose}
			disabled={false}
		>
			<Grid container spacing={2}>
				<Grid item>
					<FormGroup>
						<Typography variant='button'>
							<strong>{'Fecha de la solicitud: '} </strong>
							{moment().format('DD/MM/YYYY')} asdf
						</Typography>
						<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
							{'Selecciona Envio'}
						</Typography>
						<CustomCheckBox
							name='serviceType'
							value={1}
							disabled={false}
							onChange={(event) => onPropertyCheckChange(event)}
							checked={sendToEos}
							label='Los equipos serán enviados a la planta de EOS por el cliente.'
						/>
						<CustomCheckBox
							name='serviceType'
							value={2}
							disabled={false}
							onChange={(event) => onPropertyCheckChange(event)}
							checked={sendToOtherAddress}
							label='Programar recolección en la siguiente dirección.'
						/>
						{sendToOtherAddress && (
							<Card
								style={{
									borderStyle: 'solid',
									borderWidth: '.1em',
									borderRadius: 5,
									borderColor: COLOR_DANGER_NORMAL
								}}
							>
								<CardContent
									style={{
										paddingTop: '.5em',
										paddingBottom: '.5em'
									}}
								>
									<Typography variant='body2' align='center' style={{ color: COLOR_DANGER_NORMAL }}>
										{'Se programará un servicio de recolección a través de un transportista tercero. ' +
											'Esta opción generará costos de acuerdo a la negociación a pagar directamente a la empresa transportadora'}
									</Typography>
								</CardContent>
							</Card>
						)}
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1} justifyContent='space-between'>
						<Grid item xs={12}>
							{isValidToAddInternal && (
								<>
									<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
										{'Cliente'}
									</Typography>
									<Select
										placeholder={'Seleccionar cliente'}
										options={allCustomers}
										onChange={onSelectCustomer}
										value={selectedCustomer}
										styles={{
											menu: (base) => ({
												...base,
												zIndex: 31
											})
										}}
									/>
								</>
							)}

							<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
								{'Sucursal'}
							</Typography>
							<Select
								isLoading={isLoadingBranchs}
								isDisabled={isLoadingBranchs}
								placeholder={'Seleccionar sucursal'}
								options={allBranchs}
								onChange={onBranchOfficeChange}
								value={selectedBranch}
								styles={{
									menu: (base) => ({ ...base, zIndex: 31 })
								}}
							/>
							{otherBranch && (
								<TextBox
									active={true}
									inactiveTitlePosition={PositionPlacement.TOP}
									label={'Otra sucursal'}
									fullWidth={true}
									margin='dense'
									name={'otherBranch'}
									onChange={(event) => {
										onPropertyChange(event)
									}}
									value={requestData ? requestData.otherBranch : ''}
								/>
							)}
							<>
								<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
									{'Sinergia de Sucursal '}
								</Typography>
								<Select
									placeholder={'Seleccionar sinergia de sucursal'}
									options={allUserManagers}
									onChange={onSelectBranchManager}
									value={selectedBranchManager}
									styles={{
										menu: (base) => ({
											...base,
											zIndex: 31
										})
									}}
								/>
							</>
							<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
								<br />
								{'Dirección EOS'}
							</Typography>
						</Grid>
						<Grid item className={classes.gridItem} xs={12} sm={12} md={8} lg={8} xl={8}>
							{renderTextBox('street', 'Dirección', requestData, sendToEos, true, 'txt-address-street')}
						</Grid>
						<Grid item className={classes.gridItem} xs={12} sm={12} md={4} lg={4} xl={4}>
							{renderTextBox(
								'numberStreet',
								'Número',
								requestData,
								sendToEos,
								true,
								'txt-address-number'
							)}
						</Grid>
						<Grid item className={classes.gridItem} xs={12} sm={12} md={8} lg={8} xl={8}>
							{renderTextBox('suburb', 'Colonia', requestData, sendToEos, true, 'txt-address-suburb')}
						</Grid>
						<Grid item className={classes.gridItem} xs={12} sm={12} md={4} lg={4} xl={4}>
							{renderTextBox('zipCode', 'C.P.', requestData, sendToEos, true, 'txt-address-zip-code')}
						</Grid>
						<Grid item className={classes.gridItem} xs={12} sm={12} md={4} lg={4} xl={4}>
							{renderTextBox(
								'location',
								'Localidad',
								requestData,
								sendToEos,
								true,
								'txt-address-locality'
							)}
						</Grid>
						<Grid item className={classes.gridItem} xs={12} sm={12} md={8} lg={8} xl={8}>
							{renderTextBox('town', 'Ciudad', requestData, sendToEos, true, 'txt-address-municipality')}
						</Grid>
						{!isValidToAddInternal && (
							<Grid item xs={12}>
								{renderTextBox('actNumber', 'N° de acta', requestData, false, true, 'acta-number')}
							</Grid>
						)}
						<Grid item xs={12}>
							<Divider />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.scrapContainer}>
						<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
							{'Chatarra'}
						</Typography>
						<CustomCheckBox
							name='scrapContained'
							onChange={onScrapContainedChange}
							checked={requestData && requestData.scrapContained}
							label='¿Se considerará envío de chatarra?'
						/>
						{requestData && requestData.scrapContained && (
							<DropDownZone
								classes={classes}
								multiple={false}
								accept={['.xlsm', '.xlsx', '.eml', '.pdf', '.pps', '.ppsx', '.ppt', '.pptx']}
								message={
									<Typography align='center' variant='subtitle1'>
										{
											'Arrastre el archivo con la información de la chatarra, o haga click para seleccionar el archivo.'
										}
									</Typography>
								}
								onFilesReceived={(files) => {
									if (files.length > 0) {
										setScrapFile(files[0])
									} else {
										setScrapFile(null)
									}
								}}
							/>
						)}
						{scrapFile && (
							<FileContent files={[{ attachment: scrapFile }]} onRemoveFile={() => setScrapFile(null)} />
						)}
					</div>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1} justifyContent='space-between'>
						<Grid item className={classes.gridItem} xs={12}>
							<Grid container spacing={0} justifyContent='flex-end'>
								<Grid item className={classes.gridItem} xs={4}>
									<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
										{'Equipos'}
									</Typography>
								</Grid>
								<Grid item className={classes.gridItem} xs={8}>
									<Typography variant='subtitle1' align='right' style={{ color: COLOR_INFO_PRESSED }}>
										<strong>
											{'TOTAL DE EQUIPOS IMPORTADOS: '} {equipmentsList && equipmentsList.size}
										</strong>
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item className={classes.gridItem} xs={12}>
							<Grid container spacing={0} justifyContent='flex-end' alignItems='center'>
								<Grid item xs={5}>
									<Chip
										component='a'
										size='small'
										rel='noreferrer'
										variant='outlined'
										target='_blank'
										icon={
											<DownloadIcon
												style={{
													color: COLOR_PRIMARY_NORMAL
												}}
											/>
										}
										label={<Typography variant='button'>{'Descargar plantilla'}</Typography>}
										style={{
											color: COLOR_PRIMARY_NORMAL,
											border: 'none',
											cursor: 'pointer'
										}}
										href={getTemplate(
											loggedUser.toJS().customer
												? loggedUser.toJS().customer.code
												: SERVICE_INTERNAL
										)}
									/>
								</Grid>
							</Grid>
						</Grid>

						{errorsList && <ValidationErrors errors={errorsList} />}

						<Grid item className={classes.gridItem} xs={12} sm={12} md={12} lg={12} xl={12}>
							<DropDownZone
								classes={classes}
								multiple={false}
								accept={['.xlsm', '.xlsx', '.eml', '.pdf', '.pps', '.ppsx', '.ppt', '.pptx']}
								isLoadingFile={isLoadingEquipments}
								message={
									<Typography align='center' variant='subtitle1'>
										{
											'Arrastre el archivo con el listado de equipos aquí, o haga click para seleccionar el archivo.'
										}
									</Typography>
								}
								onFilesReceived={(files) => {
									if (files.length > 0) {
										let pdfFileExtension
										const filesAdded = files.map((file) => {
											pdfFileExtension = files.find(
												(fileFound) =>
													getExtension(fileFound.name) === '.xlsm' ||
													getExtension(fileFound.name) === '.xlsx'
											)
											if (pdfFileExtension) {
												return {
													id: file.name,
													attachment: file
												}
											} else {
												return ''
											}
										})
										setFilesAdded(filesAdded)
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							{pdfFile.length > 0 && <FileContent files={pdfFile} onRemoveFile={onRemoveFile} />}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/** Toaster */}
			{toasterOptions.open && <Toaster {...toasterOptions} onClose={() => setToasterOptions({ open: false })} />}
		</LateralActionPanel>
	)
}

export default RequestPanel
