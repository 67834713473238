import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/** Material UI import section */
import { makeStyles } from '@material-ui/core/styles'
import {
    Button,
    DialogTitle,
    TextField,
    Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/** Custom components import section */
import Dialog from '../../common/Dialog'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';
import { COLOR_WHITE, COLOR_SUCCESS_NORMAL } from '../../../resources/constants/Colors';
import { COLOR_PRIMARY_NORMAL } from '../../../resources/constants/Colors';
import { isValidPassword } from '../../../store/helpers/UserHelper';


/** Resources import section */
/**
 *
 *
 * @param {*} props
 * @returns
 */
const EditPasswordUser = props => {
    const classes = useStyle();
    let isUserLoggerUserEdit = (props.loggedUserName === props.editUserName);
    // Define local state
    const [editedPasswordUser, setEditedPasswordUser] = useState({
        actualPassword: "",
        newPassword: "",
        checkPassword: "",
        editUserName: props.editUserName,
        loggedUserName: props.loggedUserName,
    });
    const [values, setValues] = useState({ showPassword: false, showNewPassword: false, showViewActualPassword: false });


    /**Handle view password confirm */
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    /**Handle view password confirm */
    const handleClickNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    /**Handle view actual password */
    const handleClicViewActualPassword = () => {
        setValues({ ...values, showViewActualPassword: !values.showViewActualPassword });
    };

    /**Clic en view password textfiel */
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };



    /**
     * Update the property of the user's password
     * @param {*} property 
     * @param {*} value 
     */
    const updateUserPasswordProperty = (property, value) => {
        let updatePasswordUser = { ...editedPasswordUser };
        updatePasswordUser[property] = value.replace(/\s/g, '');
        setEditedPasswordUser(updatePasswordUser);
    }

    /**
    * canBeSaved
    */
    const canBeSaved = () => {
        /** Check if the user logged in is the user selected to add actual password */
        if (isUserLoggerUserEdit) {
            if (isNullOrEmpty(editedPasswordUser.actualPassword)) return false;
            if (isNullOrEmpty(editedPasswordUser.checkPassword)) return false;
            if (!editedPasswordUser.actualPassword || editedPasswordUser.actualPassword.length < 6) return false;
            if (!editedPasswordUser.checkPassword || editedPasswordUser.checkPassword.length < 6) return false;
            if (editedPasswordUser.newPassword !== editedPasswordUser.checkPassword) return false;
            if (editedPasswordUser.actualPassword === editedPasswordUser.newPassword) return false;
            if(!(isValidPassword(editedPasswordUser.newPassword))) return false;

        }
        else {
            if (isNullOrEmpty(editedPasswordUser.checkPassword)) return false;
            if (!editedPasswordUser.checkPassword || editedPasswordUser.checkPassword.length < 6) return false;
            if (editedPasswordUser.newPassword !== editedPasswordUser.checkPassword) return false;
            if(!(isValidPassword(editedPasswordUser.newPassword))) return false;
        }
        return true;
    }

    const onConfirmUpdatePassword = () => {
        if (props.onUpdatePassword) {
            props.onUpdatePassword(editedPasswordUser);
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            isSaving={props.isSaving}
            maxWidth="xs"
            header={
                <DialogTitle>
                    {<Typography variant="h6" style={{color:COLOR_PRIMARY_NORMAL, marginLeft:"-20px"}}><strong>{"Cambiar contraseña".toUpperCase()}</strong></Typography>}
                </DialogTitle>
            }
            actions={
                <Fragment>
                    <Button
                        key={"btn-confirm"}
                        data-cy={"btn-confirm"}
                        className={classes.buttonSave}
                        disabled={props.isSaving || !canBeSaved()}
                        onClick={onConfirmUpdatePassword}
                    >
                        Confirmar
                </Button>
                </Fragment>
            }
        >
            {/** Check if the user logged in is the user selected to add actual password */}
            {isUserLoggerUserEdit && <TextField fullWidth
                label={"Contraseña actual"}
                data-cy="txt-current-password"
                className={classes.textFieldMargin}
                value={editedPasswordUser.actualPassword}
                onChange={(event) => { updateUserPasswordProperty("actualPassword", event.target.value) }}
                name="password"
                autocomplete="user-password"
                variant="outlined"
                type={values.showViewActualPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={handleClicViewActualPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showViewActualPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "text"
                }}
            />}
            {isUserLoggerUserEdit && <span><br/><br/></span>}

            <TextField label={"Nueva contraseña"} fullWidth
                data-cy="txt-new-password"
                variant="outlined"
                className={classes.textFieldMargin}
                value={editedPasswordUser.newPassword}
                onChange={(event) => { updateUserPasswordProperty("newPassword", event.target.value) }}
                type={values.showNewPassword ? 'text' : 'password'}
                helperText={"La contraseña debe contener mínimo 6 caracteres (may\u00fasculas, min\u00fasculas, n\u00fameros y caracteres especiales)"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={handleClickNewPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "text"
                }}
            /><br /><br />

            <TextField label={"Confirmar nueva contraseña"}
                variant="outlined"
                data-cy="txt-confirm-password"
                className={classes.textFieldMargin}
                value={editedPasswordUser.checkPassword}
                onChange={(event) => { updateUserPasswordProperty("checkPassword", event.target.value) }}
                type={values.showPassword ? 'text' : 'password'}
                helperText={"La contraseña debe contener mínimo 6 caracteres (may\u00fasculas, min\u00fasculas, n\u00fameros y caracteres especiales)"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "text"
                }}
            /><br /><br />

        </Dialog>

    );
}

let useStyle = makeStyles((theme) => ({
    buttonSave: {
        padding: "10px",
        background: COLOR_SUCCESS_NORMAL, "&:hover": {
            backgroundColor: "#2B935E"
        },
        "&.Mui-disabled":{
            color: COLOR_WHITE,
            opacity: .75,
        },
        color: COLOR_WHITE,
        top: ".3px",
        left: "-15px",
        width: "110px",
        height: "36px"
    },
    cssLabel: {
    },    
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
        }
    },   
    cssFocused: {
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `${COLOR_PRIMARY_NORMAL} !important`,
        color: `${COLOR_PRIMARY_NORMAL} !important`
    }
}))

export default withRouter(EditPasswordUser);