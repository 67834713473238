import { createSelector } from 'reselect'
import { toSafeObject, toSafeArray } from '../../store/helpers/ImmutableHelper'
import { sortBy } from '../../store/helpers/SortingHelper'

/**
 * Create a selector for internal sort criteria
 */
export const queryParametersSelector = createSelector(
	(state) => state.suppliers.get('suppliersQueryParameters'),
	(suppliersQueryParameters) => toSafeObject(suppliersQueryParameters)
)

/**
 * Create a selector for the filtered and sorted suppliers
 */
export const suppliersSelector = createSelector(
	(state) => state.suppliers.get('allSuppliers'),
	(state) => state.suppliers.get('suppliersQueryParameters'),
	(suppliers, queryParameters) => {

		let queryObject = toSafeObject(queryParameters)
		const getFilteredSuppliers = (suppliers) => {
			if (queryObject && queryObject.query) { 
				let fixedQuery = queryObject.query.trim().toLowerCase()
				return suppliers.filter((x) => {
					return x.code.trim().toLowerCase().startsWith(fixedQuery) ||
                    x.name.trim().toLowerCase().includes(fixedQuery) ||
                    x.rfc.trim().toLowerCase().includes(fixedQuery)
				})	
			} else 
				return suppliers
		}

		const getSortedUsers = (supplier) => {
			if (queryObject && queryObject.sortBy) {
				return sortBy(supplier, queryObject.sortBy, queryObject.sortDirection)
			} else return supplier
		}

		let result = toSafeArray(suppliers)
		result = getFilteredSuppliers(result)
        result = getSortedUsers(result)		
		return result
	}
)
