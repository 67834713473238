import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

/** Material UI import section */
import { Typography } from '@material-ui/core'

/** Custom componets import section */
import LateralActionPanel from '../../common/LateralActionPanel'
import TextBox from '../../common/TextBox'
import DropDownZone from '../../common/DropDownZone'
import FileContent from '../../common/Dropzone/FileContent'

/** Resources import section */
import { COLOR_INFO_NORMAL } from '../../../resources/constants/Colors'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
import { getOptions } from '../../../store/helpers/CustomerHelper'

const allowedFileExtensions = ['.doc', '.docx', '.eml', '.pdf', '.pps', '.ppsx', '.ppt', '.pptx', '.xls', '.xlsx'];

const AddingReportPanel = (props) => {
	/** Destructuring properties */
	const { opened, saving, customers, onClose, onSave } = props

	/** Define local state */
	const [reportModel, setReportModel] = useState({
		customer: null,
		comments: ''
	})
	const [attachment, setAttachment] = useState(null)
	const [customerOptions, setCustomerOptions] = useState([])

	/** 
	 * Load customers
	 */
	useEffect(() => {
		const customerOptions = getOptions(customers)
		setCustomerOptions(customerOptions)
	}, [customers])

	/**
	 * Handle selection of some customer
	 */
	const onSelectedCustomerChange = (customer) => {
		setReportModel((prevModel) => ({ ...prevModel, customer }))
	}

	/** 
	 * Handle changing of form properties 
	 */
	const onPropertyChange = (event) => {
		const { name, value } = event.target
		setReportModel((prevModel) => ({ ...prevModel, [name]: value }))
	}

	/** 
	 * Handler changing of file input 
	 */
	const onFilesChange = (files) => {
		if (files.length > 0) {
			setAttachment(files[0])
		} else {
			setAttachment(null)
		}
	}

	/** 
	 * Handle clicking on save button 
	 */
	const handleSaveButtonClick = () => {
		onSave(reportModel, attachment)
	}

	/** 
	 * Determines if form can be saved 
	 */
	const canBeSaved = () => {
		if (isNullOrEmpty(reportModel.comments)) return false
		if (!reportModel.customer) return false
		if (!attachment) return false
		return true
	}

	return (
		<LateralActionPanel
			title='Agregar reporte'
			show={opened}
			isSaving={saving}
			onCancel={onClose}
			onClose={onClose}
			canBeSaved={!canBeSaved()}
			onSaveItem={handleSaveButtonClick}
		>
			<div>
				<Typography
					variant='button'
					style={{ color: COLOR_INFO_NORMAL }}
				>
					Cliente
				</Typography>
				<Select
					placeholder={'Seleccionar cliente'}
					options={customerOptions}
					onChange={onSelectedCustomerChange}
					//value={selectedCustomer}
					styles={{
						menu: (base) => ({ ...base, zIndex: 31 })
					}}
				/>
				<TextBox
					active={true}
					label='Comentarios'
					fullWidth={true}
					margin='dense'
					name='comments'
					type='text'
					onChange={onPropertyChange}
					value={reportModel.comments}
				/>
				<DropDownZone
					datacy='drop-zone-documents-operating'
					//classes={classes}
					multiple={false}
					accept={allowedFileExtensions}
					message={`Extensiones de archivos permitidas: ${allowedFileExtensions.map(ext => ` [${ext}] `).join(',')}`}
					onFilesReceived={onFilesChange}
				/>
				{attachment && (
					<FileContent
						files={[{ attachment }]}
						onRemoveFile={() => setAttachment(null)}
					/>
				)}
			</div>
		</LateralActionPanel>
	)
}

AddingReportPanel.propTypes = {
	opened: PropTypes.bool.isRequired,
	saving: PropTypes.bool.isRequired,
	customers: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired
}

AddingReportPanel.defaultProps = {
	opened: false,
	saving: false,
	customers: [],
	onClose: () => console.warn('[onClose] not defined!'),
	onSave: () => console.warn('[onSave] not defined!')
}

export default AddingReportPanel
