import React from 'react'
import propTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
/** Material UI */
import { makeStyles } from '@material-ui/core/styles'

/**
 * A react-hook-form validated text field
 */
const ValidatedTextField = (props) => {
	const {
		errors,
		name,
		autoFocus,
		label,
		type,
		rules,
		control,
		defaultValue,
		startAdornment
	} = props

	const hasError = () => (errors[name] ? true : false)
	const errorMessage = () => (hasError() ? errors[name].message : null)

	const classes = useDefaultStyles()

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<TextField
					className={classes.textField}
					autoFocus={autoFocus}
					fullWidth
					error={hasError()}
					helperText={errorMessage()}
					label={label}
					type={type}
					{...field}
					InputLabelProps={{
						classes: {
							root: classes.cssLabel,
							focused: classes.cssFocused,
						},
					}}
					InputProps={{
						classes: {
							root: classes.cssOutlinedInput,
							focused: classes.cssFocused,
						},
						inputMode: 'text',
						className: classes.input,
						startAdornment: startAdornment
					}}
				/>
			)}
			defaultValue={defaultValue}
			rules={rules}
		/>
	)
}
ValidatedTextField.propTypes = {
	autoFocus: propTypes.bool.isRequired,
	name: propTypes.string.isRequired,
	label: propTypes.string.isRequired,
	defaultValue: propTypes.string.isRequired,
	rules: propTypes.object,
	control: propTypes.object.isRequired,
	errors: propTypes.object,
}
ValidatedTextField.defaultProps = {
	autoFocus: false,
	hasError: false,
}

const useDefaultStyles = makeStyles((theme) => ({
	cssLabel: {},
	cssFocused: {
		color: '#6C757D !important',
	},
	cssOutlinedInput: {
		color: '#6C757D !important',
	},
	notchedOutline: {
		borderColor: 'red !important',
		borderRadius: 8,
	},
	notchedOutlineSuccess: {
		borderWidth: '1px',
		borderColor: `red`,
		color: '#545CD8 !important',
		borderRadius: 8,
	},
	input: {
		'&.Mui-disabled': {
			color: 'black',
			opacity: 1,
		},
	},
}))
export default ValidatedTextField
