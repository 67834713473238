import React from 'react';
/** Import Material-UI */
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
// import 
import { COLOR_DANGER_NORMAL, COLOR_WHITE } from '../../resources/constants/Colors'

/**
 * Add button component
 *
 * @class AddItemPanel
 * @extends {Component}
 */
const ButtonDelete = props => {
    return (

        <Fab
            size="small"
            disabled={props.disabled}
            style={{ backgroundColor: COLOR_DANGER_NORMAL }}
            onClick={props.onClick}>
            <DeleteIcon style={{ color: COLOR_WHITE }} />
        </Fab>

    )
}
export default (ButtonDelete)