import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogActions, DialogContent, LinearProgress } from '@material-ui/core';
import ButtonDelete from '../common/ButtonDelete'
/**
 * Dialog component
 *
 * @export
 * @class Dialog
 * @extends {Component}
 */
export const DialogView = props => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth = {true}
            maxWidth={props.maxWidth}
        >
            <DialogTitle >
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    
                    {props.header}
                    
                    <ButtonDelete onClick={props.onClose} disabled={props.isSaving} />
                </div>
            </DialogTitle>

            <DialogContent
                style={props.style}
            >
                {props.children}
            </DialogContent>
            {/* <Divider /> */}
            {props.isSaving && <LinearProgress color="secondary" />}
            <DialogActions>
                {props.actions}
            </DialogActions>
        </Dialog>
    );
}

DialogView.propTypes = {
    open: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.string,
    style: PropTypes.shape({
        maxWidth: PropTypes.string,
    }),
    header: PropTypes.element.isRequired,
    children: PropTypes.element,
    actions: PropTypes.element,
};

DialogView.defaultProps = {
    open: false,
    
}

export default (DialogView);