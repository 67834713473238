import React, {  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Material UI */
import { 
    Grid,
    Typography,
    ListSubheader,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import ServiceIcon from '@material-ui/icons/LocalShippingOutlined';

/** Custom component import section */
import LateralActionPanel from '../../common/LateralActionPanel';
import TextBox from '../../common/TextBox';
import {TextBoxFieldRequired} from '../../common/TextBox';

/** Helpers import section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';
import { searchSupplierByCode } from '../../../store/suppliers/SupplierActions';

const AddSupplierPanel = props => {
    const [supplierModel, setSupplierModel] = useState({...props.supplierModel, isTransportService:true});
    
    const dispatch = useDispatch();
    const allSuppliers = useSelector(state => state.suppliers.get('allSuppliers'));

    const onPropertyChange = (property, value) => {
        setSupplierModel({
            ...supplierModel,
            [property]: value
        })
    }

    const onSupplierCodeChange = event => {
        let supplierCode = event.target.value;
        onPropertyChange('code', supplierCode);
        if (supplierCode.length > 1) {
            dispatch(searchSupplierByCode(supplierCode))
            .then(foundSupplier => {
                if (foundSupplier) {
                    foundSupplier.isTransportService = supplierModel.isTransportService;
                    setSupplierModel(foundSupplier);
                }
                else {
                    setSupplierModel({
                        token: '',
                        code: supplierCode,
                        name: '',
                        email: '',
                        taxId: '',
                        isTransportService: true,
                    });
                }
            })
            .catch(() => {
                // Nothing code
            })
        }        
    }

    const onIsTransportServiceChange = event => {
        let { checked } = event.target;
        onPropertyChange('isTransportService', checked);
    }

    const isSupplierAlreadyAdded = () => {
        return allSuppliers.some(u => u.get('token') === supplierModel.token);
    }

    const canBeSaved = () => {
        if (!supplierModel.code || supplierModel.code.length === 0) return false;
        if (isSupplierAlreadyAdded()) return false;
        return true;
    }

    const onSaveButtonClick = () => {
        if (props.onCreateSupplier) {
            props.onCreateSupplier(supplierModel);
        }
        else {
            console.warn("Callback 'onCreateSupplier' not defined!");
        }
    }

    const getCodeTextHelper = () => {
        if (isSupplierAlreadyAdded()) { return (<Typography color="error" variant="caption">{"Ya existe un proveedor con este número"}</Typography>); }
        return null;
    }

    return (
        <LateralActionPanel
            title="Agregar proveedor"
            show={props.open}
            onClose={props.onClose}
            isSaving={props.isSaving}
            canBeSaved={!canBeSaved()}
            onSaveItem={onSaveButtonClick}
            onCancel={props.onClose}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} >
                    <TextBoxFieldRequired
                        autoFocus
                        required
                        autoComplete='none'
                        isSuccess={!isNullOrEmpty(supplierModel.code)}
                        active={true}
                        label="Número de proveedor"
                        fullWidth
                        margin={"dense"}
                        name="code"
                        value={supplierModel.code}
                        onChange={onSupplierCodeChange}
                        helperText={getCodeTextHelper()}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextBox                       
                        disabled
                        active={true}
                        label="Nombre"
                        fullWidth={true}
                        margin="dense"
                        name="name"
                        value={supplierModel.name}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextBox
                        disabled
                        active={true}
                        label="RFC"
                        fullWidth={true}
                        margin="dense"
                        name="taxId"
                        value={supplierModel.taxId}
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 10, marginLeft:"-10px" }}>
                    <List dense subheader={<ListSubheader>Servicios ofrecidos</ListSubheader>} >
                        <ListItem>
                            <ListItemIcon>
                                <ServiceIcon />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label" primary="Servicio de Transporte" />
                            <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                onChange={onIsTransportServiceChange}
                                checked={supplierModel.isTransportService} 
                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                            />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </LateralActionPanel>
    )
}

AddSupplierPanel.defaultProps = {
    supplierModel: {
        token: '',
        code: '',
        name: '',
        taxId: '',
        isTransportService: ''
    }
}

export default AddSupplierPanel;  