import React from 'react';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import propTypes from 'prop-types';

/**Import material UI sections */
import {
    Fab,
    Typography,
    makeStyles
} from '@material-ui/core';

/** Custom components import section */
import DataList from '../../common/DataList/DataList';
import ActiveSupplierIcon from '@material-ui/icons/Check';
import InactiveSupplierIcon from '@material-ui/icons/Block';
/**
 * Customers table presentional component
 */
const CustomersTable = props => {
    const classes = useStyles();

    const onRenderCellItem = (dataSource, item) => {
        switch (dataSource) {
            case "name":
                return <Typography variant="overline">{item.name}</Typography>
            case "userName":
                return <Typography variant="overline">{item.userName}</Typography>
            case "email":
                return <Typography variant="overline">{item.email}</Typography>
            case "active":
                return <Fab
                    size="small"
                    disabled
                    className={item.active ? classes.button : classes.inactiveButton}
                >
                    {item.active ?
                        <ActiveSupplierIcon className={classes.activeIcon} />
                        : <InactiveSupplierIcon className={classes.activeIcon} />
                    }
                </Fab>
            default:
                return null
        }
    }

    const onShowUsersButtonClick = itemToken => {
        if (props.onShowCustomerUsersButtonClick) {
            props.onShowCustomerUsersButtonClick(itemToken);
        }
    }

    const onSortingCriteriaChange = (dataSource, sortCriteria) => {
        if (props.onSortingCriteriaChange) {
            props.onSortingCriteriaChange(dataSource, sortCriteria);
        }
    }

    return (
        <DataList
            data={props.customers}
            configuration={customersTableConfiguration}
            onRenderCellItem={onRenderCellItem}
            onRowClick={(event) => onShowUsersButtonClick(event.token)}
            onSortingCriteriaChange={onSortingCriteriaChange}
            sortConfig={props.sortConfig}
            noDataMessage={props.noDataMessage}
        />
    )
}

CustomersTable.propTypes = {
    customers: propTypes.array.isRequired,
    onUserClick: propTypes.func
}

CustomersTable.defaultProps = {
    customers: []
}

const customersTableConfiguration = {
    columns: [{
        dataSource: "code",
        header: "Código",
        isSortable: true
    }, {
        dataSource: "name",
        header: "Nombre",
        isSortable: true
    }, {
        dataSource: "active",
        header: "Acceso concedido"
    }]
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 35,
        height: 35,
        backgroundColor: " #3AC47D",
        "&.Mui-disabled": {
            backgroundColor: " #3AC47D",
        }
    },
    inactiveButton: {
        width: 35,
        height: 35,
        backgroundColor: "#D92550",
        "&.Mui-disabled": {
            backgroundColor: "#D92550",
        },
    },
    activeIcon: {
        color: "white"
    },
}))

export default withImmutablePropsToJS(CustomersTable)