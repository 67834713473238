import { fromJS } from 'immutable';
import { 
    SET_IS_AUTHENTICATED, 
    SET_LOGGED_USER, 
    IS_LOADING_USER_PROFILE,
    EXECUTE_LOGOUT,
} from './SessionActions'

let initialSessionState = fromJS({
    isLoadingUser: false,
    isAuthenticated: false,
    loggedUser: null,
    isExecutingLogout: false
})

const sessionReducer = (state= initialSessionState, action) => {
    switch (action.type) {
        case SET_IS_AUTHENTICATED: {
            return state.set('isAuthenticated', action.isAuthenticated);
        }
        case SET_LOGGED_USER:{
            let user = fromJS(action.user);
            return state.merge({
                loggedUser: user,
                isLoadingUser: false
            });
        }
        case IS_LOADING_USER_PROFILE:{
            return state.set('isLoadingUser', action.status);
        }
        case EXECUTE_LOGOUT:{
            //return state.set('isExecutingLogout', true);
            return state;
        }
        default: {
            return state;
        }
    }
}

export default sessionReducer