import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/** Material UI */
import { Grid, Typography } from '@material-ui/core'

/** Custom component import section */
import LateralActionPanel from '../../common/LateralActionPanel'
import TextBox from '../../common/TextBox'
import { TextBoxFieldRequired } from '../../common/TextBox'

/** Actions import section */

/** Helpers impor section */
import { toSafeArray } from '../../../store/helpers/ImmutableHelper'
import { UserTypes } from '../../../resources/constants/AppConstants'
import { isValidEmail, isNullOrEmpty, isValidPassword } from '../../../store/helpers/StringHelper'
import { findUserFromIdentityByUserName } from '../../../store/users/UserActions'

const AddInternalUserPanel = (props) => {
	const dispatch = useDispatch()

	const [userModel, setUserModel] = useState(props.userModel)
	const [existUserEmailInIdentity, setExistUserEmailInIdentity] = useState(false)
	const allUsers = useSelector((state) => toSafeArray(state.users.get('allUsers')))

	const onPropertyChange = (event) => {
		let property = event.target.name
		let value = event.target.value

		if (property === 'userName' && value.length > 0) {
			dispatch(findUserFromIdentityByUserName(value)).then((result) => {
				if (result !== null) {
					setExistUserEmailInIdentity(true)
					setUserModel({
						...userModel,
						name: result.name,
						userName: result.userName,
						password: '',
						passwordConfirmaiton: ''
					})
				} else {
					setExistUserEmailInIdentity(false)
				}
			})
		}
		if (property === 'phoneNumber' && value.length > 0) {
			let regexPhoneNumber = /^([0-9]+$)/
			if (!regexPhoneNumber.test(value)) return
		}

		setUserModel({
			...userModel,
			[property]: value
		})
	}

	const isUserAlreadyAdded = () => {
		return allUsers.some((u) => u.userName === userModel.userName)
	}

	const onSaveButtonClick = () => {
		if (props.onCreateUser) {
			props.onCreateUser(userModel)
		} else {
			console.warn("Callback 'onCreateUser' not defined!")
		}
	}

	const matchPasswords = () => {
		return (
			isValidPassword(userModel.password) &&
			userModel.password === userModel.passwordConfirmaiton
		)
	}

	const getUserNameTextHelper = () => {
		if (existUserEmailInIdentity === true && !isUserAlreadyAdded()) {
			return (
				<Typography color='secondary' variant='caption'>
					{
						'Ya existe un usuario registrado con este email en otras aplicaciones, solo de clic en guardar para concederle acceso a esta plataforma'
					}
				</Typography>
			)
		}
		if (!isValidEmail(userModel.userName)) {
			return (
				<Typography color='error' variant='caption'>
					{'Escriba un correo válido'}
				</Typography>
			)
		}
		if (isUserAlreadyAdded()) {
			return (
				<Typography color='error' variant='caption'>
					{'Este usuario ya ha sido agregado'}
				</Typography>
			)
		}
		return null
	}

	const getPasswordTextHelper = () => {
		if (!isNullOrEmpty(userModel.passwordConfirmaiton) && !matchPasswords()) {
			return (
				<Typography color='error' variant='caption'>
					{'Las contraseñas no coinciden'}
				</Typography>
			)
		}
		return null
	}

	const canBeSaved = () => {
		if (
			!existUserEmailInIdentity ||
			(!existUserEmailInIdentity && isUserAlreadyAdded) ||
			isUserAlreadyAdded
		) {
			if (!isValidEmail(userModel.userName)) return false
			if (!userModel.name || userModel.name.length === 0) return false
			if (isUserAlreadyAdded()) return false
			if (!isValidPassword(userModel.password)) return false
			if (!isValidPassword(userModel.passwordConfirmaiton)) return false
			if (!matchPasswords()) return false
			return true
		} else {
			return true
		}
	}

	return (
		<LateralActionPanel
			title='Agregar usuario'
			show={props.open}
			onClose={props.onClose}
			isSaving={props.isSaving || props.isFindingUser}
			canBeSaved={!canBeSaved()}
			onSaveItem={onSaveButtonClick}
			onCancel={props.onClose}
		>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<TextBoxFieldRequired
						isSuccess={!isNullOrEmpty(userModel.userName)}
						required
						autoFocus
						fullWidth
						active={true}
						autoComplete='@nel'
						label='Ingresa el correo electrónico'
						margin={'dense'}
						name='userName'
						value={userModel.userName}
						onChange={onPropertyChange}
						helperText={getUserNameTextHelper()}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextBoxFieldRequired
						disabled={existUserEmailInIdentity}
						isSuccess={!isNullOrEmpty(userModel.name)}
						required
						fullWidth
						active={true}
						label='Nombre'
						margin='dense'
						name='name'
						value={userModel.name}
						onChange={onPropertyChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextBox
						disabled={existUserEmailInIdentity}
						active={true}
						fullWidth
						label='Teléfono'
						margin='dense'
						name='phoneNumber'
						value={userModel.phoneNumber}
						onChange={onPropertyChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextBoxFieldRequired
						disabled={existUserEmailInIdentity}
						isSuccess={!isNullOrEmpty(userModel.password)}
						required
						fullWidth
						active={true}
						autoComplete='off'
						label='Contraseña'
						margin={'dense'}
						type='password'
						name='password'
						onChange={onPropertyChange}
						value={userModel.password}
						helperText={
							'La contraseña debe contener mínimo 6 caracteres (incluir may\u00fasculas, min\u00fasculas, n\u00fameros y caracteres especiales).'
						}
					/>
					<br />
				</Grid>
				<Grid item xs={12}>
					<TextBoxFieldRequired
						disabled={existUserEmailInIdentity}
						required
						active={true}
						label='Confirmar contraseña'
						fullWidth={true}
						margin={'dense'}
						type='password'
						name='passwordConfirmaiton'
						onChange={onPropertyChange}
						isSuccess={!isNullOrEmpty(userModel.passwordConfirmaiton)}
						value={userModel.passwordConfirmaiton}
						helperText={getPasswordTextHelper()}
					/>
				</Grid>
			</Grid>
		</LateralActionPanel>
	)
}

AddInternalUserPanel.defaultProps = {
	userModel: {
		userName: '',
		name: '',
		phoneNumber: '',
		password: '',
		passwordConfirmaiton: '',
		type: UserTypes.NON_ACTIVE_DIRECTORY
	}
}

export default AddInternalUserPanel
