import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

/** Custom components import section */
import Layout from '../layout/Layout'
import Toaster from '../common/Toaster'
import Loader from '../common/Loader'
import ProfileHeader from '../common/Profile/ProfileHeader'

/** Material UI components import section */
import { Tabs, Paper, Tab, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { toSafeObject } from '../../store/helpers/ImmutableHelper'
import AddDocumentsPanel from '../operatingDocuments/components/AddDocumentsPanel'
import NoDataMessage from '../common/NoDataMessage'
import { AddDocFake, AddBtnFake } from '../common/FakeButtons'
import EditDocumentPanel from '../operatingDocuments/components/EditDocumentPanel'

import LogoHelp from '../../resources/images/logoHelp.png'
//import useSort from '../../hooks/useSort';
import OperatingDocumentsStack from '../operatingDocuments/components/OperatingDocuments'
import UsersInsupplier from '../suppliers/components/UsersInsupplier'
import FinalDispositionTransfer from '../customers/components/FinalDispositionTransfer'

import { CUSTOMER_TYPE } from '../../store/helpers/AppConsts'
/**Import store section */
import { loadUsers, addExternalUser, activeUser } from '../../store/users/UserActions'
import {
	loadCustomer,
	updateFile,
	uploadAttachments,
	activeCustomer
} from '../../store/customers/CustomerActions'
import { internalUsersSelector } from '../../store/users/UsersSelectors'

const useSupplierProfileStyles = makeStyles({
	root: {
		marginTop: 20,
		width: 1100
	}
})

const Show = (props) => {
	const classes = useSupplierProfileStyles()
	const dispatch = useDispatch()
	let history = useHistory()
	let location = useLocation()
	let customerToken = props.match.params.token
	const internalUser = useSelector(internalUsersSelector)
	const [isLoadingSupplier, setIsLoadingSupplier] = useState(false)
	const [selectedTab, setValueTab] = useState(0)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [isLoadingUsers, setIsLoadingUsersFlag] = useState(true)
	const [users, setLocalUsers] = useState([])
	const [handleOpenAddFilesPanel, setHandleOpenAddFilesPanel] = useState(false)
	const [editDocumentPanelOptions, setEditDocumentPanelOptions] = useState({
		open: false,
		documentModel: null
	})
	const [showAddExternalUserPanel, setShowAddExternalUserPanel] = useState(false)

	const [pageNumber, setPageNumber] = useState(1)
	const [pagination, setPagination] = useState({ pageSize: 10, total: 0 })
	const [sortConfig, setSortConfig] = useState({ by: 'name', criteria: 'desc' })
	const customerProfile = useSelector((state) => toSafeObject(state.customers.get('customer')))
	const isSaving = useSelector((state) => state.customers.get('isSavingCustomer'))

	useEffect(() => {
		let usersPerPage = 10
		let allTotal = internalUser.length
		const indexOfLastUser = pageNumber * usersPerPage
		let indexOfFirtsUser = indexOfLastUser - usersPerPage
		let currentUsers = internalUser.slice(indexOfFirtsUser, indexOfLastUser)
		setLocalUsers(currentUsers)
		setPagination({ pageSize: usersPerPage, total: allTotal })
	}, [internalUser, pagination.total, pageNumber])

	useEffect(() => {
		dispatch(loadCustomer(customerToken)).finally(() => {
			setIsLoadingSupplier(false)
		})
	}, [customerToken, dispatch])

	useEffect(() => {
		setIsLoadingUsersFlag(true)
		setIsLoadingUsersFlag(true)
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.pageNumber) {
			setPageNumber(queryParameters.pageNumber)
		}
		let newSortConfig = {}
		if (!!queryParameters.orderBy) newSortConfig.by = queryParameters.orderBy
		if (!!queryParameters.orderType) newSortConfig.criteria = queryParameters.orderType
		setSortConfig(newSortConfig)

		dispatch(loadUsers(customerToken, null, queryParameters)).finally(() =>
			setIsLoadingUsersFlag(false)
		)
	}, [dispatch, props.match.url, customerToken, location.search])

	const ChangeTabSelected = (event, value) => {
		setValueTab(value)
	}

	/**
	 * On change page number
	 */
	const onChangePageNumber = (page) => {
		setPageNumber(page)
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onUpdateCustomerStatus = (customer, status) => {
		return dispatch(activeCustomer(status, customer))
			.finally(() => {
				if (!status) setValueTab(1)
				else setValueTab(0)
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Error al actualizar la informacion del cliente.',
					variant: 'error'
				})
			})
	}

	/** handle open files*/
	const openAddLateralFilesPanel = () => {
		setHandleOpenAddFilesPanel(true)
	}

	const onDownloadDocumentClick = (event) => {
		let documentToken = event.currentTarget.id
		let urlPdf = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/${documentToken}/download/${customerToken}/customer`
		window.open(urlPdf, '_blank', 'titlebar=no,width=800,height=800,location=no,resizable=yes')
	}

	const onEditDocumentClick = (item) => {
		let documentModel = customerProfile.attachments.find((a) => {
			return a.token === item.token
		})
		setEditDocumentPanelOptions({ open: true, documentModel })
	}

	const onCloseDocumentClick = () => {
		setEditDocumentPanelOptions({ open: false, documentModel: null })
	}

	const onFilesReceived = (files, expirationDate) => {
		dispatch(uploadAttachments(files, expirationDate, customerToken))
			.then(() => {
				setHandleOpenAddFilesPanel(false)
				setToasterOptions({
					open: true,
					message: 'Archivo agregado correctamente',
					variant: 'success'
				})
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Error al agregar archivo',
					variant: 'error'
				})
			})
	}

	const onUpdateSupplierDocument = (documentModel) => {
		dispatch(updateFile(documentModel, customerToken))
			.then(() => {
				setEditDocumentPanelOptions({ open: false, documentModel: null })
				setToasterOptions({
					open: true,
					message: 'Archivo actualizado correctamente',
					variant: 'success'
				})
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Error al agregar archivo',
					variant: 'error'
				})
			})
	}

	const onCreateExternalUser = (user) => {
		dispatch(addExternalUser(customerToken, null, user))
			.then((all) => {
				setShowAddExternalUserPanel(false)
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Error al agregar el usuario',
					variant: 'error'
				})
			})
	}

	const onActiveUser = (activeOrDisable, user) => {
		dispatch(activeUser(activeOrDisable, user)).catch(() => {
			setToasterOptions({
				open: true,
				message: 'Error al actualizar la informacion del usuario.',
				variant: 'error'
			})
		})
	}

	const getNoDataMessasge = (messageToAdd, iconToAdd, image) => {
		let message = (
			<Typography>
				Para agregar un nuevo {messageToAdd} da clic en el boton "Agregar" {iconToAdd} y
				captura los datos solicitados.
			</Typography>
		)
		return <NoDataMessage message={message} image={image} />
	}

	let isDisabledToAddUsers = customerProfile && !customerProfile.active
	let customerCodeToValidatePROPIMEX = ''
	if (customerProfile != null) {
		customerCodeToValidatePROPIMEX = customerProfile.code
	}

	return (
		<Layout>
			<div className={classes.root}>
				{
					{
						true: <Loader />,
						false: (
							<ProfileHeader
								fieldsInHeader={headerFields(customerProfile)}
								userProfile={customerProfile}
								onUpdateUserStatus={onUpdateCustomerStatus}
								isSaving={isSaving}
							/>
						)
					}[isLoadingSupplier]
				}
				{!isLoadingSupplier && (
					<div>
						<Paper square>
							<Tabs
								value={selectedTab}
								indicatorColor='secondary'
								textColor='secondary'
								onChange={ChangeTabSelected}
								aria-label='wrapped label tabs example'
							>
								{!isDisabledToAddUsers && <Tab value={0} label='Usuarios' />}
								<Tab value={1} label='Documentos operativos' />
								{customerCodeToValidatePROPIMEX === '1210' && (
									<Tab value={2} label='Traslados Disposicion final' />
								)}
							</Tabs>
						</Paper>
						<Divider />

						{!isDisabledToAddUsers &&
							selectedTab === 0 &&
							{
								true: <Loader />,
								false: (
									<UsersInsupplier
										onAddUserClick={() => setShowAddExternalUserPanel(true)}
										isLoadingUsers={isLoadingUsers}
										onClose={() => setShowAddExternalUserPanel(false)}
										users={users}
										showAddExternalUserPanel={showAddExternalUserPanel}
										onCreateExternalUser={onCreateExternalUser}
										onActiveUser={onActiveUser}
										pagination={pagination}
										sortConfig={sortConfig}
										noDataMessage={getNoDataMessasge(
											'usuario',
											<AddBtnFake />,
											LogoHelp
										)}
										disabledAdd={isDisabledToAddUsers}
										onChangePageNumber={onChangePageNumber}
										pageNumber={pageNumber}
									/>
								)
							}[isLoadingUsers]}

						{selectedTab === 1 && (
							<OperatingDocumentsStack
								documents={customerProfile ? customerProfile.attachments : []}
								openAddFilesPanel={openAddLateralFilesPanel}
								onEditDocumentClick={onEditDocumentClick}
								onDownloadDocumentClick={onDownloadDocumentClick}
								noDataMessage={getNoDataMessasge(
									'documento',
									<AddDocFake />,
									LogoHelp
								)}
								pagination={pagination}
								sortConfig={sortConfig}
								// disabledAdd={customerProfile && !customerProfile.active}
							/>
						)}
						{selectedTab === 2 && customerProfile.isCustomerKof && (
							<FinalDispositionTransfer customerCode={customerProfile.code} />
						)}
					</div>
				)}
			</div>
			{handleOpenAddFilesPanel && (
				<AddDocumentsPanel
					open={handleOpenAddFilesPanel}
					onClose={() => setHandleOpenAddFilesPanel(false)}
					onFileReceived={onFilesReceived}
					documents={customerProfile.attachments}
					isSaving={isSaving}
				/>
			)}
			{editDocumentPanelOptions.open && (
				<EditDocumentPanel
					open={editDocumentPanelOptions.open}
					documentModel={editDocumentPanelOptions.documentModel}
					onClose={onCloseDocumentClick}
					onUpdateSupplierDocument={onUpdateSupplierDocument}
					isSaving={isSaving}
					documentType={CUSTOMER_TYPE}
				/>
			)}
			{toasterOptions.open && (
				<Toaster {...toasterOptions} onClose={() => setToasterOptions({ open: false })} />
			)}
		</Layout>
	)
}

const headerFields = (customerModel) => {
	let configuration = customerModel
		? [
				{
					label: 'Nombre',
					propertyName: 'name',
					value: `${customerModel.code} ${customerModel.name}`
				}
		  ]
		: []
	return configuration
}

export default withRouter(Show)
