/**Import react section */
import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/** Material UI import section */
import {
    Button,
    DialogTitle,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
/** Custom components import section */
import Dialog from '../../common/Dialog'
import CropImageSelector from '../../common/CropImageSelector'
import { COLOR_PRIMARY_NORMAL, COLOR_WHITE,  COLOR_SUCCESS_NORMAL} from '../../../resources/constants/Colors';



/** Resources import section */
/**
 *
 *
 * @param {*} props
 * @returns
 */
const UploadImageDialog = props => {
    const classes = useStyle();
    // Define local state
    const [imagenAvatarBase64, setImagenAvatarBase64] = useState(null);

    /**Get roles from user to back end */
    useEffect(() => {
        if (props.fileBase64)
            setImagenAvatarBase64(props.fileBase64)
    }, [props.fileBase64])

    /**
    * canBeSaved
    */
    const canBeSaved = () => {
        return true;
    }

    const onUploadAvatarUser = () => {
        if (props.onUploadLogoUser) {
            props.onUploadLogoUser(imagenAvatarBase64);
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            isSaving={props.isSaving}
            header={
                <DialogTitle >
                    <Typography variant="h6" style={{color:COLOR_PRIMARY_NORMAL, marginLeft:"-20px"}}><strong>{"ACTUALIZAR IMÁGEN"}</strong></Typography>
                </DialogTitle>
            }
            actions={
                <Fragment>
                    <Button
                    className={classes.buttonSave}
                        disabled={props.isSaving || !canBeSaved()}
                        onClick={onUploadAvatarUser}
                    >
                        Guardar
                </Button>
                </Fragment>
            }
        >
            <CropImageSelector
                fileBase64={props.fileBase64}
                // onChange={handleOnCropChange}
            />
        </Dialog>

    );
}


let useStyle = makeStyles((theme) => ({
    buttonSave: {
        padding: "10px",
        background: COLOR_SUCCESS_NORMAL, "&:hover": {
            backgroundColor: "#2B935E"
        },
        "&.Mui-disabled":{
            color: COLOR_WHITE,
            opacity: .75,
        },
        color: COLOR_WHITE,
        top: ".3px",
        left: "-15px",
        width: "110px",
        height: "36px"
    },
    cssLabel: {
    },    
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
        }
    },   
    cssFocused: {
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `${COLOR_PRIMARY_NORMAL} !important`,
        color: `${COLOR_PRIMARY_NORMAL} !important`
    }
}))

export default withRouter(UploadImageDialog);