import moment from 'moment'

const RequestTableExtConfig = [
	{
		title: "Estatus",
		sortable: true,
		dataSource: "estatus",
		width: "135px"
	},
	{
		title: "Folio",
		sortable: true,
		dataSource: "folio",
		width: "125px"
	},
	{
		title: "Fecha Solicitud",
		sortable: true,
		dataSource: "fechaSolicitud",
		width: "100px",
		onRenderProperty: (item) =>
			item.fechaSolicitud ? (moment(item.fechaSolicitud).format("DD/MM/YYYY")): ''
	},
	{
		title: "Sucursal",
		dataSource: "sucursal",
		sortable: true,
		width: "155px",
		onRenderProperty: (item) =>
			`${item.codigoSucursal} | ${item.sucursal}`
	},
	{
		title: "Folio cliente",
		dataSource: "sbai",
		sortable: true,
		width: "120px"
	},
	{
		title: "Equipos",
		dataSource: "total",
		sortable: true,
	},
	{
		title: "Recibidos",
		dataSource: "totalRecibidos",
		sortable: true,
	},
	{
		title: "% Recibidos",
		dataSource: "porcetajeRecibidos",
		sortable: true,
		onRenderProperty: (item) =>
			`${item.porcetajeRecibidos} % `
	},
	{
		title: "Destruidos",
		sortable: true,
		dataSource: "totalDestruidos"
	},
	{
		title: "% Destruidos Recibidos",
		dataSource: "porcetajeDestruidos",
		sortable: true,
		onRenderProperty: (item) =>
			`${item.porcetajeDestruidos} % `
	},
	{
		title: "Contiene manifiesto",
		sortable: true,
		dataSource: "containsManifestFile",
		onRenderProperty: (item) =>
			item.containsManifestFile ? 'Si': 'No'
	},
]
export default RequestTableExtConfig
