import { fromJS } from 'immutable';
import { 
    IS_LOADING_USERS, 
    SET_USERS,
    SET_USER_PROFILE,
    IS_SAVING_USER,
    IS_FINDING_USER,
    ADD_USER,
    UPDATE_USER,
    ADD_ROLE_IN_PROFILE,
    REMOVE_ROLE_FROM_PROFILE,
    UPDATE_EXTERNAL_USER,
    IS_UPDATING_PASSWORD_USER,
    IS_LOADING_ACTIVE_USER,
    UPDATE_ACTIVE_USER,
    SET_USERS_IN_ROLE,
    SET_INTERNAL_USER_QUERYPARAMETERS,
    UPDATE_USER_AVATAR
} from './UserActions';

let initialServicesState = fromJS({
    isLoadingUsers: false,
    isLoadingActiveUser: false,
    isSavingUser: false,
    isFindingUser: false,
    isUpdatingPassword: false,
    allUsers: [],
    userProfile: null,
    usersInRole:[],
    internalQueryParameters: null,

})

const servicesReducer = (state = initialServicesState, action) => {
    switch(action.type) {
        case IS_LOADING_USERS: {
            return state.set('isLoadingUsers', action.status);
        }
        case SET_USERS: {
            return state.merge({
                allUsers: fromJS(action.users),
                isLoadingUsers: false
            })
        }
        case SET_USER_PROFILE:{
            let user = fromJS(action.user);
            return state.set('userProfile', user);
        }
        case IS_SAVING_USER: {
            return state.set('isSavingUser', action.status);
        }
        case IS_FINDING_USER: {
            return state.set('isFindingUser', action.status);
        }
        case ADD_USER: {
            let newUser = fromJS(action.user);
            return state.updateIn(['allUsers'], allUsers => pushUser(allUsers, newUser)).set('isSavingUser', false);
        }
        case UPDATE_USER: {
            let user = fromJS(action.user);
            return state.updateIn(['allUsers'], allUsers => updateUser(allUsers, user)).set('isSavingUser', false);
        }
        case ADD_ROLE_IN_PROFILE: {
            let role = fromJS(action.role);
            return state.updateIn(['userProfile', 'roles'], allRoles => allRoles.push(role)).set('isSavingUser', false);
        }
        case REMOVE_ROLE_FROM_PROFILE: {
            return state.updateIn(['userProfile', 'roles'], allRoles => allRoles.filter(r => r.get('roleName') !== action.roleName)).set('isSavingUser', false);
        }
        case UPDATE_EXTERNAL_USER: {
            let externalUser = fromJS(action.externalUser)
            return state.updateIn(['allUsers'], allUsers => updateExternalUser(allUsers, externalUser)).set('isSavingUser', false);
        }
        case IS_UPDATING_PASSWORD_USER:{
            return state.merge({
                isUpdatingPassword: action.status
            })
        }
        case IS_LOADING_ACTIVE_USER:{
            return state.merge({
                isLoadingActiveUser: action.isLoading  
            })
        }
        case UPDATE_ACTIVE_USER:{
            let user = fromJS(action.user);
            return state.updateIn(['allUsers'], allUsers => updateUserIsActive(allUsers, user)).set('isLoadingActiveUser', false);
        }
        case SET_USERS_IN_ROLE:{
            return state.merge({
                usersInRole: fromJS(action.usersInRole),
            })
        }
        case SET_INTERNAL_USER_QUERYPARAMETERS: {
            return state.merge({
                internalQueryParameters: fromJS(action.queryParametes)
            })
        }
        case UPDATE_USER_AVATAR:{
            let user = fromJS(action.user);
            return state.updateIn(['allUsers'], allUsers => updateUserAvatar(allUsers, user)).setIn(['userProfile', 'avatarUrl'], user.get("avatar_url"));
        }
        default: {
            return state
        }
    }
}

const pushUser = (allUsers, newUser) => {
    if (allUsers.some(user => user.get('userName') === newUser.get('userName'))) {
        return allUsers;
    }
    return allUsers.push(newUser);
}

const updateUser = (allUsers, userToUpdate) => {
    let indexOfUser = allUsers.findIndex(user => user.get('userName') === userToUpdate.get('userName'));
    if (indexOfUser >= 0) {
        return allUsers.setIn([indexOfUser, 'roles'], userToUpdate.get('roles'));
    }
    return allUsers;
}

const updateUserIsActive = (allUsers, userToUpdate) => {
    let indexOfUser = allUsers.findIndex(user => user.get('userName') === userToUpdate.get('userName'));
    if (indexOfUser >= 0) {
        return allUsers.setIn([indexOfUser,"active"], userToUpdate.get("active"));
    }
    return allUsers;
}

const updateExternalUser = (allUsers, userToUpdate) => {
    let indexOf = allUsers.findIndex(user => user.get('id') === userToUpdate.get('id'));
    if (indexOf >= 0) {
        return allUsers.set(indexOf, userToUpdate);
    }

    return allUsers;
}

const updateUserAvatar= (allUsers, userToUpdate) => {
    let indexOfUser = allUsers.findIndex(user => user.get('userName') === userToUpdate.get('user_name'));
    if (indexOfUser >= 0) {
        return allUsers.setIn([indexOfUser, "avatarUrl"], userToUpdate.get("active"));
    }
    return allUsers;
}

export default servicesReducer