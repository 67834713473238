import React, { useState, useEffect } from 'react'
import { Box, Typography, CircularProgress, Fab, Grid, Paper, Button } from '@material-ui/core'
import { WhiteTextBox } from '../../common/TextBox'
import ActiveUserIcon from '@material-ui/icons/Check'
import InactiveUserIcon from '@material-ui/icons/Block'
import UploadImgCropDialog from '../../common/UploadImageDialog'

import { COLOR_FOCUS_NORMAL, COLOR_WHITE } from '../../../resources/constants/Colors'

import '../../../resources/styles/avatar.css'
import ProfileAvatar from './ProfileAvatar'
import { makeStyles } from '@material-ui/styles'
import Dialog from '../Dialog'

const useStyles = makeStyles(() => ({
	headerBox: {
		margin: 0,
		padding: 0
	},
	button: {
		width: 35,
		height: 35,
		backgroundColor: ' #3AC47D',
		'&.Mui-disabled': {
			backgroundColor: ' #3AC47D'
		}
	},
	inactiveButton: {
		width: 35,
		height: 35,
		backgroundColor: '#D92550',
		'&.Mui-disabled': {
			backgroundColor: '#D92550'
		}
	},
	activeIcon: {
		color: 'white'
	},
	avatar: {
		backgroundColor: 'rgba(108, 117, 125, 1)'
	},
	paper: {
		padding: 2,
		textAlign: 'center',
		color: 'rgba(68, 64, 84, 1)'
	},
	logoFileInput: {
		display: 'none'
	},
	textField: {
		cssLabel: {},
		cssOutlinedInput: {
			'&$cssFocused $notchedOutline': {}
		},
		cssFocused: {},
		notchedOutline: {
			borderWidth: '1px',
			borderColor: `white !important`,
			color: `white !important`
		}
	}
}))

const ProfileHeader = (props) => {
	const classes = useStyles()
	const [logoFileBase64, setLogoFileBase64] = useState(null)

	const [profileModel, setProfileModel] = useState(props.userProfile)
	const [showConfirmationChangeActive, setShowConfirmationChangeActive] = useState(false)

	/**Section for UseEffect */
	useEffect(() => {
		if (props.userProfile) {
			setProfileModel(props.userProfile)
		}
	}, [props.userProfile])

	/**
	 * On select logo file
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	const onSelecteFile = (fileBase64) => {
		setLogoFileBase64(fileBase64)
	}

	const uploadLogoFile = (file) => {
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var myFileItemReader = new FileReader()
			myFileItemReader.addEventListener(
				'load',
				() => {
					const myResult = myFileItemReader.result
					onSelecteFile(myResult)
					props.onSetHandleOpenUploadImg()
				},
				false
			)
			myFileItemReader.readAsDataURL(fileUpload)
		}
	}

	const toggleUserStatus = (status) => {
		let { userProfile } = props
		if (props.onUpdateUserStatus) {
			props
				.onUpdateUserStatus(userProfile, !status)
				.finally(() => {
					setShowConfirmationChangeActive(false)
				})
				.catch(() => {
					console.error('Error to update status')
				})
		}
	}

	const getButtons = () => {
		let buttons = []
		buttons.push(
			<Box key='btn-update-password' p={1} flexShrink={1}>
				<Typography variant='body2'>{'Acceso concedido'}</Typography>
				{props.isSaving && <CircularProgress color='secondary' size={20} disableShrink />}
				{!props.isSaving && (
					<Fab
						size='small'
						className={
							profileModel && profileModel.active
								? classes.button
								: classes.inactiveButton
						}
						onClick={() => {
							setShowConfirmationChangeActive(true)
						}}
					>
						{profileModel && profileModel.active ? (
							<ActiveUserIcon className={classes.activeIcon} />
						) : (
							<InactiveUserIcon className={classes.activeIcon} />
						)}
					</Fab>
				)}
			</Box>
		)
		return buttons
	}

	const getFields = (fieldsInHeader) => {
		return fieldsInHeader.map((field) => {
			return (
				<Grid
					item
					xs
					container
					justifyContent='center'
					alignItems='flex-end'
					key={field.label}
				>
					<Grid item>
						<WhiteTextBox
							active={field.active}
							label={field.label}
							name={field.propertyName}
							value={field.value}
						/>
					</Grid>
				</Grid>
			)
		})
	}

	const getActionsChangeActive = () => {
		return (
			<Grid container spacing={2} justifyContent='flex-end' alignItems='center'>
				<Grid item xs={4}>
					<Button
						variant='contained'
						color='primary'
						onClick={() => toggleUserStatus(profileModel.active)}
					>
						Confirmar
					</Button>
				</Grid>
			</Grid>
		)
	}

	return (
		<Paper
			style={{ backgroundColor: COLOR_FOCUS_NORMAL, color: COLOR_WHITE }}
			className={classes.paper}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} className={classes.hederGrid}>
					<Box
						className={classes.headerBox}
						display='flex'
						p={1}
						alignItems='flex-start'
						justifyContent='center'
					>
						<Box p={1} display='flex' justifyContent='center' width='100%'>
							<ProfileAvatar {...profileModel} uploadLogoFile={uploadLogoFile} />
						</Box>
						{getButtons()}
					</Box>
				</Grid>
				{props.fieldsInHeader && getFields(props.fieldsInHeader)}
				<UploadImgCropDialog
					open={props.handleOpenUploadImg}
					fileBase64={logoFileBase64}
					onClose={() => props.onSetHandleCloseUploadImg()}
					onUploadImagen={props.onUploadLogoUser}
					isSaving={props.isSaving}
				/>
			</Grid>
			<Dialog
				open={showConfirmationChangeActive}
				isSaving={props.isSaving}
				onClose={() => setShowConfirmationChangeActive(false)}
				header={
					<Typography component='span' variant='h6'>
						{'Confirmación de cambio de estatus'}
					</Typography>
				}
				children={
					<Grid container style={{ margin: '2em 0' }}>
						{`Por favor confirme que desea ${
							profileModel && profileModel.active ? 'desactivar' : 'activar'
						} el acceso.`}
					</Grid>
				}
				actions={getActionsChangeActive()}
			/>
		</Paper>
	)
}

ProfileHeader.defaultProps = {
	profileModel: {
		type: '',
		avatarUrl: ''
	}
}

export default ProfileHeader
