/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

/** Material UI Section*/
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

/** Custom component import section */
import LateralActionPanel from '../../common/LateralActionPanel'
import DropDownZone from '../../common/DropDownZone'
import DateSelector from '../../common/DateSelector'
import NotificationDays from './NotificationDays'
import TextBox from '../../common/TextBox'

/**Import helper section */
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import FileContent from '../../common/Dropzone/FileContent'
import { COLOR_INFO_NORMAL } from '../../../resources/constants/Colors'
import { getOptions } from '../../../store/helpers/CustomerHelper'

const useStyles = makeStyles((theme) => ({
	fileForm: {
		padding: '15px'
	},
	itemToImportErrorText: {
		color: 'red',
		fontSize: '12px'
	}
}))

const AddDocumentsPanel = (props) => {
	const classes = useStyles()
	const [pdfFile, setPdfFile] = useState([])
	const [expirationDate, setExpirationDate] = useState(null)
	const [notificationValue, setNotificationValue] = useState(0)
	const [notificationUnit, setNotificationUnit] = useState('D')
	const [notificationRequired, setNotificationRequired] = useState(false)
	const [numberDocument, setNumberDocument] = useState(0)
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	let allCustomers = getOptions(props.customers ? props.customers : [])

	const canBeSaved = () => {
		if (!selectedCustomer) return false
		if (onExisteDocumentWithSameNameDontSaveFiles().length > 0) return false
		return true
	}

	/**Exist document add with name */
	const onExisteDocumentWithSameNameDontSaveFiles = () => {
		let existDocument = pdfFile.filter((u) => u.existFile === true)
		return existDocument
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileId) => {
		let index = pdfFile.findIndex((attachment) => attachment.id === fileId)
		if (index !== -1) {
			let newListFiles = pdfFile.filter((file) => {
				return file.id !== fileId
			})
			setPdfFile(newListFiles)
		}
	}

	/**
	 * Save information in back end
	 */
	const onSaveButtonClick = () => {
		if (props.onFileReceived) {
			let formatExpirationDate = null
			if (expirationDate !== null) {
				formatExpirationDate = moment(expirationDate).format()
			}
			let notificationDays = calculateDays()
			props.onFileReceived(pdfFile, {
				expirationDate: formatExpirationDate,
				notificationDays: notificationDays,
				numberDocument: numberDocument,
				customer: selectedCustomer
			})
		}
	}

	const onPropertyChange = (event) => {
		let { value } = event.target
		setNumberDocument(value)
	}

	/**Exist document add with name */
	const onExisteDocumentWithSameName = (fileName) => {
		let existDocument = pdfFile.find((u) => u.fileName === fileName)
		return existDocument ? true : false
	}

	const onNotificationChange = (event) => {
		let { value, name } = event.target

		if (name === 'notification' && value.length > 0) {
			let regexNotification = /^([0-9]+$)/
			if (!regexNotification.test(value)) return
		}
		setNotificationValue(value)
	}

	const onUnitsChange = (event) => {
		let { value } = event.target
		setNotificationUnit(value)
	}

	const onNotificationRequired = (event) => {
		let { checked } = event.target
		setNotificationRequired(checked)
	}

	/**
	 * On seelct customer
	 */
	const onSelectCustomer = (customer) => {
		setSelectedCustomer(customer)
	}

	const calculateDays = () => {
		if (notificationUnit === 'W') return notificationValue * 7
		return notificationValue
	}

	return (
		<LateralActionPanel
			title='Agregar Archivos'
			show={props.open}
			onClose={props.onClose}
			isSaving={props.isSaving}
			canBeSaved={!canBeSaved()}
			onSaveItem={onSaveButtonClick}
			onCancel={props.onClose}
			disabled={pdfFile.length <= 0}
		>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant='button' style={{ color: COLOR_INFO_NORMAL }}>
						{'Cliente'}
					</Typography>
					<Select
						placeholder={'Seleccionar cliente'}
						options={allCustomers}
						onChange={onSelectCustomer}
						value={selectedCustomer}
						styles={{
							menu: (base) => ({ ...base, zIndex: 31 })
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<br />
					<TextBox
						active={true}
						label='Número de documento'
						fullWidth={true}
						margin='dense'
						name='NumberDocument'
						type='number'
						onChange={(event) => {
							onPropertyChange(event)
						}}
						value={numberDocument}
					/>
				</Grid>
				<Grid item xs={6}>
					<DateSelector
						dataCy='data-picker-expiration'
						label='Fecha de vencimiento'
						disablePast={true}
						onChange={(event) => {
							setExpirationDate(event.target.value)
						}}
						value={expirationDate ? expirationDate : null}
					/>
				</Grid>
				<Grid item xs={6} style={{ marginTop: '30px' }}>
					<FormControlLabel
						labelPlacement='start'
						data-cy='check-notification'
						control={
							<Checkbox
								disabled={!expirationDate}
								onChange={(event) => onNotificationRequired(event)}
								value={notificationRequired}
								color='primary'
							/>
						}
						label='¿Desea notificación?'
					/>
				</Grid>
				{expirationDate && notificationRequired && (
					<NotificationDays
						onUnitsChange={onUnitsChange}
						notificationValue={notificationValue}
						onNotificationChange={onNotificationChange}
						notificationUnit={notificationUnit}
					/>
				)}
				<Grid item xs={12}>
					<DropDownZone
						datacy='drop-zone-documents-operating'
						classes={classes}
						multiple={false}
						accept={['.pdf', '.xlsx', '.eml', '.pps', '.ppsx', '.ppt', '.pptx']}
						message={
							"Seleccione los archivo(s) con extensión ('.pdf', '.xlsx', '.eml', '.pps', '.ppsx', '.ppt', '.pptx')"
						}
						onFilesReceived={(files) => {
							if (files.length > 0) {
								const filesAdded = files.map((file) => {
									let existFile = onExisteDocumentWithSameName(file.name)
									return {
										id: uuidv4(),
										attachment: file,
										existFile: existFile
									}
								})
								setPdfFile(pdfFile.concat(filesAdded))
							}
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					{pdfFile.length > 0 && (
						<FileContent files={pdfFile} onRemoveFile={onRemoveFile} />
					)}
				</Grid>
			</Grid>
		</LateralActionPanel>
	)
}

AddDocumentsPanel.propTypes = {
	onFileReceived: PropTypes.func,
	documents: PropTypes.array,
	open: PropTypes.bool,
	isSaving: PropTypes.bool
}
AddDocumentsPanel.defaultProps = {
	documents: [],
	open: false,
	isSaving: false
}

export default AddDocumentsPanel
