import React from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import makeStyles from '@material-ui/core/styles/makeStyles'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'

/** Resources import section */
import { COLOR_DANGER_NORMAL } from '../../../resources/constants/Colors'

/** Define component's styles */
const useStyles = makeStyles((theme) => ({
	errorsContainer: {
		border: `1px solid ${COLOR_DANGER_NORMAL}`,
		borderRadius: 10,
		paddingTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
	}
}))

const ValidationErrors = (props) => {
	const { errors = [] } = props

	const classes = useStyles()

	if (!errors || errors.length === 0) {
		return null
	}
	return (
		<div className={classes.errorsContainer}>
			<Typography variant='subtitle1'>ERRORES</Typography>
			<List>
				{errors.map(({ errorCode, serialNumber, dispositionRequestFolio }) => (
					<ListItem dense key={`${errorCode}-${serialNumber}`}>
						<ListItemText
							primary={serialNumber}
							secondary={`Esta serie se encuentra en otra solicitud con folio ${dispositionRequestFolio}`}
						/>
					</ListItem>
				))}
			</List>
		</div>
	)
}

ValidationErrors.propTypes = {
	errors: PropTypes.arrayOf(
		PropTypes.shape({
			serialNumber: PropTypes.string,
			dispositionRequestFolio: PropTypes.string,
			errorCode: PropTypes.string
		})
	)
}

export default ValidationErrors
