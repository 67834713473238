import React from 'react'
import PropTypes from 'prop-types'

/** Custom components import section */
import Loader from '../../common/Loader'
import DataList from '../../common/DataList/DataList'

/** Material UI import section */
import { Chip, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const ReportsTable = (props) => {
	const { loading, reports, onDeleteRow } = props

	const classes = useStyles()

	const renderCustomCell = (dataSource, item) => {
		switch (dataSource) {
			case 'customer': {
				const {
					customer: { code, name }
				} = item
				return (
					<Typography variant='overline'>{`${code} ${name}`}</Typography>
				)
			}

			case 'actions': {
				const {
					attachment: { name }
				} = item
				return (
					<>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/Documents/reports/${name}`}
						>
							<Chip
								className={classes.buttonDownload}
								component='div'
								icon={<CloudDownloadIcon />}
								id={item.token}
								data-cy='btn-see-doc'
								size='small'
								label='Descargar'
							/>
						</a>
						<Tooltip title='Eliminar reporte'>
							<IconButton
								className={classes.buttonRemove}
								data-token={item.token}
								onClick={onDeleteRow}
							>
								<DeleteForeverIcon />
							</IconButton>
						</Tooltip>
					</>
				)
			}
			default:
				return null
		}
	}

	return (
		<div>
			{
				{
					true: <Loader />,
					false: (
						<DataList
							configuration={reportsTableConfiguration}
							data={reports}
							onRenderCellItem={renderCustomCell}
						/>
					)
				}[loading]
			}
		</div>
	)
}

ReportsTable.propTypes = {
	loading: PropTypes.bool.isRequired,
	reports: PropTypes.array.isRequired,
	onDeleteRow: PropTypes.func.isRequired
}

ReportsTable.defaultProps = {
	loading: false,
	reports: [],
	onDeleteRow: () => console.warn('[onDeleteRow] is not defined!')
}

const useStyles = makeStyles((theme) => ({
	buttonDownload: {
		cursor: 'pointer',
		backgroundColor: theme.palette.info.light,
		color: theme.palette.common.white
	},
	buttonRemove: {
		color: theme.palette.error.dark
	}
}))

const reportsTableConfiguration = {
	columns: [
		{
			//     dataSource: "numberDocument",
			//     header: "MES",
			//     isSortable: true
			// }, {
			dataSource: 'fileName',
			header: 'NOMBRE',
			isSortable: true
		},
		{
			dataSource: 'customer',
			header: 'CLIENTE',
			isSortable: true
		},
		{
			dataSource: 'comments',
			header: 'COMENTARIOS'
		},
		{
			dataSource: 'actions',
			header: ''
		}
	]
}

export default ReportsTable
