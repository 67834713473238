import React, { Component } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
    root: {
        zIndex: 99999
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 12,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

/**
 * Toast component
 *
 * @class Toaster
 * @extends {Component}
 */
class Toaster extends Component {

    /**
     * Render
     *
     * @returns
     * @memberof Toast
     */
    render() {
        const { classes, variant } = this.props;

        return (
            <Snackbar
                open={this.props.open}
                autoHideDuration={6000}
                onClose={this.props.onClose}
                className={classes.root}
            >
                <SnackbarContent className={classes[variant]}
                    message={
                        <span className={classes.message}>
                            {this.props.message}
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.props.onClose}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        );
    }
}

export default withStyles(styles)(Toaster);