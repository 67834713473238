import { fromJS } from 'immutable';
import * as documentActions from './DocumentActions';

let initialDocumentsState = fromJS({
    isLoadingDocuments: false,
    isSavingDocument: false,
    allDocuments: [],
    report: null,
    supplier: null
})

const supplierReducer = (state = initialDocumentsState, action) => {
    switch (action.type) {
        case documentActions.IS_LOADING_DOCUMENTS: {
            return state.merge({
                isLoadingDocuments: action.status
            })
        }
        case documentActions.IS_SAVING_DOCUMENTS: {
            return state.merge({
                isSavingDocument: action.status
            })
        }
        case documentActions.SET_ALL_DOCUMENTS: {
            return state.merge({
                allDocuments: fromJS(action.documents),
                isLoadingDocuments: false,
                isSavingDocument: false
            })
        }
        case documentActions.SET_DOCUMENTS_REPORT: {
            let report = fromJS(buildDocumentsReport(action.documents));
            return state.set('report', report);
        }
        case documentActions.ADD_FILE_DOCUMENT: {
            let attahcment = fromJS(action.attahcment);
            return state.updateIn(['allDocuments'], allDocuments => allDocuments.push(attahcment)).set('isSavingDocument', false);
        }
        case documentActions.UPDATE_DOCUMENT: {
            let doc = fromJS(action.doc);
            return state.updateIn(['allDocuments'], allDocuments => updateDocument(allDocuments, doc)).set('isSavingDocument', false);
        }
        case documentActions.REMOVE_DOCUMENT: {
            return state.updateIn(['allDocuments'], allDocuments => allDocuments.filter(document => document.get('token') !== action.documentToken))
                .set('isSavingDocument', false);
        }
        default: {
            return state;
        }
    }
}

const updateDocument = (allDocuments, docToUpdate) => {
    let indexOfUser = allDocuments.findIndex(doc => doc.get('token') === docToUpdate.get('token'));
    return allDocuments.setIn([indexOfUser], docToUpdate);
}

const buildDocumentsReport = documents => {
    return {
        enabled: documents.length > 0,
        total: documents.length,
        eosDocuments: documents.filter(d => d.supplier === null && d.customer === null),
        supplierDocuments: documents.filter(d => d.supplier !== null),
        customerDocuments: documents.filter(d => d.customer !== null)
    }
}

export default supplierReducer;