/**
 * Determine if a string is null or empty
 *
 * @export
 * @param {string} value
 */
export const isNullOrEmpty = (value) => {
	if (!value) {
		return true
	}
	let trimmed = String(value).trim()
	if (trimmed.length === 0) {
		return true
	}
	return false
}

export const isValidEmail = (email) => {
	if (isNullOrEmpty(email)) {
		return true
	}
	let regexEmail = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regexEmail.test(email)
}

export const isValidPassword = (newPassword) => {
	if (isNullOrEmpty(newPassword)) return true
	let regexNewPassword = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
	return regexNewPassword.test(newPassword)
}

export const isNumber = (number) => {
	if (isNullOrEmpty(number)) return true
	let regexNum = /^(((?=.*[1-9])))/
	return regexNum.test(number)
}
