import React from 'react';
import Select from 'react-select';
import PropType from 'prop-types';

import { DispositionRequestStatus } from '../../resources/constants/AppConstants';

const RequestStatusSelector = (props) => {
    const { onChange } = props;
    const options = buildOptions();

    return (
        <Select
            id="status-Select"
            placeholder={"Seleccionar estatus"}
            options={options}
            styles={styles}
            onChange={onChange}
            menuPortaTarget={document.parentNode}
            menuPosition="fixed"
            menuPlacement={"bottom"}
            maxMenuHeight={105}

        />
    );
}

RequestStatusSelector.propTypes = {
    onChange: PropType.func.isRequired
}

RequestStatusSelector.defaultProps = {
    onChange: () => console.warn('[onChange] is not defined!')
}

const buildOptions = () => {
    const statusDescriptions = [
        "Solicitada", "Confirmada", "Programada", "Entregada en planta", "En proceso", "Terminada", "Cancelada", "Liberada"
    ]

    return Object.values(DispositionRequestStatus)
        .map((value, index) =>
        ({
            value,
            label: statusDescriptions[index]
        })
        );
}

const styles = {
    menu: (base) => ({ ...base, zIndex: 62 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
    menuList: (base) => ({ ...base, paddingTop: 0 }),
};


export default RequestStatusSelector;