/**Import react section */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'

/** Material UI Section*/
import {
	makeStyles,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Typography,
	Grid
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

/** Custom component import section */
import DropDownZone from '../../common/DropDownZone'
import FileContent from '../../common/Dropzone/FileContent'
import CustomCheckBox from '../../common/CustomCheckBox'

/** Resources import section */
import {
	COLOR_SUCCESS_NORMAL,
	COLOR_DANGER_NORMAL,
	COLOR_WHITE,
	COLOR_PRIMARY_NORMAL,
	COLOR_SECONDARY_NORMAL
} from '../../../resources/constants/Colors'
import { TextBoxFieldRequired } from '../../common/TextBox'
import { PositionPlacement } from '../../../resources/constants/AppConstants'

/**
 * Component to upload evidence
 * @param {*} props
 */
const ManualReceptionEvidenceDialog = (props) => {
	const { incidentInputDisplayed } = props

	/** Defines local state */
	const [folio, setFolio] = useState('')
	const [pdfFile, setPdfFile] = useState([])
	const [hasIncident, setHasIncident] = useState(false)
	const [incidentFile, setIncidentFile] = useState([])

	const classes = useStyles()

	const disableSaveButton = () => {
		if (pdfFile.length === 0) return true
        if (incidentInputDisplayed && hasIncident && incidentFile.length === 0) return true
		return false
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileId) => {
		let index = pdfFile.findIndex((attachment) => attachment.id === fileId)
		if (index !== -1) {
			let newListFiles = pdfFile.filter((file) => {
				return file.id !== fileId
			})
			setPdfFile(newListFiles)
		}
	}

	/**
	 * Save information in back end
	 */
	const onSaveButtonClick = () => {
		if (props.onSaveManualEvidence) {
			props.onSaveManualEvidence(folio, pdfFile, incidentFile)
		}
	}

	/**Exist document add with name */
	const onExisteDocumentWithSameName = (fileName) => {
		let existDocument = pdfFile.find((u) => u.fileName === fileName)
		return existDocument ? true : false
	}

	const handleIncidentChange = (event) => {
		const { checked } = event.target
		setHasIncident(checked)
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			maxWidth='xs'
			fullWidth
		>
			<DialogTitle disableTypography className={classes.root}>
				<Typography variant='button' className={classes.title}>
					<strong>{'Agregar evidencia manual'}</strong>
				</Typography>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={props.onClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextBoxFieldRequired
							active={true}
							inactiveTitlePosition={
								PositionPlacement.TOP
							}
							label={'Folio'}
							fullWidth={true}
							margin='dense'
							name={'folio'}
							onChange={(event) => {
								setFolio(event.target.value)
							}}
							// value={
							// 	requestData
							// 		? requestData.otherBranch
							// 		: ''
							// }
						/>
					</Grid>
					<Grid item xs={12}>
						<DropDownZone
							datacy='drop-zone-documents-operating'
							classes={classes}
							multiple={true}
							message={'Clic aquí para seleccionar los archivos'}
							onFilesReceived={(files) => {
								if (files.length > 0) {
									const filesAdded = files.map((file) => {
										let existFile = onExisteDocumentWithSameName(
											file.name
										)
										return {
											id: uuidv4(),
											attachment: file,
											existFile: existFile
										}
									})
									setPdfFile(pdfFile.concat(filesAdded))
								}
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						{pdfFile.length > 0 && (
							<FileContent
								files={pdfFile}
								onRemoveFile={onRemoveFile}
							/>
						)}
					</Grid>
					{incidentInputDisplayed && (
						<Grid item xs={12}>
							<div className={classes.incidentContainer}>
								<CustomCheckBox
									onChange={handleIncidentChange}
									checked={hasIncident}
									label='¿Hubo incidencias?'
								/>
								{hasIncident && (
									<DropDownZone
										classes={classes}
										multiple={false}
										accept={['.doc', '.docx', '.eml', '.pdf', '.pps', '.ppsx', '.ppt', '.pptx', '.xls', '.xlsx']}
										message={
											<Typography
												align='center'
												variant='subtitle1'
											>
												{
													'Arrastre la evidencia de las incidencias o haga click para seleccionar el archivo.'
												}
											</Typography>
										}
										onFilesReceived={(files) => {
											if (files.length > 0) {
												setIncidentFile(files)
											} else {
												setIncidentFile([])
											}
										}}
									/>
								)}
								{incidentFile.length > 0 && (
									<FileContent
										files={incidentFile.map((file) => ({
											attachment: file
										}))}
										onRemoveFile={() => setIncidentFile([])}
									/>
								)}
							</div>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<div className={classes.wrapper}>
					<Button
						data-cy='btn-accept'
						className={classes.buttonAccept}
						variant='contained'
						onClick={onSaveButtonClick}
						disabled={disableSaveButton() || props.isSaving}
					>
						{'Subir evidencia'}
					</Button>
					{props.isSaving && (
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
							disableShrink
						/>
					)}
				</div>
			</DialogActions>
		</Dialog>
	)
}

ManualReceptionEvidenceDialog.propTypes = {
	open: PropTypes.bool.isRequired,
    incidentInputDisplayed: PropTypes.bool,
	onSaveManualEvidence: PropTypes.func.isRequired,
	isSaving: PropTypes.bool.isRequired
}

ManualReceptionEvidenceDialog.defaultProps = {
	open: false,
    incidentInputDisplayed: false,
	isSaving: false
}

const useStyles = makeStyles((theme) => ({
	title: {
		color: COLOR_PRIMARY_NORMAL
	},
	incidentContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonAccept: {
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: COLOR_WHITE,
		right: '10px',
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	buttonProgress: {
		color: COLOR_SECONDARY_NORMAL,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	closeButton: {
		position: 'absolute',
		right: '22px',
		top: theme.spacing(1),
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL
		},
		width: '37px',
		height: '36px',
		paddingTop: 8
	}
}))

export default ManualReceptionEvidenceDialog
