import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4'

/** Material-UI */
import {
    Card,
    CardContent,
    Table
} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Checkbox, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

/** Resources import */
import { COLOR_LIGHT_GRAY } from '../../../resources/constants/Colors';

/**
 * Class represeting a data grid
 *
 * @class DataGrid
 * @extends {Component}
 */
const DataList = props => {
    const { sortConfig } = props;
    const classes = useStyles();
    const [columns, setColumns] = useState([])

    useEffect(() => {
        const setColumnsConfigurationState = () => {
            let columns = [];
            if (props.configuration && props.configuration.columns) {
                columns = props.configuration.columns;
            } else if (props.data && props.data.length > 0) {
                columns = Object.keys(props.data[0]).map((property) => {
                    return {
                        header: property,
                        dataSource: property
                    }
                });
            }
            setColumns(columns)
        }
        setColumnsConfigurationState();
    }, [props.data, props.configuration])

    const onSortingCriteriaChange = (dataSource) => {
        if (!props.onSortingCriteriaChange) {
            return
        }
        if (dataSource === sortConfig.by) {
            let newSortCriteria = sortConfig.criteria === "asc" ? "desc" : "asc";
            props.onSortingCriteriaChange(dataSource, newSortCriteria)
        } else {
            props.onSortingCriteriaChange(dataSource, "desc")
        }
    };

    const getColumns = () => {
        return columns.map((column) => {
            let isSortedBy = column.dataSource === sortConfig.by;

            return (
                <TableCell key={uuidv4()} className={classes.tableHead}>
                    {(
                        column.isSortable === true &&
                        <Tooltip
                            title="Sort"
                            placement={1 ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300}
                        >
                            <TableSortLabel
                                active={isSortedBy}
                                direction={sortConfig.criteria || 'asc'}
                                onClick={() => { onSortingCriteriaChange(column.dataSource) }}
                            >
                                {column.header}
                            </TableSortLabel>
                        </Tooltip>
                    ) || column.header}
                </TableCell>
            )
        })
    }

    const getRows = () => {
        let rowsToDisplay = props.data;
        return rowsToDisplay.map((item) => {
            return item ? (
                <DataGridRow
                    classes={classes.tableRow}
                    key={uuidv4()}
                    item={item}
                    columns={columns}
                    onCellButtonClick={props.onCellButtonClick}
                    onRenderCellItem={props.onRenderCellItem}
                    onRowClick={props.onRowClick}
                    cellStyle={props.cellStyle}
                />
            ) : <TableRow key={uuidv4()} style={{ height: '59px' }} />
        })
    }

    const getNoDataMessage = () => {
        if (!props.data || props.data.length === 0) {
            if (props.noDataMessage) { return props.noDataMessage; }
            return (
                <div className={classes.messageContainer}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography>Sin datos para mostar.</Typography>
                        </CardContent>
                    </Card>
                </div>
            );
        }
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {getColumns()}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.rowsSection}>
                        {(props.data && props.data.length > 0) ? getRows() : null}
                    </TableBody>
                </Table>
                {getNoDataMessage()}
            </div>
        </div>
    );
}
DataList.propTypes = {
    data: PropTypes.array.isRequired,
    configuration: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string,
                dataSource: PropTypes.string.isRequired,
                isSortable: PropTypes.bool
            })
        ),
        //sortBy: 
        //sortAscending
    }),
    onRenderCellItem: PropTypes.func,
    cellStyle: PropTypes.oneOfType([
        PropTypes.shape,
        PropTypes.string
    ])
};
DataList.defaultProps = {
    data: [],
    sortConfig: { by: '', criteria: '' }
}

/**
 * Component representing a data grid row
 *
 * @class DataGridRow
 * @extends {Component}
 */
class DataGridRow extends Component {

    /**
     * Get the cells of the row
     *
     * @memberof DataGridRow
     */
    getCells() {
        let cellContent;
        let align = "left"
        return this.props.columns.map((column) => {
            const { item } = this.props;
            if (this.props.onRenderCellItem) {
                let overridedContent = this.props.onRenderCellItem(column.dataSource, item);
                if (!overridedContent) {
                    if (typeof item[column.dataSource] === "boolean") {
                        cellContent = item[column.dataSource] === true ? <CheckBoxIcon color="primary" fontSize="small" /> : null
                        align = "center"
                    } else {
                        cellContent =
                            <Typography variant='overline' style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>
                                {item[column.dataSource]}
                            </Typography>
                    }
                } else {
                    cellContent = overridedContent;
                }
            } else {
                if (typeof item[column.dataSource] === "boolean") {
                    cellContent =
                        <Checkbox
                            disabled
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={item[column.dataSource]}
                        />;
                    align = "center"
                } else {
                    cellContent =
                        <Typography variant='overline' style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>
                            {item[column.dataSource]}
                        </Typography>
                }
            }
            return (
                <TableCell
                    key={uuidv4()}
                    align={align}
                    size="small"
                    style={{
                        backgroundColor: item.custom_color,
                        ...this.props.cellStyle
                    }}
                >
                    {cellContent}
                </TableCell>
            )
        })
    }

    /**
     * Get cells
     *
     * @returns
     * @memberof DataGridRow
     */
    render() {
        const { classes } = this.props;
        return (
            <TableRow
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                    if (this.props.onRowClick) {
                        this.props.onRowClick(this.props.item, event)
                    }
                }}
                hover
                className={classNames(classes.tableRow)}>
                {this.getCells()}
            </TableRow>
        )
    }
}
DataGridRow.propTypes = {
    item: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    cellStyle: PropTypes.oneOfType([
        PropTypes.shape,
        PropTypes.string
    ])
};
const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: '30',
        backgroundColor: "#fff"
    },
    tableContainer: {
        zIndex: '30',
        minWidth: 300,
        height: "calc(100% - 30px)",
        overflowX: 'auto',
    },
    table: {
        zIndex: '30',
        minWidth: 300
    },
    tableHead: {
        backgroundColor: "#fff",
        position: "sticky"
    },
    tableRow: {
        cursor: "pointer",
    },
    rowsSection: {
        overflowX: 'auto',
    },
    popperFilter: {
        zIndex: "999",
        width: "180px"
    },
    messageContainer: {
        padding: theme.spacing(15, 20, 15, 20),
        alignItems: "center",
        textAlign: 'center'
    },
    card: {
        paddingTop: 8,
        backgroundColor: COLOR_LIGHT_GRAY,
        justifyContent: "center",
        alignItems: "center",
        textAlign: 'center'
    }
}))

export default DataList;