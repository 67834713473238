import {
    SupplierTypes
} from '../../resources/constants/AppConstants';

/**
 * Method to get all available roles for internal users
 */
export const getAllSupplierTypes = () => {
    return allSupplierTypes;
}

export const getSupplierTypeDecription = key => {
    let supplierType = allSupplierTypes.find(type => Number(type.key) === key);
    return supplierType ? supplierType.label : '-';
}

const allSupplierTypes = [
    {
        key: `${SupplierTypes.CARRIER}`,
        label: "Transportista"
    },
    {
        key: `${SupplierTypes.DISPOSITION}`,
        label: "De disposición",
    },
];

export const combineSuppliers = (identitySupplier, finalDispositionSupplier) => {
    return {
        token: identitySupplier.token,
        code: identitySupplier.code,
        name: identitySupplier.name,
        fullName: identitySupplier.full_name,
        fullNameTradeName: identitySupplier.full_name_trade_name,
        fullAddress: identitySupplier.full_address,
        phoneNumber: identitySupplier.phone_number,
        rfc: identitySupplier.rfc,
        avatarUrl: finalDispositionSupplier.avatarUrl,
        active: finalDispositionSupplier.active,
        attachments: finalDispositionSupplier.attachments,
        isTransportService: finalDispositionSupplier.is_transport_service,
    };
}