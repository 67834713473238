import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

/** Material UI import section */
import {
    makeStyles
} from '@material-ui/core';

/** Custom components import section */
import Layout from '../layout/Layout';
import Loader from '../common/Loader';
import Toaster from '../common/Toaster';
import UserForm from './components/UserForm';
import RolesForm from './components/RolesForm';
import EditPasswordUser from './components/EditPasswordUser'

/** Actions import section */
import { 
    loadUserProfile,
    addUserInRole,
    removeUserFromRole,
    saveInternalUserData,
    uploadLogoUser,
    updateUserPasswordDataFromBackend,
} from '../../store/users/UserActions';

/** Helpers import section */
import { toSafeObject } from '../../store/helpers/ImmutableHelper';
import { isUserInRole } from '../../store/helpers/UserHelper';
import { AppRoles } from '../../resources/constants/AppConstants';

const useUserProfileStyles = makeStyles(({
    root: {
        marginTop: 20,
        width: 1100
    }
}))

const UserProfile = props => {
    const classes = useUserProfileStyles();
    let userId = props.match.params.token;
    
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [handleOpenUploadImg, setHandleOpenUploadImg] = useState(false);
    const [handleOpenEditPassword, setHandleOpenEditPassword] = useState(false);
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    
    const dispatch = useDispatch();
    const loggedUser = useSelector(state => toSafeObject(state.session.get('loggedUser')));
    const userProfile = useSelector(state => toSafeObject(state.users.get('userProfile')));
    const isSavingUser = useSelector(state => state.users.get('isSavingUser'));
    
    const disableRolesEdition = !isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles);

    useEffect(() => {
        dispatch(loadUserProfile(userId)).finally(() => setIsLoadingUser(false));
    }, [userId, dispatch])

    const onSaveUserProfile = userModel => {
        return dispatch(saveInternalUserData(userId, userModel))
        .then(() => {
            /** Nothig code */
            return Promise.resolve();
        })
        .catch(() => {
            setToasterOptions({ open: true, message: "No se pudo actualizar la información del usuario", variant: 'error' });
        });    
    }

    const onToggleUserRole = roleName => {
        if (isUserInRole(roleName, userProfile.roles)) {
            return dispatch(removeUserFromRole(userProfile.id, roleName))
            .then(() => {
                /** Nothig code */
                return Promise.resolve();
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "No se puede quitar el role", variant: 'error' });
            });

        }
        else {
            return dispatch(addUserInRole(userProfile.id, roleName))
            .then(() => {
                /** Nothig code */
                return Promise.resolve();
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "No se pudo asignar el role", variant: 'error' });
            });
        }
    }

    const onUploadLogoUser = (imageBase64) => {
        let userId = props.match.params.token;
        dispatch(uploadLogoUser(userId, imageBase64))
            .then(()=> {
                onSetHandleCloseUploadImg()
                setToasterOptions({ open: true, message: "Avatar actualizado correctamente", variant: 'success' })
            })
            .catch(()=>{
                setToasterOptions({ open: true, message: "Error al actualizar avatar", variant: 'error' })
            })

    }

    const onUpdatePassword = (user) => {
        let userId = props.match.params.token;
        dispatch(updateUserPasswordDataFromBackend(user, userId))
            .then(()=> {
                onHandelCloseEditPassword()
                setToasterOptions({ open: true, message: "Contraseña actualizada correctamente", variant: 'success' })
            })
            .catch(()=>{
                setToasterOptions({ open: true, message: "Error al actualizar contraseña", variant: 'error' })
            })

    }

    /** Open modal for edit user avatar*/
    const onSetHandleOpenUploadImg = () => {
        setHandleOpenUploadImg(true);
    }
    /** Close modal for edit user avatar*/
    const onSetHandleCloseUploadImg = () => {
        setHandleOpenUploadImg(false);
    }
    
    /** Open modal for edit passwor from user*/
    const onHandelOpenEditPassword = () => {
        setHandleOpenEditPassword(true)
    }

    /** close modal for edit passwor from user*/
    const onHandelCloseEditPassword = () => {
        setHandleOpenEditPassword(false)
    }
    

    return (
        <Layout>
            <div>
                <div className={classes.root}>
                    {
                        {
                            true:   <Loader/>,
                            false:  <UserForm
                                        loggedUser={loggedUser}
                                        userProfile={userProfile}
                                        onUploadLogoUser={onUploadLogoUser}
                                        handleOpenUploadImg={handleOpenUploadImg}
                                        onSetHandleOpenUploadImg={onSetHandleOpenUploadImg}
                                        onSetHandleCloseUploadImg={onSetHandleCloseUploadImg}
                                        onSaveUserProfile={onSaveUserProfile}
                                        isSaving={isSavingUser}
                                        onHandelOpenEditPassword={onHandelOpenEditPassword}
                                    />
                        }[isLoadingUser]
                    }
                    {
                        !isLoadingUser &&
                        <RolesForm
                            disabled={disableRolesEdition}
                            userProfile={userProfile}
                            onToggleRole={onToggleUserRole}
                            isSaving={isSavingUser}
                        />
                    }
                </div>
            </div>
            {
                handleOpenEditPassword &&
                <EditPasswordUser
                    loggedUserName={loggedUser.userName}
                    editUserName={userProfile.userName}
                    open={handleOpenEditPassword}
                    isSaving={isSavingUser}
                    onClose={() => setHandleOpenEditPassword(false)}
                    onUpdatePassword={onUpdatePassword}
                />
            }
            {
                toasterOptions.open &&
                <Toaster
                    {...toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </Layout>
    );
}

export default withRouter(UserProfile);