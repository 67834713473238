/**import react section */
import React from 'react';
import propTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/resource-timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import ptLocale from '@fullcalendar/core/locales/pt';
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import moment from 'moment';

/**Import resource section */
import { COLOR_SUCCESS_NORMAL } from '../../../resources/constants/Colors';

const Calendar = props => {

    const formatObjectEvents = (events) => {
        let allEvents = [];
        props.events.forEach(event => {
            allEvents.push({
                title: `Entrega: ${event.serviceType === 1 ? "EOS" : "Programada"} | folio: ${event.folio} |${event.userConfirmCollection ? event.userConfirmCollection:"Entrega en planta"}`,
                start: moment(event.programmedDate).format("YYYY-MM-DD"),
                end: moment(event.programmedDate).format("YYYY-MM-DD, h:mm:ss "),
                backgroundColor: event.serviceType === 1 ? COLOR_SUCCESS_NORMAL : "",

            })
        })
        return allEvents;
    };

    return (
        <FullCalendar
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            defaultView="timeGridWeek"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            timeZone="UTC"
            locales={[esLocale, ptLocale]}
            header={{
                left: 'prev,today,next',
                center: 'title',
                right: 'timeGridDay,timeGridWeek,dayGridMonth'
            }}
            contentHeight='100%'
            events={props.events ? formatObjectEvents(props.events) : []}
        />

    )
}

Calendar.propTypes = {
    events: propTypes.array.isRequired,
}

Calendar.defaultProps = {
    events: []
}

export default withImmutablePropsToJS(Calendar)