import axios from 'axios'
import { isNullOrEmpty } from '../helpers/StringHelper'

/** Helpers import section */
import userManager from '../helpers/UserManager'
import { getAuthorizedRequestConfigurationUser } from '../helpers/RequestHelper'
import { combineUsers } from '../helpers/UserHelper'
export const SET_USERS = 'SET_USERS'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const IS_LOADING_USERS = 'IS_LOADING_USERS'
export const IS_SAVING_USER = 'IS_SAVING_USER'
export const IS_FINDING_USER = 'IS_FINDING_USER'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const ADD_ROLE_IN_PROFILE = 'ADD_ROLE_IN_PROFILE'
export const REMOVE_ROLE_FROM_PROFILE = 'REMOVE_ROLE_FROM_PROFILE'
export const UPDATE_EXTERNAL_USER = 'UPDATE_EXTERNAL_USER'
export const IS_UPDATING_PASSWORD_USER = 'IS_UPDATING_PASSWORD_USER'
export const UPDATE_ACTIVE_USER = 'UPDATE_ACTIVE_USER'
export const IS_LOADING_ACTIVE_USER = 'IS_LOADING_ACTIVE_USER'
export const SET_USERS_IN_ROLE = 'SET_USERS_IN_ROLE'
export const SET_INTERNAL_USER_QUERYPARAMETERS = 'SET_INTERNAL_USER_QUERYPARAMETERS'
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR'

export const setUsers = (users) => {
	return {
		type: SET_USERS,
		users
	}
}

const setIsLoadingUsers = (status) => {
	return {
		type: IS_LOADING_USERS,
		status
	}
}
/**
 * Set internal user sort criteria
 */
export const setInternalQueryParameters = (queryParametes) => {
	return {
		type: SET_INTERNAL_USER_QUERYPARAMETERS,
		queryParametes
	}
}

export const updateUserAvatar = (user) => {
	return {
		type: UPDATE_USER_AVATAR,
		user
	}
}

export const loadUsers = (customerToken, supplierToken, queryParameters) => {
	return function(dispatch) {
		dispatch(setIsLoadingUsers(true))
		let allUsers = []
		return userManager
			.getUser()
			.then((user) => {
				let config = getAuthorizedRequestConfigurationUser(user)
				let endPoint = ''
				if (customerToken) {
					endPoint = `${
						process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL
					}users/all${`?customerToken=${customerToken}`}`
				} else if (supplierToken) {
					endPoint = `${
						process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL
					}users/all${`?supplierToken=${supplierToken}`}`
				} else {
					endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/all`
				}
				return axios.get(endPoint, config)
			})
			.then((usersResponse) => {
				allUsers = usersResponse.data
				return axios.all(
					allUsers.map((user) =>
						axios.get(
							`${process.env.REACT_APP_IDENTITY_SERVER}/api/user/userName/${user.userName}`
						)
					)
				)
			})
			.then((allUserResponses) => {
				let allIdentityUsers = allUserResponses.map((response) => response.data)
				allUsers.forEach((user) => {
					let identityUser = allIdentityUsers.find((u) => u.user_name === user.userName)
					if (identityUser) {
						user.name = identityUser.full_name
						user.email = identityUser.email
					}
				})
				let mappedUsers = allUsers.map((user) => mapFromServerResponseToLocalUser(user))
				dispatch(setUsers(mappedUsers))
				if (queryParameters) {
					dispatch(setInternalQueryParameters(queryParameters))
				}
				return Promise.resolve(mappedUsers)
			})
			.catch((error) => {
				console.error('Error loading the users list', error)
				setIsLoadingUsers(false)
				return Promise.reject()
			})
	}
}

var findUserRequest = null

const setIsFindingUser = (status) => {
	return {
		type: IS_FINDING_USER,
		status
	}
}

export const findUserByUserName = (userName) => {
	return function(dispatch) {
		dispatch(setIsFindingUser(true))
		if (findUserRequest != null) {
			findUserRequest.cancel()
		}
		let cancelToken = axios.CancelToken
		findUserRequest = cancelToken.source()
		let config = { cancelToken: findUserRequest.token }
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/users/internalbyquery?q=${userName}`

		return axios
			.get(endPoint, config)
			.then((result) => {
				findUserRequest = null
				let foundUser = null
				if (result.data && result.data.length === 1) {
					let userData = result.data[0]
					foundUser = {
						id: userData.id,
						userName: userData.user_name,
						name: userData.full_name,
						email: userData.email,
						type: userData.user_type
					}
				}
				dispatch(setIsFindingUser(false))
				return Promise.resolve(foundUser)
			})
			.catch((error) => {
				console.error('Error finding user', error)
				dispatch(setIsFindingUser(false))
				return Promise.resolve(null)
			})
	}
}

const setIsSavingUser = (status) => {
	return {
		type: IS_SAVING_USER,
		status
	}
}

const addUser = (user) => {
	return {
		type: ADD_USER,
		user
	}
}

export const addInternalUser = (user) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingUser(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			UserName: user.userName,
			UserType: user.type,
			Email: user.email,
			Name: user.name
		}
		let identityUser
		let identityEndPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/internaluser`
		if (user.id === 'undefined' || user.id === null) {
			return axios
				.post(identityEndPoint, command)
				.then((identityUserResponse) => {
					dispatch(setIsSavingUser(false))
					identityUser = identityUserResponse.data
					let finalDispositionCommand = {
						UserId: identityUser.id,
						UserName: identityUser.user_name,
						UserType: identityUser.user_type
					}
					let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users`
					return axios.post(endPoint, finalDispositionCommand, config)
				})
				.then((finalDispositionUserResponse) => {
					let addedUser = finalDispositionUserResponse.data
					addedUser.name = user.name
					addedUser.email = user.email
					dispatch(setIsSavingUser(false))
					dispatch(addUser(addedUser))
				})
		} else {
			let command = {
				UserId: user.id,
				UserName: user.userName,
				UserType: user.type
			}
			let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users`
			return axios
				.post(endPoint, command, config)
				.then((response) => {
					let addedUser = response.data
					addedUser.type = addedUser.userType
					addedUser.name = user.name
					addedUser.email = user.email
					dispatch(setIsSavingUser(false))
					dispatch(addUser(response.data))
				})
				.catch((error) => {
					console.error('Error adding internal user', error)
					dispatch(setIsSavingUser(false))
					return Promise.reject()
				})
		}
	}
}

export const addExternalUser = (customerToken, supplierToken, user) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingUser(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let identiyCommand = {
			UserName: user.userName,
			Name: user.name,
			Email: user.userName,
			PhoneNumber: user.phoneNumber,
			Password: user.password
		}
		let identityEndPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/externaluser`
		let identityUser
		return axios
			.post(identityEndPoint, identiyCommand)
			.then((identityUserResponse) => {
				identityUser = identityUserResponse.data
				let finalDispositionCommand = {
					UserId: identityUser.id,
					UserName: identityUser.user_name,
					UserType: identityUser.user_type,
					CustomerToken: customerToken,
					SupplierToken: supplierToken
				}
				dispatch(setIsSavingUser(false))
				let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users`
				return axios.post(endPoint, finalDispositionCommand, config)
			})
			.then((finalDispositionUserResponse) => {
				let finalDispositionUser = finalDispositionUserResponse.data
				let addedUser = combineUsers(identityUser, finalDispositionUser)
				dispatch(addUser(addedUser))
			})
			.catch((error) => {
				console.error('Error adding external user', error)
				dispatch(setIsSavingUser(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

const setUserProfile = (user) => {
	return {
		type: SET_USER_PROFILE,
		user
	}
}

export const loadUserProfile = (userId) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingUsers(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)

		let identityEndPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/${userId}`
		let finalDispositionEndPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/${userId}`

		return axios
			.all([axios.get(identityEndPoint), axios.get(finalDispositionEndPoint, config)])
			.then(([identityResponse, finalDispositionResponse]) => {
				let loggedUser = combineUsers(identityResponse.data, finalDispositionResponse.data)
				dispatch(setUserProfile(loggedUser))
			})
			.catch((error) => {
				console.error('Error loading user profile', error)
				dispatch(setIsLoadingUsers(false))
				return Promise.resolve(null)
			})
	}
}

export const saveInternalUserData = (userId, userData) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingUser(true))
		let identityCommand = {
			Name: userData.name,
			Email: userData.email,
			PhoneNumber: userData.phoneNumber
		}
		let identityEndPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/${userId}`
		return axios
			.put(identityEndPoint, identityCommand)
			.then((identityResult) => {
				let identityUser = identityResult.data
				let userProfile = getState()
					.users.get('userProfile')
					.toJS()
				userProfile.name = identityUser.full_name
				userProfile.email = identityUser.email
				userProfile.phoneNumber = identityUser.phone_number
				dispatch(setIsSavingUser(false))
				dispatch(setUserProfile(userProfile))
			})
			.catch((error) => {
				console.error('Error saving user data', error)
				dispatch(setIsSavingUser(false))
				return Promise.reject()
			})
	}
}

const activeOrDisableUser = (user) => {
	return {
		type: UPDATE_ACTIVE_USER,
		user
	}
}

const isLoadingActiveUser = (isLoading) => {
	return {
		type: IS_LOADING_ACTIVE_USER,
		isLoading
	}
}

export const activeUser = (activeOrDisable, user) => {
	return function(dispatch, getState) {
		dispatch(isLoadingActiveUser(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			UserId: user.userId,
			IsActiveUser: activeOrDisable
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/status`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				dispatch(isLoadingActiveUser(false))
				let updateUser = mapFromServerResponseToLocalUser(response.data)
				dispatch(activeOrDisableUser(updateUser))
			})
			.catch((error) => {
				console.error('Error active or disable user', error)
				dispatch(isLoadingActiveUser(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to add role
 * @param {*} role
 */
const addRoleInProfile = (role) => {
	return {
		type: ADD_ROLE_IN_PROFILE,
		role
	}
}

export const addUserInRole = (userId, roleName) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingUser(true))
		let command = {
			UserId: userId,
			Role: roleName
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/roles`
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				dispatch(addRoleInProfile(response.data))
			})
			.catch((error) => {
				console.error('Error adding user in role.', error)
				dispatch(setIsSavingUser(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to delete role
 * @param {*} roleName
 */
const removeRoleFromProfile = (roleName) => {
	return {
		type: REMOVE_ROLE_FROM_PROFILE,
		roleName
	}
}

export const removeUserFromRole = (userId, roleName) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingUser(true))
		let command = {
			UserId: userId,
			RoleName: roleName
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/roles`
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		return axios({
			method: 'delete',
			url: endPoint,
			data: command,
			headers: config.headers
		})
			.then(() => {
				dispatch(removeRoleFromProfile(roleName))
			})
			.catch((error) => {
				console.error('Error removing user from role.', error)
				dispatch(setIsSavingUser(false))
				return Promise.reject()
			})
	}
}

export const uploadLogoUser = (userId, fileBase64) => {
	return function(dispatch) {
		dispatch(setIsSavingUser(true))
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/${userId}/avatar`
		let data = { fileBase64 }

		return axios
			.put(endPoint, data, null)
			.then((serverResponse) => {
				dispatch(setIsSavingUser(false))
				dispatch(updateUserAvatar(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingUser(false))
				console.error('Error uploading user avatar', error)
				return Promise.reject()
			})
	}
}

export const updateUserPasswordDataFromBackend = (user, userId) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingUser(true))
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/users/${userId}/password`
		return axios
			.put(endPoint, user)
			.then(() => {
				dispatch(setIsSavingUser(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingUser(false))
				console.error('error', error)
				return Promise.reject()
			})
	}
}

export const filterUsers = (query, sort) => {
	return function(dispatch, getState) {
		let allUsers = getState().users.get('allUsers')
		if (isNullOrEmpty(query)) {
			let sortedUsers = allUsers.sort((user1, user2) => {
				if (user1.get(sort.by) < user2.get(sort.by)) {
					return sort.criteria === 'desc' ? 1 : -1
				}
				if (user1.get(sort.by) > user2.get(sort.by)) {
					return sort.criteria === 'desc' ? -1 : 1
				}
				return 0
			})

			return Promise.resolve(sortedUsers)
		} else {
			query = query.toLowerCase()
			let filteredChains = allUsers.filter(
				(u) =>
					u
						.get('userName')
						.toLowerCase()
						.includes(query) ||
					u
						.get('name')
						.toLowerCase()
						.includes(query)
			)
			let sortedUsers = filteredChains.sort((user1, user2) => {
				if (user1.get(sort.by) < user2.get(sort.by)) {
					return sort.criteria === 'desc' ? 1 : -1
				}
				if (user1.get(sort.by) > user2.get(sort.by)) {
					return sort.criteria === 'desc' ? -1 : 1
				}
				return 0
			})
			return Promise.resolve(sortedUsers)
		}
	}
}

export const findUserFromIdentityByUserName = (userName) => {
	return function(dispatch) {
		dispatch(setIsFindingUser(true))
		if (findUserRequest != null) {
			findUserRequest.cancel()
		}
		let cancelToken = axios.CancelToken
		findUserRequest = cancelToken.source()
		let config = { cancelToken: findUserRequest.token }
		let command = {
			userName: userName
		}
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}/api/user/findUserName`
		return axios
			.post(endPoint, command, config)
			.then((result) => {
				findUserRequest = null
				let foundUser = null
				if (result.data) {
					let userData = result.data
					foundUser = {
						id: userData.id,
						userName: userData.user_name,
						name: userData.full_name,
						email: userData.email,
						type: userData.user_type
					}
				}
				dispatch(setIsFindingUser(false))
				return Promise.resolve(foundUser)
			})
			.catch((error) => {
				console.error('Error finding user', error)
				dispatch(setIsFindingUser(false))
				return Promise.resolve(null)
			})
	}
}

export const findUserByEmail = (email) => {
	return function(dispatch, getState) {
		let allUsers = getState().users.get('users')
		let filteredUsersByEmail = allUsers.find((u) => u.get('email').toLowerCase() === email)
		return Promise.resolve(filteredUsersByEmail ? true : false)
	}
}

export const setUsersInRole = (usersInRole) => {
	return {
		type: SET_USERS_IN_ROLE,
		usersInRole
	}
}

export const getUsersInRole = (roleName) => {
	return function(dispatch, getState) {
		let allUsers = []

		let usersInRole = getState().users.get('usersInRole')
		if (usersInRole.size > 0) {
			// The profile has been already loaded... return
			return Promise.resolve()
		}
		let customerToken = 'undefined'
		return userManager
			.getUser()
			.then((user) => {
				let config = getAuthorizedRequestConfigurationUser(user)
				let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/usersinrole/${roleName}/${customerToken}`
				return axios.get(endPoint, config)
			})
			.then((usersResponse) => {
				allUsers = usersResponse.data
				return axios.all(
					allUsers.map((user) =>
						axios.get(
							`${process.env.REACT_APP_IDENTITY_SERVER}/api/user/userName/${user.userName}`
						)
					)
				)
			})
			.then((allUserResponses) => {
				let allIdentityUsers = allUserResponses.map((response) => response.data)
				allUsers.forEach((user) => {
					let identityUser = allIdentityUsers.find((u) => u.user_name === user.userName)
					if (identityUser) {
						user.name = identityUser.full_name
						user.email = identityUser.email
					}
				})
				let mappedUsers = allUsers.map((user) => mapFromServerResponseToLocalUser(user))
				dispatch(setUsersInRole(mappedUsers))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error loading the users in role list', error)
				return Promise.reject()
			})
	}
}
/**
 * Action to return list users in Rol from selected
 * @param {*} roleName
 */
export const getUsersInRoleList = (roleName, customerToken) => {
	return function(dispatch, getState) {
		let allUsers = []
		return userManager
			.getUser()
			.then((user) => {
				let config = getAuthorizedRequestConfigurationUser(user)
				let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/usersinrole/${roleName}/${customerToken}`
				return axios.get(endPoint, config)
			})
			.then((usersResponse) => {
				allUsers = usersResponse.data
				return axios.all(
					allUsers.map((user) =>
						axios.get(
							`${process.env.REACT_APP_IDENTITY_SERVER}/api/user/userName/${user.userName}`
						)
					)
				)
			})
			.then((allUserResponses) => {
				let allIdentityUsers = allUserResponses.map((response) => response.data)
				allUsers.forEach((user) => {
					let identityUser = allIdentityUsers.find((u) => u.user_name === user.userName)
					if (identityUser) {
						user.name = identityUser.full_name
						user.email = identityUser.email
					}
				})
				let mappedUsers = allUsers.map((user) => mapFromServerResponseToLocalUser(user))
				return Promise.resolve(mappedUsers)
			})
			.catch((error) => {
				console.error('Error loading the users in role list', error)
				return Promise.reject()
			})
	}
}

export const getUsersFromIdentityById = (roleNames) => {
	return function(dispatch, getState) {
		let allUsers = []

		return userManager
			.getUser()
			.then((user) => {
				return axios.all(
					roleNames.map((roleName) =>
						axios.get(
							`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}users/usersinrole/${roleName}`
						)
					)
				)
			})
			.then((usersResponse) => {
				allUsers = usersResponse.map((response) => response.data)
				let arrayOfUsers = []
				allUsers = allUsers.map((response) =>
					response.map((l) => arrayOfUsers.push(l.userId))
				)
				return axios.all(
					arrayOfUsers.map((user) =>
						axios.get(`${process.env.REACT_APP_IDENTITY_SERVER}/api/user/${user}`)
					)
				)
			})
			.then((allUserResponses) => {
				let allIdentityUsers = allUserResponses.map((response) => response.data)
				return Promise.resolve(allIdentityUsers)
			})
			.catch((error) => {
				console.error('Error loading the users in role list', error)
				return Promise.reject()
			})
	}
}

const mapFromServerResponseToLocalUser = (user) => {
	let mappedUser = { ...user }
	mappedUser.id = user.userId
	mappedUser.name = user.name || ''
	mappedUser.email = user.email || ''
	mappedUser.type = user.userType
	mappedUser.active = user.isActive
	return mappedUser
}
