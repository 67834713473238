import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom'

/** Material UI import section */
import {
    makeStyles,
    Avatar,
    Box,
    CardActions,
    Typography
} from '@material-ui/core';
import SuppliersIcon from '@material-ui/icons/Business';

/** Custom components import section */
import MemoryPager from '../common/DataList/MemoryPager';
import Layout from '../layout/Layout';
import AddItemButton from '../common/AddItemButton';
import Loader from '../common/Loader';
import Toaster from '../common/Toaster';
import SuppliersTable from './components/SuppliersTable';
import AddSupplierPanel from './components/AddSupplierPanel';
import NoDataMessage from '../common/NoDataMessage';
import { AddBtnFake } from '../common/FakeButtons';

/** Actions import section */
import {
    loadAllSuppliers,
    createSupplier,
    setSuppliersQueryParameters
} from '../../store/suppliers/SupplierActions';

import {
    suppliersSelector,
} from '../../store/suppliers/SuppliersSelector'

/** Resources import section */
import { COLOR_SECONDARY_NORMAL, COLOR_LIGHT_GRAY } from '../../resources/constants/Colors';
import NoSuppliersImage from '../../resources/images/noSuppliers.png';
import NoResultsFound from '../../resources/images/searchEmpty.png';

const useSupplierStyles = makeStyles(({
    root: {
        marginTop: 10,
        minWidth: "90vw",
    }
}))

const Index = props => {
    const classes = useSupplierStyles();
    let location = useLocation()
    let history = useHistory()

    const suppliers = useSelector(suppliersSelector)
    const [pageNumber, setPageNumber] = useState(1);
    const [pagination, setPagination] = useState({ pageSize: 10, total: 0 });

    const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(true);
    const [allSuppliersPaginated, setAllSuppliersPaginated] = useState([]);
    const [query, setQuery] = useState(null);
    const [searchBoxActive, setSearchBoxActive] = useState(false);
    const [sortConfig, setSortConfig] = useState({ by: 'name', criteria: 'asc' })
    const [showAddSupplierPanel, setShowAddSupplierPanel] = useState(false);
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    const dispatch = useDispatch();
    const allSuppliers = useSelector(state => state.suppliers.get('allSuppliers'));
    const isSavingSupplier = useSelector(state => state.suppliers.get('isSavingSupplier'));

    /** Load all initial suppliers into application store */
    useEffect(() => {
        const queryParameters = queryString.parse(location.search)
        if (!!queryParameters.pageNumber) {
            setPageNumber(queryParameters.pageNumber)
        }
        let newQuery = queryParameters.query ? queryParameters.query : '';
        if (!!newQuery) {
            setQuery(newQuery);
            setSearchBoxActive(true);
        }

        let newSortConfig = {}
        if (!!queryParameters.sortBy) newSortConfig.by = queryParameters.sortBy;
        if (!!queryParameters.sortDirection) newSortConfig.criteria = queryParameters.sortDirection;
        setSortConfig(newSortConfig)
        dispatch(loadAllSuppliers(queryParameters)).finally(() => setIsLoadingSuppliers(false));
    }, [dispatch, props.match.url, location.search])


    useEffect(() => {
            let usersPerPage = 50;
            let allTotal = suppliers.length;
            const indexOfLastUser = pageNumber * usersPerPage;
            let indexOfFirtsUser = indexOfLastUser - usersPerPage;
            let currentUsers = suppliers.slice(indexOfFirtsUser, indexOfLastUser)
            setAllSuppliersPaginated(currentUsers)
            setPagination({ pageSize: usersPerPage, total: allTotal });
    }, [suppliers, pagination.total, pageNumber])


    const onAddSupplierClick = () => {
        setShowAddSupplierPanel(true);
    }

    const onToggleSearchStatus = active => {
        setSearchBoxActive(active);
    }

    const onSupplierClick = supplier => {
        props.history.push(`/suppliers/${supplier.token}`);
    }

/**
 * Update sourt criteria
 */
const onSortingCriteriaChange = (sortBy, sortCriteria) => {
    const queryParams = queryString.parse(location.search)
    queryParams.sortBy = sortBy
    queryParams.sortDirection = sortCriteria
    history.push({ search: queryString.stringify(queryParams) })
    dispatch(setSuppliersQueryParameters(queryParams))
}

const onSearchBoxChange = (searchQuery) => {
    const queryParams = queryString.parse(location.search)
    if (searchQuery) {
        queryParams.query = searchQuery
        setQuery(searchQuery);
    }
    else delete queryParams.query
    history.push({ search: queryString.stringify(queryParams) })
    dispatch(setSuppliersQueryParameters(queryParams))
}

/**
* On change page number
*/
const onChangePageNumber = (page) => {
    setPageNumber(page)
    const queryParams = queryString.parse(location.search)
    queryParams.pageNumber = page
    history.push({ search: queryString.stringify(queryParams) })
}

    const onCreateSupplier = supplier => {
        dispatch(createSupplier(supplier))
            .then(() => {
                setShowAddSupplierPanel(false);
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al agregar el proveedor", variant: 'error' });
            })
    }

    // const onUpdateSupplierStatus = (supplier, status) => {
    //     setSelectedSupplierToken(supplier.token)
    //     dispatch(updateSupplierStatus(supplier, status))
    //         .finally(() => {
    //             setSelectedSupplierToken(null);
    //         })
    //         .catch(() => {
    //             setToasterOptions({ open: true, message: "Error al actualizar la informacion del proveedor.", variant: 'error' });
    //         })        
    // }

    const getNoDataMessasge = () => {
        let message, image;
        if (allSuppliers.size > 0 && allSuppliersPaginated.length === 0) {
            message = (
                <Typography>
                    No se encontraron resultados para esta búsqueda.
                </Typography>);
            image = NoResultsFound;
        }
        else {
            message = (
                <Typography>
                    Para agregar un nuevo proveedor da clic en el boton "Agregar" <AddBtnFake /> y captura los datos solicitados.
                </Typography>
            );
            image = NoSuppliersImage;
        }
        return (
            <NoDataMessage
                message={message}
                image={image}
            />
        );
    }

    return (
        <Layout
            searchBoxOptions={{
                enabled: true,
                placeholder: "Buscar proveedores",
                value: query,
                onChange: onSearchBoxChange,
                active: searchBoxActive,
                onToggleStatus: onToggleSearchStatus
            }}
        >
            <div className={classes.root}>
                {/** Suppliers header */}
                <SuppliersHeader
                    onAddSupplierButtonClick={onAddSupplierClick}
                />
                {/** Suppliers table */}
                {
                    {
                        true: <Loader />,
                        false: <SuppliersTable
                            suppliers={allSuppliersPaginated}
                            onSupplierRowClick={onSupplierClick}
                            sortConfig={sortConfig}
                            onSortingCriteriaChange={onSortingCriteriaChange}
                            noDataMessage={getNoDataMessasge()}
                        //selectedSupplierToken={selectedSupplierToken}
                        //onUpdateSupplierStatus={onUpdateSupplierStatus}
                        // isSavingSupplier={isSavingSupplier}
                        />
                    }[isLoadingSuppliers]
                }
                <CardActions>
                    <MemoryPager
                        pageNumber={pageNumber - 1}
                        pageSize={pagination.pageSize}
                        count={pagination.total}
                        onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
                    />
                </CardActions>
            </div>
            {/** Add customer panel */}
            {
                showAddSupplierPanel &&
                <AddSupplierPanel
                    open={showAddSupplierPanel}
                    isSaving={isSavingSupplier}
                    onClose={() => setShowAddSupplierPanel(false)}
                    onCreateSupplier={onCreateSupplier}
                />
            }
            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    {...toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </Layout>
    );
}

export default withRouter(Index);

const useHeaderStyles = makeStyles(({
    root: {
        marginTop: -20,
        marginBottom: 0
    },
    icon: {
        // marginRight: 10
        color: COLOR_SECONDARY_NORMAL
    },
    avatar: {
        marginTop: 8,
    },
    buttonAddUser: {
        marginTop: 3,
    },
    title: {
        marginTop: 6,
        marginLeft: 8,
        color: COLOR_SECONDARY_NORMAL
    }
}))

const SuppliersHeader = props => {
    const classes = useHeaderStyles();

    return (
        <Box
            className={classes.root}
            display="flex"
            p={1}
            alignItems="center"
            justifyContent="flex-start"
        >
            <Box
                className={classes.avatar}
                display="flex"
                width="100%"
            >
                <Avatar style={{ color: COLOR_SECONDARY_NORMAL, backgroundColor: COLOR_LIGHT_GRAY, fontSize: "14px", height: "35px", width: "35px" }}>
                    <SuppliersIcon className={classes.icon} />
                </Avatar>
                <Typography variant="button" className={classes.title}><strong>PROVEEDORES</strong></Typography>
            </Box>
            <Box
                className={classes.buttonAddUser}
                p={1}
                flexShrink={0}
            >
                <AddItemButton
                    title={"Agregar proveedor"}
                    onClick={props.onAddSupplierButtonClick}
                />
            </Box>
        </Box>
    );
}
