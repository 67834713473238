import React, { useState } from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import {
	Button,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Typography
} from '@material-ui/core'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'

/** Custom components import section */
import { DialogView } from '../../common/Dialog'

/** Resources import section */
import {
	COLOR_PRIMARY_NORMAL,
	COLOR_SUCCESS_NORMAL,
	COLOR_DANGER_NORMAL
} from '../../../resources/constants/Colors'

const ConfirmationDialog = (props) => {
	const {
		executing,
		invalidPasswordDisplayed,
		data,
		onClose,
		onConfirm
	} = props

	/** Local state */
	const [showPassword, setShowPassword] = useState(false)
	const [password, setPassword] = useState('')

	const classes = useStyles()

	const disableSaveButton = () => {
		if (password && password.length > 4) return false
		return true
	}
	/**
	 * Toggle status in order to change between show or hide password
	 */
	const onToggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	/**
	 * Handle change of password
	 */
	const onPropertyChange = (event) => {
		const { value } = event.target
		setPassword(value)
	}

	/**
	 * Handle click event of confirmation button
	 */
	const onButtonConfirmClick = () => {
		onConfirm(password, data.token)
	}

	return (
		<DialogView
			open={true}
			isSaving={executing}
			onClose={onClose}
			header={<div>Confirmar</div>}
			maxWidth={'sm'}
			children={
				<div className={classes.dialogContent}>
					<Typography>
						Confirme que desea eliminar el reporte: {data.fileName}
					</Typography>
					<TextField
						fullWidth
						data-cy='txt-confirm-password'
						autoComplete='off'
						label={'Contraseña'}
						variant='outlined'
						name='password'
						onChange={onPropertyChange}
						placeholder='Ingrese su contraseña para confirmar la operación'
						helperText={
							invalidPasswordDisplayed ? (
								<strong style={{ color: COLOR_DANGER_NORMAL }}>
									{
										'Contraseña incorrecta, por favor verifique.'
									}
								</strong>
							) : (
								''
							)
						}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										edge='end'
										aria-label='toggle password visibility'
										onClick={onToggleShowPassword}
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							),
							classes: {
								root: classes.cssOutlinedInput,
								focused: classes.cssFocused,
								notchedOutline: classes.notchedOutline
							},
							inputMode: 'text',
							autoComplete: 'off'
						}}
					/>
				</div>
			}
			actions={
				<Button
					className={classes.buttonAccept}
					onClick={onButtonConfirmClick}
					disabled={disableSaveButton() || props.executing}
				>
					OK
				</Button>
			}
		/>
	)
}

ConfirmationDialog.propTypes = {
	executing: PropTypes.bool,
    invalidPasswordDisplayed: PropTypes.bool,
	data: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired
}

ConfirmationDialog.defaultProps = {
	executing: false,
    invalidPasswordDisplayed: false,
	data: {},
	onClose: () => console.warn('[onClose] not defined'),
	onConfirm: () => console.warn('[onConfirm] not defined')
}

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		'& > *': {
			marginBottom: 10
		}
	},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {}
	},
	cssFocused: {},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: `${COLOR_PRIMARY_NORMAL} !important`,
		color: `${COLOR_PRIMARY_NORMAL} !important`
	},
	buttonAccept: {
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: theme.palette.common.white,
		right: '10px',
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: theme.palette.common.white,
			opacity: 0.75
		}
	}
}))

export default ConfirmationDialog
