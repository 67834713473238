import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import {OidcProvider, loadUser} from 'redux-oidc';
import {Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import App from './App';
import CallbackPage from '../src/views/home/CallbackPage';

import * as serviceWorker from './serviceWorker';

import reducers from './store/RootReducer';
import userManager from './store/helpers/UserManager';

import './resources/fonts/roboto/font-face.css';
import './resources/styles/main.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themeContent from './resources/themes/theme.json';
const homeTheme = createMuiTheme(themeContent);

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));
loadUser(store, userManager);

ReactDOM.render(
    <MuiThemeProvider theme={homeTheme}>
        <Provider store={store}> 
            <OidcProvider store={store} userManager={userManager}>
                <Router basename={`${process.env.REACT_APP_ROOT}`}>
                    <Switch>
                        <Route exact path='/callback' component={CallbackPage} />
                        <Route path='/' component={App} />
                    </Switch>
                </Router>
            </OidcProvider>
        </Provider> 
    </MuiThemeProvider>,
    document.getElementById('root')
);
// expose store when run in Cypress
if (window.Cypress) {
    window.store = store
  }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
