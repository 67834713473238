import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'

/** Material UI import section */
import { OutlinedInput, Typography, makeStyles, Box } from '@material-ui/core'
import { Avatar, Paper, Button } from '@material-ui/core'
import Vehicle from '@material-ui/icons/LocalShipping'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

/** Custom components import section */
import Toaster from '../../common/Toaster'

/** Redux import section */
import { useDispatch, useSelector } from 'react-redux'
import { saveAlertSetting, loadAlert } from '../../../store/customers/CustomerActions'
import { getAlertSettingSelector } from '../../../store/customers/CustomerSelectors'

/** Helpers impor section */
import {
	COLOR_SECONDARY_NORMAL,
	COLOR_LIGHT_GRAY,
	COLOR_ALTERNATE_NORMAL,
	COLOR_ALTERNATE_PRESSED
} from '../../../resources/constants/Colors'
import Loader from '../../common/Loader'

const useUserStyles = makeStyles({
	root: {
		padding: 10
	},
	icon: {
		color: COLOR_SECONDARY_NORMAL
	},
	rowsDirection: {
		display: 'flex',
		flexDirection: 'row'
	},
	buttonAddUser: {
		marginTop: 3
	},
	primaryTitle: {
		marginTop: 6,
		marginLeft: 8,
		color: COLOR_ALTERNATE_NORMAL
	},
	secondaryTitle: {
		marginTop: 2,
		marginLeft: 8,
		color: COLOR_SECONDARY_NORMAL
	},
	subtitle: {
		marginTop: 6,
		marginLeft: 8,
		fontSize: 10,
		color: COLOR_SECONDARY_NORMAL
	},
	button_other: {
		borderRadius: 15
	},
	button_save: {
		borderRadius: 15,
		backgroundColor: COLOR_ALTERNATE_NORMAL,
		color: 'white',
		'&:hover': {
			backgroundColor: COLOR_ALTERNATE_PRESSED
		}
	},
	spaceBtn: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around'
	},
	textField: {
		width: 70,
		height: 20,
		marginLeft: 6,
		borderRadius: 5,
		fontSize: 10
	}
})

const AlertTypes = {
	Coolers: 'COOLERS',
	Doors: 'DOORS'
}

const FinalDispositionTransfer = (props) => {
	const { customerCode } = props

	const classes = useUserStyles()

	/** Defines local state */
	const [loadingAlertSetting, setLoadingAlertSetting] = useState(false)
	const [selectedAlertType, setSelectedAlertType] = useState(AlertTypes.Coolers)
	const [selectedCoolersQuantity, setSelectedCoolersQuantity] = useState(0)
	const [selectedDoorsQuantity, setSelectedDoorsQuantity] = useState(0)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})

	/** Connect with store */
	const dispatch = useDispatch()
	const alertSetting = useSelector((state) => getAlertSettingSelector(state))

	useEffect(() => {
		dispatch(loadAlert(customerCode)).finally(() => {
			setLoadingAlertSetting(false)
		})
	}, [customerCode, dispatch])

	useEffect(() => {
		if (alertSetting) {
			setSelectedCoolersQuantity(alertSetting.coolersQuantity)
			setSelectedDoorsQuantity(alertSetting.doorsQuantity)
			setSelectedAlertType(alertSetting.type)
		} else {
			setSelectedCoolersQuantity(0)
			setSelectedDoorsQuantity(0)
			setSelectedAlertType(AlertTypes.Coolers)
		}
	}, [alertSetting])

	const handleAlertTypeChange = (event) => {
		const { value } = event.target
		setSelectedAlertType(value)
	}

	const handleCoolersQuantityChange = (event) => {
		const { value } = event.target
		setSelectedCoolersQuantity(value)
	}

	const handleDoorsQuantityChange = (event) => {
		const { value } = event.target
		setSelectedDoorsQuantity(value)
	}

	const handleResetForm = () => {
		setSelectedCoolersQuantity(0)
		setSelectedDoorsQuantity(0)
	}

	const handleSaveData = (event) => {
		event.preventDefault()
		const alertSetting = {
			customerCode,
			type: selectedAlertType,
			coolersQuantity: selectedCoolersQuantity,
			doorsQuantity: selectedDoorsQuantity
		}
		dispatch(saveAlertSetting(alertSetting))
			.then(() => {})
			.catch(() => {
				if (selectedDoorsQuantity < 1) {
					setToasterOptions({
						open: true,
						message: 'El número de puertas debe ser mayor a cero',
						variant: 'error'
					})
				}
				if (selectedCoolersQuantity < 1) {
					setToasterOptions({
						open: true,
						message: 'El número de equipos debe ser mayor a cero',
						variant: 'error'
					})
				}
			})
	}

	if (loadingAlertSetting) {
		return <Loader />
	}

	return (
		<Paper className={classes.root}>
			{/* Header section */}
			<TransferHeader />
			{/* Content section */}
			<Box className={classes.root} display='flex' width='100%' justifyContent={'center'}>
				<form onSubmit={handleSaveData}>
					<Box flexDirection={'column'}>
						<div className={classes.rowsDirection}>
							<Avatar
								style={{
									color: COLOR_SECONDARY_NORMAL,
									backgroundColor: COLOR_LIGHT_GRAY,
									fontSize: '14px',
									height: '35px',
									width: '35px'
								}}
							>
								<Vehicle className={classes.icon} />
							</Avatar>
							<div>
								<Box flexDirection={'column'}>
									<Typography variant='button' className={classes.primaryTitle}>
										<strong>{'Alertas para el portal CRM'}</strong>
									</Typography>
								</Box>
								<Box>
									<p className={classes.subtitle}>
										Configure las alertas para la creación de solicitudes de
										Disposición final
									</p>
									<Typography variant='button' className={classes.secondaryTitle}>
										<strong>{'Enviar alertas por:'}</strong>
									</Typography>
								</Box>

								<Box marginLeft={-5} mb={3}>
									<RadioGroup
										className={classes.spaceBtn}
										onChange={handleAlertTypeChange}
									>
										<Box
											display='flex'
											flexDirection={'row'}
											alignItems={'center'}
										>
											<p className={classes.subtitle}>No. Equipos</p>
											<Radio
												checked={selectedAlertType === AlertTypes.Coolers}
												value={AlertTypes.Coolers}
												style={{ color: COLOR_ALTERNATE_NORMAL }}
												name='radioButton-COOLERS'
											/>
										</Box>
										<Box
											display='flex'
											flexDirection={'row'}
											alignItems={'center'}
										>
											<p className={classes.subtitle}>No. Puertas</p>
											<Radio
												checked={selectedAlertType === AlertTypes.Doors}
												value={AlertTypes.Doors}
												style={{ color: COLOR_ALTERNATE_NORMAL }}
												name='radioButton-DOORS'
											/>
										</Box>
									</RadioGroup>
								</Box>

								<Box>
									<p className={classes.subtitle} mb={3}>
										Configure la cantidad ideal de equipos por traslado de
										Disposición final
									</p>
									<Typography variant='button' className={classes.secondaryTitle}>
										<strong>{'Cantidad ideal'}</strong>
									</Typography>
								</Box>

								<Box marginLeft={-6} mb={3}>
									<Box className={classes.spaceBtn}>
										<Box display='flex' flexDirection={'column'}>
											<p className={classes.subtitle}>No. Equipos</p>
											<OutlinedInput
												id='coolersTX'
												className={classes.textField}
												type='number'
												onChange={handleCoolersQuantityChange}
												value={selectedCoolersQuantity}
											/>
										</Box>
										<Box display='flex' flexDirection={'column'}>
											<p className={classes.subtitle} marginLeft={5}>
												No. Puertas
											</p>
											<OutlinedInput
												id='doorsTX'
												className={classes.textField}
												type='number'
												onChange={handleDoorsQuantityChange}
												value={selectedDoorsQuantity}
											/>
										</Box>
									</Box>
								</Box>

								<Box className={classes.spaceBtn} mb={20}>
									<Button
										size='small'
										variant='contained'
										className={classes.button_other}
										onClick={handleResetForm}
									>
										Restablecer valores
									</Button>
									<Button
										size='small'
										variant='contained'
										className={classes.button_save}
										type='submit'
									>
										Guardar cambios
									</Button>
								</Box>
							</div>
						</div>
					</Box>
				</form>
				{/** Toaster */}

				{toasterOptions.open && (
					<Toaster
						{...toasterOptions}
						onClose={() => setToasterOptions({ open: false })}
					/>
				)}
			</Box>
		</Paper>
	)
}

FinalDispositionTransfer.propTypes = {
	customerCode: propTypes.string.isRequired
}

FinalDispositionTransfer.defaultProps = {
	customerCode: ''
}

export default withRouter(FinalDispositionTransfer)

/**
 * Defines header section on Final disposition transfer
 * @param {object} props
 * @returns
 */
const TransferHeader = (props) => {
	const classes = useHeaderStyles()

	return <div className={classes.root}></div>
}

const useHeaderStyles = makeStyles((theme) => ({
	root: {
		height: theme.spacing(8),
		marginBottom: 0
	}
}))
