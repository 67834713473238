/**Import react section */
import React from 'react'
import 'react-circular-progressbar/dist/styles.css'

/**Import material UI Section */
import { makeStyles } from '@material-ui/styles'

import { Grid, Typography } from '@material-ui/core'
import {
	COLOR_PRIMARY_NORMAL,
	COLOR_WHITE,
	COLOR_SUCCESS
} from '../../../resources/constants/Colors'
import CircularProgressBar from '../../common/CircularProgressBar'

const GraphicsPercentaje = (props) => {
	const classes = useStyles()

	const { percentageItemsReception, percentageWorkOrderLinesClose } = props
	return (
		<Grid
			container
			direction='row'
			justifyContent='space-around'
			alignItems='center'
			className={classes.root}
		>
			<Grid item xs={3} container style={{ display: 'flex' }} justifyContent='space-around'>
				<Typography align='center' className={classes.text}>
					{' Equipos recibidos'}
				</Typography>
				<div className={classes.contentGraphics}>
					<CircularProgressBar
						value={percentageItemsReception ? percentageItemsReception : 0}
						color={`rgba(64,224,208, ${80 / 100})`}
					/>
				</div>
			</Grid>
			<Grid item xs={3} container style={{ display: 'flex' }} justifyContent='space-around'>
				<Typography align='center' className={classes.text}>
					{' Equipos destruidos '}
				</Typography>
				<div className={classes.contentGraphics}>
					<CircularProgressBar
						value={percentageWorkOrderLinesClose}
						color={`rgba(44, 236, 9, ${80 / 100})`}
					/>
				</div>
			</Grid>
		</Grid>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		paddingTop: 10,
		paddingBottom: 10
	},
	total: {
		backgroundColor: `${COLOR_WHITE}`,
		borderLeft: `10px solid ${COLOR_SUCCESS}`
	},
	text: {
		marginTop: '-5px',
		color: COLOR_PRIMARY_NORMAL
	},
	textTotal: {
		paddingLeft: 10
	},
	contentGraphics: {
		width: 80,
		height: 80
	}
}))

export default GraphicsPercentaje
