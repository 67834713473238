import React from 'react'
import propTypes from 'prop-types'
/**Import material UI sections */
import { Fab, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import DataList from '../../common/DataList/DataList'

import { COLOR_WHITE, COLOR_FOCUS_NORMAL } from '../../../resources/constants/Colors'
import { UserTypes } from '../../../resources/constants/AppConstants'

/**
 * Users table presentional component
 */
const UsersTable = (props) => {
	const onRenderCellItem = (dataSource, item) => {
		switch (dataSource) {
			case 'name':
				return <Typography variant='overline'>{item.name}</Typography>
			case 'userName':
				return <Typography variant='overline'>{item.userName}</Typography>
			case 'email':
				return <Typography variant='overline'>{item.email}</Typography>
			case 'active':
				return props.isLoadingActiveUser && props.selectedUserId === item.userId ? (
					<CircularProgress color='secondary' size={20} />
				) : (
					<FormControlLabel
						control={
							<CustomSwitch
								onChange={(event) => {
									toggleActiveUser(event.target.checked, item)
								}}
								checked={item.active ? true : false}
							/>
						}
					/>
				)
			default:
				return null
		}
	}

	const onRowClick = (item, event) => {
		let tagName = event.target.tagName
		if (props.onUserRowClick && tagName !== 'INPUT') {
			props.onUserRowClick(item)
		}
	}

	const onSortingCriteriaChange = (dataSource, sortCriteria) => {
		if (props.onSortingCriteriaChange) {
			props.onSortingCriteriaChange(dataSource, sortCriteria)
		}
	}

	const toggleActiveUser = (assigned, item) => {
		const userSelected = props.users.find((user) => user.userId === item.userId)
		if (props.onActiveUser) {
			props.onActiveUser(assigned, userSelected)
		}
	}

	return (
		<DataList
			data={props.users}
			configuration={
				props.usersType === UserTypes.ACTIVE_DIRECTORY
					? internalUsersTableConfiguration
					: externalUsersTableConfiguration
			}
			onRenderCellItem={onRenderCellItem}
			onRowClick={onRowClick}
			onSortingCriteriaChange={onSortingCriteriaChange}
			sortConfig={props.sortConfig}
			noDataMessage={
				<span>
					{props.noDataMessage && props.noDataMessage}
					{!props.noDataMessage && (
						<Typography>
							Para agregar un nuevo usuario da clic en el boton "Agregar" <AddFab /> y
							captura los datos solicitados.
						</Typography>
					)}
				</span>
			}
		/>
	)
}

UsersTable.propTypes = {
	usersType: propTypes.oneOf([UserTypes.ACTIVE_DIRECTORY, UserTypes.NON_ACTIVE_DIRECTORY])
		.isRequired,
	users: propTypes.array.isRequired,
	onUserClick: propTypes.func
}

UsersTable.defaultProps = {
	users: []
}

const internalUsersTableConfiguration = {
	columns: [
		{
			dataSource: 'userName',
			header: 'Número de socio',
			isSortable: true
		},
		{
			dataSource: 'name',
			header: 'Nombre',
			isSortable: true
		},
		{
			dataSource: 'email',
			header: 'Correo',
			isSortable: true
		},
		{
			dataSource: 'active',
			header: 'Activo',
			isSortable: true
		}
	]
}

const externalUsersTableConfiguration = {
	columns: [
		{
			dataSource: 'userName',
			header: 'Usuario',
			isSortable: true
		},
		{
			dataSource: 'name',
			header: 'Nombre',
			isSortable: true
		},
		{
			dataSource: 'phoneNumber',
			header: 'Teléfono'
		}
		// , {
		//     dataSource: "active",
		//     header: "Activo"
		// }
	]
}

const AddFab = () => {
	return (
		<Fab size='small' style={{ backgroundColor: COLOR_FOCUS_NORMAL }}>
			<AddIcon style={{ color: COLOR_WHITE }} />
		</Fab>
	)
}

const CustomSwitch = withStyles({
	switchBase: {
		color: 'white',
		'&$checked': {
			color: '#3AC47D'
		},
		'&$checked + $track': {
			backgroundColor: '#008265'
		}
	},
	checked: {},
	track: {}
})(Switch)

export default UsersTable
