/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';

/** Material UI Section*/
import { makeStyles } from '@material-ui/styles';
/** Custom component import section */
import TextBox from '../../common/TextBox';

/**Import helper section */

/** Material UI Section*/
import {
    Grid,
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({

}))

const NotificationDays = props => {
    const classes = useStyles();


    return (
        <Grid item xs={12} >
            <Box display="flex" justifyContent="flex-start" m={1} p={1} >
                <Box p={1}>
                    <Typography variant="subtitle1" display="block" style={{ marginTop: "5px", marginLeft: "-10px" }} noWrap>
                        Notificarme
                    </Typography>
                </Box>
                <Box p={1} style={{ marginTop: "-10px" }}>
                    <TextBox
                        dataCy='input-notification'
                        fullWidth
                        active={true}
                        margin="dense"
                        name="notification"
                        value={props.notificationValue}
                        variant="outlined"
                        onChange={props.onNotificationChange}
                    />
                </Box>
                <Box p={1} >
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            data-cy='select-type-notification'
                            style={{ height: 40, width: 150, marginTop: "-3px" }}
                            value={props.notificationUnit}
                            onChange={props.onUnitsChange}
                        >
                            <MenuItem value="D">Días</MenuItem>
                            <MenuItem value="W">Semanas</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Grid>
    )
}

NotificationDays.propTypes = {
    onFileReceived: PropTypes.func,
    documents: PropTypes.array,
    open: PropTypes.bool,
    isSaving: PropTypes.bool,

}
NotificationDays.defaultProps = {
    documents: [],
    open: false,
    isSaving: false
}

export default NotificationDays;  