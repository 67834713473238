import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import uuidv4 from 'uuid/v4'

/** Material UI import section */
import { makeStyles } from '@material-ui/core/styles'
import {
	AppBar,
	Avatar,
	Toolbar,
	Fab,
	List,
	ListItem,
	ListItemText,
	Divider,
	Typography,
	ClickAwayListener,
	Popper,
	Paper,
	Tooltip,
	Button,
	ListItemIcon,
	Badge
} from '@material-ui/core'
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined'
import DocumentIcon from '@material-ui/icons/Description'
import TicketIcon from '@material-ui/icons/Receipt'
import SettingsIcon from '@material-ui/icons/Settings'
import NotificationIcon from '@material-ui/icons/NotificationsNone'
import AppartamentIcon from '@material-ui/icons/BusinessTwoTone'
import PeopleIcon from '@material-ui/icons/PeopleTwoTone'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import AssignmentIcon from '@material-ui/icons/Assignment'

/** Customs components import section */
import SearchBox from '../common/SearchBox'
import Notifications from './Notifications'
/** Helpers import section */

/** Resources import section */
import {
	COLOR_ALTERNATE_NORMAL,
	COLOR_WHITE,
	COLOR_GRAY,
	COLOR_PRIMARY_LIGHT,
	COLOR_PRIMARY_NORMAL,
	COLOR_FOCUS_NORMAL
} from '../../resources/constants/Colors'
import eosLogo from '../../resources/images/logoEOS.png'

/**Import icons top bar menu*/
import { toSafeObject } from '../../store/helpers/ImmutableHelper'
import { isUserInRole } from '../../store/helpers/UserHelper'
import { AppRoles } from '../../resources/constants/AppConstants'
import { executeLogout } from '../../store/session/SessionActions'

const useStyle = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		paddingLeft: '20px',
		boxShadow: '0px 2px 6px #0000000A',
		opacity: '1',
		backgroundColor: COLOR_WHITE
	},
	flex: {
		marginLeft: 'auto'
	},
	eosLogo: {
		maxHeight: '45px',
		cursor: 'pointer'
	},
	breadCrum: {
		color: 'white'
	},
	avatar: {
		margin: 10,
		width: 20,
		height: 20
	},
	removeBorder: {
		boxShadow: '0px 0px 0px 0px'
	},
	settingsIcon: {
		boxShadow: '0px 0px 0px 0px',
		cursor: 'pointer',
		color: COLOR_GRAY,
		height: 25,
		width: 35,
		borderRadius: '50% !important'
	},
	searchContainer: {
		marginLeft: 23
	},
	optionIcon: {
		boxShadow: '0px 0px 0px 0px',
		backgroundColor: `${COLOR_FOCUS_NORMAL} !important`,
		cursor: 'pointer !important',
		marginRight: '23px !important',
		height: 25,
		width: 35,
		borderRadius: '50% !important'
	},
	avarIcon: {
		marginLeft: 15,
		cursor: 'pointer',
		height: 35,
		width: 35
	},
	arrowIcon: {
		cursor: 'pointer',
		color: COLOR_GRAY
	},
	notificationIcon: {
		boxShadow: '0px 0px 0px 0px',
		cursor: 'pointer',
		marginRight: 23,
		color: COLOR_GRAY,
		height: 25,
		width: 35
	},
	customBadge: {
		backgroundColor: '#D92550',
		color: 'white'
	}
}))

/**
 * NavBar component
 * @param {*} props
 */
const NavBar = (props) => {
	const classes = useStyle()
	const { searchBoxOptions, notificationData } = props

	// Connect with store
	const [anchorEl, setAnchorEl] = useState(null)
	const dispatch = useDispatch()

	/**Selected user fron store */
	const loggedUser = useSelector((state) =>
		toSafeObject(state.session.get('loggedUser'))
	)
	const firstLetterName = loggedUser.name.charAt(0).toUpperCase()

	/**Get roles fron user loggout */
	const userAvatar = loggedUser ? loggedUser.avatarUrl : null

	/** Close session*/
	const onLogout = () => {
		dispatch(executeLogout())
	}

	/**Redirect to  page  */
	const redirectToProfileView = () => {
		props.history.push(`/users/${loggedUser.id}`)
	}

	const onReportsButtonClick = () => {
		props.history.push(`/reports`)
	}

	const onDocumentsButtonClick = () => {
		props.history.push(`/documents`)
	}

	const onCalendarButtonClick = () => {
		props.history.push(`/calendar`)
	}

	const onRedirectToRequests = () => {
		props.history.push(`/requests`)
	}

	const redirectToHome = () => {
		props.history.push(`/`)
	}

	/**Handle menú notification */
	const handleOnClickToShowPopper = (event) => {
		if (anchorEl && anchorEl.id === event.currentTarget.id)
			setAnchorEl(null)
		else setAnchorEl(event.currentTarget)
	}

	const closePopper = () => {
		setAnchorEl(null)
	}

	const getNotification = () => {
		if (
			isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles) ||
			isUserInRole(AppRoles.ROLE_DOCUMENTS_OPERATIVES, loggedUser.roles)
		) {
			return [
				<Tooltip key={uuidv4()} title={'Notificaciones'}>
					<Fab
						size='small'
						id='notification'
						className={classes.notificationIcon}
						style={{ backgroundColor: 'rgba(84, 92, 216, 1)' }}
						onClick={handleOnClickToShowPopper}
					>
						<Badge
							classes={{ badge: classes.customBadge }}
							badgeContent={
								notificationData ? notificationData.total : 0
							}
						>
							<NotificationIcon style={{ color: COLOR_WHITE }} />
						</Badge>
					</Fab>
				</Tooltip>,
				<Notifications
					key={uuidv4()}
					classes={classes}
					history={props.history}
					user={loggedUser}
					anchorEl={anchorEl}
					onClickAway={closePopper}
					notificationData={notificationData}
				/>
			]
		}
		return null
	}

	const getSettings = () => {
		if (isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles)) {
			return [
				<Tooltip key={uuidv4()} title={'Administración'}>
					<React.Fragment>
						<Fab
							size='small'
							id='settings'
							data-cy='btn-setting-arrow'
							className={classes.settingsIcon}
							style={{ backgroundColor: COLOR_ALTERNATE_NORMAL }}
							onClick={handleOnClickToShowPopper}
						>
							<SettingsIcon style={{ color: COLOR_WHITE }} />
						</Fab>
						<ArrowDropDownIcon
							id={'settings'}
							className={classes.arrowIcon}
							onClick={handleOnClickToShowPopper}
						/>
					</React.Fragment>
				</Tooltip>,
				<Settings
					key={uuidv4()}
					classes={classes}
					history={props.history}
					user={loggedUser}
					anchorEl={anchorEl}
					onClickAway={closePopper}
				/>
			]
		}
		return null
	}

	/// Update url logo from update avar user
	let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${userAvatar}`
	let logoUrl = urlImage + '?token=' + uuidv4()

	return (
		<div>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Tooltip title='Inicio' placement='right'>
						<img
							src={eosLogo}
							className={classes.eosLogo}
							alt='eos-logo'
							onClick={redirectToHome}
						/>
					</Tooltip>
					{/** Search options */}
					{searchBoxOptions.enabled && (
						<div className={classes.searchContainer}>
							<SearchBox {...searchBoxOptions} />
						</div>
					)}
					<div className={classes.flex}></div>
					{/** Notifications */}
					{notificationData.enabled && getNotification()}

					{/** Calendar */}
					{(isUserInRole(AppRoles.SALES_MANAGER, loggedUser.roles) ||
						isUserInRole(
							AppRoles.ADMINISTRATOR,
							loggedUser.roles
						)) && (
						<Tooltip title={'Calendario'}>
							<Fab
								size='small'
								className={classes.optionIcon}
								onClick={onCalendarButtonClick}
							>
								<CalendarIcon style={{ color: COLOR_WHITE }} />
							</Fab>
						</Tooltip>
					)}

					{/** Documents */}
					{(isUserInRole(AppRoles.SALES_MANAGER, loggedUser.roles) ||
						isUserInRole(
							AppRoles.CUSTOMER_GOVERNMENT,
							loggedUser.roles
						) ||
						isUserInRole(
							AppRoles.ROLE_DOCUMENTS_OPERATIVES,
							loggedUser.roles
						) ||
						isUserInRole(
							AppRoles.ADMINISTRATOR,
							loggedUser.roles
						)) && (
						<Tooltip title={'Documentos'}>
							<Fab
								size='small'
								className={classes.optionIcon}
								onClick={onDocumentsButtonClick}
							>
								<DocumentIcon style={{ color: COLOR_WHITE }} />
							</Fab>
						</Tooltip>
					)}

					{/** Requests */}
					<Tooltip title={'Solicitudes'}>
						<Fab
							size='small'
							className={classes.optionIcon}
							onClick={onRedirectToRequests}
						>
							<TicketIcon style={{ color: COLOR_WHITE }} />
						</Fab>
					</Tooltip>

					{/** Reports */}
					{(isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles) ||
						isUserInRole(
							AppRoles.CUSTOMER_VISUALIZATOR,
							loggedUser.roles
						)) && (
						<Tooltip title={'Reportes'}>
							<Fab
								size='small'
								className={classes.optionIcon}
								onClick={onReportsButtonClick}
							>
								<AssignmentIcon
									style={{ color: COLOR_WHITE }}
								/>
							</Fab>
						</Tooltip>
					)}

					{/** Settings */}
					{getSettings()}

					{/** User profile options */}
					{userAvatar ? (
						<Avatar
							color='secondary'
							id='profile'
							data-cy='avatar-logued-user'
							className={classes.avarIcon}
							onClick={handleOnClickToShowPopper}
							src={`${logoUrl}`}
							alt='image profile'
						/>
					) : (
						<Avatar
							id='profile'
							data-cy='avatar-logued-user'
							className={classes.avarIcon}
							onClick={handleOnClickToShowPopper}
						>
							{firstLetterName}
						</Avatar>
					)}
					<ArrowDropDownIcon
						id='profile'
						className={classes.arrowIcon}
						onClick={handleOnClickToShowPopper}
					/>
					<UserProfile
						anchorEl={anchorEl}
						onClickAway={closePopper}
						user={loggedUser}
						onCloseSession={onLogout}
						onEditProfile={redirectToProfileView}
						userAvatar={userAvatar}
					/>
				</Toolbar>
			</AppBar>
		</div>
	)
}

NavBar.propTypes = {
	searchBoxOptions: PropTypes.shape({
		enabled: PropTypes.bool,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func
	}).isRequired,
	notificationData: PropTypes.shape({
		enabled: PropTypes.bool.isRequired,
		total: PropTypes.number,
		eosDocuments: PropTypes.array,
		customerDocuments: PropTypes.array
	}).isRequired
}

NavBar.defaultProps = {
	notificationData: {
		enabled: true
	}
}

/**
 * Funcion to show option from adminsitration
 * @param {*} props
 */
const Settings = (props) => {
	const classes = userProfileStyle()
	let isInternalUser = isUserInRole(AppRoles.ADMINISTRATOR, props.user.roles)
	let isOpen = props.anchorEl
		? props.anchorEl &&
		  (props.anchorEl.id === 'settings' ||
				props.anchorEl.id === 'settings-arrow')
		: false

	const onUsersOptionClick = () => {
		if (isInternalUser) {
			props.history.push('/users')
		} else {
			let customerToken = props.user.customer.token
			props.history.push(`/customers/${customerToken}/users`)
		}
		props.onClickAway()
	}
	return (
		<Popper
			open={isOpen}
			anchorEl={props.anchorEl}
			transition
			placement={'bottom'}
			className={classes.popperRoot}
			disablePortal
		>
			<ClickAwayListener onClickAway={props.onClickAway}>
				<Paper className={classes.paperRoot}>
					<List component='nav' aria-label='nested-list-subheader'>
						{isInternalUser && (
							<ListItem
								data-cy='item-customers'
								button
								className={classes.outlinedRoot}
								classes={{
									root: classes.outlinedRoot,
									selected: classes.outlinedRoot
								}}
								onClick={() => {
									props.history.push('/customers')
									props.onClickAway()
								}}
							>
								<ListItemIcon
									className={classes.outlinedRoot}
									style={{
										marginRight: '-25px',
										marginLeft: '10px'
									}}
								>
									<AppartamentIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography>{'Clientes'}</Typography>
									}
								/>
							</ListItem>
						)}
						{isInternalUser && <Divider />}
						<ListItem
							button
							data-cy='item-suppliers'
							classes={{
								root: classes.outlinedRoot,
								selected: classes.outlinedRoot
							}}
							onClick={() => {
								props.history.push('/suppliers')
								props.onClickAway()
							}}
						>
							<ListItemIcon
								className={classes.outlinedRoot}
								style={{
									marginRight: '-20px',
									marginLeft: '10px'
								}}
							>
								<AppartamentIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography>{'Proveedores'}</Typography>
								}
							/>
						</ListItem>
						{isInternalUser && <Divider />}
						<ListItem
							data-cy='item-users'
							button
							classes={{
								root: classes.outlinedRoot,
								selected: classes.outlinedRoot
							}}
							onClick={onUsersOptionClick}
						>
							<ListItemIcon
								className={classes.outlinedRoot}
								style={{
									marginRight: '-20px',
									marginLeft: '10px'
								}}
							>
								<PeopleIcon />
							</ListItemIcon>
							<ListItemText
								primary={<Typography>{'Usuarios'}</Typography>}
							/>
						</ListItem>
					</List>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

/**
 * User profile component
 */
const UserProfile = (props) => {
	let classes = userProfileStyle()
	const firstLetterName = props.user
		? props.user.name.charAt(0).toUpperCase()
		: ''
	let isOpen = props.anchorEl
		? props.anchorEl &&
		  (props.anchorEl.id === 'profile' ||
				props.anchorEl.id === 'profile-arrow')
		: false
	return (
		<Popper
			open={isOpen}
			anchorEl={props.anchorEl}
			transition
			placement={'bottom'}
			disablePortal
		>
			<ClickAwayListener onClickAway={props.onClickAway}>
				<Paper className={classes.root}>
					<div className={classes.userInfo}>
						{props.userAvatar ? (
							<Avatar
								className={classes.avatar}
								src={`${process.env.REACT_APP_IDENTITY_SERVER}/${props.userAvatar}`}
							/>
						) : (
							<Avatar className={classes.avatar}>
								{firstLetterName}
							</Avatar>
						)}
						<div className={classes.userData}>
							<Typography variant='subtitle2'>
								{props.user.name}
							</Typography>
							<Typography
								variant='caption'
								display='block'
								gutterBottom
							>
								{props.user.email}
							</Typography>
						</div>
					</div>
					<Divider />
					<Button
						size='small'
						data-cy='btn-logout'
						onClick={props.onCloseSession}
					>
						Cerrar sesi&oacute;n
					</Button>
					<Button
						size='small'
						onClick={() => {
							props.onEditProfile()
							props.onClickAway()
						}}
					>
						Editar mi perfil
					</Button>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

const userProfileStyle = makeStyles((theme) => ({
	root: {
		width: '250px',
		marginTop: '25px'
	},
	avatar: {
		margin: 10,
		width: 50,
		height: 50,
		backgroundColor: theme.palette.secondary.main
	},
	userInfo: {
		display: 'flex'
	},
	userData: {
		marginTop: '10px'
	},
	popperRoot: {
		marginLeft: '85.8%',
		marginTop: '3.5em'
	},
	paperRoot: {
		display: 'flex',
		marginTop: '13px',
		width: '180px'
	},
	outlinedRoot: {
		'&:hover': {
			backgroundColor: `${COLOR_PRIMARY_LIGHT} !important`,
			color: COLOR_PRIMARY_NORMAL,
			borderColor: COLOR_PRIMARY_NORMAL
		}
	},
	customBadge: {
		backgroundColor: 'red',
		color: 'red'
	}
}))

export default NavBar
