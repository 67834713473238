/**Import react section */
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";

/**Import materiaL UI section */
import { Typography, AppBar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/** Helpers import section */
import repareLogo from "../../resources/images/logoRepare.png";
import eosLogo from "../../resources/images/logoEOS.png";
import loginLogo from "../../resources/images/login.png";

/** Import components */
import LoginView from './LoginView'

/** Import actions */
import { executeLoging, loadUserProfile } from '../../store/session/SessionActions'

const AnonymousView = props => {
	const classes = useStyles();
	const dispatch = useDispatch()
	const isValidatingPassword = useSelector((state) =>
		!state.session.get('validatingPassword')
	)	
	const [loginViewState, setLoginViewState] = useState({
		open: false,
		error: false,
		errorMessage: null,
	})

	const onLoginClick = () => {
		setLoginViewState({ open: true, error: false })
	};

	const onCloseLoginView = () => {
		setLoginViewState({ open: false })
	}

	const onExecuteLogIn = (credentials) => {
		setLoginViewState({ open: true, error: false })
		dispatch(executeLoging(credentials.userName, credentials.password))
			.then(() => {
				dispatch(loadUserProfile())
			})
			.catch((error) => {
				setLoginViewState({
					open: true,
					error: true,
					errorMessage: error,
				})
			})		
	}

	return (
		<div className={classes.root}>
			<main className={classes.main}>
				<div className={classes.toobarPosition}>
					<AppBar position='static' className={classes.toolBarColor}>
						<img
							src={repareLogo}
							alt='repare-logo'
							className={classes.repareLogo}
						/>
					</AppBar>
				</div>
				<div className={classes.leftContainer}>
					<img
						src={loginLogo}
						alt='home-logo'
						className={classes.homeLogo}
					/>
				</div>
				<div className={classes.middleRigthContainer}>
					<Typography
						variant='h2'
						gutterBottom
						className={classes.portalName}
					>
						BIENVENIDO
					</Typography>
					<Typography
						variant='h6'
						gutterBottom
						className={classes.subtitleText}
					>
						DISPOSICIÓN FINAL SJR
					</Typography>
					<img
						src={eosLogo}
						alt='eos-logo'
						className={classes.eosLogo}
					/>
					<Button
						size='medium'
						className={classes.button}
                        onClick={() => onLoginClick()}
                        data-cy={"login-button"}
					>
						<Typography variant='h6' gutterBottom>
							INGRESAR
						</Typography>
					</Button>
				</div>
			</main>
			{loginViewState.open && (
					<LoginView
						{...loginViewState}
						onClose={onCloseLoginView}
						onLogin={onExecuteLogIn}
						isValidatingPassword={isValidatingPassword}
					/>
				)}
		</div>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		height: "calc(100%)"
	},
	toolBar: theme.mixins.toolbar,
	grown: {
		flexGrow: 1
	},
	repareLogo: {
		height: "42px",
		marginTop: "8px",
		left: "43px",
		width: "271px",
		opacity: 1
	},
	main: {
		display: "grid",
		gridTemplateColumns: "2fr 1fr",
		gridTemplateRows: "30px",
		gridRowGap: 0,
		height: "calc(100%)"
	},
	leftContainer: {
		gridColumnStart: 1,
		gridColumnEnd: 2,
		gridRowStart: 2,
		gridRowEnd: 3,
		minWidth: "40vw",
		maxWidth: "100vw",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto"
	},
	toolBarColor: {
		backgroundColor: "white",
		height: "50px",
		opacity: 1
	},
	rightContainer: {
		gridColumnStart: 2,
		gridColumnEnd: 3,
		gridRowStart: 4,
		gridRowEnd: 6,
		backgroundColor: "white",
		color: theme.palette.common.white,
		textAlign: "center",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto"
	},
	middleLeftContainer: {
		gridColumnStart: 1,
		gridColumnEnd: 2,
		gridRowStart: 2,
		gridRowEnd: 3,
		backgroundColor: "red"
	},
	middleRigthContainer: {
		gridColumnStart: 2,
		gridColumnEnd: 2,
		gridRowStart: 2,
		gridRowEnd: 2,
		textAlign: "center",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto"
	},
	portalName: {
		color: "#545CD8"
	},
	subtitleText: {
		marginTop: "-20px",
		color: "#6C757D"
	},
	toobarPosition: {
		gridColumnStart: 1,
		gridColumnEnd: 3,
		gridRowStart: 1,
		gridRowEnd: 2,
		marginTop: "-8px",
		marginLeft: "-8px",
		marginRight: "-8px",
		height: "70px"
	},
	button: {
		background: "#545CD8",
		color: "white",
		marginTop: "30px",
		minHeight: "1vh",
		maxHeight: "7vh",
		width: "100%",
		maxWidth: "330px",
		"&:hover": {
			backgroundColor: "#2C35C0",
			color: "White"
		}
	},
	eosLogo: {
		height: "101px",
		width: "237px",
		opacity: 1,
		marginLeft: "10px",
		marginTop: "30px"
	},
	homeLogo: {
		width: "110%",
		height: "auto"
	}
}));

export default withRouter(AnonymousView);
