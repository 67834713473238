import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import MomentUtils from '@date-io/moment';
import '../../resources/translations/momentjs.es'

/** Material UI import section */
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

/**
 * Date time picker
 *
 * @export
 * @class DateSelector
 * @extends {Component}
 */
const DateSelector = ({
    dataCy,
    format,
    disabled = false,
    views,
    value,
    name = 'datePicker',
    label = 'Fecha',
    openTo,
    disableFuture = false,
    disablePast = false,
    onChange    
}) => {
    moment.locale('es');
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker 
                data-cy={dataCy}
                fullWidth
                autoOk
                disableToolbar
                variant="inline"
                format= {format}
                disabled={disabled}
                views={views}
                margin="normal"
                value={value} 
                label={label}
                openTo={openTo}
                disableFuture={disableFuture}
                disablePast={disablePast}
                onChange={(value)  => {
                    if (onChange) {
                        onChange({target:{ value: value, name }})
                    }
                }} 
            />
        </MuiPickersUtilsProvider>  
    );
}

DateSelector.propTypes = {
    dataCy: PropTypes.string,
    format: PropTypes.string,
    disabled: PropTypes.bool,
    views: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
    name: PropTypes.string,
    label: PropTypes.string,
    openTo: PropTypes.oneOf(["date", "year", "month"]),
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    onChange: PropTypes.func,    

}

export default DateSelector