import axios from 'axios';

/**Import helper section */
import { getAuthorizedRequestConfigurationUser } from '../helpers/RequestHelper';
import IdentityWebApiClient from '../helpers/IdentityWebApiClient';
import { combineSuppliers } from '../helpers/SupplierHelper';

export const IS_LOADING_SUPPLIERS = "IS_LOADING_SUPPLIERS";
export const IS_SEARCHING_SUPPLIER = "IS_SEARCHING_SUPPLIER";
export const SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS";
export const IS_SAVING_SUPPLIERS = "IS_SAVING_SUPPLIERS";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const SET_SUPPLIER = "SET_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_PROPERTY = "UPDATE_SUPPLIER_PROPERTY";
export const ADD_FILE_SUPPLIER = "ADD_FILE_SUPPLIER"
export const UPDATE_FILE_SUPPLIER = "UPDATE_FILE_SUPPLIER"
export const SET_SUPPLIERS_QUERYPARAMETERS = "SET_SUPPLIERS_QUERYPARAMETERS"

export const setAllSuppliers = suppliers => {
    return {
        type: SET_ALL_SUPPLIERS,
        suppliers
    }
}

const setIsLoadingSuppliers = status => {
    return {
        type: IS_LOADING_SUPPLIERS,
        status
    }
}

/**
 * Set internal user sort criteria
 */
export const setSuppliersQueryParameters = (queryParametes) => {
	return {
		type: SET_SUPPLIERS_QUERYPARAMETERS,
		queryParametes
	}
}

export const loadAllSuppliers = (queryParameters) => {
    return function (dispatch, getState) {
        dispatch(setIsLoadingSuppliers(true));
        let user = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(user)
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}suppliers`;
        let allSuppliers;
        return axios.get(endPoint, config)
            .then(suppliersResponse => {
                allSuppliers = suppliersResponse.data;
                return axios.all(allSuppliers.map(supplier => axios.get(`${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${supplier.token}`)));
            })
            .then(identitySupplierResponses => {
                let allIdentitySuppliers = identitySupplierResponses.map(response => response.data);
                let mappedSuppliers = allSuppliers.map(supplier => {
                    let identitySupplier = allIdentitySuppliers.find(s => s.token === supplier.token);
                    return combineSuppliers(identitySupplier, supplier)
                })
                dispatch(setAllSuppliers(mappedSuppliers));
                if (queryParameters) {
					dispatch(setSuppliersQueryParameters(queryParameters))
				}
                return Promise.resolve(mappedSuppliers)
            })
            .catch((error) => {
                dispatch(setIsLoadingSuppliers(false));
                console.error("Error loading all suppliers", error)
                return Promise.reject();
            });
    }
}

export const setSupplier = supplier => {
    return {
        type: SET_SUPPLIER,
        supplier
    }
}

export const loadSupplier = supplierToken => {
    return function (dispatch, getState) {
        dispatch(setIsLoadingSuppliers(true));
        let user = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(user)
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}suppliers/${supplierToken}`;
        return axios.all([
            axios.get(`${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${supplierToken}`),
            axios.get(endPoint, config),
        ])
            .then(([identityResponse, suppliersResponse]) => {
                let identitySupplier = identityResponse.data;
                let finalDispositionSupplier = suppliersResponse.data;
                let mappedSupplier = combineSuppliers(identitySupplier, finalDispositionSupplier);
                dispatch(setSupplier(mappedSupplier));
            })
            .catch((error) => {
                dispatch(setIsLoadingSuppliers(false));
                console.error("Error loading supplier", error)
                return Promise.reject();
            });
    }
}

const setIsSearchingSupplier = status => {
    return {
        type: IS_SEARCHING_SUPPLIER,
        status
    }
}

export const searchSupplierByCode = supplierCode => {
    return function (dispatch) {
        dispatch(setIsSearchingSupplier(true))
        let config = {};
        return IdentityWebApiClient.searchSupplierByCode(supplierCode, config)
            .finally(supplier => {
                dispatch(setIsLoadingSuppliers(false));
                return Promise.resolve(supplier);
            });
    }
}

const setIsSavingSupplier = status => {
    return {
        type: IS_SAVING_SUPPLIERS,
        status
    }
}

const addSupplier = supplier => {
    return {
        type: ADD_SUPPLIER,
        supplier
    }
}

export const createSupplier = supplier => {
    return function (dispatch, getState) {
        dispatch(setIsSavingSupplier(true));
        let user = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(user)
        let command = {
            Token: supplier.token,
            IsTransportService: supplier.isTransportService,
        }
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}suppliers`;
        return axios.all([
            axios.post(endPoint, command, config),
            axios.get(`${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${supplier.token}`)
        ])
            .then(([finalDispositionResponse, identityResponse]) => {
                let mappedSupplier = combineSuppliers(identityResponse.data, finalDispositionResponse.data);
                dispatch(addSupplier(mappedSupplier));
            })
            .catch((error) => {
                dispatch(setIsSavingSupplier(false));
                console.error("Error saving supplier", error)
                return Promise.reject();
            });
    }
}

const updateSupplierProperty = (propertyName, propertyData) => {
    return {
        type: UPDATE_SUPPLIER_PROPERTY,
        propertyName,
        propertyData
    }
}

/**
 * Update supplier status
 * @param {object} supplier 
 * @param {bool} status 
 */
export const updateSupplierStatus = (supplier, status) => {
    return function (dispatch, getState) {
        dispatch(setIsSavingSupplier(true));
        let loggedUser = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(loggedUser);
        let command = {
            Token: supplier.token,
            Properties: {
                Active: status
            }
        };
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}suppliers`;
        return axios.put(endPoint, command, config)
            .then(response => {
                let properties = response.data;
                dispatch(updateSupplierProperty('active', properties['active']))
            })
            .catch(error => {
                dispatch(setIsSavingSupplier(false));
                console.error('Error updating supplier', error);
                return Promise.reject();
            });
    }
}

const addFiles = files => {
    return {
        type: ADD_FILE_SUPPLIER,
        files
    }
}
export const uploadAttachments = (files, documentData, supplierToken) => {
    return function (dispatch, getState) {
        dispatch(setIsSavingSupplier(true));
        let loggedUser = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(loggedUser);
        let command = {
            ExpirationDate: documentData.expirationDate,
            NotificationDays: documentData.notificationDays,
            SupplierToken: supplierToken,
            AttachmentType: 'Supplier',
        };
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}suppliers/attachments`;
        let data = new FormData()
        data.append('files', files)
        data.append('command', JSON.stringify(command));
        for (var index = 0; index < files.length; index++) {
            data.append('FileNames', files[index].attachment.name);
            data.append('file', files[index].attachment);
        }
        return axios.post(endPoint, data, config)
            .then(response => {
                dispatch(addFiles(response.data))
                return Promise.resolve();
            })
            .catch(error => {
                dispatch(setIsSavingSupplier(false));
                console.error('Error added file for supplier', error);
                return Promise.reject();
            });
    }
}

const updateAttachmentProperties = (attachmentToken, properties) => {
    return {
        type: UPDATE_FILE_SUPPLIER,
        attachmentToken,
        properties
    }
}

export const updateFile = (documentModel, supplierToken) => {
    return function (dispatch, getState) {
        dispatch(setIsSavingSupplier(true));
        let loggedUser = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(loggedUser);
        let command = {
            ExpirationDate: documentModel.expirationDate,
            AttachmentType: 'Supplier',
            SupplierToken: supplierToken,
            FileName: documentModel.fileName,
            Description: documentModel.description,
            AttachmentToken: documentModel.token,
            NotificationDays: documentModel.notificationDays
        };
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}suppliers/attachment`;

        return axios.put(endPoint, command, config)
            .then(response => {
                let properties = response.data;

                dispatch(updateAttachmentProperties(documentModel.token, properties))
                return Promise.resolve();
            })
            .catch(error => {
                dispatch(setIsSavingSupplier(false));
                console.error('Error added file for supplier', error);
                return Promise.reject();
            });
    }
}

export const loadSupplierFromIdentity = (token) => {
    return function (dispatch, getState) {
        dispatch(setIsLoadingSuppliers(true));
        let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/suppliers/${token}`;
        return axios.get(endPoint, null)
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setIsLoadingSuppliers(false));
                    return Promise.resolve(serverResponse.data);
                }
            })
            .catch((error) => {
                dispatch(setIsLoadingSuppliers(false));
                console.error("Error to load supplier", error.response)
                return Promise.reject();
            })
    }
}


