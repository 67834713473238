/** Import ReactJS */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Custom componentes import section */
import IndexRequest from '../requests/Index'
import Loader from '../common/Loader';

/** Helpers import section */
import { toSafeObject } from '../../store/helpers/ImmutableHelper';
import { isUserInRole } from '../../store/helpers/UserHelper';
import { AppRoles } from '../../resources/constants/AppConstants';
import { isDisabledUser } from '../../store/helpers/UserHelper';

const Index = (props) => {
    const loggedUser = useSelector(state => toSafeObject(state.session.get('loggedUser')));
    const isLoggedUserAdministrator = isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles);
    const [isLoadingUserInformation, setIsLoadingUserInformation] = useState(true);

    useEffect(() => {
        if (loggedUser) {
            setIsLoadingUserInformation(false);
        }

        if (loggedUser && (loggedUser.roles.length < 1 || isDisabledUser(loggedUser))) {
            props.history.push(`/users/${loggedUser.id}`);
        }
    }, [loggedUser, props.history]);

    return (
        <div style={{ gridRowStart: '2', gridRowEnd: '3' }} >
            {isLoadingUserInformation && <Loader />}
            {!isLoadingUserInformation &&
                <IndexRequest
                    isLoggedUserAdministrator={isLoggedUserAdministrator}
                    history={props.history}
                />}
        </div>
    );
}

export default withRouter(Index);