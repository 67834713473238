import React from 'react'

/** Material UI import section */
import { makeStyles, Paper, Typography, Grid } from '@material-ui/core'
import NoDataMessage from '../common/NoDataMessage'

/** Resources import section */
import { COLOR_DARK_NORMAL } from '../../resources/constants/Colors'
import logoHelp from '../../resources/images/logoHelp.png'
import { OptionSettingsFake, OptionAddBtn } from '../common/FakeButtons'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: 30,
		maxWidth: 1000,
		padding: theme.spacing(5, 20),
		textAlign: 'center'
	}
}))

const Welcome = (props) => {
	const classes = useStyles()
	const {
		isLoggedUserAdministrator,
		onOpenPanelCreateRequest,
		isUserCustomerServiceApplicant
	} = props

	const getMessage = () => {
		if (isLoggedUserAdministrator) {
			return (
				<Typography datacy='text-for-administrator'>
					Para iniciar el primer paso es, dar de alta usuarios haciendo clic en el ícono
					"Ajustes" <OptionSettingsFake /> ubicado en la barra superior.
				</Typography>
			)
		}
		return (
			<>
				{isUserCustomerServiceApplicant && (
					<div>
						<Typography style={{ marginBottom: '2em' }}>
							No existen solicitudes de disposición final registradas en el sistema.
							Puede crear una nueva solicitud presionando el botón que ésta debajo de
							esté mensaje.
						</Typography>
						<OptionAddBtn
							title={'Crear nueva solicitud'}
							onClick={onOpenPanelCreateRequest}
						/>
					</div>
				)}
				{!isUserCustomerServiceApplicant && (
					<Typography style={{ marginBottom: '2em' }}>
						No existen solicitudes de disposición final registradas en el sistema.{' '}
					</Typography>
				)}
			</>
		)
	}

	const getTittle = () => {
		if (isLoggedUserAdministrator) {
			return (
				<Typography name='welcome' data-cy='txt-welcome'>
					<strong>BIENVENIDO A LA PLATAFORMA</strong>
				</Typography>
			)
		}
		return <strong>LISTA DE SOLICITUDES VACIA</strong>
	}

	return (
		<Paper className={classes.paper}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				spacing={2}
			>
				<Grid item xs={12} sm={12}>
					<Typography variant='h6' gutterBottom style={{ color: COLOR_DARK_NORMAL }}>
						{getTittle()}
					</Typography>
				</Grid>
				{isLoggedUserAdministrator && (
					<Grid item xs={12} sm={12}>
						<Typography variant='h6'>"Disposición final de enfriadores"</Typography>
					</Grid>
				)}
			</Grid>
			<NoDataMessage message={getMessage()} image={logoHelp} />
		</Paper>
	)
}

export default Welcome
