import { UserTypes } from '../../resources/constants/AppConstants'
import { isNullOrEmpty } from './StringHelper'
export const isActiveDirectoryUser = (user) => {
	if (user === undefined || user === null) {
		return false
	}
	return user.type === UserTypes.ACTIVE_DIRECTORY
}
/**
 * Function to determine is user in role
 * @param {*} roleName
 * @param {*} roles
 */
export function isUserInRole(roleName, roles) {
	if (roles === undefined || roles === null) {
		return false
	}

	if (roles.length > 0) {
		return roles.some((role) => role.roleName === roleName)
	}
	return false
}

export const isDisabledUser = (user) => {
	if (user.userName === 'adimbptm') {
		return false
	}
	if (!user.active) {
		return true
	}
	if (user.type !== UserTypes.ACTIVE_DIRECTORY) {
		if (user.customer && !user.customer.isActiveCustomer) {
			return true
		}
	}
	return false
}
/**
 * Detemrine show SBAI
 * @param {*} user
 */
export const isDisabledSBAI = (user) => {
	if (user.type === UserTypes.ACTIVE_DIRECTORY) return true
	if (user.customer && user.customer.isCustomerKof) {
		return true
	}
	return false
}

export const combineUsers = (identityUser, finalDispositionUser) => {
	return {
		id: identityUser.id,
		userName: identityUser.user_name,
		name: identityUser.full_name,
		email: identityUser.email,
		phoneNumber: identityUser.phone_number,
		type: identityUser.user_type,
		avatarUrl: identityUser.avatar_url,
		active: finalDispositionUser.isActive,
		roles: finalDispositionUser.roles || [],
		customer: finalDispositionUser.customer,
		supplier: finalDispositionUser.supplier ? mapSupplier(finalDispositionUser.supplier) : null
	}
}

export const mapSupplier = (supplier) => {
	let mappedsupplier = {}
	mappedsupplier.active = supplier.active
	mappedsupplier.isTransportService = supplier.is_transport_service
	mappedsupplier.token = supplier.token
	return mappedsupplier
}

export const isValidPassword = (password) => {
	if (isNullOrEmpty(password)) return true
	let regexNewPassword = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
	return regexNewPassword.test(password)
}

export const isValidEmail = (email) => {
	if (isNullOrEmpty(email)) {
		return true
	}
	let regexEmail = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regexEmail.test(email)
}
