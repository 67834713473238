import React from 'react';

/** Material UI import section */
import { IconButton, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddTwoTone';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import SettingsIcon from '@material-ui/icons/Settings';

/** Resources import section */
import {
    COLOR_FOCUS_NORMAL,
    COLOR_WHITE,
    COLOR_INFO_NORMAL,
    COLOR_ALTERNATE_NORMAL,
    COLOR_DARK_NORMAL
} from '../../resources/constants/Colors';

export const AddBtnFake = () => {
    return (
        <IconButton
            size="small"
            style={{ cursor: 'none', width: 25, height: 25, backgroundColor: COLOR_FOCUS_NORMAL }}
        >
            <AddIcon fontSize="inherit" style={{ color: COLOR_WHITE }} />
        </IconButton>
    );
}

export const AddDocFake = () => {
    return (
        <IconButton
            size="small"
            style={{ cursor: 'none', width: 25, height: 25, backgroundColor: COLOR_INFO_NORMAL }}
        >
            <FileIcon fontSize="inherit" style={{ color: COLOR_WHITE }} />
        </IconButton>
    );
}

export const OptionSettingsFake = () => {
    return (
        <IconButton
            size="small"
            style={{ cursor: 'none', width: 25, height: 25, backgroundColor: COLOR_ALTERNATE_NORMAL }}
        >
            <SettingsIcon fontSize="inherit" style={{ color: COLOR_WHITE }} />
        </IconButton>
    );
}

export const OptionAddBtn = (props) => {
    return (
        <Button variant="contained"
            style={{ color: COLOR_WHITE, backgroundColor: COLOR_DARK_NORMAL }}
            onClick={props.onClick}
            data-cy={"btn-create-request"}
        >
            <Typography variant="caption">{props.title}</Typography>
        </Button>
    )
}
