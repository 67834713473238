import React from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import { makeStyles, Typography } from '@material-ui/core'
import AddItemButton from '../../common/AddItemButton'

const ReportsHeader = (props) => {
	const { addingButtonDisplayed, onAddButtonClick } = props

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<Typography variant='button'>
					<strong>REPORTES MENSUALES</strong>
				</Typography>
			</div>
			<div>
				{addingButtonDisplayed && (
					<AddItemButton
						title='Agregar reporte'
						onClick={onAddButtonClick}
					/>
				)}
			</div>
		</div>
	)
}

ReportsHeader.propTypes = {
	addingButtonDisplayed: PropTypes.bool.isRequired,
	onAddButtonClick: PropTypes.func
}

ReportsHeader.defaultProps = {
	onAddButtonClick: () => console.warn('[onAddButtonClick] not defined!')
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1)
	},
	title: {
		flex: '1 1 0'
	}
}))

export default ReportsHeader
