import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

/** Custom components import section */
import Loader from '../../common/Loader'
import LayoutHeaderRequests from './LayoutHeaderRequests'
import GraphicsPercentaje from './GraphicsPercentaje'
import DataList from '../../common/DataList/DataList'
import RequestsToolBar from './RequestsToolbar'
import { buildRequestToolbarConfiguration } from './RequestsToolbar'
import GeneralInformation from './GeneralInformation'
import DialogEvidenceReception from './DialogEvidenceReception'
import ManualReceptionEvidenceDialog from './ManualReceptionEvidenceDialog'
import EvidencesPartial from './EvidencesPartial'
import RecollectionDialog from './RecollectionDialog'
import Toaster from '../../common/Toaster'
import MaterialDeliveryTab from './MaterialDeliveryTab'
import RecollectionComponetTab from './RecollectionComponetTab'

/** Material UI import section */
import {
	AppBar,
	List,
	ListItem,
	ListItemText,
	Tabs,
	Tab,
	Typography,
	Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import WorkLineIcon from '@material-ui/icons/ViewHeadline'

/** Actions import section */
import {
	loadRequestFromBackEnd,
	addEvidenceInReceptionFolio,
	removeEvidenceInReceptionItem,
	addCoordinatorEvidence,
	removeCoordinatorEvidence,
	updateRecollectionRequestBackEnd,
	addReceptionEvidenceDispositionRequest
} from '../../../store/dispositionRequests/DispositionRequestsActions'

/** Resources import section */
import {
	COLOR_SUCCESS,
	COLOR_PRIMARY_NORMAL,
	COLOR_ALTERNATE_NORMAL,
	COLOR_WARNING_NORMAL,
	COLOR_WHITE
} from '../../../resources/constants/Colors'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
import { isDisabledSBAI } from '../../../store/helpers/UserHelper'
import { RequestStatus } from '../../../store/helpers/StatusHelper'

/**
 * Component to show panel request
 * @param {*} props
 */
function RequestPanelShow(props) {
	/** Destructuting properties */
	const { classes, onClosePanelShow } = props

	/** Define local state */
	const requestData = useSelector((state) =>
		state.dispositionRequests.get('dispositionRequest')
			? state.dispositionRequests.get('dispositionRequest').toJS()
			: null
	)
	const loggedUser = useSelector((state) => state.session.get('loggedUser'))
	const isSavingRequest = useSelector((state) => state.dispositionRequests.get('isSavingRequest'))
	let serviceTypeIdentifier =
		requestData && requestData.service_type === 2 ? 'Program' : 'SendToPlantOES'

	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [receptionEvidence, setReceptionEvidenceDialog] = useState({
		open: false,
		receptionFolio: ''
	})
	const [manualReceptionEvidence, setManualReceptionEvidenceDialog] = useState({
		open: false,
		receptionFolio: ''
	})
	const [evidenceCoordinatorDialog, setEvidenceCoordinatorDialog] = useState({
		opened: false
	})
	const [recollectionDialog, setRecollectionDialog] = useState({
		open: false,
		request: null
	})
	const [isLoadingRequest, setIsLoadingRequest] = useState(false)
	const [selectedTab, setValueTab] = useState(0)

	const dispatch = useDispatch()

	useEffect(() => {
		if (props.selectedRequestToken) {
			setIsLoadingRequest(true)
			dispatch(loadRequestFromBackEnd(props.selectedRequestToken)).finally(() => {
				setIsLoadingRequest(false)
			})
		}
	}, [props.selectedRequestToken, dispatch])

	const gotoServiceTabView = (event, value) => {
		setValueTab(value)
	}

	const onOpenEvidenceDialog = (folio) => {
		setReceptionEvidenceDialog({ open: true, receptionFolio: folio })
	}

	const onCloseModalEvidence = () => {
		setReceptionEvidenceDialog({ open: false, receptionFolio: '' })
	}

	const onOpenManualEvidenceDialog = (folio) => {
		setManualReceptionEvidenceDialog({ open: true, receptionFolio: folio })
	}

	const onCloseManualEvidenceDialog = () => {
		setManualReceptionEvidenceDialog({ open: false, receptionFolio: '' })
	}

	const onAddEvidenceReception = (evidenceFiles, incidentFiles) => {
		dispatch(
			addEvidenceInReceptionFolio(
				evidenceFiles,
				incidentFiles,
				receptionEvidence.receptionFolio,
				requestData.token
			)
		)
			.then(() => {
				setReceptionEvidenceDialog({ open: false, receptionFolio: '' })
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'Se generó un error al agregar la evidencia de solicitud',
					variant: 'error'
				})
			})
	}

	const onAddManualEvidenceReception = (folio, manualEvidenceFiles, incidentFiles) => {
		dispatch(
			addReceptionEvidenceDispositionRequest(
				folio,
				manualEvidenceFiles,
				incidentFiles,
				requestData.token
			)
		)
			.then(() => {
				setManualReceptionEvidenceDialog({ open: false })
				onClosePanelShow()
			})
			.catch((error) => {
				setManualReceptionEvidenceDialog({ open: false })
				setToasterOptions({
					open: true,
					message: 'Se generó un error al agregar la evidencia en la solicitud',
					variant: 'error'
				})
			})
	}

	const onRemoveEvidence = (folio, evidenceName) => {
		dispatch(removeEvidenceInReceptionItem(folio, evidenceName))
			.then(() => {})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Se generó un error al eliminar la evidencia de solicitud',
					variant: 'error'
				})
			})
	}

	const renderItems = (item) => {
		return (
			<ListItem key={item.token} divider className={classes.lineItem}>
				<ListItemAvatar>
					<Tooltip
						title={item.workStatus === 2 ? 'Cerrado' : 'En proceso'}
						aria-label='status'
					>
						<Avatar
							style={{
								background:
									item.workStatus === 2 ? COLOR_SUCCESS : COLOR_WARNING_NORMAL
							}}
						>
							<WorkLineIcon />
						</Avatar>
					</Tooltip>
				</ListItemAvatar>
				<ListItemText
					primary={
						<>
							{props.isRoleAdmin &&
								`Serie: ${item.serie} - Orden Producción: ${item.workOrdenProduccion}`}
							{!props.isRoleAdmin && `Serie: ${item.serie}`}{' '}
						</>
					}
					secondary={
						<React.Fragment>
							{props.isRoleAdmin && (
								<Typography
									component='span'
									variant='body2'
									className={classes.inline}
								>
									{`Fecha entrada: ${
										item.workOrderFecha
											? moment(item.workOrderFecha).format('DD/MM/YYYY')
											: ''
									} - Fecha cierre: 
                            ${
								item.workClosingDate
									? moment(item.workClosingDate).format('DD/MM/YYYY')
									: ''
							}`}
								</Typography>
							)}
							{!props.isRoleAdmin && (
								<Typography
									component='span'
									variant='body2'
									className={classes.inline}
								>
									{`Modelo: ${item.model}`}
								</Typography>
							)}
						</React.Fragment>
					}
				/>
			</ListItem>
		)
	}

	/**
	 * Open modal for confirm disposition requests
	 */

	const onOpenModalAuthorizeRequest = () => {
		props.onOpenModalConfirmRequest('onAuthorizeRequest')
	}

	const onOpenModalConfirmRequest = () => {
		props.onOpenModalConfirmRequest(serviceTypeIdentifier)
	}

	const onOpenModalCollectionRequest = () => {
		props.onOpenModalConfirmRequest('onUpdateColectionDate')
	}

	const onOpenModalLiberatedRequest = () => {
		props.onOpenModalConfirmRequest('onLiberatedRequest')
	}

	const onOpenModalCanceldRequest = () => {
		props.onOpenModalConfirmRequest('onCancelRequest')
	}

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'receptionFecha') {
			if (item.receptionFecha) {
				return moment(item[dataSource]).format('DD/MM/YYYY')
			} else {
				return '-'
			}
		}
		if (dataSource === 'serie') {
			if (item.serie && requestData.status === RequestStatus.CANCEL) {
				return <strike>{item.serie}</strike>
			} else {
				return item.serie
			}
		}
	}

	const onOpenModalForUploadEvidenceCordinator = () => {
		setEvidenceCoordinatorDialog({ opened: true })
	}

	const onCloseModalForUploadEvidenceCordinator = () => {
		setEvidenceCoordinatorDialog({ opened: false })
	}

	const onOpenRecollectionDialog = (recollectionRequestToken) => {
		const recollection = requestData.recollectionRequests.find(
			(r) => r.token === recollectionRequestToken
		)
		setRecollectionDialog({ open: true, recollection: recollection })
	}

	const onCloseModalRecollection = () => {
		setRecollectionDialog({ open: false, recollection: null })
	}

	/**
	 * On add evidence coodinator
	 * @param {*} files
	 */
	const onUploadCordinatorEvidence = (files) => {
		dispatch(addCoordinatorEvidence(files, requestData.token))
			.then(() => {
				onCloseModalForUploadEvidenceCordinator()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'Se generó un error al agregar la evidencia del coordinador',
					variant: 'error'
				})
			})
	}

	/**
	 * On remove evidence coodinator
	 * @param {*} token
	 * @param {*} evidenceName
	 */
	const onRemoveCoordinatorEvidence = (token, evidenceName) => {
		dispatch(removeCoordinatorEvidence(token, evidenceName))
			.then(() => {})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Se generó un error al eliminar la evidencia del coordinador',
					variant: 'error'
				})
			})
	}

	/**
	 * On update recollection date
	 * @param {*} requestData
	 * @param {*} token
	 */
	const onUpdateRecollectionRequest = (requestData) => {
		dispatch(updateRecollectionRequestBackEnd(requestData))
			.then(() => {
				setRecollectionDialog({ open: false, recollection: null })
				setToasterOptions({
					open: true,
					message: 'Se ha actualizado la información correctamente',
					variant: 'success'
				})
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'Se generó un error al actualizar la solicitud',
					variant: 'warning'
				})
			})
	}

	return (
		<form className={classNames(classes.form)} tabIndex={0} role='button'>
			<div className={classes.headerForm}>
				<div className={classes.contentHeader}>
					<div className={classes.buttons}>
						{requestData && (
							<RequestsToolBar
								actions={buildRequestToolbarConfiguration(
									loggedUser.toJS(),
									requestData.status,
									{
										onClickAuthorizeRequest: onOpenModalAuthorizeRequest,
										onClickAcceptRequest: onOpenModalConfirmRequest,
										onClickCollectionRequest: onOpenModalCollectionRequest,
										onClickLiberatedRequest: onOpenModalLiberatedRequest,
										onClickCancelRequest: onOpenModalCanceldRequest
									},
									requestData.confirmDate,
									requestData.programmeDate
								)}
							/>
						)}
					</div>

					<LayoutHeaderRequests requestData={requestData} isLoading={props.isLoading} />
				</div>

				<div className={classes.content}>
					{isLoadingRequest ? (
						<Loader />
					) : (
						<>
							<GraphicsPercentaje
								totalCounterToClose={
									requestData ? requestData.totalCounterToClose : 0
								}
								percentageItemsReception={
									requestData ? requestData.percentageItemsReception : 0
								}
								percentageWorkOrderLinesClose={
									requestData ? requestData.percentageWorkOrderLinesClose : 0
								}
							/>
							<AppBar position='static' color='default'>
								<Tabs
									value={selectedTab}
									indicatorColor='secondary'
									textColor='secondary'
									onChange={gotoServiceTabView}
									aria-label='disabled tabs example'
								>
									<Tab wrapped style={{ minWidth: '80px' }} label='Detalles' />
									<Tab
										wrapped
										style={{ minWidth: '100px' }}
										label='Entrega de materiales'
									/>
									<Tab
										wrapped
										style={{ minWidth: '100px' }}
										label='Ordenes de producción'
									/>
									<Tab
										wrapped
										style={{ minWidth: '80px' }}
										label='Recolecciones'
									/>
									<Tab
										wrapped
										style={{ minWidth: '80px' }}
										label={`Equipos: ${
											requestData ? requestData.totalCounterToClose : 0
										}`}
									/>
									<Tab wrapped style={{ minWidth: '80px' }} label='Evidencias' />
								</Tabs>
							</AppBar>

							{selectedTab === 0 && requestData && (
								<GeneralInformation requestData={requestData} />
							)}

							{selectedTab === 1 && requestData && (
								<MaterialDeliveryTab
									receptions={requestData.receptions}
									manualReceptionEvidences={requestData.manualEvidences}
									isRoleAdmin={props.isRoleAdmin}
									onRemoveEvidence={onRemoveEvidence}
									onOpenEvidenceDialog={onOpenEvidenceDialog}
									onAddButtonClick={
										onOpenManualEvidenceDialog
										// onOpenModalToAddRequest
									}
									classes={classes}
								/>
							)}

							{selectedTab === 2 && requestData && (
								<div>
									<List className={classes.contentTab}>
										{requestData.workOrders.map((item) => {
											return <div key={item.token}>{renderItems(item)}</div>
										})}
										{requestData.workOrders.length === 0 && (
											<ListItem
												key={'withreceptions'}
												divider
												className={classes.lineItem}
											>
												<ListItemText
													primary={'Sin ordenes de producción'}
												/>
											</ListItem>
										)}
									</List>
								</div>
							)}

							{selectedTab === 3 && requestData && (
								<div className={classes.contentTab}>
									<RecollectionComponetTab
										programmeDate={requestData.programmeDate}
										branch={requestData.branch}
										recollections={requestData.recollectionRequests}
										onOpenRecollectionDialog={onOpenRecollectionDialog}
										canBeEdit={props.isRoleAdmin && !requestData.isClose}
									/>
								</div>
							)}

							{selectedTab === 4 && requestData && (
								<div className={classes.contentTab}>
									<DataList
										data={requestData.details}
										onRenderCellItem={onRenderCellItem}
										configuration={requestTableConfiguration(
											props.isRoleAdmin,
											isDisabledSBAI(loggedUser.toJS())
										)}
									/>
								</div>
							)}

							{selectedTab === 5 && requestData && (
								<div className={classes.contentTab}>
									<EvidencesPartial
										requestData={requestData}
										onOpenModalForUploadEvidenceCordinator={
											onOpenModalForUploadEvidenceCordinator
										}
										onRemoveCoordinatorEvidence={onRemoveCoordinatorEvidence}
										isSavingRequest={isSavingRequest}
										isValitToAddEvidenceCoodinator={
											props.isValitToAddEvidenceCoodinator
										}
									/>
								</div>
							)}
						</>
					)}
				</div>
			</div>

			{/** Add Evidence */}
			{receptionEvidence.open && (
				<DialogEvidenceReception
					{...receptionEvidence}
					incidentInputDisplayed
					open={receptionEvidence.open}
					isSaving={isSavingRequest}
					onClose={onCloseModalEvidence}
					onSaveEvidence={onAddEvidenceReception}
				/>
			)}

			{manualReceptionEvidence.open && (
				<ManualReceptionEvidenceDialog
					{...manualReceptionEvidence}
					open={manualReceptionEvidence.open}
					isSaving={isSavingRequest}
					onClose={onCloseManualEvidenceDialog}
					onSaveManualEvidence={onAddManualEvidenceReception}
				/>
			)}

			{evidenceCoordinatorDialog.opened && (
				<DialogEvidenceReception
					open={evidenceCoordinatorDialog.opened}
					isSaving={isSavingRequest}
					onClose={onCloseModalForUploadEvidenceCordinator}
					{...evidenceCoordinatorDialog}
					onSaveEvidence={onUploadCordinatorEvidence}
				/>
			)}

			{recollectionDialog.open && (
				<RecollectionDialog
					open={recollectionDialog.open}
					isSaving={isSavingRequest}
					onClose={onCloseModalRecollection}
					recollection={recollectionDialog.recollection}
					onSave={onUpdateRecollectionRequest}
					{...recollectionDialog}
				/>
			)}

			{/** Toaster */}
			{toasterOptions.open && (
				<Toaster {...toasterOptions} onClose={() => setToasterOptions({ open: false })} />
			)}
		</form>
	)
}

const requestTableConfiguration = (isAdmin, isDisableSbis) => {
	let columns = [
		{
			dataSource: 'serie',
			header: 'Serie',
			isSortable: false
		},
		{
			dataSource: 'sbai_code',
			header: isDisableSbis ? 'SBAI' : 'Folio cliente',
			isSortable: false
		},
		{
			dataSource: 'receptionFecha',
			header: 'Fecha recepción',
			isSortable: false
		},
		{
			dataSource: 'receptionFolio',
			header: 'Folio',
			isSortable: false
		},
		{
			dataSource: 'documentSap',
			header: 'Doc. sap',
			isSortable: false
		}
	]

	if (isAdmin) {
		columns.push({
			dataSource: 'receptionPeso',
			header: 'Peso',
			isSortable: false
		})
	}
	return { columns }
}

RequestPanelShow.propTypes = {
	selectedRequestToken: PropTypes.string.isRequired,
	onAddRecollectionEvidenceManually: PropTypes.func.isRequired,
	onOpenModalToAddRequest: PropTypes.func.isRequired
}

RequestPanelShow.defaultProps = {
	onAddRecollectionEvidenceManually: () =>
		console.warn('[onAddRecollectionEvidenceManually] not defined!'),
	onOpenModalToAddRequest: () => console.warn('[onOpenModalToAddRequest] not defined')
}

const useStyles = () => ({
	form: {
		display: 'grid',
		height: '100vh',
		gridTemplateRows: '50px auto 60px'
	},
	headerForm: {
		gridRowStart: '1',
		gridRowEnd: '2',
		marginBottom: 15,
		zIndex: '1',
		backgroundColor: COLOR_ALTERNATE_NORMAL
	},
	grow: {
		flexGrow: 1
	},
	title: {
		display: 'flex',
		padding: '30px 20px'
	},
	contentHeader: {
		gridRowStart: '1',
		gridRowEnd: '2',
		backgroundColor: COLOR_ALTERNATE_NORMAL,
		zIndex: 99999
	},
	contentTab: {
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '55vh'
	},
	buttons: {
		marginLeft: '30em',
		zIndex: '300',
		position: 'fixed',
		top: '.3em',
		padding: '5px'
	},
	colorWhithe: {
		color: 'white'
	},
	colotText: {
		color: ` ${COLOR_WHITE} !important`
	},
	btnDownload: {
		textTransform: 'uppercase',
		backgroundColor: COLOR_ALTERNATE_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_ALTERNATE_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_ALTERNATE_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	lineItem: {
		borderLeft: `3px solid ${COLOR_PRIMARY_NORMAL}`,
		margin: '5px'
	},
	iconList: {
		fontSize: '18px',
		cursor: 'pointer'
	}
})

export default withStyles(useStyles)(RequestPanelShow)
