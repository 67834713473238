import { fromJS } from 'immutable'
import * as reportActions from './ReportActions'

let initialCustomersState = fromJS({
	allReports: []
})

const reportsReducer = (state = initialCustomersState, action) => {
	switch (action.type) {
		case reportActions.SET_ALL_REPORTS: {
			const reports = fromJS(action.reports)
			return state.set('allReports', reports)
		}
		case reportActions.ADD_REPORT: {
			const report = fromJS(action.report)
			return state.update('allReports', (allReports) =>
				allReports.splice(0, 0, report)
			)
		}
		case reportActions.REMOVE_REPORT: {
			const updatedState = state.update('allReports', (allReports) => 
				allReports.filter(r => r.get('token') !== action.token)
			)
			return updatedState
		}
		default: {
			return state
		}
	}
}

// const updateCustomerStatus = (allCustomers, customerToUpdate) => {
//     let indexOfUser = allCustomers.findIndex(customer => customer.get('code') === customerToUpdate.get('code'));
//     if (indexOfUser >= 0) {
//         return allCustomers.setIn([indexOfUser,"active"], customerToUpdate.get("active"));
//     }
//     return allCustomers;
// }

// const updateAttachment = (allAttachment, attachmentToken, properties) => {
//     let indexOfAttachment = allAttachment.findIndex(attachment => attachment.get('token') === attachmentToken);
//     if (indexOfAttachment >= 0) {
//         return allAttachment.update(indexOfAttachment, attachmentToUpdate => attachmentToUpdate.merge(properties));
//     }
//     return allAttachment;
// }

export default reportsReducer
