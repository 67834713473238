/** Import ReactJS */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

/** Import components */
import HomeIndex from './Index';
import UsersIndex from '../../views/users/Index';
import UserProfile from '../../views/users/View';
import CustomersIndex from '../customers/Index';
import CustomerProfile from '../customers/Show';
import SuppliersIndex from '../suppliers/Index';
import SupplierProfile from '../suppliers/View';
import RequestsIndex from '../requests/Index';
import DocumentsIndex from '../operatingDocuments/Index';
import CalendarIndex from '../calendar/Index';
import ReportsIndex from '../reports/Index';

const AuthenticatedView = () => {
	return (
		<Switch>
			<Route exact path='/' component={HomeIndex} />
			<Route exact path='/users' component={UsersIndex} />
			<Route exact path='/users/:token' component={UserProfile} />
			<Route exact path='/customers' component={CustomersIndex} />
			<Route exact path='/customers/:token' component={CustomerProfile} />
			<Route exact path='/suppliers' component={SuppliersIndex} />
			<Route exact path='/suppliers/:token' component={SupplierProfile} />
			<Route exact path='/requests' component={RequestsIndex} />
			<Route exact path='/documents' component={DocumentsIndex} />
			<Route exact path='/calendar' component={CalendarIndex} />
			<Route exact path='/reports' component={ReportsIndex} />
		</Switch>
	);
}

export default AuthenticatedView;