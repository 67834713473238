import React from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Componet Material UI
 */
import {List, ListItem, ListItemText,Typography} from '@material-ui/core'
import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CreatorIcon from '@material-ui/icons/Person';
import ConfirmIcon from '@material-ui/icons/ThumbUpAlt';
import CalendarIcon from '@material-ui/icons/EventNote';
import CommentsIcon from '@material-ui/icons/SpeakerNotesOffOutlined';

import {COLOR_ALTERNATE_NORMAL} from '../../../resources/constants/Colors'
import repareDataMock from '../../../data/RepareDataMock'

/**
 * Component to show panel request
 * @param {*} props 
 */
function GeneralInformation(props) {
    const { classes, requestData } = props;

    function renderTextRequest(label, value) {
        return (<Typography component="span" variant="subtitle2"><strong>{label}</strong>{value}</Typography>)
    }
    /**
     * General information
     */
	return (
            <List className={classes.contentTab}>
                <ListItem  divider className={classes.listContent}>
                    <ListItemText
                        primary={<Typography component="span" variant="subtitle2"><strong>{repareDataMock.name}</strong></Typography>}
                        secondary={<>
                                {renderTextRequest("RFC: ", repareDataMock.taxId)}<br/>
                                {renderTextRequest("DIRECCIÓN: ", repareDataMock.address)}<br/>
                                {renderTextRequest("", repareDataMock.state)}<br/>
                                {renderTextRequest("C.P. ", repareDataMock.zipCode)}
                        </>} 
                    />
                </ListItem>

                <ListItem  divider className={classes.listContent} >
                    <ListItemAvatar>
                        <Avatar>
                            <CreatorIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        secondary={<>
                                {renderTextRequest("Creado por: ", requestData.createdBy)}<br/>
                                {renderTextRequest("Fecha de creación: ", requestData.creationDate ? moment(requestData.creationDate).format("DD/MM/YYYY") : "")}<br/>
                                {renderTextRequest("Sucursal: ", requestData.branch)}<br/>
                                {requestData.branchManagerName && renderTextRequest("Sinergia de Sucursal: ", requestData.branchManagerName)}
                            </>}
                         />
                </ListItem>

                <ListItem  divider className={classes.listContent} >
                    <ListItemAvatar>
                        <Avatar>
                            <ConfirmIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        secondary={<>
                            {renderTextRequest("Fecha de confirmación: ", requestData.confirmDate ? moment(requestData.confirmDate).format("DD/MM/YYYY") : "")}<br/>
                            {renderTextRequest("Confirmado por: ", requestData.confirm_by)}
                        </>} />
                </ListItem>

                {requestData.comments && 
                  <ListItem  divider className={classes.listContent} >
                    <ListItemAvatar>
                        <Avatar>
                            <CommentsIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        secondary= {renderTextRequest("Comentarios: ", requestData.comments)}/>
                </ListItem>
                }

                <ListItem  divider className={classes.listContent} >
                    <ListItemAvatar>
                        <Avatar>
                            <CalendarIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        secondary= {<>
                            {renderTextRequest("Fecha de entrega a planta: ", requestData.deliveryDate ? moment(requestData.deliveryDate).format("DD/MM/YYYY") : "")}<br/>
                            {renderTextRequest("Fecha de termino: ", requestData.finishedDate ? moment(requestData.finishedDate).format("DD/MM/YYYY") : "")}
                        </>}
                        />
                </ListItem>
            </List>
	)
}

GeneralInformation.propTypes = {
    requestData: PropTypes.object.isRequired,
}

const useStyles = () => ({
    contentTab: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "55vh" 
    },
    listContent:{
        borderLeft: `3px solid ${COLOR_ALTERNATE_NORMAL}`,
     margin: "10px" 
    }
})


export default withStyles(useStyles)(GeneralInformation)
