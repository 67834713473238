import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { RequestStatus } from '../../../store/helpers/StatusHelper'

/** Material UI import section */
import { List, ListItem, ListItemText, Typography, Chip, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import FilesIcon from '@material-ui/icons/FileCopy'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import UploadFileIcon from '@material-ui/icons/CloudUpload'

/** Custom components import section */
import ChipDocument from '../../common/ChipDocument'

/** Resources import section */
import evidenceCoordinatorIcon from '../../../resources/images/evidence03.png'
import evidenceFileIcon from '../../../resources/images/evidence02.png'
import {
	COLOR_ALTERNATE_NORMAL,
	COLOR_FOCUS_NORMAL,
	COLOR_WHITE,
	COLOR_EVIDENCES
} from '../../../resources/constants/Colors'

/**
 * Component to show panel request
 * @param {*} props
 */
function EvidencesPartial(props) {
	const { classes, requestData, isValitToAddEvidenceCoodinator } = props

	const onRemoveEvidence = (name) => {
		if (props.onRemoveCoordinatorEvidence) {
			props.onRemoveCoordinatorEvidence(requestData.token, name)
		}
	}

	/**
	 * General information
	 */
	return (
		<List className={classes.contentTab}>
			{requestData.exist_file_templated && (
				<ListItem divider className={classes.listContent}>
					<ListItemAvatar>
						<Avatar>
							<CloudDownloadIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Typography component='span' variant='subtitle2'>
								<strong>{'PLANTILLA AGREGADA POR EL COORDINADOR'}</strong>
							</Typography>
						}
						secondary={
							<ChipDocument
								label={
									<Link
										component={'a'}
										target='_blank'
										className={classes.colotText}
										href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/Documents/DispositionRequests/Template/${requestData.name_templated}`}
									>
										{`Descargar_plantilla_del_coordinador_${requestData.name_templated}`}
									</Link>
								}
								isVisibleButtonDelete={false}
								chipColor={COLOR_EVIDENCES}
								icon={evidenceFileIcon}
							/>
						}
					/>
				</ListItem>
			)}
			{requestData.hasScrap && (
				<ListItem divider className={classes.listContent}>
					<ListItemAvatar>
						<Avatar>
							<CloudDownloadIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Typography component='span' variant='subtitle2'>
								<strong>{'SOLICITUDES DE CHATARRA'}</strong>
							</Typography>
						}
						secondary={
							<ChipDocument
								label={
									<Link
										component={'a'}
										target='_blank'
										className={classes.colotText}
										href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/Documents/DispositionRequests/Scrap/${requestData.token}/${requestData.scrapEvidenceFile}`}
									>
										{`Descargar archivo ${requestData.scrapEvidenceFile}`}
									</Link>
								}
								isVisibleButtonDelete={false}
								chipColor={COLOR_EVIDENCES}
								icon={evidenceFileIcon}
							/>
						}
					/>
				</ListItem>
			)}
			<ListItem divider className={classes.listContent}>
				<ListItemAvatar>
					<Avatar>
						<FilesIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={
						<Typography component='span' variant='subtitle2'>
							<strong>{'EVIDENCIAS DEL COORDINADOR'}</strong>
						</Typography>
					}
					secondary={
						<React.Fragment>
							{requestData.coordinatorEvidences.length === 0 && (
								<Typography component='span' variant='subtitle2'>
									<strong>{'Sin evidencias de coordinadores'}</strong>
								</Typography>
							)}
							{requestData.coordinatorEvidences.map((evidence) => {
								return (
									<span key={evidence.name}>
										<ChipDocument
											label={
												<Link
													target='_blank'
													className={classes.colotText}
													href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/${evidence.relativePath}`}
												>
													{`${moment(evidence.creation_date).format(
														'DD/MM/YYYY'
													)} | ${evidence.name}`}
												</Link>
											}
											isVisibleButtonDelete={
												isValitToAddEvidenceCoodinator &&
												!requestData.isClose &&
												requestData.status !== RequestStatus.CANCEL
											}
											onRemoveEvidence={() => onRemoveEvidence(evidence.name)}
											chipColor={COLOR_FOCUS_NORMAL}
											icon={evidenceCoordinatorIcon}
										/>
										<br /> <br />
									</span>
								)
							})}

							{isValitToAddEvidenceCoodinator &&
								!requestData.isClose &&
								requestData.status !== RequestStatus.CANCEL && (
									<>
										<br />
										<br />
										<Chip
											component='span'
											icon={
												<UploadFileIcon className={classes.colorWhithe} />
											}
											size='small'
											disabled={props.isSavingRequest}
											label='Subir evidencia'
											className={classes.btnDownload}
											onClick={props.onOpenModalForUploadEvidenceCordinator}
										/>
									</>
								)}
						</React.Fragment>
					}
				/>
			</ListItem>
		</List>
	)
}

EvidencesPartial.propTypes = {
	requestData: PropTypes.object.isRequired
}

const useStyles = () => ({
	contentTab: {
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '55vh'
	},
	listContent: {
		borderLeft: `3px solid ${COLOR_ALTERNATE_NORMAL}`,
		margin: '10px'
	},
	colorWhithe: {
		color: 'white'
	},
	btnDownload: {
		backgroundColor: '#83588A',
		color: '#FFFFFF',
		textTransform: 'uppercase',
		height: '28px !important'
	},
	iconList: {
		fontSize: '18px',
		cursor: 'pointer'
	},
	colotText: {
		color: ` ${COLOR_WHITE} !important`
	}
})

export default withStyles(useStyles)(EvidencesPartial)
