import React, { useState } from 'react'

/**Import material UI sections */
import {
	Checkbox,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Paper,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

/** Helpers import section */
import { COLOR_WHITE } from '../../../resources/constants/Colors'
import { getAvailableRolesByUserType } from '../../../store/helpers/RoleHelper'
import { isUserInRole } from '../../../store/helpers/UserHelper'

const useRoleStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(5)
	},
	roleItem: {
		color: COLOR_WHITE,
		marginTop: 10,
		marginBottom: 15,
		fontWeight: 600,
		opacity: 0.6,
		height: '90px',
		width: '840px'
	},
	circularProgress: {
		marginTop: 5,
		marginLeft: 5
	}
}))

const RolesForm = (props) => {
	const classes = useRoleStyles()
	const disabled = props.disabled ? true : false
	const cursor = disabled ? 'not-allowed' : 'pointer'
	const { type, roles } = props.userProfile
	const [selectedRole, setSelectedRole] = useState(null)

	const onToggleRole = (event) => {
		let roleName = event.currentTarget.id
		if (!disabled && !props.isSaving && props.onToggleRole) {
			setSelectedRole(roleName)
			props.onToggleRole(roleName).finally(() => setSelectedRole(null))
		}
	}

	let options = getAvailableRolesByUserType(type)
	return (
		<Paper className={classes.root}>
			<Grid container justifyContent='center' alignItems='center' spacing={3}>
				<List>
					<Typography variant='subtitle1' styles={{ color: '#444054', fontSize: '12px' }}>
						{'ROLES'}
					</Typography>
					{options.map((roleOption) => {
						let isDisabled = disabled || props.isSaving
						let showSpinner = props.isSaving && selectedRole === roleOption.key
						return (
							<ListItem
								dense
								id={roleOption.key}
								key={roleOption.key}
								className={classes.roleItem}
								disabled={isDisabled}
								style={{ backgroundColor: roleOption.color, cursor: cursor }}
								onClick={onToggleRole}
							>
								<ListItemIcon>
									{
										{
											true: (
												<CircularProgress
													className={classes.circularProgress}
													size={30}
												/>
											),
											false: (
												<Checkbox
													disabled={isDisabled}
													style={{ color: COLOR_WHITE, cursor: cursor }}
													checked={isUserInRole(roleOption.key, roles)}
												/>
											)
										}[showSpinner]
									}
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography variant='subtitle1' style={{ fontWeight: 600 }}>
											{roleOption.label}
										</Typography>
									}
									secondary={<Typography>{roleOption.description}</Typography>}
								/>
							</ListItem>
						)
					})}
				</List>
			</Grid>
		</Paper>
	)
}

export default RolesForm
