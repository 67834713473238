import { SET_ACCESS_TOKEN, EXECUTE_LOGOUT } from './SessionActions'

const OIDC_USER_PROFILE = 'oidc.userprofile'

/**
 * Get oidc user profile from session store
 * @returns
 */
const getOidUserProfile = () => {
	try {
		return JSON.parse(sessionStorage.getItem(OIDC_USER_PROFILE))
	} catch (error) {
		return null
	}
}
let user = getOidUserProfile()

let initialSessionState = {
	user: user,
	isLoadingUser: false,
}

export const reducer = (state = initialSessionState, action) => {
	switch (action.type) {
		case SET_ACCESS_TOKEN: {
			// Update state
			let updatedState = { ...state }
			updatedState.user = { ...action.accessToken }
			updatedState.user.profile = { ...action.userProfile }
			// Store the user profile in the session store
			sessionStorage.setItem(
				OIDC_USER_PROFILE,
				JSON.stringify(updatedState.user)
			)
			return updatedState
		}
		case EXECUTE_LOGOUT: {
			let updatedState = { ...state }
			updatedState.user = null
			sessionStorage.removeItem(OIDC_USER_PROFILE)
			return updatedState
		}
		default:
			return state
	}
}
