import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/** Material UI */
import {
    Grid,
    Typography
} from '@material-ui/core';

/** Custom component import section */
import LateralActionPanel from '../../common/LateralActionPanel';
import TextBox from '../../common/TextBox';
import { TextBoxFieldRequired } from '../../common/TextBox';

/** Helpers import section */
import { toSafeArray } from '../../../store/helpers/ImmutableHelper';
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';

const AddCustomerPanel = props => {
    const [customerModel, setCustomerModel] = useState(props.customerModel);

    const allCustomers = useSelector(state => toSafeArray(state.customers.get('allCustomers')));

    const onPropertyChange = event => {
        let property = event.target.name;
        let value = event.target.value;
        setCustomerModel({
            ...customerModel,
            [property]: value
        })
    }

    const onCustomerCodeChange = event => {
        onPropertyChange(event);
    }

    const isCustomerAlreadyAdded = () => {
        return allCustomers.some(u => u.code === customerModel.code);
    }

    const canBeSaved = () => {
        if (!customerModel.code || customerModel.code.length === 0) return false;
        if (!customerModel.name || customerModel.name.length === 0) return false;
        if (isCustomerAlreadyAdded()) return false;
        return true;
    }

    const onSaveButtonClick = () => {
        if (props.onCreateCustomer) {
            props.onCreateCustomer(customerModel);
        }
        else {
            console.warn("Callback 'onCreateCustomer' not defined!");
        }
    }

    const getCodeTextHelper = () => {
        if (isCustomerAlreadyAdded()) { return (<Typography color="error" variant="caption">{"Ya existe un cliente con este código"}</Typography>); }
        return null;
    }

    return (
        <LateralActionPanel
            title="Agregar cliente"
            show={props.open}
            onClose={props.onClose}
            isSaving={props.isSaving}
            canBeSaved={!canBeSaved()}
            onSaveItem={onSaveButtonClick}
            onCancel={props.onClose}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} >
                    <TextBoxFieldRequired
                        dataCy="clave-customer"
                        active={true}
                        autoFocus
                        required
                        autoComplete='none'
                        isSuccess={!isNullOrEmpty(customerModel.code)}
                        label="Clave del cliente"
                        fullWidth
                        margin={"dense"}
                        name="code"
                        value={customerModel.code}
                        onChange={onCustomerCodeChange}
                        helperText={getCodeTextHelper()}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextBoxFieldRequired
                        dataCy="txt-name"
                        active={true}
                        required
                        isSuccess={!isNullOrEmpty(customerModel.name)}
                        label="Nombre"
                        fullWidth={true}
                        margin="dense"
                        name="name"
                        value={customerModel.name}
                        onChange={onPropertyChange}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextBox
                        active={true}
                        label="RFC"
                        fullWidth={true}
                        margin="dense"
                        name="taxId"
                        value={customerModel.taxId}
                        onChange={onPropertyChange}
                    />
                </Grid>
            </Grid>
        </LateralActionPanel>
    )
}

AddCustomerPanel.defaultProps = {
    customerModel: {
        code: '',
        name: '',
        taxId: ''
    }
}

export default AddCustomerPanel;  