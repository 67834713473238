
/**
 * Get the file extension, including a dot
 */
export function getExtension(fileName) {
    if (fileName) fileName = fileName.trim();
    if (!fileName) return null
    let args = fileName.split('.')
    if (args.length < 2) return null
    if (args[args.length-1] === "") return null
    return `.${args[args.length-1].toLowerCase()}`
}