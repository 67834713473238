import { makeStyles } from '@material-ui/core/styles'

import { COLOR_DANGER_NORMAL, COLOR_SUCCESS_NORMAL, COLOR_INFO_NORMAL,
    COLOR_SECONDARY_NORMAL, COLOR_YELLOW_NORMAL } from '../../../resources/constants/Colors';

/**
 * Requests toolbar styles
 */
export const useRequestsToolbar = makeStyles((theme) => ({
    root: {
        height: theme.mixins.toolbar.minHeight,
        display: 'flex'
    },
    contextualMenu: {
        padding: '5px'
    },
    menuItem: {
        margin: '3px',
        cursor: 'pointer'
    },
    menuItemLabel: {
        marginLeft: '12px'
    },
    inputSearch: {
        transition: theme.transitions.create("width"),
        backgroundColor: '#f6f2f2',
        height: 35,
        borderRadius: 50,
        width: 200,
        right: 35,
        paddingLeft: 25,
    },
    grow: {
        flexGrow: 1,
    },
    colorWhithe:{
        color: "white",
    },
    btnDownload:{
        backgroundColor: "#83588A",
        color: "#FFFFFF", 
        textTransform: "uppercase",
        height: "28px !important",
    },
    buttonAddRequets: {
        marginRight: 55,
        marginTop:-11,
    },
    indexList: {
        height: `calc(100vh - ${270}px)`,
    },
    btnDownloadReport:{
        backgroundColor: "#30B1FF",
        color: "#FFFFFF", 
        textTransform: "uppercase",
        height: "28px !important",
        cursor: 'pointer'
    },
    iconSize:{
        width: "135px"
    },
    colorIconRequestInit:{
        color: COLOR_SECONDARY_NORMAL, 
        marginRight: 5,
    },
    colorIconRequestInProcess:{
        color: COLOR_YELLOW_NORMAL, 
        marginRight: 5,
    },
    colorIconRequestCompleted:{
        color: COLOR_INFO_NORMAL, 
        marginRight: 5,
    },
    colorIconRequestLibered:{
        color: COLOR_SUCCESS_NORMAL, 
        marginRight: 5,
    },
    colorIconRequestCancel:{
        color: COLOR_DANGER_NORMAL, 
        marginRight: 5,
    },
    positionPopper:{
        zIndex:9999
    }
}))
