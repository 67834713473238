import React from 'react';

/** Material-UI import section */
import { Grid } from '@material-ui/core';
import ScaleLoader from 'react-spinners/ScaleLoader';
import '../../resources/styles/loader.css'

const Loader = () => {
    return (
        <div className="loader-container" >
            <Grid container>
                <Grid item xs={12}>
                    <ScaleLoader
                        sizeUnit={"px"}
                        height={40}
                        width={4}
                        margin={"2px"}
                        color={'rgba(0, 0, 0, 0.38)'}
                        loading={true}
                        className="loader"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default (Loader);