import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';

/** Custom components import section */
import AnonymousView from './views/home/AnonymousView';
import AuthenticatedView from './views/home/AuthenticatedView';
import { loadUserProfile } from './store/session/SessionActions';
import LoadingPermissions from './views/home/LoadingPermissions';
import ExecutingLogout from './views/home/ExecutingLogout';
import { isUserInRole } from './store/helpers/UserHelper';
import { AppRoles } from './resources/constants/AppConstants';
import { loadDocumentsReport } from './store/operatingDocuments/DocumentActions';
import UnauthorizedView from './views/home/UnauthorizedView';

const App = () => {
    const dispatch = useDispatch();
    const isLoadingUser = useSelector(state => state.oidc.isLoadingUser);
	const oidcUser = useSelector(state => state.oidc.user);
	const loggedUser = useSelector(state => state.session.get('loggedUser'));
	const isExecutingLogout = useSelector(state => state.session.get('isExecutingLogout'));

	const isAuthenticated = () => {
		if (!(isLoadingUser === false && oidcUser)) { return false; }
		return true;
    }
    
    const isAuthorized = () => {
		if (!loggedUser || loggedUser.get('roles').size === 0)
			return false
		return true
	}

    useEffect(() => {
        if (oidcUser) {
            dispatch(loadUserProfile());
        }
    }, [dispatch, oidcUser]);

    useEffect(() => {
        if (loggedUser && (isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.toJS().roles) 
            || isUserInRole(AppRoles.ROLE_DOCUMENTS_OPERATIVES, loggedUser.get('roles').toJS()))) {
            dispatch(loadDocumentsReport());
        }
    }, [dispatch, loggedUser])

    if (isExecutingLogout) { return (<ExecutingLogout/>); }
    if (!isAuthenticated()) { return (<AnonymousView/>); }
    else{
        if(isLoadingUser || loggedUser ===null)
            return <LoadingPermissions/>
        else if (isAuthorized())
			return <AuthenticatedView />
        else
            return <UnauthorizedView />
    }
}

export default App;
