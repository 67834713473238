import React, { useState } from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import {
	makeStyles,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Typography,
	Grid
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

/** Common components import section */
import DateSelector from '../../common/DateSelector'

/** Helpers import section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'

/** Resources import section */
import {
	COLOR_SUCCESS_NORMAL,
	COLOR_DANGER_NORMAL,
	COLOR_WHITE,
	COLOR_PRIMARY_NORMAL,
	COLOR_SECONDARY_NORMAL
} from '../../../resources/constants/Colors'
import RequestStatusSelector from '../../common/RequestStatusSelector'

/**
 * DownloadReportDialog component to show a modal confirmation.
 * @param {object} props
 */
const DownloadReportDialog = (props) => {
	const { open, title, isSaving, statusDisplayed, onClose, onDownloadReport } = props
	const classes = useStyles()

	/** Local state */
	const [requestData, setRequestData] = useState({ startDate: null, endDate: null })

	/**
	 * Handle start date changing
	 * @param {object} event
	 */
	const onChangeStartDate = (event) => {
		let { value } = event.target
		let name = 'startDate'
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	/**
	 * Handle end date changing
	 * @param {object} event
	 */
	const onChangeEndDate = (event) => {
		let { value } = event.target
		let name = 'endDate'
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	const onStatusChange = (selectedStatus) => {
		const { value } = selectedStatus
		setRequestData({
			...requestData,
			status: value
		})
	}

	/**
	 * Determines if report can be downloaded
	 */
	const canBeDownload = () => {
		if (statusDisplayed) {
			if (isNullOrEmpty(requestData && requestData.status)) {
				return false
			}
		} else {
			if (
				isNullOrEmpty(requestData && requestData.startDate) ||
				(requestData && requestData.startDate.length < 1)
			) {
				return false
			}
			if (
				isNullOrEmpty(requestData && requestData.endDate) ||
				(requestData && requestData.endDate.length < 1)
			) {
				return false
			}
		}
		return true
	}

	/**
	 * Hanlde click event from download report
	 */
	const onDownloadReportClick = () => {
		if (onDownloadReport) {
			onDownloadReport(requestData)
		}
	}

	/**
	 * Render the component content
	 */
	return (
		<Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
			<DialogTitle disableTypography className={classes.root}>
				<Typography variant='button' className={classes.title}>
					<strong>{title}</strong>
				</Typography>
				<IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container justifyContent='space-between' alignItems='center'>
					{
						{
							true: (
								<Grid item xs={12}>
									<RequestStatusSelector onChange={onStatusChange} />
								</Grid>
							),
							false: (
								<>
									<Grid item xs={5}>
										<DateSelector
											dataCy='date-picker-start'
											active={true}
											label={'Fecha de inicio'}
											onChange={onChangeStartDate}
											value={requestData ? requestData.startDate : null}
										/>
									</Grid>
									<Grid item xs={5}>
										<DateSelector
											dataCy='date-picker--end'
											active={true}
											label={'Fecha fin'}
											onChange={onChangeEndDate}
											value={requestData ? requestData.endDate : null}
										/>
									</Grid>
								</>
							)
						}[statusDisplayed]
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<div className={classes.wrapper}>
					<Button
						data-cy='btn-accept'
						className={classes.buttonAccept}
						variant='contained'
						onClick={onDownloadReportClick}
						disabled={!canBeDownload() || isSaving}
					>
						{'Descargar'}
					</Button>
					{isSaving && (
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
							disableShrink
						/>
					)}
				</div>
			</DialogActions>
		</Dialog>
	)
}

DownloadReportDialog.propsTypes = {
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
	onDownloadReport: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isSaving: PropTypes.bool,
	statusDisplayed: PropTypes.bool
}

DownloadReportDialog.defaultProps = {
	open: false,
	title: 'Descargar reporte por rango de fechas',
	onDownloadReport: () => console.warn('[onDownloadReport] not defined!'),
	onClose: () => console.warn('[onClose] not defined!'),
	isSaving: false,
	statusDisplayed: false
}

const useStyles = makeStyles((theme) => ({
	title: {
		color: COLOR_PRIMARY_NORMAL
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonAccept: {
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: COLOR_WHITE,
		right: '10px',
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	buttonProgress: {
		color: COLOR_SECONDARY_NORMAL,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	closeButton: {
		position: 'absolute',
		right: '22px',
		top: theme.spacing(1),
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL
		},
		width: '37px',
		height: '36px',
		paddingTop: 8
	},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {}
	},
	cssFocused: {},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: `${COLOR_PRIMARY_NORMAL} !important`,
		color: `${COLOR_PRIMARY_NORMAL} !important`
	},
	colorWhithe: {
		color: 'white'
	}
}))

export default DownloadReportDialog
