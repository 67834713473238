/** Import ReactJS */
import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles'
import {
    TextField,
    Typography
} from '@material-ui/core';
import { PositionPlacement } from '../../resources/constants/AppConstants';

const useDefaultStyles = makeStyles({
    cssLabel: {
    },
    cssFocused: {
        color: 'black !important'
    },
    cssOutlinedInput: {
        color: 'black !important',
        '&$cssFocused $notchedOutline': {
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `rgba(108, 117, 125, 1)!important`,
        color: `rgba(108, 117, 125, 1) !important`
    }
});

const TextBox = props => {
    const {
        dataCy,
        active,
        inactiveTitlePosition,
        autoFocus,
        autoComplete,
        label,
        value,
        name,
        helperText,
        fullWidth,
        margin,
        disabled,
        type,
        multiline,
        rowsMax,
        rows,
        style
    } = props;
    const classes = useDefaultStyles();

    const onChangeHandler = event => {
        if (props.onChange) {
            props.onChange(event);
        }
        else {
            console.warn("onChange callback not found.")
        }
    }

    const onBlurHandler = (value) => {
        if (props.onBlur) {
            props.onBlur(value)
        }
    }
    return (
        {
            true: <TextField
                data-cy={dataCy}
                autoFocus={autoFocus || false}
                autoComplete={autoComplete}
                label={label}
                value={value}
                name={name}
                className={classes.textField}
                helperText={helperText || ""}
                fullWidth={fullWidth}
                margin={margin}
                variant="outlined"
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                type={type}
                multiline={multiline}
                disabled={disabled}
                rowsMax={rowsMax}
                rows={rows}
                style={style}
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "text"
                }}
            />,
            false: <div>
                {inactiveTitlePosition === PositionPlacement.TOP && <Typography variant="subtitle1">{label}</Typography>}
                <Typography variant="h6" >{value}</Typography>
                {inactiveTitlePosition === PositionPlacement.BOTTOM && <Typography variant="subtitle1">{label}</Typography>}
            </div>
        }[active]
    );
}

TextBox.propTypes = {
    active: PropTypes.bool,
    inactiveTitlePosition: PropTypes.oneOf([
        PositionPlacement.TOP,
        PositionPlacement.BOTTOM,
    ]),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    margin: PropTypes.string,
    rowsMax: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    helperText: PropTypes.node,
    type: PropTypes.string,
}

TextBox.defaultProps = {
    active: false,
    inactiveTitlePosition: PositionPlacement.BOTTOM,
    disabled: false,
    multiline: false,
    margin: 'normal',
}

export default (TextBox);


const useWhiteStyles = makeStyles({
    cssLabel: {
        color: 'white !important'
    },
    cssOutlinedInput: {
        color: 'white !important'
    },
    cssFocused: {
        color: 'white !important'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'white !important',
        color: 'white !important'
    }
});

export const WhiteTextBox = props => {
    const {
        active,
        inactiveTitlePosition,
        autoFocus,
        label,
        value,
        name,
        helperText,
        fullWidth,
        margin,
        disabled,
        type,
        multiline,
        autoComplete
    } = props;
    const classes = useWhiteStyles();

    const onChangeHandler = event => {
        if (props.onChange) {
            props.onChange(event);
        }
        else {
            console.warn("onChange callback not found.")
        }
    }

    return (
        {
            true: <TextField
                id={uuidv4()}
                autoFocus={autoFocus || false}
                autoComplete={autoComplete}
                label={label}
                value={value}
                name={name}
                helperText={helperText || ""}
                fullWidth={fullWidth}
                margin={margin}
                variant="outlined"
                onChange={onChangeHandler}
                type={type}
                multiline={multiline}
                disabled={disabled}
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                    inputMode: "text",
                }}
            />,
            false: <div>
                {inactiveTitlePosition === PositionPlacement.TOP && <Typography variant="subtitle1">{label}</Typography>}
                <Typography variant="h6" >{value}</Typography>
                {inactiveTitlePosition === PositionPlacement.BOTTOM && <Typography variant="subtitle1">{label}</Typography>}
            </div>
        }[active]
    );
}

WhiteTextBox.defaultProps = {
    active: false,
    inactiveTitlePosition: PositionPlacement.BOTTOM,
    disabled: false,
    multiline: false,
    autoComplete: 'off',
}

const useRequiredStyles = makeStyles({
    cssLabel: {
        // color: '#D92550 !important'
    },
    cssOutlinedInput: {
        color: 'black !important'
    },
    cssFocused: {
        color: 'black !important'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#545CD8 !important',
    },
    notchedOutlineSuccess: {
        borderWidth: '1px',
        borderColor: 'rgba(108, 117, 125, 1) !important',
        color: 'black !important'
    }
});

export const TextBoxFieldRequired = props => {
    const {
        dataCy,
        active,
        inactiveTitlePosition,
        autoFocus,
        label,
        value,
        name,
        helperText,
        fullWidth,
        margin,
        disabled,
        type,
        multiline,
        rowsMax,
        rows,
        required,
        autoComplete,
        variant,
        isSuccess
    } = props;

    const classes = useRequiredStyles();
    
    const onChangeHandler = event => {
        if (props.onChange) {
            props.onChange(event);
        }
        else {
            console.warn("onChange callback not found.")
        }
    }
    const onBlurHandler = (value) => {
        if (props.onBlur) {
            props.onBlur(value)
        }
        else {
            console.log("onBlurField not found")
        }
    }
    return (
        {
            true: <TextField
                autoComplete={autoComplete}
                // id={uuidv4()}
                autoFocus={autoFocus || false}
                label={label}
                value={value}
                required={required}
                data-cy={dataCy}
                name={name}
                className={classes.textField}
                helperText={helperText || ""}
                fullWidth={fullWidth}
                margin={margin}
                variant={variant}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                type={type}
                multiline={multiline}
                disabled={disabled}
                rowsMax={rowsMax}
                rows={rows}
                style={{ borderColor: "green" }}
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: isSuccess ? classes.notchedOutlineSuccess : classes.notchedOutline,
                    },
                    inputMode: "text",
                }}
            />,
            false: <div>
                {inactiveTitlePosition === PositionPlacement.TOP && <Typography variant="subtitle1">{label}</Typography>}
                <Typography variant="h6" >{value}</Typography>
                {inactiveTitlePosition === PositionPlacement.BOTTOM && <Typography variant="subtitle1">{label}</Typography>}
            </div>
        }[active]
    );
}

TextBoxFieldRequired.propTypes = {
    active: PropTypes.bool,
    inactiveTitlePosition: PropTypes.oneOf([
        PositionPlacement.TOP,
        PositionPlacement.BOTTOM,
    ]),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    required: PropTypes.bool,
    isSuccess: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    margin: PropTypes.string,
    rowsMax: PropTypes.string,
    dataCy: PropTypes.string,
    rows: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    helperText: PropTypes.node,
    type: PropTypes.string,
    variant: PropTypes.string,
}

TextBoxFieldRequired.defaultProps = {
    active: false,
    inactiveTitlePosition: PositionPlacement.BOTTOM,
    disabled: false,
    multiline: false,
    required: false,
    isSuccess: false,
    margin: 'normal',
    autoComplete: 'off',
    variant: "outlined",
    dataCy: "",

}

