import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/** Import Material-UI */
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Clear';

/** Resources import section */
import {
    COLOR_SUCCESS_NORMAL,
    COLOR_WHITE,
    COLOR_PRIMARY_NORMAL,
    COLOR_DANGER_NORMAL,
    COLOR_SUCCESS_PRESSED
} from '../../resources/constants/Colors';

/** Defines styles for add item drawer */
const addItemDrawerStyles = theme => ({
    drawer: {
        width: "420px",
        display: 'grid',
        height: "100vh",
        gridTemplateRows: '50px auto 60px',
    },
    title: {
        display: "flex",
        // backgroundColor: theme.palette.primary.main,
        padding: "30px 20px",
        // position: "fixed",

    },
    content: {
        padding: "20px",
        // overflow:'scroll' 
    },
    buttons: {
        marginLeft: "19em",
        zIndex: '300',
        position: "fixed",
        top: ".3em",
        padding: "5px",

    },
    buttonSave: {
        backgroundColor: COLOR_SUCCESS_NORMAL, "&:hover": {
            backgroundColor: `${COLOR_SUCCESS_NORMAL} !important`
        },
        width: "37px",
        borderRadius: "50% !important",
        height: "36px",
        margin: "5px"
    },
    buttonCancel: {
        backgroundColor: `${COLOR_DANGER_NORMAL} !important`, "&:hover": {
            backgroundColor: `${COLOR_DANGER_NORMAL} !important`
        },
        borderRadius: "50% !important",
        width: "37px",
        height: "36px"
    },
    colorIconButton: {
        color: COLOR_WHITE,
    },
    fabProgressSaved: {
        marginLeft: "19.6em",
        color: COLOR_SUCCESS_PRESSED,
        zIndex: '300',
        position: "fixed",
        top: ".7em",
    }
})

/**
 * Add item panel component
 *
 * @class AddItemPanel
 * @extends {Component}
 */
const LateralActionPanel = props => {
    const { classes } = props;

    return (
        <Drawer anchor="right" open={props.show} onClose={props.onClose}>
            <div
                className={classNames(classes.drawer)}
                tabIndex={0}
                role="button"
            >
                <div style={{ gridRowStart: '1', gridRowEnd: '2', backgroundColor: "#fff", zIndex: 99999 }} >
                    <div className={classes.buttons}>
                        <Fab disabled={props.canBeSaved || props.disabled}
                            data-cy="btn-save"
                            className={classes.buttonSave}
                            onClick={props.onSaveItem}>
                            <SaveIcon className={classes.colorIconButton} />
                        </Fab>
                        <Fab
                            data-cy="btn-cancel"
                            // disabled={props.disabled}
                            className={classes.buttonCancel}
                            onClick={props.onCancel}
                        >
                            <DeleteIcon
                                className={classes.colorIconButton} />
                        </Fab>
                    </div>
                    {props.isSaving && <CircularProgress className={classes.fabProgressSaved} size={40} />}
                    <div className={classes.title}>
                        <Typography style={{ color: COLOR_PRIMARY_NORMAL }} variant="subtitle1" gutterBottom >{props.title.toUpperCase()}</Typography>
                    </div>
                </div>
                <div style={{ gridRowStart: '2', gridRowEnd: '3' }} className={classes.content}>
                    {props.children}
                </div>
                {props.footer &&
                    <div style={{ gridRowStart: '3', gridRowEnd: '4' }} className={classes.content}>
                        {props.footer}
                    </div>
                }
            </div>
        </Drawer>
    )
}

LateralActionPanel.propTypes = {
    disabled: PropTypes.bool, /**disabled button save and close */
    canBeSaved: PropTypes.bool, /**Disabled button save in invalid data */
    onSaveItem: PropTypes.func, /**Save data */
}
export default withStyles(addItemDrawerStyles)(LateralActionPanel)