/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

/** Custom components import section */

/**Import material ui section */
import { CardHeader, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/** Resources import section */
import { COLOR_PRIMARY_NORMAL, COLOR_WHITE } from '../../../resources/constants/Colors';
import yourlogohere from '../../../resources/images/yourlogohere.png';
import { getDispositionRequestStatusDescription } from '../../../store/helpers/StatusHelper';

const LayoutHeaderRequests = props => {
    const classes = useStyles();
    const {requestData } = props;
    let customerName = requestData && <span>{"CLIENTE: "}{requestData.customer.fullCustomer}</span>

    return (
        <CardHeader
            className={classes.cardHeader}
            avatar={
                <img
                    src={yourlogohere}
                    alt="logoSupplier"
                    className={classes.logoSupplier}
                />
            }
            subheader={<p>
                <Typography component="span" className={classes.textColor}>
                    <strong>{customerName}</strong><br />
                    {'RFC: '}{!props.isLoading && requestData && requestData.customer.taxtId} <br />
                    {"N° DE ACTA: "}{!props.isLoading && requestData ? requestData.actNumber : '-'}<br/>
                    {"FOLIO: "}{!props.isLoading && requestData ? requestData.folio : '-'}<br/>
                </Typography>
                <Typography component="span" variant="button" align="right" className={classes.textColor}>
                    {"Estatus: "}<strong>{!props.isLoading && requestData ? getDispositionRequestStatusDescription(requestData.status) : ""}</strong>
                </Typography>
            </p>}
        >
        </CardHeader>
    )
}

LayoutHeaderRequests.propTypes = {
    requestData: PropTypes.object,
}

LayoutHeaderRequests.defaultProps = {
    requestData: null,
}

const useStyles = makeStyles(() => ({
    cardHeader:{
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
    },
    logoSupplier: {
        maxHeight: 80,
        marginTop: "-5px"
    },
    listContent: {
        paddingLeft: "5px",
        paddingTop: 0,
        paddingRight: "5px",
        paddingBottom: 0,
        background:COLOR_PRIMARY_NORMAL, 
        color:COLOR_WHITE,
        width:"250px"
    },
    textColor:{
        color:COLOR_WHITE,
    }
}))
export default withImmutablePropsToJS(LayoutHeaderRequests)