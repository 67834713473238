import { isNullOrEmpty } from './StringHelper'

/**
 * Sort an array of objects based on sorting values
 * @param {{by: string, criteria: string}} sorting Sorting criteria, by is the property name considered to sort the array
 * @param {Array} dataSet Array with objects to sort
 * @returns {Array} New array sorted
 */
const sortDataSet = (sorting, dataSet) => {
	if (!!dataSet) {
		return dataSet.sort((item1, item2) => {
			if (item1[sorting.by] < item2[sorting.by]) {
				return sorting.criteria === 'desc' ? 1 : -1
			}
			if (item1[sorting.by] > item2[sorting.by]) {
				return sorting.criteria === 'desc' ? -1 : 1
			}
			return 0
		})
	} else {
		return dataSet
	}
}

/**
 * Filter objects in dataSet if those items contains the query value on some of the properties listed on columnNames.
 * @param {string} query String value to search on properties
 * @param {Array} propertyNames Property names over query will be searched
 * @param {Array} dataSet Array of objects to filter
 * @returns New array with filtered objects
 */
const searchByQuery = (query, propertyNames, dataSet) => {
	if (isNullOrEmpty(query) || !Array.isArray(propertyNames) || propertyNames === 'status') {
		return dataSet
	}

	const filteredDataSet = dataSet.filter((item) => {
		const itemContainsQuery = propertyNames.some((column) => {
			const value = String(item[column])
			if (isNullOrEmpty(value) || value === undefined) {
				return false
			}
			return value?.toLowerCase().includes(query)
		})

		return itemContainsQuery
	})
	return filteredDataSet
}

const searchByFilters = (filters, dataSet) => {
    if ((!filters) || !Array.isArray(filters) || filters.length === 0) { return dataSet; }
    let filteredDataSet = dataSet.filter(item => {
        let matchCriteria = filters.some(filter => {
            let { name, values } = filter;
            if (item.hasOwnProperty(name)) {
                return values.includes(item[name].toString());
            }
            return false;
        });
        return matchCriteria;
    });
    return filteredDataSet;
}


export { searchByQuery, sortDataSet, searchByFilters }
