/**Import react section */
import React from 'react'
import PropTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import material ui section */
import { Fab, FormGroup, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ApprovedIcon from '@material-ui/icons/Done'
import CancelIcon from '@material-ui/icons/Clear'
import CalendarIcon from '@material-ui/icons/TodayOutlined'
import LiberatedIcon from '@material-ui/icons/Lock'

/** Resources import section */
import {
	COLOR_DANGER_NORMAL,
	COLOR_WHITE,
	COLOR_SUCCESS_NORMAL,
	COLOR_INFO_NORMAL
} from '../../../resources/constants/Colors'
import { isUserInRole } from '../../../store/helpers/UserHelper'
import { AppRoles } from '../../../resources/constants/AppConstants'
import { RequestStatus } from '../../../store/helpers/StatusHelper'

const RequestsToolBar = (props) => {
	const classes = useStyles()
	const { actions } = props

	const configurationButtonName = (key) => {
		switch (key) {
			case 'btnAuthorize':
				return <Typography variant='caption'>{'Autorizar'}</Typography>
			case 'btnAccept':
				return <Typography variant='caption'>{'Confirmar recepción'}</Typography>
			case 'btnConfirmCollection':
				return <Typography variant='caption'>{'Confirmar recolección'}</Typography>
			case 'btnCancel':
				return <Typography variant='caption'>{'Cancelar solicitud'}</Typography>
			case 'btnLiberated':
				return <Typography variant='caption'>{'Terminar solicitud'}</Typography>
			default:
				return null
		}
	}

	const configurationStyleButton = (key) => {
		switch (key) {
			case 'btnAccept':
			case 'btnAuthorize':
			case 'btnLiberated':
				return classes.buttonAccepted
			case 'btnConfirmCollection':
				return classes.buttonConfirmCollection
			case 'btnCancel':
				return classes.buttonCancel
			default:
				return null
		}
	}

	const dataCy = (key) => {
		switch (key) {
			case 'btnAuthorize':
				return 'btn-auth-request'
			case 'btnAccept':
				return 'btn-accept-request'
			case 'btnConfirmCollection':
				return 'btn-confirm-collection'
			case 'btnCancel':
				return 'btn-cancel-request'
			case 'btnLiberated':
				return 'btn-liberated-request'
			default:
				return null
		}
	}

	const configurationIconButton = (key) => {
		switch (key) {
			case 'btnAccept':
			case 'btnAuthorize':
				return <ApprovedIcon />
			case 'btnConfirmCollection':
				return <CalendarIcon />
			case 'btnCancel':
				return <CancelIcon />
			case 'btnLiberated':
				return <LiberatedIcon />
			default:
				return null
		}
	}

	const renderButtons = () => {
		return actions.map((action) => (
			<Fab
				data-cy={dataCy(action.key)}
				variant='extended'
				size='small'
				key={action.key}
				className={configurationStyleButton(action.key)}
				onClick={action.onClick}
			>
				{configurationIconButton(action.key)}
				{configurationButtonName(action.key)}
			</Fab>
		))
	}

	return <FormGroup row>{renderButtons()}</FormGroup>
}

/** Prop types validations */
RequestsToolBar.propTypes = {
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOf(['btnAccept', 'btnLiberated', 'btnConfirmCollection', 'btnCancel'])
				.isRequired,
			onClick: PropTypes.func
		})
	)
}

RequestsToolBar.defaultProps = {}

const useStyles = makeStyles((theme) => ({
	buttonAccepted: {
		boxShadow: '0px 0px 0px 0px',
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: COLOR_WHITE
		}
	},
	buttonCancel: {
		margin: theme.spacing(1),
		boxShadow: '0px 0px 0px 0px',
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL,
			color: COLOR_WHITE
		}
	},
	buttonConfirmCollection: {
		boxShadow: '0px 0px 0px 0px',
		backgroundColor: COLOR_INFO_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_INFO_NORMAL,
			color: COLOR_WHITE
		}
	},
	formControlLabel: {
		marginRight: 0,
		marginLeft: '1em'
	},
	refreshButton: {
		margin: theme.spacing(1)
	}
}))
export default withImmutablePropsToJS(RequestsToolBar)

export const buildRequestToolbarConfiguration = (
	loggedUser,
	requestStatus,
	callbacks,
	confirmDate,
	programmeDate
) => {
	let toolbarConfig = []
	if (
		isUserInRole(AppRoles.SALES_MANAGER, loggedUser.roles) ||
		isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles)
	) {
		console.log({ loggedUser, requestStatus, callbacks })
		if (requestStatus === RequestStatus.PENDING_AUTH) {
			toolbarConfig.push({
				key: 'btnAuthorize',
				onClick: callbacks.onClickAuthorizeRequest
			})
		} else if (requestStatus === RequestStatus.REQUESTED) {
			toolbarConfig.push({
				key: 'btnAccept',
				onClick: callbacks.onClickAcceptRequest
			})
		}
		// Determina si ya cambio de estatus y no ha sido agregado la confirmación muestra la opcion para confirmar
		else if (
			requestStatus === RequestStatus.CONFIRMED ||
			requestStatus === RequestStatus.SCHEDULED ||
			requestStatus === RequestStatus.DELIVERED ||
			requestStatus === RequestStatus.IN_PROCESS
		) {
			if (confirmDate === null) {
				toolbarConfig.push({
					key: 'btnAccept',
					onClick: callbacks.onClickAcceptRequest
				})
			} else {
				// Determna puede agregar fechas de recolección de material
				toolbarConfig.push({
					key: 'btnConfirmCollection',
					onClick: callbacks.onClickCollectionRequest
				})
			}
		} else if (requestStatus === RequestStatus.COMPLETED) {
			toolbarConfig.push({
				key: 'btnLiberated',
				onClick: callbacks.onClickLiberatedRequest
			})
		}
	}
	// Determine if customer can be cancel
	if (
		isUserInRole(AppRoles.CUSTOMER_SERVICE_APPLICANT, loggedUser.roles) &&
		requestStatus === RequestStatus.REQUESTED
	) {
		toolbarConfig.push({
			key: 'btnCancel',
			onClick: callbacks.onClickCancelRequest
		})
	}
	return toolbarConfig
}
