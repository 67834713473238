import React from 'react'

/**Import material UI section*/
import { Chip, makeStyles, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { COLOR_DANGER_NORMAL,COLOR_WHITE } from '../../resources/constants/Colors'

const ChipDocument = (props) => {
    const classes = chipDocumentStyles();
    const { label, downloadDocument, isVisibleButtonDelete, onRemoveEvidence, chipColor, icon } = props;

    const onChipClick = () => {
        if (downloadDocument) {
            downloadDocument()
        }
    }

    return (
        isVisibleButtonDelete ?
            <Chip
            component="span"
                onClick={onChipClick}
                style={{
                    backgroundColor: `${chipColor}`,
                    color:COLOR_WHITE,
                    "&:hover": {
                        backgroundColor: `${chipColor}`,
                        color:COLOR_WHITE,
                    },
                }}
                icon={<Box component="span" borderRadius="50%" className={classes.boxCircle} style={{
                    backgroundColor: `${chipColor}`,
                }}>
                    <img src={icon} className={classes.image} alt="iconRemove"/>

                </Box>}
                onDelete={onRemoveEvidence}
                label={label}
                deleteIcon={isVisibleButtonDelete ? <ClearIcon className={classes.backgroundColorIcon} /> : null}
            /> :
            <Chip
                component="span"
                onClick={onChipClick}
                style={{
                    backgroundColor: `${chipColor}`,
                    color:COLOR_WHITE,
                    "&:hover": {
                        backgroundColor: `${chipColor}`,
                        color:COLOR_WHITE,
                    },
                }}
                icon={<Box component="span" borderRadius="50%" className={classes.boxCircle} style={{
                    backgroundColor: `${chipColor}`,
                }}>
                    <img src={icon} className={classes.image} alt="iconNotRemove"/>

                </Box>}
                label={label}
            />
    )
}

const chipDocumentStyles = makeStyles(theme => ({
    boxCircle: {
        marginLeft: "-25px !importante",
        width: "32px !important",
        height: "32px !important",
        "&.MuiChip-icon": {
            marginLeft: "-5px"
        },
    },
    avatar: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    },
    image: {
        width: "33px",
        height: "auto",
        marginTop: "0px",
        marginLeft: "0px"
    },
    backgroundColorIcon: {
        color: `${COLOR_DANGER_NORMAL}`,
    }
}));

export default ChipDocument
