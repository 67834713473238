import { fromJS } from 'immutable';
import * as supplierActions from './SupplierActions';

let initialSuppliersState = fromJS({
    isLoadingSuppliers: false,
    isSearchingSupplier: false,
    isSavingSupplier: false,
    allSuppliers: [],
    supplier: null,
    suppliersQueryParameters: null,
})

const supplierReducer = (state = initialSuppliersState, action) => {
    switch (action.type) {
        case supplierActions.IS_LOADING_SUPPLIERS: {
            return state.merge({
                isLoadingSuppliers: action.status
            })
        }
        case supplierActions.IS_SEARCHING_SUPPLIER: {
            return state.merge({
                isSearchingSupplier: action.status
            })
        }
        case supplierActions.IS_SAVING_SUPPLIERS: {
            return state.merge({
                isSavingSupplier: action.status
            })
        }
        case supplierActions.SET_ALL_SUPPLIERS: {
            return state.merge({
                allSuppliers: fromJS(action.suppliers),
                isLoadingSuppliers: false
            })
        }
        case supplierActions.SET_SUPPLIER: {
            return state.merge({
                supplier: fromJS(action.supplier),
                isLoadingSuppliers: false
            })
        }
        case supplierActions.ADD_SUPPLIER: {
            let supplier = fromJS(action.supplier);
            return state
                .updateIn(['allSuppliers'], allSuppliers => allSuppliers.push(supplier))
                .set('isSavingSupplier', false);
        }
        case supplierActions.UPDATE_SUPPLIER: {
            let token = action.supplierToken;
            let properties = action.properties;
            return state
                .updateIn(['allSuppliers'], allSuppliers => updateSupplierStatus(allSuppliers, token, properties))
                .set('isSavingSupplier', false);
        }
        case supplierActions.UPDATE_SUPPLIER_PROPERTY: {
            return state.setIn(['supplier', action.propertyName], action.propertyData).set('isSavingSupplier', false);
        }
        case supplierActions.ADD_FILE_SUPPLIER: {
            let newFiles = fromJS(action.files);
            return state.setIn(['supplier', 'attachments'], newFiles).set('isSavingSupplier', false);
        }
        case supplierActions.UPDATE_FILE_SUPPLIER: {
            let token = action.attachmentToken;
            let properties = action.properties;
            return state
                .updateIn(['supplier', 'attachments'], allAttachments => updateAttachment(allAttachments, token, properties))
                .set('isSavingSupplier', false)
        }
        case supplierActions.SET_SUPPLIERS_QUERYPARAMETERS: {
            return state.merge({
                suppliersQueryParameters: fromJS(action.queryParametes)
            })
        }
        default: {
            return state;
        }
    }
}

const updateSupplierStatus = (allSuppliers, supplierToken, properties) => {
    let indexOfSupplier = allSuppliers.findIndex(supplier => supplier.get('token') === supplierToken);
    if (indexOfSupplier >= 0) {
        return allSuppliers.update(indexOfSupplier, supplierToUpdate => supplierToUpdate.merge(properties));
    }
    return allSuppliers;
}

const updateAttachment = (allAttachment, attachmentToken, properties) => {
    let indexOfAttachment = allAttachment.findIndex(attachment => attachment.get('token') === attachmentToken);
    if (indexOfAttachment >= 0) {
        return allAttachment.update(indexOfAttachment, attachmentToUpdate => attachmentToUpdate.merge(properties));
    }
    return allAttachment;
}

export default supplierReducer;