import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

/** Material UI import section */
import {
	List,
	ListItem,
	ListItemText,
	Chip,
	Link,
	Typography,
	makeStyles
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ReceptionIcon from '@material-ui/icons/ClosedCaption'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import UploadFileIcon from '@material-ui/icons/CloudUpload'
import AttachIcon from '@material-ui/icons/AttachFile'
import DeleteFile from '@material-ui/icons/DeleteOutlineOutlined'

/** Custom components import section */
import ChipDocument from '../../common/ChipDocument'
import AddItemButton from '../../common/AddItemButton'

/** Resources import section */
import { COLOR_EVIDENCES } from '../../../resources/constants/Colors'
import manifestFileIcon from '../../../resources/images/evidence02.png'

/**
 * Component to show panel request
 * @param {*} props
 */
function MaterialDeliveryTab(props) {
	const {
		classes,
		receptions,
		manualReceptionEvidences,
		isRoleAdmin,
		onRemoveEvidence,
		onAddButtonClick,
		onOpenEvidenceDialog
	} = props
        console.log(`🚀 ~ file: MaterialDeliveryTab.js ~ line 44 ~ MaterialDeliveryTab ~ manualReceptionEvidences`, manualReceptionEvidences);

	const styles = useStyles();

	
	/**
	 * Render items
	 * @param {*} item
	 */
	const renderItemsReceptions = (item) => {
		return (
			<ListItem key={item.token} divider className={classes.lineItem}>
				<ListItemAvatar>
					<Avatar>
						<ReceptionIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={`Folio: ${item.folio} - Total de entradas: ${item.countItems}`}
					secondary={
						<React.Fragment>
							<Typography
								component='span'
								className={classes.inline}
							>
								{`Fecha recepción: ${
									item.fecha
										? moment(item.fecha).format(
												'DD/MM/YYYY'
										  )
										: ''
								}`}
							</Typography>
							<br />
							<Typography component='span' variant='subtitle2'>
								<strong>
									{
										{
											true:
												'SIN EVIDENCIAS EN ESTA ENTRADA',
											false:
												'EVIDENCIAS DE LAS ENTRADAS DEL MATERIAL'
										}[item.evidences.length === 0]
									}
								</strong>
							</Typography>
							{item.evidences.map((evidence) => (
								<EvidenceItem
									key={item.creation_date}
									classes={classes}
									name={evidence.name}
									isManifiest={evidence.isManifiest}
									deletingEnabled={isRoleAdmin}
									relativePath={evidence.relativePath}
									creationDate={evidence.creation_date}
									onRemoveEvidence={() =>
										onRemoveEvidence(
											item.receptionFolio,
											evidence.name
										)
									}
								/>
							))}
							<hr />
							<Typography component='span' variant='subtitle2'>
								<strong>
									{
										{
											true: 'SIN INCIDENCIAS',
											false:
												'EVIDENCIA DE LAS INCIDENCIAS'
										}[item.incidentEvidences.length === 0]
									}
								</strong>
							</Typography>
							{item.incidentEvidences.map((evidence) => (
								<EvidenceItem
									key={item.creation_date}
									classes={classes}
									name={evidence.name}
									isManifiest={evidence.isManifiest}
									deletingEnabled={isRoleAdmin}
									relativePath={evidence.relativePath}
									creationDate={evidence.creation_date}
									onRemoveEvidence={() =>
										onRemoveEvidence(
											item.receptionFolio,
											evidence.name
										)
									}
								/>
							))}
							{item.evidences.map((evidence) => {
								return (
									<span key={evidence.name}>
										{evidence.isManifiest && (
											<>
												<hr />
												<strong>
													{'DOCUMENTO MANIFIESTO'}
												</strong>
												<br />
												<ChipDocument
													label={
														<Link
															target='_blank'
															className={
																classes.colotText
															}
															href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/${evidence.relativePath}`}
														>
															{`${moment(
																evidence.creation_date
															).format(
																'DD/MM/YYYY'
															)} | ${
																evidence.name
															}`}
														</Link>
													}
													isVisibleButtonDelete={
														isRoleAdmin
													}
													onRemoveEvidence={() =>
														onRemoveEvidence(
															item.receptionFolio,
															evidence.name
														)
													}
													chipColor={COLOR_EVIDENCES}
													icon={manifestFileIcon}
												/>
												<br /> <br />
											</>
										)}
									</span>
								)
							})}

							{isRoleAdmin && (
								<>
									<br />
									<Chip
										component='span'
										icon={
											<UploadFileIcon
												className={classes.colorWhithe}
											/>
										}
										size='small'
										label='Subir evidencia'
										className={classes.btnDownload}
										onClick={() =>
											onOpenEvidenceDialog(
												item.receptionFolio
											)
										}
									/>
								</>
							)}
						</React.Fragment>
					}
				/>
			</ListItem>
		)
	}

	return (
		<>
			<div className={styles.buttonContainer}>
				<AddItemButton
					title='Agregar evidencia manual'
					onClick={onAddButtonClick}
				/>
			</div>
			<List className={classes.contentTab}>
				{receptions.map((item) => {
					return (
						<div key={item.token}>
							{renderItemsReceptions(item)}
						</div>
					)
				})}
				{receptions.length === 0 && (
					<ListItem
						key={'withreceptions'}
						divider
						className={classes.lineItem}
					>
						<ListItemText primary={'Sin ordenes de recepción'} />
					</ListItem>
				)}
				{manualReceptionEvidences.map((item) => {
					return (
						<div key={item.token}>
							<ManualEvidence 
								{...item} 
								classes={classes}
							/>
						</div>
					)
				})}
				{manualReceptionEvidences.length === 0 && (
					<ListItem
						key={'withreceptions'}
						divider
						className={classes.lineItem}
					>
						<ListItemText primary={'Sin evicencias de recepción manuales'} />
					</ListItem>
				)}
			</List>
		</>
	)
}

MaterialDeliveryTab.propTypes = {
	isRoleAdmin: PropTypes.bool,
	onAddButtonClick: PropTypes.func,
	manualReceptionEvidences: PropTypes.array
}

MaterialDeliveryTab.defaultProps = {
	manualReceptionEvidences: [],
	onAddButtonClick: () => console.warn('[onAddButtonClick] not defined!')
}

const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		textAlign: 'right',
		padding: 5,
	},
}))


export default MaterialDeliveryTab

function EvidenceItem(props) {
	const {
		name,
		isManifiest,
		deletingEnabled,
		receptionFolio,
		creationDate,
		relativePath,
		onRemoveEvidence,
		classes
	} = props
	return (
		<div style={{ padding: '3px' }}>
			{!isManifiest && (
				<Typography
					component='span'
					variant='subtitle2'
					style={{ display: 'flex', alignItems: 'center' }}
				>
					{deletingEnabled && (
						<DeleteFile
							onClick={() =>
								onRemoveEvidence(receptionFolio, name)
							}
							className={classes.iconList}
							color='error'
						/>
					)}
					<Link
						target='_blank'
						href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/${relativePath}`}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<AttachIcon
							className={classes.iconList}
							color='primary'
						/>
						{`${moment(creationDate).format(
							'DD/MM/YYYY'
						)} | ${name}`}
					</Link>
				</Typography>
			)}
		</div>
	)
}

EvidenceItem.propTypes = {
	name: PropTypes.string.isRequired,
	isManifiest: PropTypes.bool.isRequired,
	deletingEnabled: PropTypes.bool.isRequired,
	receptionFolio: PropTypes.string.isRequired,
	creationDate: PropTypes.string.isRequired,
	relativePath: PropTypes.string.isRequired,
	onRemoveEvidence: PropTypes.func.isRequired
}


function ManualEvidence(props) {
	const {
		folio,
		files,
		creationDate,
		classes
	} = props
        console.log(`🚀 ~ file: MaterialDeliveryTab.js ~ line 351 ~ ManualEvidence ~ files`, files);
	return (
		<ListItem divider className={classes.lineItem}>
			<ListItemAvatar>
				<Avatar>
					<ReceptionIcon />
				</Avatar>
			</ListItemAvatar>
			<div style={{ padding: '3px' }}>
				<Typography variant='subtitle2'>Folio: {folio}</Typography>
				<Typography variant='subtitle2'>Fecha: {moment(creationDate).format('DD/MM/YYYY')}
				</Typography>
				{files.map(file => 
					<Typography
						component='span'
						variant='subtitle2'
						style={{ display: 'flex', alignItems: 'center' }}
					>
						{/* {deletingEnabled && (
							<DeleteFile
								onClick={() =>
									onRemoveEvidence(receptionFolio, name)
								}
								className={classes.iconList}
								color='error'
							/>
						)} */}
						<Link
							target='_blank'
							href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/${file.path}`}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<AttachIcon
								className={classes.iconList}
								color='primary'
							/>
							{`${file.name}`}

						</Link>
					</Typography>
				)
				}
			</div>
		</ListItem>
	)
}

ManualEvidence.propTypes = {
		name: PropTypes.string.isRequired,
		isManifiest: PropTypes.bool.isRequired,
		deletingEnabled: PropTypes.bool.isRequired,
		receptionFolio: PropTypes.string.isRequired,
		creationDate: PropTypes.string.isRequired,
		relativePath: PropTypes.string.isRequired,
		onRemoveEvidence: PropTypes.func.isRequired
	}	