import { fromJS } from 'immutable'
import * as customerActions from './CustomerActions'

let initialCustomersState = fromJS({
	isLoadingCustomers: false,
	isSavingCustomer: false,
	allCustomers: [],
	customer: null,
	query: '',
	customerQueryParameters: null,
	alertSetting: null
})

const customerReducer = (state = initialCustomersState, action) => {
	switch (action.type) {
		case customerActions.IS_LOADING_CUSTOMERS: {
			return state.merge({
				isLoadingCustomers: action.status
			})
		}
		case customerActions.IS_SAVING_CUSTOMERS: {
			return state.merge({
				isSavingCustomer: action.status
			})
		}
		case customerActions.SET_ALL_CUSTOMERS: {
			return state.merge({
				allCustomers: fromJS(action.customers),
				isLoadingCustomers: false
			})
		}
		case customerActions.SET_CUSTOMER: {
			return state.merge({
				customer: fromJS(action.customer),
				isLoadingCustomers: false
			})
		}
		case customerActions.ADD_CUSTOMER: {
			let customer = fromJS(action.customer)
			return state
				.updateIn(['allCustomers'], (allCustomers) => allCustomers.push(customer))
				.set('isSavingCustomer', false)
		}
		case customerActions.UPDATE_ACTIVE_CUSTOMER: {
			let customer = fromJS(action.customer)
			return state.updateIn(['allCustomers'], (allCustomers) =>
				updateCustomerStatus(allCustomers, customer)
			)
		}
		case customerActions.UPDATE_CUSTOMER_PROPERTY: {
			return state
				.setIn(['customer', action.propertyName], action.propertyData)
				.set('isSavingCustomer', false)
		}
		case customerActions.ADD_FILE_CUSTOMER: {
			let newFiles = fromJS(action.files)
			return state.setIn(['customer', 'attachments'], newFiles).set('isSavingCustomer', false)
		}
		case customerActions.UPDATE_FILE_CUSTOMER: {
			let token = action.attachmentToken
			let properties = action.properties
			return state
				.updateIn(['customer', 'attachments'], (allAttachments) =>
					updateAttachment(allAttachments, token, properties)
				)
				.set('isSavingCustomer', false)
		}
		case customerActions.REMOVE_DOCUMENT_CUSTOMER: {
			return state
				.updateIn(['customer', 'attachments'], (allAttachments) =>
					allAttachments.filter(
						(document) => document.get('token') !== action.documentToken
					)
				)
				.set('isSavingCustomer', false)
		}
		case customerActions.SET_CUSTOMER_QUERYPARAMETERS: {
			return state.merge({
				customerQueryParameters: fromJS(action.queryParametes)
			})
		}
		case customerActions.SET_ALERT_SETTING: {
			return state.set('alertSetting', fromJS(action.alertSetting))
		}
		default: {
			return state
		}
	}
}

const updateCustomerStatus = (allCustomers, customerToUpdate) => {
	let indexOfUser = allCustomers.findIndex(
		(customer) => customer.get('code') === customerToUpdate.get('code')
	)
	if (indexOfUser >= 0) {
		return allCustomers.setIn([indexOfUser, 'active'], customerToUpdate.get('active'))
	}
	return allCustomers
}

const updateAttachment = (allAttachment, attachmentToken, properties) => {
	let indexOfAttachment = allAttachment.findIndex(
		(attachment) => attachment.get('token') === attachmentToken
	)
	if (indexOfAttachment >= 0) {
		return allAttachment.update(indexOfAttachment, (attachmentToUpdate) =>
			attachmentToUpdate.merge(properties)
		)
	}
	return allAttachment
}

export default customerReducer
