import React, { useState, useEffect } from 'react'
import uuidv4 from 'uuid/v4'

/** Material UI import section */
import { makeStyles, Avatar, Box, Fab, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import ChangePasswordIcon from '@material-ui/icons/VpnKey'
import EditIcon from '@material-ui/icons/Edit'
import EditAvatarIcon from '@material-ui/icons/Create'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Clear'

/** Custom components import section */
import { WhiteTextBox } from '../../common/TextBox'
import UploadImgCropDialog from './UploadImageDialog'

/** Helpers import section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
import { isActiveDirectoryUser, isUserInRole } from '../../../store/helpers/UserHelper'

/** Resources import section */
import {
	COLOR_DANGER_NORMAL,
	COLOR_INFO_NORMAL,
	COLOR_FOCUS_NORMAL,
	COLOR_PRIMARY_NORMAL,
	COLOR_SUCCESS_NORMAL,
	COLOR_WHITE
} from '../../../resources/constants/Colors'

import '../../../resources/styles/avatar.css'

import { AppRoles } from '../../../resources/constants/AppConstants'

const useAvatarStyles = makeStyles((theme) => ({
	root: {
		marginTop: '10px'
	},
	cardTitle: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main
		// cursor:"pointer"
	},
	subtitle: {
		backgroundColor: '#D0DEED'
	},
	logoFileInput: {
		display: 'none'
	}
}))

const UserAvatar = (props) => {
	const classes = useAvatarStyles()
	const { name, avatarUrl } = props
	const uploadLogoFileRef = React.createRef()
	const firstLetterName = name.charAt(0).toUpperCase()

	const getAvatarUrl = () => {
		if (avatarUrl !== null) {
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${avatarUrl}`
			let logoUrl = urlImage + '?token=' + uuidv4()
			return logoUrl
		} else return null
	}

	return (
		<Box>
			<Avatar
				onClick={() => {
					uploadLogoFileRef.current.click()
				}}
				alt='Avatar'
				src={getAvatarUrl()}
				className='container'
			>
				<span styles={{ fontSize: 40 }}>
					{isNullOrEmpty(avatarUrl) ? firstLetterName.toUpperCase() : null}
				</span>
				<div className='overlay'>
					<EditAvatarIcon className='icon' />
				</div>
			</Avatar>
			<input
				className={classes.logoFileInput}
				type='file'
				accept='image/*'
				ref={uploadLogoFileRef}
				multiple={false}
				onChange={props.uploadLogoFile}
			/>
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	form: {
		height: '500px',
		width: '350px',
		padding: '10px'
	},
	headerGrid: {
		margin: 0,
		padding: 0
	},
	headerBox: {
		margin: 0,
		padding: 0
	},
	button: {
		width: 35,
		height: 34
	},
	buttonIcon: {
		height: 20,
		width: 20
	},
	root: {
		marginTop: '10px'
	},
	cardTitle: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main
		// cursor:"pointer"
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	subtitle: {
		backgroundColor: '#D0DEED'
	},
	content: {
		padding: '10px',
		height: `calc(100vh - ${150}px)`
		// overflow:'scroll'
	},
	logoFileInput: {
		display: 'none'
	},
	textField: {
		cssLabel: {},
		cssOutlinedInput: {
			'&$cssFocused $notchedOutline': {}
		},
		cssFocused: {},
		notchedOutline: {
			borderWidth: '1px',
			borderColor: `white !important`,
			color: `white !important`
		}
	}
}))

const Mode = {
	VIEW: 'VIEW',
	EDITION: 'EDITION'
}

const UserForm = (props) => {
	const classes = useStyles()
	const { loggedUser } = props

	const [mode, setMode] = useState(Mode.VIEW)

	const [logoFileBase64, setLogoFileBase64] = useState(null)

	const [userModel, setUserModel] = useState(props.userProfile)
	const isInternalUser = isActiveDirectoryUser(userModel)

	useEffect(() => {
		if (props.userProfile) {
			setUserModel(props.userProfile)
		}
	}, [props.userProfile])

	const canInformationBeEdited = () => {
		if (isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles)) {
			return true
		}
		return true
	}

	const canPasswordBeEdited = () => {
		if (!isInternalUser) {
			return true
		}
		return false
	}

	const onEditButtonClick = () => {
		setMode(Mode.EDITION)
	}

	const onCancelButtonClick = () => {
		setMode(Mode.VIEW)
		setUserModel(props.userProfile)
	}

	const onSaveButtonClick = () => {
		if (props.onSaveUserProfile) {
			props.onSaveUserProfile(userModel)
			setMode(Mode.VIEW)
		}
	}
	/**
	 * On select logo file
	 *
	 * @param {*} fileBase64
	 * @memberof Profile
	 */
	const onSelecteFile = (fileBase64) => {
		setLogoFileBase64(fileBase64)
	}

	const uploadLogoFile = (file) => {
		let fileUpload = file.target.files[0]
		/// Determine if the image to add not is undefined or null
		if (fileUpload !== undefined && fileUpload !== null) {
			var myFileItemReader = new FileReader()
			myFileItemReader.addEventListener(
				'load',
				() => {
					const myResult = myFileItemReader.result
					onSelecteFile(myResult)
					props.onSetHandleOpenUploadImg()
				},
				false
			)
			myFileItemReader.readAsDataURL(fileUpload)
		}
	}

	const onUpdateProperty = (event) => {
		let name = event.target.name
		let value = event.target.value
		setUserModel({
			...userModel,
			[name]: value
		})
	}

	const canBeSaved = () => {
		if (!userModel.userName || userModel.userName.length === 0) return false
		if (!userModel.name || userModel.name.length === 0) return false
		if (!userModel.email || userModel.email.length === 0) return false
		// if (!(isValidEmail(userModel.userName))) return false;
		return true
	}

	const getButtons = () => {
		let buttons = []
		if (canPasswordBeEdited()) {
			if (mode === Mode.VIEW) {
				buttons.push(
					<Box
						// key="btn-update-password"
						key={`${'btn-update-password'}`}
						p={1}
						datacy={'update-password'}
						flexShrink={1}
					>
						<Tooltip
							title={<Typography variant='caption'>Actualizar contraseña</Typography>}
							color='secondary'
						>
							<Fab
								size='small'
								data-cy={'update-password'}
								className={classes.button}
								style={{ backgroundColor: COLOR_INFO_NORMAL }}
								onClick={props.onHandelOpenEditPassword}
							>
								<ChangePasswordIcon className={classes.buttonIcon} />
							</Fab>
						</Tooltip>
					</Box>
				)
			}
		}
		if (canPasswordBeEdited()) {
			if (mode === Mode.VIEW) {
				buttons.push(
					<Box key='btn-update-data' data-cy={'btn-update-data'} p={1} flexShrink={0}>
						<Tooltip title='Actualizar datos' color='secondary'>
							<Fab
								size='small'
								className={classes.button}
								style={{ backgroundColor: COLOR_PRIMARY_NORMAL }}
								onClick={onEditButtonClick}
							>
								<EditIcon className={classes.buttonIcon} />
							</Fab>
						</Tooltip>
					</Box>
				)
			} else {
				buttons.push(
					<Box key='btn-save-data' p={1} flexShrink={1}>
						<Tooltip
							title={<Typography variant='caption'>Guardar</Typography>}
							color='secondary'
						>
							<Fab
								size='small'
								className={classes.button}
								style={{ backgroundColor: COLOR_SUCCESS_NORMAL }}
								onClick={onSaveButtonClick}
								disabled={!canBeSaved()}
							>
								<SaveIcon className={classes.buttonIcon} />
							</Fab>
						</Tooltip>
					</Box>
				)
				buttons.push(
					<Box key='btn-cancel' p={1} flexShrink={0}>
						<Tooltip title='Cancelar' color='secondary'>
							<Fab
								size='small'
								className={classes.button}
								style={{ backgroundColor: COLOR_DANGER_NORMAL }}
								onClick={onCancelButtonClick}
							>
								<CancelIcon className={classes.buttonIcon} />
							</Fab>
						</Tooltip>
					</Box>
				)
			}
		}
		return buttons
	}

	const getFields = () => {
		let fields = []
		let active = mode === Mode.EDITION
		let widthValue = isInternalUser ? 3 : 4
		if (isInternalUser) {
			fields.push(
				<Grid
					key={'user-name'}
					item
					xs={widthValue}
					container
					justifyContent='center'
					alignItems='flex-end'
				>
					<Grid item>
						<WhiteTextBox
							active={active}
							disabled={isInternalUser}
							label={'Número de socio'}
							name='userName'
							value={userModel.userName}
							onChange={onUpdateProperty}
						/>
					</Grid>
				</Grid>
			)
		}
		fields.push(
			<Grid key='user-email' item xs={widthValue}>
				<Grid item>
					<WhiteTextBox
						active={active}
						disabled={isInternalUser}
						label='Correo electrónico'
						name='email'
						value={userModel.email}
						onChange={onUpdateProperty}
					/>
				</Grid>
			</Grid>
		)
		fields.push(
			<Grid
				key='user-full-name'
				item
				xs={widthValue}
				container
				justifyContent='center'
				alignItems='flex-end'
			>
				<Grid item>
					<WhiteTextBox
						active={active}
						disabled={isInternalUser}
						label='Nombre'
						name='name'
						value={userModel.name}
						onChange={onUpdateProperty}
					/>
				</Grid>
			</Grid>
		)
		if (!isInternalUser) {
			fields.push(
				<Grid
					key={'user-name'}
					item
					xs={widthValue}
					container
					justifyContent='center'
					alignItems='flex-end'
				>
					<Grid item>
						<WhiteTextBox
							active={active}
							disabled={!isInternalUser}
							label={'Usuario'}
							name='userName'
							value={userModel.userName}
							onChange={onUpdateProperty}
						/>
					</Grid>
				</Grid>
			)
		}
		if (isInternalUser) {
			fields.push(
				<Grid
					key='user-phone-number'
					item
					xs={widthValue}
					container
					justifyContent='center'
					alignItems='flex-end'
				>
					<Grid item>
						<WhiteTextBox
							active={active}
							label='Teléfono'
							name='phoneNumber'
							value={userModel.phoneNumber}
							onChange={onUpdateProperty}
						/>
					</Grid>
				</Grid>
			)
		}
		return fields
	}

	return (
		<Paper
			style={{ backgroundColor: COLOR_FOCUS_NORMAL, color: COLOR_WHITE, marginBottom: 15 }}
			className={classes.paper}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} className={classes.hederGrid}>
					<Box
						className={classes.headerBox}
						display='flex'
						p={1}
						alignItems='flex-start'
						justifyContent='center'
					>
						{canInformationBeEdited && <Box p={1} width='30px' />}
						{canInformationBeEdited && <Box p={1} width='30px' />}
						<Box p={1} display='flex' justifyContent='center' width='100%'>
							<UserAvatar {...userModel} uploadLogoFile={uploadLogoFile} />
						</Box>
						{getButtons()}
					</Box>
				</Grid>
				{getFields()}
				{
					<UploadImgCropDialog
						open={props.handleOpenUploadImg}
						fileBase64={logoFileBase64}
						onClose={() => props.onSetHandleCloseUploadImg()}
						onUploadLogoUser={props.onUploadLogoUser}
						isSaving={props.isSaving}
					/>
				}
			</Grid>
		</Paper>
	)
}

UserForm.defaultProps = {
	userProfile: {
		userName: '',
		name: '',
		phoneNumber: '',
		type: '',
		avatarUrl: ''
	}
}

export default UserForm
