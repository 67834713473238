/** Import ReactJS */
import React from 'react';
import PropTypes from 'prop-types';

/** Material-UI imports */
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Tooltip, ButtonGroup, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone';
import { COLOR_FOCUS_NORMAL, COLOR_WHITE, COLOR_ALTERNATE_NORMAL, COLOR_INFO_NORMAL, COLOR_EVIDENCES } from '../../../resources/constants/Colors';
import ShowListIcon from '@material-ui/icons/ViewList'
import ShowRequestIcon from '@material-ui/icons/FormatListNumbered'
import ConfigureIcon from '@material-ui/icons/SettingsOutlined'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

/**
 * Component header
 * @param {*} props 
 */
const RequestIndexHeader = props => {
    const classes = useStyle();
    const { setConfigurationAnchorEl, configuratorAnchorEl, btnDownloadStatusReport } = props;

    return (
        <div className={classes.root}>
            <ButtonGroup variant='text' size='small'>
                {(props.isValitToAddRequest || props.isValidToAddInternal) && props.gridMode && <Tooltip title={props.title}>
                    <Button
                        className={classes.buttonIcon}
                        onClick={props.onClick}
                    >
                        <AddIcon color='primary' style={{ color: COLOR_WHITE }} />
                    </Button>
                </Tooltip>}
                {props.gridMode && <Tooltip title={"Descargar reporte"}>
                    <Button
                        className={classes.buttonSbaiIcon}
                        onClick={props.onDownloadReport}
                    >
                        <CloudDownloadIcon color='secondary' style={{ color: COLOR_WHITE }} />
                    </Button>
                </Tooltip>}
                {btnDownloadStatusReport.displayed &&
                    <Tooltip title="Descargar reporte por estatus">
                        <Button className={classes.buttonDownload} onClick={btnDownloadStatusReport.onClick}>
                            <CloudDownloadIcon />
                        </Button>
                    </Tooltip>
                }
                <Tooltip component={"span"} title='Mostrar solicitudes'  >
                    <Button
                        disabled={props.gridMode}
                        onClick={props.switchViewMode}
                        className={!props.gridMode ? classes.buttonSbaiIcon : classes.btnDisabled}
                    >
                        <ShowRequestIcon />
                    </Button>
                </Tooltip>
                <Tooltip component={"span"} title='Mostrar SBAI' >
                    <Button
                        disabled={!props.gridMode}
                        onClick={props.switchViewMode}
                        className={props.gridMode ? classes.buttonListIcon : classes.btnDisabled}
                    >
                        <ShowListIcon />
                    </Button>
                </Tooltip>

                {props.gridMode && <Tooltip component={"span"} title='Configuración de la tabla' >
                    <Button
                        className={classes.btnConfingIcon}
                        onClick={(event) => setConfigurationAnchorEl(configuratorAnchorEl ? null : event.currentTarget)
                        }
                    >
                        <ConfigureIcon />
                    </Button>
                </Tooltip>}

            </ButtonGroup>

        </div>

    );
}

RequestIndexHeader.propTypes = {
    btnDownloadStatusReport: PropTypes.shape({
        displayed: PropTypes.bool,
        onClick: PropTypes.func
    })
}

RequestIndexHeader.defaultProps = {
    btnDownloadStatusReport: {
        displayed: false,
        onClick: () => console.warn('[onClick] not definfed!')
    }
}

/** Import components */
const useStyle = makeStyles(theme => ({
    root: {
        zIndex: '300'
    },
    buttonDownload: {
        backgroundColor: `${COLOR_EVIDENCES} !important`,
        color: theme.palette.common.white,
    },
    buttonIcon: {
        backgroundColor: `${COLOR_FOCUS_NORMAL} !important`,
    },
    buttonListIcon: {
        backgroundColor: `${COLOR_ALTERNATE_NORMAL} !important`,
        color: COLOR_WHITE,
    },
    buttonSbaiIcon: {
        backgroundColor: `${COLOR_INFO_NORMAL} !important`,
        color: COLOR_WHITE,
    },
    btnDisabled: {
        backgroundColor: "#b9bcbd",
        color: COLOR_WHITE,
    },
    btnConfingIcon: {
        backgroundColor: "rgba(84, 92, 216, 1) !important",
        color: COLOR_WHITE,
    }
}))


export default RequestIndexHeader