import { createSelector } from 'reselect'
import { toSafeArray } from '../../store/helpers/ImmutableHelper'

const getEquipments = (state) => state.dispositionRequests.get('equipmentsList')
const getErrors = (state) => state.dispositionRequests.get('errorsList')

export const getEquipmentsSelector = createSelector([getEquipments], (equipments) =>
	!!equipments ? toSafeArray(equipments) : []
)

export const getErrorsSelector = createSelector([getErrors], (errors) =>
	!!errors ? toSafeArray(errors) : []
)
