import axios from 'axios'

/**Import helper section */
import { getAuthorizedRequestConfigurationUser } from '../helpers/RequestHelper'

export const IS_LOADING_CUSTOMERS = 'IS_LOADING_CUSTOMERS'
export const SET_ALL_CUSTOMERS = 'SET_ALL_CUSTOMERS'
export const IS_SAVING_CUSTOMERS = 'IS_SAVING_CUSTOMERS'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const UPDATE_ACTIVE_CUSTOMER = 'UPDATE_ACTIVE_CUSTOMER'
export const UPDATE_CUSTOMER_PROPERTY = 'UPDATE_CUSTOMER_PROPERTY'
export const ADD_FILE_CUSTOMER = 'ADD_FILE_CUSTOMER'
export const UPDATE_FILE_CUSTOMER = 'UPDATE_FILE_CUSTOMER'
export const REMOVE_DOCUMENT_CUSTOMER = 'REMOVE_DOCUMENT_CUSTOMER'
export const SET_CUSTOMER_QUERYPARAMETERS = 'SET_CUSTOMER_QUERYPARAMETERS'
export const SET_ALERT_SETTING = 'SET_ALERT_SETTING'

export const setAllCustomers = (customers) => {
	return {
		type: SET_ALL_CUSTOMERS,
		customers
	}
}

const setIsLoadingCustomers = (status) => {
	return {
		type: IS_LOADING_CUSTOMERS,
		status
	}
}
/**
 * Set internal user sort criteria
 */
export const setCustomerQueryParameters = (queryParametes) => {
	return {
		type: SET_CUSTOMER_QUERYPARAMETERS,
		queryParametes
	}
}

export const loadAllCustomers = (queryParameters) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingCustomers(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers`
		return axios
			.get(endPoint, config)
			.then((customersResponse) => {
				let mappedCustomers = customersResponse.data.map((c) =>
					mapFromServerResponseToLocalCustomer(c)
				)
				dispatch(setAllCustomers(mappedCustomers))
				if (queryParameters) {
					dispatch(setCustomerQueryParameters(queryParameters))
				}
				return Promise.resolve(mappedCustomers)
			})
			.catch((error) => {
				dispatch(setIsLoadingCustomers(false))
				console.error('Error loading all customers', error)
				return Promise.reject()
			})
	}
}

export const setCustomer = (customer) => {
	return {
		type: SET_CUSTOMER,
		customer
	}
}

export const loadCustomer = (customerToken) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingCustomers(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers/${customerToken}`
		return axios
			.get(endPoint, config)
			.then((customersResponse) => {
				let mappedCustomer = mapFromServerResponseToLocalCustomer(customersResponse.data)
				dispatch(setCustomer(mappedCustomer))
			})
			.catch((error) => {
				dispatch(setIsLoadingCustomers(false))
				console.error('Error loading customer', error)
				return Promise.reject()
			})
	}
}

const setIsSavingCustomer = (status) => {
	return {
		type: IS_SAVING_CUSTOMERS,
		status
	}
}

const addCustomer = (customer) => {
	return {
		type: ADD_CUSTOMER,
		customer
	}
}

export const createCustomer = (customer) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingCustomer(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		let command = {
			Code: customer.code,
			Name: customer.name,
			TaxId: customer.taxId
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers`
		return axios
			.post(endPoint, command, config)
			.then((customersResponse) => {
				let mappedCustomer = mapFromServerResponseToLocalCustomer(customersResponse.data)
				dispatch(addCustomer(mappedCustomer))
			})
			.catch((error) => {
				dispatch(setIsSavingCustomer(false))
				console.error('Error saving customer', error)
				return Promise.reject()
			})
	}
}

const updateCustomerProperty = (propertyName, propertyData) => {
	return {
		type: UPDATE_CUSTOMER_PROPERTY,
		propertyName,
		propertyData
	}
}

export const activeCustomer = (active, customer) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingCustomer(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			customerToken: customer.token,
			IsActiveCustomer: active
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers/status`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				let updateCustomer = mapFromServerResponseToLocalCustomer(response.data)
				dispatch(updateCustomerProperty('active', updateCustomer['active']))
			})
			.catch((error) => {
				dispatch(setIsSavingCustomer(false))
				console.error('Error active or disable customer', error)
				return Promise.reject()
			})
	}
}

const mapFromServerResponseToLocalCustomer = (customer) => {
	let mappedCustomer = { ...customer }
	mappedCustomer.active = customer.isActiveCustomer
	return mappedCustomer
}

const addFiles = (files) => {
	return {
		type: ADD_FILE_CUSTOMER,
		files
	}
}

export const uploadAttachments = (files, documentData, customerToken) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingCustomer(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			ExpirationDate: documentData.expirationDate,
			NotificationDays: documentData.notificationDays,
			CustomerToken: customerToken,
			AttachmentType: 'Customer'
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers/attachments`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch(addFiles(response.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingCustomer(false))
				console.error('Error added file for customer', error)
				return Promise.reject()
			})
	}
}

const updateAttachmentProperties = (attachmentToken, properties) => {
	return {
		type: UPDATE_FILE_CUSTOMER,
		attachmentToken,
		properties
	}
}

export const updateFile = (documentModel, customerToken) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingCustomer(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			ExpirationDate: documentModel.expirationDate,
			CustomerToken: customerToken,
			AttachmentType: 'Customer',
			FileName: documentModel.fileName,
			Description: documentModel.description,
			AttachmentToken: documentModel.token,
			NotificationDays: documentModel.notificationDays
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers/attachment`

		return axios
			.put(endPoint, command, config)
			.then((response) => {
				let properties = response.data

				dispatch(updateAttachmentProperties(documentModel.token, properties))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingCustomer(false))
				console.error('Error added file for customer', error)
				return Promise.reject()
			})
	}
}

export const removeDocumentCustomer = (documentToken) => {
	return {
		type: REMOVE_DOCUMENT_CUSTOMER,
		documentToken
	}
}

export const removeCustomerDocumentInBackend = (documentModel) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingCustomer(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/delete/${documentModel.token}`

		return axios
			.delete(endPoint, config)
			.then((response) => {
				dispatch(removeDocumentCustomer(documentModel.token))
			})
			.catch((error) => {
				dispatch(setIsSavingCustomer(false))
				console.error('Error removing file for customer', error)
				return Promise.reject()
			})
	}
}

export const saveAlertSetting = (alertSetting) => {
	return function(dispatch, getState) {
		const loggedUser = getState().oidc.user
		const config = getAuthorizedRequestConfigurationUser(loggedUser)
		const endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers/alertsettings`
		console.log('🚀 ~ file: CustomerActions.js ~ line 299 ~ saveAlertSetting ~ alertSetting', {
			alertSetting,
			loggedUser,
			config,
			endPoint
		})

		return axios
			.put(endPoint, alertSetting, config)
			.then((response) => {})
			.catch((error) => {
				console.error('file: CustomerActions.js ~ error', error)
				return Promise.reject()
			})
	}
}

export const setAlertSetting = (alertSetting) => {
	return {
		type: SET_ALERT_SETTING,
		alertSetting
	}
}

//
export const loadAlert = (customerCode) => {
	return function(dispatch, getState) {
		let command = {
			CustomerCode: customerCode
		}
		const loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}customers/alertsettings/detail`

		return axios
			.post(endPoint, command, config)
			.then((alertResponse) => {
				// let mappedAlert = mapFromServerResponseToLocalCustomer(alertResponse.data)
				dispatch(setAlertSetting(alertResponse.data))
			})
			.catch((error) => {
				// dispatch(setIsLoadingCustomers(false))
				// console.error('Error loading alert', error)
				return Promise.reject()
			})
	}
}
