import axios from 'axios'
import moment from 'moment'

/**Import helper section */
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserParameters
} from '../helpers/RequestHelper'
import { incidentsReportMock } from '../../data/IncidentsReportMock'
export const IS_LOADING_REQUESTS = 'IS_LOADING_REQUESTS'
export const SET_ALL_REQUESTS = 'SET_ALL_REQUESTS'

export const IS_LOADING_EQUIPMENTS = 'IS_LOADING_EQUIPMENTS'
export const SET_EQUIPMENTS_LIST = 'SET_EQUIPMENTS_LIST'
export const SET_ERRORS_LIST = 'SET_ERRORS_LIST'
export const ADD_REQUEST = 'ADD_REQUEST'

export const IS_LOADING_REQUEST = 'IS_LOADING_REQUEST'
export const IS_SAVING_REQUEST = 'IS_SAVING_REQUEST'
export const SET_REQUEST = 'SET_REQUEST'
export const CHANGE_PAGE_NUMBER = 'CHANGE_PAGE_NUMBER'
export const UPDATE_REQUEST_DATE = 'UPDATE_REQUEST_DATE'
export const SET_EVENTS_REQUEST_DATES = 'SET_EVENTS_REQUEST_DATES'
export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST'
export const ADD_EVIDENCE_IN_RECEPTION = 'ADD_EVIDENCE_IN_RECEPTION'
export const IS_VERIFY_EXIST_DOCUMENT_SAP = 'IS_VERIFY_EXIST_DOCUMENT_SAP'
export const ADD_DOCUMENT_TRAZABILITY_SAP = 'ADD_DOCUMENT_TRAZABILITY_SAP'
export const IS_DONWLOAD_REPORT_SBAI = 'IS_DONWLOAD_REPORT_SBAI'
export const IS_LOADING_ALL_SBAIS = 'IS_LOADING_ALL_SBAIS'
export const SET_ALL_SBAIS = 'SET_ALL_SBAIS'
export const ADD_COORDINATOR_EVIDENCE = 'ADD_COORDINATOR_EVIDENCE'
export const SET_TOTAL_ALL_REQUEST = 'SET_TOTAL_ALL_REQUEST'
export const ADD_RECILLECTION_IN_REQUEST = 'ADD_RECILLECTION_IN_REQUEST'
export const UPDATE_RECOLLECTION_DATE = 'UPDATE_RECOLLECTION_DATE'
export const UPDATE_ADD_CONTAINS = 'ContainsManifestFile'
export const IS_DONWLOAD_REPORT_DATE = 'IS_DONWLOAD_REPORT_DATE'

const setTotalAllRequests = (pagination, hastRequest) => {
	return {
		type: SET_TOTAL_ALL_REQUEST,
		pagination,
		hastRequest
	}
}

export const setAllRequests = (requests, pagination, query, hastRequest) => {
	return {
		type: SET_ALL_REQUESTS,
		requests,
		pagination,
		query,
		hastRequest
	}
}

const setIsLoadingRequests = (isLoadingRequests) => {
	return {
		type: IS_LOADING_REQUESTS,
		isLoadingRequests
	}
}

const updateRequestDate = (request) => {
	return {
		type: UPDATE_REQUEST_DATE,
		request
	}
}

const setIsLoadingEquipments = (isLoadingEquipments) => {
	return {
		type: IS_LOADING_EQUIPMENTS,
		isLoadingEquipments
	}
}

const setEquipments = (equipmentsList) => {
	return {
		type: SET_EQUIPMENTS_LIST,
		equipmentsList
	}
}

export const setErrors = (errorsList) => {
	return {
		type: SET_ERRORS_LIST,
		errorsList
	}
}

const addRequest = (dispositionRequest) => {
	return {
		type: ADD_REQUEST,
		dispositionRequest
	}
}

const setIsLoadingRequest = (isLoadingRequest) => {
	return {
		type: IS_LOADING_REQUEST,
		isLoadingRequest
	}
}

const setIsSavingRequest = (isSavingRequest) => {
	return {
		type: IS_SAVING_REQUEST,
		isSavingRequest
	}
}

const setRequest = (dispositionRequest) => {
	return {
		type: SET_REQUEST,
		dispositionRequest
	}
}

export const changePageNumber = (pageNumber) => {
	return {
		type: CHANGE_PAGE_NUMBER,
		pageNumber
	}
}

export const setEventsRequestsDates = (events) => {
	return {
		type: SET_EVENTS_REQUEST_DATES,
		events
	}
}

export const updateStatusRequest = (status, token) => {
	return {
		type: UPDATE_STATUS_REQUEST,
		status,
		token
	}
}

export const updateEvidenceInReceptionItem = (receptionFolio, evidences) => {
	return {
		type: ADD_EVIDENCE_IN_RECEPTION,
		receptionFolio,
		evidences
	}
}

export const updateCoordinatorEvidence = (token, evidences) => {
	return {
		type: ADD_COORDINATOR_EVIDENCE,
		token,
		evidences
	}
}

export const addRecollectionList = (recollection) => {
	return {
		type: ADD_RECILLECTION_IN_REQUEST,
		recollection
	}
}

export const updateRecollectionDate = (recollection) => {
	return {
		type: UPDATE_RECOLLECTION_DATE,
		recollection
	}
}

export const updateContainerManifest = (token, containtManifest) => {
	return {
		type: UPDATE_ADD_CONTAINS,
		token,
		containtManifest
	}
}

/**
 * Action to all request from filters
 * @param {*} pageNumber
 * @param {*} query
 * @param {*} customerToken
 */
export const loadAllRequestsFromBackEnd = (
	query,
	customerToken,
	supplierToken,
	filters,
	hastRequest
) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingRequests(true))
		let user = getState().oidc.user
		let pagination = getState()
			.dispositionRequests.get('pagination')
			.toJS()
		let config = getAuthorizedRequestConfigurationUser(user)
		let userName = user.profile.preferred_username
		let sorting = null
		if (filters.orderBy) {
			sorting = {
				orderBy: filters.orderBy,
				orderType: filters.orderType
			}
		}

		let command = {
			CustomerToken: customerToken,
			SupplierToken: supplierToken,
			PageNumber: pagination.pageNumber,
			PageSize: pagination.pageSize,
			Query: query,
			OrderBy: sorting ? sorting.orderBy : 'Folio',
			OrderType: sorting ? sorting.orderType : 'desc',
			UserName: userName
		}

		if (!!filters) {
			if (!!filters.pageNumber) command.PageNumber = filters.pageNumber
			if (!!filters.status) command.Status = filters.status
		}

		if (!!filters) {
			if (!!filters.pageNumber) command.PageNumber = filters.pageNumber
			if (!!filters.customerCodes) command.CustomerCodes = filters.customerCodes
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/filters`
		return axios
			.post(endPoint, command, config)
			.then((response) => {
				const hasRequests = response.data.requests.length > 0
				let pagination = {
					total: response.data.total,
					pageNumber: response.data.pageNumber,
					pageSize: response.data.pageSize
				}
				dispatch(
					setAllRequests(response.data.requests, pagination, response.query, hasRequests)
				)
				return Promise.resolve(response.data.requests)
			})
			.catch((error) => {
				dispatch(setIsLoadingRequest(false))
				console.error('Error loading all disposition requests', error)
				return Promise.reject()
			})
	}
}

export const countAllRequestsFromBackEnd = (
	query,
	customerToken,
	supplierToken,
	filters,
	hastRequest
) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingRequests(true))
		let user = getState().oidc.user
		let pagination = getState()
			.dispositionRequests.get('pagination')
			.toJS()
		let config = getAuthorizedRequestConfigurationUser(user)
		let userName = user.profile.preferred_username
		let command = {
			CustomerToken: customerToken,
			SupplierToken: supplierToken,
			PageNumber: pagination.pageNumber,
			PageSize: pagination.pageSize,
			Query: query,
			UserName: userName
		}

		if (!!filters) {
			if (!!filters.pageNumber) command.PageNumber = filters.pageNumber
			if (!!filters.status) command.Status = filters.status
		}

		if (!!filters) {
			if (!!filters.pageNumber) command.PageNumber = filters.pageNumber
			if (!!filters.customerCodes) command.CustomerCodes = filters.customerCodes
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/total`
		return axios
			.post(endPoint, command, config)
			.then((response) => {
				let pagination = {
					total: response.data
				}
				if (response.data > 0) {
					hastRequest = true
				}
				dispatch(setTotalAllRequests(pagination, hastRequest))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsLoadingRequest(false))
				console.error('Error loading all disposition requests', error)
				return Promise.reject()
			})
	}
}

export const uploadEquipmentsList = (files, customerToken) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingEquipments(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/import`

		let command = {
			CustomerToken: customerToken
		}

		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				const { details, errors } = response.data
				dispatch(setIsLoadingEquipments(false))
				dispatch(setEquipments(details))
				dispatch(setErrors(errors))
				return Promise.resolve({
					actNumber: response.data.act_number,
					hasEquipments: details.length > 0,
					hasErrors: errors.length > 0
				})
			})
			.catch((error) => {
				dispatch(setIsLoadingEquipments(false))
				console.error('Error loading all disposition requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const removeEquipmentsList = () => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingEquipments(true))
		dispatch(setEquipments([]))
		dispatch(setIsLoadingEquipments(false))

		return Promise.resolve()
	}
}

/**
 * Action to add disposition request
 * @param {*} requestData
 * @param {*} branchoffice
 * @param {*} filesAdded
 * @param {*} userData
 */
export const addDispositionRequest = (
	requestData,
	branchoffice,
	filesAdded,
	userData,
	selectedCustomer,
	branchManager,
	scrapFile
) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		let user = getState().oidc.user
		let creatorEmail = user.profile.email
		let config = getAuthorizedRequestConfigurationUser(user)
		let allUsers = []
		let usersInRole = getState()
			.users.get('usersInRole')
			.toJS()
		if (usersInRole.length !== 0) {
			allUsers = usersInRole.map((x) => x.email)
		}

		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest`
		let details = getState().dispositionRequests.toJS().equipmentsList
		let command = {
			ServiceType: requestData.serviceType,
			ActNumber: requestData.actNumber,
			UserId: userData.id,
			CreatedBy: userData.name,
			CustomerToken: userData.customer
				? userData.customer.token
				: selectedCustomer
				? selectedCustomer.value
				: '',
			BranchCode: branchoffice ? branchoffice.value : '',
			Branch: branchoffice ? branchoffice.label : '',
			OtherBranch: requestData.otherBranch ? requestData.otherBranch : '',
			BranchManagerEmail: branchManager ? branchManager.value : '',
			BranchManagerName: branchManager ? branchManager.label : '',
			Address: {
				Street: requestData.street,
				NumberStreet: requestData.numberStreet,
				Suburb: requestData.suburb,
				Location: requestData.location,
				Town: requestData.town,
				ZipCode: requestData.zipCode
			},
			Details: details,
			EmailsToSend: allUsers,
			CreatorEmail: creatorEmail,
			ScrapContained: !!requestData.scrapContained
		}

		let data = new FormData()
		// data.append('files', filesAdded)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < filesAdded.length; index++) {
			data.append('FileNames', filesAdded[index].attachment.name)
			data.append('file', filesAdded[index].attachment)
		}
		data.append('files', scrapFile)

		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch(addRequest(response.data))
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error adding disposition request', error)
				return Promise.reject()
			})
	}
}

export const loadRequestFromBackEnd = (requestToken) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingRequest(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/${requestToken}`
		return axios
			.get(endPoint, config)
			.then((response) => {
				const dispositionRequest = mapDispositionRequest(response.data)
				dispatch(setRequest(dispositionRequest))
			})
			.catch((error) => {
				dispatch(setIsLoadingRequest(false))
				console.error(`Error loading disposition requests by token ${requestToken}`, error)
				return Promise.reject()
			})
	}
}

/**
 * Action to change status
 * @param {*} requestData
 * @param {*} requestToken
 */
export const authorizeCrmDispositionRequest = (requestData, requestToken) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		const loggedUser = getState().oidc.user
		const userName = loggedUser.profile.name
		const config = getAuthorizedRequestConfigurationUser(loggedUser)

		const command = {
			DispositionRequestToken: requestToken,
			SBAI: requestData.sbai,
			ConfirmBy: userName
		}

		const endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/authorization`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				dispatch(setIsSavingRequest(false))
				dispatch(updateStatusRequest(1, requestToken))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error authorizing disposition request', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to change status
 * @param {*} requestData
 * @param {*} requestToken
 */
export const requestUpdateStatusBackEnd = (requestData, requestToken) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		let loggedUser = getState().oidc.user
		let userName = loggedUser.profile.name
		let config = getAuthorizedRequestConfigurationUser(loggedUser)

		let command = {
			RequestToken: requestToken,
			ConfirmBy: userName,
			ConfirmationDate: requestData.confirmationDate
				? requestData.confirmationDate.format('DD/MM/YYYY')
				: null,
			CollectionDate: requestData.collectionDate
				? requestData.collectionDate.format('DD/MM/YYYY')
				: null,
			Comments: requestData.comments ? requestData.comments : null
		}

		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/updatestatus`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				dispatch(setIsSavingRequest(false))
				dispatch(updateRequestDate(response.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error update status', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add recollection request backend
 * @param {*} requestData
 * @param {*} requestToken
 */
export const addRecollectionRequestBackEnd = (requestData, requestToken) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)

		let command = {
			DispositionRequestToken: requestToken,
			CarrierToken: requestData.carrier ? requestData.carrier.value : null,
			CarierName: requestData.carrier ? requestData.carrier.label : null,
			RecollectionDate: requestData.collectionDate
				? requestData.collectionDate.format('DD/MM/YYYY')
				: null,
			MarkForFinished: requestData.markForFinished ? requestData.markForFinished : false
		}

		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/recollections`
		return axios
			.post(endPoint, command, config)
			.then((response) => {
				dispatch(setIsSavingRequest(false))
				const recollectionRequests = mapRecollectionRequest(response.data)
				dispatch(addRecollectionList(recollectionRequests))
				dispatch(updateStatusRequest(response.data.statusRequest, requestToken))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error update status', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add recollection request backend
 * @param {*} requestData
 * @param {*} requestToken
 */
export const updateRecollectionRequestBackEnd = (requestData) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)

		let command = {
			RecollectionToken: requestData.token,
			RecollectionDate: requestData.recollectionDate
				? requestData.recollectionDate.format('DD/MM/YYYY')
				: null
		}

		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/recollections`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				dispatch(setIsSavingRequest(false))
				const recollectionRequest = mapRecollectionRequest(response.data)
				dispatch(updateRecollectionDate(recollectionRequest))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error update status', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to cancel request
 * @param {*} requestData
 * @param {*} requestToken
 */
export const cancelRequestBackEnd = (requestData, requestToken) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let allUsers = []
		let usersInRole = getState()
			.users.get('usersInRole')
			.toJS()
		if (usersInRole.length !== 0) {
			allUsers = usersInRole.map((x) => x.email)
		}
		let command = {
			RequestToken: requestToken,
			Comments: requestData.comments ? requestData.comments : null,
			EmailsToSend: allUsers
		}

		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/cancel`
		return axios
			.put(endPoint, command, config)
			.then((response) => {
				dispatch(setIsSavingRequest(false))
				dispatch(updateRequestDate(response.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error in cancel request', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 *
 * @param {*} customerToken
 * @param {*} supplierToken
 */
export const loadRequestDate = (customerToken, supplierToken) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingRequests(true))
		let user = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(user)
		let command = {
			CustomerToken: customerToken,
			SupplierToken: supplierToken
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/dates`
		return axios
			.post(endPoint, command, config)
			.then((response) => {
				dispatch(setIsLoadingRequests(false))
				let mapped = response.data.map((r) => mapRequestFinalDisposition(r))
				dispatch(setEventsRequestsDates(mapped))
				return Promise.resolve(mapped)
			})
			.catch((error) => {
				dispatch(setIsLoadingRequest(false))
				console.error('Error loading all disposition requests dates', error)
				return Promise.reject()
			})
	}
}

export const downloadRequestExcelReport = (requestData, token) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))
		let command = {
			Report: requestData ? requestData : {}
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/downloadReport`
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsSavingRequest(false))

					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'RequestsReport.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error loading all disposition requests dates', error)
				return Promise.reject()
			})
	}
}

export const downloadExcelStatusReport = (status) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReportDate(true))
		// const user = getState().oidc.user;
		// const config = getAuthorizedRequestConfigurationUser(user)
		const command = {
			Status: status
		}
		const endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/statusreport`
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					const url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'RequestsReportByStatuts.xlsx'
					a.click()
					dispatch(setIsDownloadReportDate(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReportDate(false))
				console.error('Error downloading excel status report', error)
				return Promise.reject()
			})
	}
}

export const getInformationDispositionRequestsFromSAP = (folio) => {
	return Promise.resolve(incidentsReportMock)
}

export const mapRequestFinalDisposition = (request) => {
	let mappedRequests = {}
	mappedRequests.token = request.token
	mappedRequests.folio = request.folio
	mappedRequests.serviceType = request.service_type
	mappedRequests.createdBy = request.createdBy
	mappedRequests.userConfirmCollection = request.userConfirmCollection
	mappedRequests.actNumber = request.actNumber
	mappedRequests.programmedDate = request.programmeDate
	return mappedRequests
}

export const addEvidenceToDispositionRequest = (dispositionRequestToken, folio, attachment) => {
	return function(dispatch, getState) {
		const loggedUser = getState().oidc.user
		const config = getAuthorizedRequestConfigurationUser(loggedUser)
		const command = {
			DispositionRequestToken: dispositionRequestToken,
			Folio: folio
		}
		let data = new FormData()
		data.append('command', JSON.stringify(command))
		data.append('file', attachment)
		const endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionRequest/evidence`
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				console.log(
					'🚀 ~ file: DispositionRequestsActions.js ~ line 667 ~ .then ~ response',
					response
				)
			})
			.catch((error) => {
				console.error('🚀 ~ file: DispositionRequestsActions.js ~ error', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to add evidence in reception folio
 * @param {*} files
 * @param {*} receptionFolio
 */
export const addEvidenceInReceptionFolio = (
	evidenceFiles,
	incidentFiles,
	receptionFolio,
	requestToken
) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))

		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			ReceptionFolio: receptionFolio,
			CreatorName: loggedUser.profile.name,
			RequestToken: requestToken,
			ReceptionEvidenceFilesNumber: evidenceFiles.length,
			IncidentEvidenceFilesNumber: incidentFiles.length
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}receptionevidence`
		let data = new FormData()
		// data.append('files', evidenceFiles)
		// data.append('files', incidentFiles)
		data.append('command', JSON.stringify(command))
		for (let index = 0; index < evidenceFiles.length; index++) {
			data.append('FileNames', evidenceFiles[index].attachment.name)
			data.append('file', evidenceFiles[index].attachment)
		}
		for (let index = 0; index < incidentFiles.length; index++) {
			data.append('FileNames', incidentFiles[index].name)
			data.append('file', incidentFiles[index])
		}
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				let containsManifestFile = response.data[0].containsManifestFile
				dispatch(updateEvidenceInReceptionItem(receptionFolio, response.data))
				if (containsManifestFile)
					dispatch(updateContainerManifest(requestToken, containsManifestFile))
				dispatch(setIsSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error uploading evidence in reception item', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add evidence in disposition request
 * @param {*} files
 * @param {*} receptionFolio
 */
export const addReceptionEvidenceDispositionRequest = (
	folio,
	evidenceFiles,
	incidentFiles,
	requestToken
) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))

		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			Folio: folio,
			CreatorName: loggedUser.profile.name,
			DispositionRequestToken: requestToken,
			ReceptionEvidenceFilesNumber: evidenceFiles.length,
			IncidentEvidenceFilesNumber: incidentFiles.length
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/evidence`
		let data = new FormData()
		// data.append('files', evidenceFiles)
		// data.append('files', incidentFiles)
		data.append('command', JSON.stringify(command))
		for (let index = 0; index < evidenceFiles.length; index++) {
			data.append('FileNames', evidenceFiles[index].attachment.name)
			data.append('file', evidenceFiles[index].attachment)
		}
		for (let index = 0; index < incidentFiles.length; index++) {
			data.append('FileNames', incidentFiles[index].name)
			data.append('file', incidentFiles[index])
		}
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				console.log(
					`🚀 ~ file: DispositionRequestsActions.js ~ line 732 ~ response`,
					response
				)
				// let containsManifestFile = response.data[0].containsManifestFile;
				// dispatch(updateEvidenceInReceptionItem(receptionFolio, response.data))
				// if(containsManifestFile)
				//     dispatch(updateContainerManifest(requestToken, containsManifestFile))
				// dispatch(setIsSavingRequest(false));
				return Promise.resolve()
			})
			.catch((error) => {
				// dispatch(setIsSavingRequest(false));
				console.error('Error uploading evidence in reception item', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const addCoordinatorEvidence = (files, token) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))

		let loggedUser = getState().oidc.user
		let config = getAuthorizedRequestConfigurationUser(loggedUser)
		let command = {
			Token: token,
			CreatorName: loggedUser.profile.name
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/coordinatorEvidence`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch(updateCoordinatorEvidence(token, response.data.coordinatorEvidences))
				dispatch(setIsSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error uploading evidence for coordinator', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove evidence in reception material
 * @param {*} receptionFolio
 * @param {*} evidenceName
 */
export const removeEvidenceInReceptionItem = (receptionFolio, evidenceName) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))

		let loggedUser = getState().oidc.user
		let params = {
			EvidenceName: evidenceName
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(loggedUser, params)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}receptionevidence/${receptionFolio}`
		return axios
			.delete(endPoint, configuration)
			.then((response) => {
				dispatch(updateEvidenceInReceptionItem(receptionFolio, response.data))
				dispatch(setIsSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error remove evidence in reception item', error)
				return Promise.reject()
			})
	}
}

export const removeCoordinatorEvidence = (token, evidenceName) => {
	return function(dispatch, getState) {
		dispatch(setIsSavingRequest(true))

		let loggedUser = getState().oidc.user
		let params = {
			EvidenceName: evidenceName
		}
		let configuration = getAuthorizedRequestConfigurationUserParameters(loggedUser, params)
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/coordinatorEvidence/${token}`
		return axios
			.delete(endPoint, configuration)
			.then((response) => {
				dispatch(updateCoordinatorEvidence(token, response.data.coordinatorEvidences))
				dispatch(setIsSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsSavingRequest(false))
				console.error('Error remove coordinator evidence', error)
				return Promise.reject()
			})
	}
}

export const isVerifyDocument = (isVerifingDocumentSap) => {
	return {
		type: IS_VERIFY_EXIST_DOCUMENT_SAP,
		isVerifingDocumentSap
	}
}

/**
 * Action to verify exist document manifiest
 * @param {*} requestToken
 */
export const verifyExistsDocumentSAp = (requestToken) => (dispatch, getState) => {
	dispatch(isVerifyDocument(true))
	let user = getState().oidc.user
	let config = getAuthorizedRequestConfigurationUser(user)
	let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/manifestDocument/${requestToken}/existsPdf`
	return axios
		.get(endPoint, config)
		.then((response) => {
			dispatch(isVerifyDocument(false))
			return Promise.resolve(response.data)
		})
		.catch((error) => {
			dispatch(isVerifyDocument(false))
			console.error(`Error verifying if pdf exists document trazability in sap`, error)
			return Promise.reject(error.response.data.Message)
		})
}

export const setAllSbais = (sbais, pagination, query) => {
	return {
		type: SET_ALL_SBAIS,
		sbais,
		pagination,
		query
	}
}

const setIsLoadingSbais = (isLoadingSbais) => {
	return {
		type: IS_LOADING_ALL_SBAIS,
		isLoadingSbais
	}
}

/**
 * Action to all request from filters
 * @param {*} pageNumber
 * @param {*} query
 * @param {*} customerToken
 */
export const loadAllSbaisFromBackEnd = (query, customerToken, filters) => {
	return function(dispatch, getState) {
		dispatch(setIsLoadingSbais(true))
		let user = getState().oidc.user
		let pagination = getState()
			.dispositionRequests.get('pagination')
			.toJS()
		let config = getAuthorizedRequestConfigurationUser(user)
		let sorting = null
		if (filters.orderBy) {
			sorting = {
				orderBy: filters.orderBy,
				orderType: filters.orderType
			}
		}

		let command = {
			CustomerToken: customerToken,
			PageNumber: pagination.pageNumber,
			PageSize: pagination.pageSize,
			Query: query,
			OrderBy: sorting ? sorting.orderBy : 'Sbai',
			OrderType: sorting ? sorting.orderType : 'desc'
		}

		if (!!filters) {
			if (!!filters.pageNumber) command.PageNumber = filters.pageNumber
		}

		if (!!filters) {
			if (!!filters.pageNumber) command.PageNumber = filters.pageNumber
			if (!!filters.customerCodes) command.CustomerCodes = filters.customerCodes
		}

		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/bysbais`
		return axios
			.post(endPoint, command, config)
			.then((response) => {
				let pagination = {
					total: response.data.total,
					pageNumber: response.data.pageNumber,
					pageSize: response.data.pageSize
				}
				dispatch(setAllSbais(response.data.requests, pagination, response.query))
				return Promise.resolve(response.data.requests)
			})
			.catch((error) => {
				dispatch(setIsLoadingSbais(false))
				console.error('Error loading all requests by sbais', error)
				return Promise.reject()
			})
	}
}

const setIsDownloadReport = (isDownloadingSbai) => {
	return {
		type: IS_DONWLOAD_REPORT_SBAI,
		isDownloadingSbai
	}
}

/**
 * Action to download report by sbai
 * @param {*} sbai
 * @param {*} customerToken
 */
export const downloadReportbySbais = (sbai, customerToken) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		let command = {
			Sbai: sbai,
			CustomerToken: customerToken
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/downloadbysbai`
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))

					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Reporte_Sbai_${sbai}.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report by sbai', error)
				return Promise.reject()
			})
	}
}

const setIsDownloadReportDate = (isDownloadingReport) => {
	return {
		type: IS_DONWLOAD_REPORT_DATE,
		isDownloadingReport
	}
}

/**
 * Action to download report by date
 * @param {*} requestData
 */
export const downloadReportByRangeDate = (requestData) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReportDate(true))
		let loggedUser = getState().oidc.user
		let usersession = getState().session.get('loggedUser')
		let customerToken = ''
		if (usersession.get('customer')) {
			customerToken = usersession.get('customer').get('token')
		}
		let command = {
			StartDate: requestData.startDate ? requestData.startDate.format('DD/MM/YYYY') : null,
			EndDate: requestData.endDate ? requestData.endDate.format('DD/MM/YYYY') : null,
			UserName: loggedUser.profile.preferred_username,
			CustomerToken: customerToken
		}
		let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}dispositionrequest/report/general`
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReportDate(false))

					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Reporte_general.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReportDate(false))
				console.error('Error donwload report general', error)
				return Promise.reject()
			})
	}
}

const mapDispositionRequest = (serverDispositionRequest) => {
	const recollectionRequests = serverDispositionRequest.recollectionRequests
		? serverDispositionRequest.recollectionRequests.map((r) => mapRecollectionRequest(r))
		: []

	return {
		...serverDispositionRequest,
		recollectionRequests
	}
}

const mapRecollectionRequest = (serverRecollectionRequest) => {
	const recollectionDate = serverRecollectionRequest.recollectionDate
		? moment(serverRecollectionRequest.recollectionDate)
		: null

	return {
		...serverRecollectionRequest,
		recollectionDate
	}
}
