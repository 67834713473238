import { fromJS } from 'immutable';
import * as branchsActions from './BranchOfficeAction';

let intialbranchsActionsState = fromJS({
    isLoadingbranchs: true,
    branchoffices: [],
})

const branchOfficesReducer = (state = intialbranchsActionsState, action) => {
    switch (action.type) {
        case branchsActions.START_LOADING_BRANCHS: {
            return state.merge({
                isLoadingbranchs: action.isLoading
            })
        }
        case branchsActions.SET_BRANCHS_LIST: {
            return state.merge({
                branchoffices: fromJS(action.items),
                isLoadingbranchs: false, 
            })
        }
        default: {
            return state;
        }
    }
}


export default branchOfficesReducer;