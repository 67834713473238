/**
 * Convert a immutable map to an javascript object
 * @param {Map} map 
 */
export const toSafeObject = map => {
    let object = null;
    if (map) {
        return map.toJS();
    }
    return object;
}

/**
 * Convert a immutable list into an javascript array
 * @param {List} list 
 */
export const toSafeArray = list => {
    let array = [];
    if (list) {
        list.forEach((item) => {
            array.push(item.toJS())
        })
    }
    return array;
}