import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'

const DispositionRequestBudget = withStyles((theme) => ({
	badge: {
		right: -15,
		top: 5,
		padding: '0 2px'
	}
}))(Badge)

export default DispositionRequestBudget
