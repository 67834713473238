import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** Material UI import section */
import { makeStyles, Paper } from '@material-ui/core'

/** Custom components import section */
import Layout from '../layout/Layout'
import ReportsHeader from './components/ReportsHeader'
import ReportsTable from './components/ReportsTable'
import AddingReportPanel from './components/AddingReportPanel'
import Toaster from '../common/Toaster'
import ConfirmationDialog from './components/RemoveConfirmationDialog'

/** Actions import section */
import {
	addReportForCustomer,
	loadReports,
	removeRequestFromBackEnd
} from '../../store/reports/ReportActions'
import { loadAllCustomers } from '../../store/customers/CustomerActions'

/** Redux selector import section */
import { getAllCustomersSelector } from '../../store/customers/CustomerSelectors'
import { getAllReportsSelector } from '../../store/reports/ReportSelectors'
import { getLoggedUserSelector } from '../../store/session/SessionSelectors'

/** Resources and helpers import section */
import { isUserInRole } from '../../store/helpers/UserHelper'
import { AppRoles } from '../../resources/constants/AppConstants'
import { confirmUserPassword } from '../../store/session/SessionActions'

const Index = () => {
	/** Defines local state */
	const [loadingReports, setLoadingReports] = useState(true)
	const [addingButtonDisplayed, setAddingButtonDisplayed] = useState(false)
	const [searchFilters, setSearchFilters] = useState({})
	const [savingReport, setSavingReport] = useState(false)
	const [addingReportPanelConfig, setAddingReportPanelConfig] = useState({
		opened: false,
		saving: false
	})
	const [
		removingConfimationDialogConfig,
		setRemovingConfimationDialogConfig
	] = useState({
		opened: false,
		executing: false
	})
	const [toasterOptions, setToasterOptions] = useState({
		opened: false,
		variant: null,
		message: null
	})

	/** Connect with redux */
	const dispatch = useDispatch()
	const loggedUser = useSelector((state) => getLoggedUserSelector(state))
	const allCustomers = useSelector((state) => getAllCustomersSelector(state))
	const allReports = useSelector((state) => getAllReportsSelector(state))

	const classes = useStyles()

	useEffect(() => {
		dispatch(loadAllCustomers())
	}, [dispatch])

	useEffect(() => {
		if (loggedUser) {
			if (isUserInRole(AppRoles.ADMINISTRATOR, loggedUser.roles)) {
				setAddingButtonDisplayed(true)
			} else {
				const {
					customer: { code }
				} = loggedUser
				setSearchFilters((filters) => ({
					...filters,
					CustomerCode: code
				}))
			}
		}
	}, [loggedUser])

	useEffect(() => {
		setLoadingReports(true)
		dispatch(loadReports(searchFilters))
			.then(() => {
				setLoadingReports(false)
			})
			.catch(() => {
				setLoadingReports(false)
				setToasterOptions({
					open: true,
					message: 'Error al cargar los reportes',
					variant: 'error'
				})
			})
	}, [dispatch, searchFilters])

	const addReport = (report, file) => {
		setSavingReport(true)
		dispatch(addReportForCustomer(report, file))
			.then(() => {
				setSavingReport(false)
				closeAddingReportPanel()
			})
			.catch(() => {
				setSavingReport(false)
				setToasterOptions({
					open: true,
					message: 'Error al guardar el reporte',
					variant: 'error'
				})
			})
	}

	const openAddingReportPanel = () => {
		setAddingReportPanelConfig({ opened: true })
	}

	const closeAddingReportPanel = () => {
		setAddingReportPanelConfig({ opened: false })
	}

	const openConfirmationDialog = (event) => {
		const token = event.currentTarget.getAttribute('data-token')
		const report = allReports.find((r) => r.token === token)
		setRemovingConfimationDialogConfig((prevState) => ({
			...prevState,
			data: report,
			opened: true
		}))
	}

	const closeConfirmationDialog = () => {
		setRemovingConfimationDialogConfig({
			opened: false
		})
	}

	const deleteReport = (password, token) => {
		setRemovingConfimationDialogConfig((prevState) => ({
			...prevState,
			executing: true
		}))
		dispatch(confirmUserPassword(password))
			.then(({ Success }) => {
				if (Success) {
					return dispatch(removeRequestFromBackEnd(token))
				}
				else {
					return Promise.reject('InvalidPassword')
				}
			})
			.then(() => {
				closeConfirmationDialog()
			})
			.catch(err => {
				const invalidPasswordDisplayed = err === 'InvalidPassword'
				setRemovingConfimationDialogConfig((prevState) => ({
					...prevState,
					executing: false,
					invalidPasswordDisplayed
				}))
				setToasterOptions({
					open: true,
					message: 'No se pudo eliminar el reporte, inténtelo nuevamente',
					variant: 'error'
				})
			})
	}

	return (
		<Layout>
			<Paper className={classes.root}>
				<ReportsHeader
					addingButtonDisplayed={addingButtonDisplayed}
					onAddButtonClick={openAddingReportPanel}
				/>
				<ReportsTable
					loading={loadingReports}
					reports={allReports}
					onDeleteRow={openConfirmationDialog}
				/>
				{addingReportPanelConfig.opened && (
					<AddingReportPanel
						{...addingReportPanelConfig}
						saving={savingReport}
						customers={allCustomers}
						onClose={closeAddingReportPanel}
						onSave={addReport}
					/>
				)}
				{removingConfimationDialogConfig.opened && (
					<ConfirmationDialog
						{...removingConfimationDialogConfig}
						isSaving={removingConfimationDialogConfig.removing}
						onClose={closeConfirmationDialog}
						onConfirm={deleteReport}
					/>
				)}
				{toasterOptions.opened && (
					<Toaster
						{...toasterOptions}
						onClose={() => setToasterOptions({ opened: false })}
					/>
				)}
			</Paper>
		</Layout>
	)
}

const useStyles = makeStyles({
	indexList: {
		height: `calc(100vh - ${150}px)`,
		overflowY: 'hidden',
		overflowX: 'hidden'
	},
	root: {
		marginTop: 10,
		width: '92%',
		padding: '10px',
		height: `calc(100vh - ${100}px)`
	},
	headerToolbar: {
		paddingBottom: '0px !important'
	},
	contentGridItem: {
		paddingTop: '0px !important'
	},
	drawer: {
		width: '670px',
		display: 'grid',
		height: '100vh',
		overflowY: 'hidden'
	}
})

export default Index
