import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import propTypes from 'prop-types'

/** Material UI import section */
import { Typography, makeStyles, Box, Avatar, Paper, CardActions } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleAlt';

/** Custom components import section */
import Toaster from '../../common/Toaster';
import AddExternalUserPanel from '../../users/components/AddExternalUserPanel';
import UsersTable from '../../users/components/UsersTable';
import AddItemButton from '../../common/AddItemButton';
import MemoryPager from '../../common/DataList/MemoryPager';

/** Helpers impor section */
import { UserTypes } from '../../../resources/constants/AppConstants';
import { COLOR_SECONDARY_NORMAL, COLOR_LIGHT_GRAY } from '../../../resources/constants/Colors';

const useUserStyles = makeStyles(({
    root: {
        padding: 10,
    }
}))

const UsersInsupplier = props => {
    const {onChangePageNumber} = props
    const classes = useUserStyles();
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const isSavingUser = useSelector(state => state.users.get('isSavingUser'));
    const isFindingUser = useSelector(state => state.users.get('isFindingUser'));
    const isLoadingActiveUser = useSelector(state => state.users.get('isLoadingActiveUser'))

    const onUserClick = user => {
        props.history.push(`/users/${user.id}`);
    }

    const onSortingCriteriaChange = (sortBy, sortCriteria) => {
        let actualParameters = queryString.parse(window.location.search);
        actualParameters.orderBy = sortBy;
        actualParameters.orderType = sortCriteria;
        let updatedQueryString = queryString.stringify(actualParameters)
        props.history.push(`${props.location.pathname}?${updatedQueryString}`)
    }

    return (
        <Paper className={classes.root}>
            {/** Users header */}
            <UsersHeader
                onAddUserButtonClick={props.onAddUserClick}
                disabledAdd={props.disabledAdd}
            />
            {/** Users table */}
            <UsersTable
                users={props.users}
                usersType={UserTypes.NON_ACTIVE_DIRECTORY}
                selectedUserId={props.selectedUserId}
                onActiveUser={props.onActiveUser}
                onUserRowClick={onUserClick}
                isLoadingActiveUser={isLoadingActiveUser}
                sortConfig={props.sortConfig}
                onSortingCriteriaChange={onSortingCriteriaChange}
                noDataMessage={props.noDataMessage} />

            <CardActions>
                <MemoryPager
                    pageNumber={props.pageNumber - 1}
                    pageSize={props.pagination.pageSize}
                    count={props.pagination.total}
                    onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
                />
            </CardActions>

            {/** Add external user panel */}
            {
                props.showAddExternalUserPanel &&
                <AddExternalUserPanel
                    open={props.showAddExternalUserPanel}
                    isSaving={isSavingUser}
                    isFindingUser={isFindingUser}
                    onClose={props.onClose}
                    onCreateUser={props.onCreateExternalUser}
                />
            }

            {
                toasterOptions.open &&
                <Toaster
                    {...toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }

        </Paper>

    );
}
UsersInsupplier.propTypes = {
    users: propTypes.array.isRequired,
    onClose: propTypes.func,
    isLoadingUsers: propTypes.bool.isRequired,
    showAddExternalUserPanel: propTypes.bool.isRequired,
    onCreateExternalUser: propTypes.func,
    onActiveUser: propTypes.func,
    pagination: propTypes.object.isRequired,
    sortConfig: propTypes.object.isRequired,
}

UsersInsupplier.defaultProps = {
    users: []
}

export default withRouter(UsersInsupplier);

const UsersHeader = props => {
    const classes = useHeaderStyles();
    return (
        <Box
            className={classes.root}
            display="flex"
            p={1}
            alignItems="center"
            justifyContent="flex-start"
        >
            <Box
                className={classes.avatar}
                display="flex"
                width="100%"
            >
                <Avatar style={{ color: COLOR_SECONDARY_NORMAL, backgroundColor: COLOR_LIGHT_GRAY, fontSize: "14px", height: "35px", width: "35px" }}>
                    <PeopleIcon className={classes.icon} />
                </Avatar>
                <Typography variant="button" className={classes.titleUser}>
                    <strong>{"USUARIOS"}</strong>
                </Typography>
            </Box>
            {!props.disabledAdd && <Box
                className={classes.buttonAddUser}
                p={1}
                flexShrink={0}
            >
                <AddItemButton
                    title={"Agregar usuario"}
                    onClick={props.onAddUserButtonClick}
                />
            </Box>}
        </Box>
    );
}

const useHeaderStyles = makeStyles(({
    root: {
        marginTop: -20,
        marginBottom: 0
    },
    icon: {
        color: COLOR_SECONDARY_NORMAL
    },
    avatar: {
        marginTop: 8,
    },
    buttonAddUser: {
        marginTop: 3,
    },
    titleUser: {
        marginTop: 6,
        marginLeft: 8,
        color: COLOR_SECONDARY_NORMAL
    }
}))