/** Import ReactJS */
import React from 'react'
import PropTypes from 'prop-types'

/** Material-UI imports */
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Fab, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'

/** Resources import section */
import {
	COLOR_FOCUS_NORMAL,
	COLOR_WHITE
} from '../../resources/constants/Colors'

/** Styles definition */
const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: '300'
	},
	buttonIcon: {
		backgroundColor: `${COLOR_FOCUS_NORMAL} !important`,
		borderRadius: '50% !important'
	}
}))

/**
 * Presentional component to render a button with add icon
 * @param {object} props 
 * @returns 
 */
const AddItemButton = (props) => {
	const { title, onClick } = props
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Tooltip title={title}>
				<Fab
					data-cy='btn-add-users'
					disableRipple
					size='small'
					className={classes.buttonIcon}
					onClick={onClick}
				>
					<AddIcon style={{ color: COLOR_WHITE }} />
				</Fab>
			</Tooltip>
		</div>
	)
}

AddItemButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

AddItemButton.defaultProps = {
    title: 'Agregar',
    onClick: () => console.warn('[onClick] not defined')
}

export default AddItemButton
