import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useQueryParam, DelimitedArrayParam } from 'use-query-params'

/** Material UI import section */
import { makeStyles, Typography, Grid, Paper } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import classNames from 'classnames'

/** Custom components import section */
import Layout from '../layout/Layout'
import Toaster from '../common/Toaster'
import NoDataMessage from '../common/NoDataMessage'
import Loader from '../common/Loader'
import RequestPanel from './components/RequestPanel'
import Welcome from '../home/Welcome'
import MemoryPager from '../common/DataList/MemoryPager'
import RequestIndex from './components/RequestIndex'
import RequestPanelShow from './components/RequestPanelShow'
import RequestActionDialog from './components/RequestActionDialog'
import DownloadReportDialog from './components/DownloadReportDialog'
import AddingManualMaterialEntry from './components/AddingManualMaterialEntry'

/**Import actions section */
import { confirmUserPassword } from '../../store/session/SessionActions'
import {
	loadAllRequestsFromBackEnd,
	changePageNumber,
	requestUpdateStatusBackEnd,
	cancelRequestBackEnd,
	addRecollectionRequestBackEnd,
	verifyExistsDocumentSAp,
	loadAllSbaisFromBackEnd,
	downloadReportbySbais,
	countAllRequestsFromBackEnd,
	downloadReportByRangeDate,
	downloadExcelStatusReport,
	addEvidenceToDispositionRequest,
    authorizeCrmDispositionRequest
} from '../../store/dispositionRequests/DispositionRequestsActions'
import { getUsersInRole, getUsersInRoleList } from '../../store/users/UserActions'
import { loadAllCustomers } from '../../store/customers/CustomerActions'

/** Helpers import section */
import { isUserInRole, isDisabledSBAI } from '../../store/helpers/UserHelper'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'

/** Resources import section */
import { AppRoles } from '../../resources/constants/AppConstants'
import NoResultsFound from '../../resources/images/searchEmpty.png'

/** Configuration table section */
import { useConfigurator } from '../common/apsys.datagrid.materialui/UseConfigurator'
import Configurator from '../common/apsys.datagrid.materialui/PopperConfigurator'
import headersConfig from './resources/headersConfig'
import headerConfigExternal from './resources/headerConfigExternal'

const Index = (props) => {
	const { isLoggedUserAdministrator } = props
	const classes = useUserStyles()
	const dispatch = useDispatch()

	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [openRequestPanel, setOpenRequestPanel] = useState(false)
	const [isSaving] = useState(false)
	const [isLoadingRequests, setIsLoadingRequests] = useState(true)
	const [requestToken, setRequestToken] = useState('')
	const [showHelperPassword, setShowHelperPassword] = useState(false)
	const [openShowRequestPanel, setOpenShoRequestPanel] = useState(false)
	const [addingManualMaterialEntryConfig, setAddingManualMaterialEntryConfig] = useState({
		open: false
	})
	const [folioDocumentSap, setFolioDocumentSap] = useState(null)
	const [gridMode, setGridMode] = useState(true)
	const [userManagers, setUserManagers] = useState([])

	const loggedUser = useSelector((state) => state.session.get('loggedUser'), shallowEqual)
	const allRequests = useSelector((state) =>
		state.dispositionRequests.get('dispositionRequests')
			? state.dispositionRequests.get('dispositionRequests').toJS()
			: []
	)
	const allSbais = useSelector((state) =>
		state.dispositionRequests.get('sbaiRequests')
			? state.dispositionRequests.get('sbaiRequests').toJS()
			: []
	)
	const isSavingRequest = useSelector((state) => state.dispositionRequests.get('isSavingRequest'))
	const hasRequests = useSelector((state) => state.dispositionRequests.get('hastRequests'))
	const isVerifingDocumentSap = useSelector((state) =>
		state.dispositionRequests.get('isVerifingDocumentSap')
	)
	const isLoadingSbais = useSelector((state) => state.dispositionRequests.get('isLoadingSbais'))
	const isLoadingRequestList = useSelector((state) =>
		state.dispositionRequests.get('isLoadingRequests')
	)
	const isDownloadingSbai = useSelector((state) =>
		state.dispositionRequests.get('isDownloadingSbai')
	)
	const isDownloadReport = useSelector((state) =>
		state.dispositionRequests.get('isDonwloadReport')
	)

	/// Customers
	const allCustomers = useSelector((state) =>
		state.customers.get('allCustomers') ? state.customers.get('allCustomers').toJS() : []
	)

	//Configuration pagination table
	const pagination = useSelector((state) => state.dispositionRequests.get('pagination'))
	const sbaipagination = useSelector((state) => state.dispositionRequests.get('sbaipagination'))
	const [query, setQuery] = useState('')
	const [customerFilterValues, setCustomersFilterValues] = useQueryParam(
		'customerCodes',
		DelimitedArrayParam
	)
	const [statusFilterValues, setStatusFilterValues] = useQueryParam('status', DelimitedArrayParam)
	const [sortConfig, setSortConfig] = useState({ by: 'folio', criteria: 'desc' })
	const [identifierRow, setIdentifierRow] = useState([])
	/**Open modal options */
	const [requestDialogOptions, setRequestDialogOptions] = useState({
		open: false,
		title: '',
		isSupplierCarrier: false,
		variant: 'default',
		isButtonAccepted: false
	})
	const [openModalReport, setOpenModalReport] = useState({ open: false })

	/** Table configuration */
	const [serviceTableConfiguration, onChangeConfiguration] = useConfigurator(
		'dashboard-services-datagrid-config',
		headersConfig
	)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)

	const onSearchBoxChange = (searchValue) => {
		const actualParameters = queryString.parse(window.location.search)
		if (searchValue) {
			actualParameters.query = searchValue
		} else {
			delete actualParameters.query
		}
		actualParameters.pageNumber = 1
		let updatedQueryString = queryString.stringify(actualParameters)
		props.history.push(`${props.location.pathname}?${updatedQueryString}`)
	}

	useEffect(() => {
		if (props.history.location) {
			const actualParameters = queryString.parse(props.history.location.search)
			let query = actualParameters.query ? actualParameters.query : ''
			setQuery(query)

			let filters = {
				status: statusFilterValues,
				customerCodes: customerFilterValues
			}
			let newSortConfig = {}
			if (actualParameters.orderBy) {
				filters.orderBy = actualParameters.orderBy
				newSortConfig.by = actualParameters.orderBy
			}
			if (actualParameters.orderType) {
				filters.orderType = actualParameters.orderType
				newSortConfig.criteria = actualParameters.orderType
			}
			setSortConfig(newSortConfig)
			let customerToken = ''
			if (loggedUser.get('customer')) {
				customerToken = loggedUser.get('customer').get('token')
			}
			let supplierToken = ''
			if (loggedUser.get('supplier')) {
				supplierToken = loggedUser.get('supplier').get('token')
			}

			dispatch(
				loadAllRequestsFromBackEnd(
					query,
					customerToken,
					supplierToken,
					filters,
					hasRequests
				)
			)
				.then(() => {
					setIsLoadingRequests(false)
				})
				.catch(() => {
					setIsLoadingRequests(false)
					setToasterOptions({
						open: true,
						message: 'Se generó un error al carga el listado de solicitudes',
						variant: 'error'
					})
				})

			dispatch(loadAllSbaisFromBackEnd(query, customerToken, filters))
				.then(() => {})
				.catch(() => {
					setToasterOptions({
						open: true,
						message: 'Se generó un error al carga el listado de solicitudes',
						variant: 'error'
					})
				})
		}
	}, [
		dispatch,
		props.history.location,
		loggedUser,
		hasRequests,
		customerFilterValues,
		statusFilterValues
	])

	useEffect(() => {
		let customerToken = 'undefined'
		if (loggedUser.get('customer')) {
			customerToken = loggedUser.get('customer').get('token')
		}
		dispatch(getUsersInRole(AppRoles.SALES_MANAGER))
		dispatch(
			getUsersInRoleList(AppRoles.CUSTOMER_VISUALIZATOR_NOTIFICATOR, customerToken)
		).then((users) => {
			setUserManagers(users)
		})
	}, [dispatch, props.match.url, loggedUser])

	useEffect(() => {
		dispatch(loadAllCustomers())
	}, [dispatch])

	/**
	 * Here we save and switch view mode grid|list and persist on local storage.
	 */
	useEffect(() => {
		let savedViewMode = localStorage.getItem('viewModeRequests')
		if (savedViewMode === 'false') {
			setGridMode(false)
		} else {
			setGridMode(true)
		}
	}, [])

	/**Change mode view in users */
	const switchViewListMode = () => {
		if (gridMode === false) {
			setGridMode(true)
			localStorage.setItem('viewModeRequests', 'true')
		} else {
			setGridMode(false)
			localStorage.setItem('viewModeRequests', 'false')
		}
	}

	/**
	 * onRequestsSortingChange
	 * @param {*} sortCriteria
	 */
	const onRequestsSortingChange = (sortCriteria) => {
		if (sortCriteria && sortCriteria.by !== null) {
			let actualParameters = queryString.parse(window.location.search)
			actualParameters.orderBy = sortCriteria.by
			actualParameters.orderType = sortCriteria.direction
			let updatedQueryString = queryString.stringify(actualParameters)
			props.history.push(`?${updatedQueryString}`)
		}
	}

	/** Update open option to add */
	useEffect(() => {
		if (props.history.location) {
			const actualParameters = queryString.parse(props.history.location.search)
			let query = actualParameters.query ? actualParameters.query : ''
			let filters = {
				status: statusFilterValues,
				customerCodes: customerFilterValues
			}
			if (actualParameters.orderBy) {
				filters.orderBy = actualParameters.orderBy
			}
			if (actualParameters.orderType) {
				filters.orderType = actualParameters.orderType
			}
			let customerToken = ''
			if (loggedUser.get('customer')) {
				customerToken = loggedUser.get('customer').get('token')
			}
			let supplierToken = ''
			if (loggedUser.get('supplier')) {
				supplierToken = loggedUser.get('supplier').get('token')
			}
			dispatch(
				countAllRequestsFromBackEnd(
					query,
					customerToken,
					supplierToken,
					filters,
					hasRequests
				)
			)
		}
	}, [
		dispatch,
		props.history.location,
		loggedUser,
		customerFilterValues,
		statusFilterValues,
		hasRequests
	])

    const onAuthorizeRequest = (requestData, token) => {  
		dispatch(confirmUserPassword(requestData.password))
			.then((result) => {
				const success = result.Success
				if (success) {
					dispatch(authorizeCrmDispositionRequest(requestData, token))
						.then(() => {
							setShowHelperPassword(false)
							setRequestDialogOptions({ open: false, title: '' })
							setToasterOptions({
								open: true,
								message: 'Se ha actualizado la información correctamente',
								variant: 'success'
							})
						})
						.catch((error) => {
							setToasterOptions({
								open: true,
								message: !isNullOrEmpty(error)
									? error
									: 'Se generó un error al actualizar la solicitud',
								variant: 'warning'
							})
						})
				} else {
					setShowHelperPassword(true)
				}
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message:
						'Se generó un error al confirmar fecha de solicitud. Intentelo nuevamente por favor',
					variant: 'error'
				})
			})
     }

	/**
	 * On update status in request
	 * @param {*} requestData
	 * @param {*} token
	 * @param {*} supplierToken
	 * @param {*} collectionDate
	 */
	const onRequestUpdateStatus = (requestData, token) => {
		let resultResponse = false
		dispatch(confirmUserPassword(requestData.password))
			.then((result) => {
				resultResponse = result.Success
				if (resultResponse) {
					dispatch(requestUpdateStatusBackEnd(requestData, token))
						.then(() => {
							setShowHelperPassword(false)
							setRequestDialogOptions({ open: false, title: '' })
							setToasterOptions({
								open: true,
								message: 'Se ha actualizado la información correctamente',
								variant: 'success'
							})
						})
						.catch((error) => {
							setToasterOptions({
								open: true,
								message: !isNullOrEmpty(error)
									? error
									: 'Se generó un error al actualizar la solicitud',
								variant: 'warning'
							})
						})
				} else {
					setShowHelperPassword(true)
				}
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message:
						'Se generó un error al confirmar fecha de solicitud. Intentelo nuevamente por favor',
					variant: 'error'
				})
			})
	}

	/**
	 * on add recollection date
	 * @param {*} requestData
	 * @param {*} token
	 */
	const onAddRecollectionRequestBackEnd = (requestData, token) => {
		let resultResponse = false
		dispatch(confirmUserPassword(requestData.password))
			.then((result) => {
				resultResponse = result.Success
				if (resultResponse) {
					dispatch(addRecollectionRequestBackEnd(requestData, token))
						.then(() => {
							setShowHelperPassword(false)
							setRequestDialogOptions({ open: false, title: '' })
							setToasterOptions({
								open: true,
								message: 'Se ha actualizado la información correctamente',
								variant: 'success'
							})
						})
						.catch((error) => {
							console.error(
								`🚀 ~ file: Index.js ~ onAddRecollectionRequestBackEnd ~ error`,
								error
							)
							setToasterOptions({
								open: true,
								message: !isNullOrEmpty(error)
									? error
									: 'Se generó un error al actualizar la solicitud',
								variant: 'warning'
							})
						})
				} else {
					setShowHelperPassword(true)
				}
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message:
						'Se generó un error al confirmar fecha de solicitud. Intentelo nuevamente por favor',
					variant: 'error'
				})
			})
	}

	/**
	 * On cancel request
	 * @param {*} requestData
	 * @param {*} token
	 */
	const onCancelRequest = (requestData, token) => {
		let resultResponse = false
		dispatch(confirmUserPassword(requestData.password))
			.then((result) => {
				resultResponse = result.Success
				if (resultResponse) {
					dispatch(cancelRequestBackEnd(requestData, token))
						.then(() => {
							setShowHelperPassword(false)
							setRequestDialogOptions({ open: false, title: '' })
							setToasterOptions({
								open: true,
								message: 'Se ha actualizado la información correctamente',
								variant: 'success'
							})
						})
						.catch((error) => {
							setToasterOptions({
								open: true,
								message: !isNullOrEmpty(error)
									? error
									: 'Se generó un error al actualizar la solicitud',
								variant: 'warning'
							})
						})
				} else {
					setShowHelperPassword(true)
				}
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message:
						'Se generó un error al confirmar fecha de solicitud. Intentelo nuevamente por favor',
					variant: 'error'
				})
			})
	}

	const onClosePanel = () => {
		if (props.history.location.search) props.history.push(props.history.location.pathname)
		setOpenRequestPanel(false)
	}

	const onChangePageNumber = (page) => {
		const actualParameters = queryString.parse(window.location.search)
		actualParameters.pageNumber = page
		let updatedQueryString = queryString.stringify(actualParameters)
		props.history.push(`${props.location.pathname}?${updatedQueryString}`)
		dispatch(changePageNumber(page))
	}

	const onRequestClick = (event, item) => {
		let tagName = event.target.tagName
		if (item && (tagName === 'TD' || tagName === 'DIV')) {
			onAddIdentifierRow(item)
			setRequestToken(item.token)
			setOpenShoRequestPanel(true)
		}
	}

	const onAddIdentifierRow = (item) => {
		let index = identifierRow.includes(item.token)
		if (index) {
			let newList = identifierRow.filter((identifier) => {
				return identifier !== item.token
			})
			setIdentifierRow(newList)
		} else {
			setIdentifierRow(identifierRow.concat(item.token))
		}
	}

	const onClosePanelShow = () => {
		setIdentifierRow([])
		setRequestToken(null)
		setOpenShoRequestPanel(false)
	}

	const onOpenPanelCreateRequest = () => {
		setOpenRequestPanel(true)
	}

	/**
	 * Common function for process de request with different callbacks
	 */

	const onOpenModalConfirmRequest = (identifier) => {
		if (identifier === 'onUpdateColectionDate') {
			setRequestDialogOptions({
				open: true,
				title: 'CONFIRMAR RECOLECCIÓN',
				onAccept: onAddRecollectionRequestBackEnd,
				isOptionToConfirmColection: true
			})
		} else if (identifier === 'onLiberatedRequest') {
			setRequestDialogOptions({
				open: true,
				title: 'TERMINAR SOLICITUD',
				onAccept: onRequestUpdateStatus,
				isOptionToLiberated: true
			})
		} else if (identifier === 'onCancelRequest') {
			setRequestDialogOptions({
				open: true,
				title: 'CANCELAR SOLICITUD',
				onAccept: onCancelRequest,
				isOptionToCancel: true
			})
		} else if (identifier === 'onAuthorizeRequest') {
			setRequestDialogOptions({
				open: true,
				title: 'AUTORIZAR SOLICITUD',
				onAccept: onAuthorizeRequest,
				type: 'REQUEST_AUTHORIZATION'
			})
		} else {
			setRequestDialogOptions({
				open: true,
				title: 'CONFIRMAR RECEPCIÓN',
				onAccept: onRequestUpdateStatus,
				isOptionToConfirm: true
			})
		}
	}

	const onCloseModalConfirmRequest = (value) => {
		setRequestDialogOptions({
			open: false,
			title: ''
		})
	}

	const openModalReportClick = (value) => {
		setOpenModalReport({ open: value, onDownloadReport })
	}

	const openAddingManualEntryDialog = () => {
		setAddingManualMaterialEntryConfig({ open: true })
	}

	const closeAddingManualEntryDialog = () => {
		setAddingManualMaterialEntryConfig({ open: false })
	}

	const addManualMaterialEntry = (folio, attachment) => {
		setAddingManualMaterialEntryConfig((prevState) => ({
			...prevState,
			isSaving: true
		}))
		dispatch(addEvidenceToDispositionRequest(requestToken, folio, attachment))
			.then(() => {
				closeAddingManualEntryDialog()
				setToasterOptions({
					open: true,
					message: 'Se agregó la evidencia',
					variant: 'success'
				})
			})
			.catch(() => {
				setAddingManualMaterialEntryConfig((prevState) => ({
					...prevState,
					isSaving: false
				}))
				setToasterOptions({
					open: true,
					message: 'No se pudo agregar la evidencia',
					variant: 'error'
				})
			})
	}

	const openStatusReportModal = () => {
		setOpenModalReport({
			open: true,
			title: 'Descargar reporte por estatus',
			statusDisplayed: true,
			onDownloadReport: downloadStatusReport
		})
	}

	const downloadStatusReport = (requestData) => {
		dispatch(downloadExcelStatusReport(requestData.status))
	}

	const getMessage = () => {
		let message = '',
			image
		const actualParameters = queryString.parse(props.history.location.search)
		let query = actualParameters.query ? actualParameters.query : ''
		if (allRequests.size === 0 && !isNullOrEmpty(query) && hasRequests) {
			message = <Typography>No se encontraron resultados para esta búsqueda.</Typography>
			image = NoResultsFound
		}
		return <NoDataMessage message={message} image={image} />
	}

	/**
	 * On Change selected customer on filters
	 * @param {*} customersSelected
	 */
	const onChangeSelectedCustomers = (customersSelected) => {
		setCustomersFilterValues([])
		let newCustomers = []
		customersSelected.forEach((customer) => {
			if (!newCustomers.includes(customer.code)) newCustomers.push(customer.code)
		})
		setCustomersFilterValues(newCustomers)
		let { search } = window.location
		if (search) {
			props.history.push(search)
		} else {
			props.history.push('/')
		}
	}

	/**
	 * On change selected status on filters
	 * @param {*} statusSelected
	 */
	const onChangeSelectedStatus = (statusSelected) => {
		setStatusFilterValues([])
		let newStatus = []
		statusSelected.forEach((status) => {
			if (!newStatus.includes(status.key)) newStatus.push(status.key)
		})
		setStatusFilterValues(newStatus)
		let { search } = window.location
		if (search) {
			props.history.push(search)
		} else {
			props.history.push('/')
		}
	}

	/**
	 * Virefing exit document trazability SAP
	 * @param {*} folio
	 */
	const verifyExistsDocumentSapDocument = (requestToken) => {
		setFolioDocumentSap(requestToken)
		return dispatch(verifyExistsDocumentSAp(requestToken))
			.then((response) => {
				if (response.existPdf) {
					setToasterOptions({
						open: true,
						message: 'Todos los manifiestos estan completos',
						variant: 'success'
					})
					return Promise.resolve(response)
				} else {
					setToasterOptions({
						open: true,
						message:
							'El documento manifiesto no existe en la carpeta de disposición final',
						variant: 'warning'
					})
				}
				setFolioDocumentSap(null)
			})
			.catch((error) => {
				setFolioDocumentSap(null)
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'El documento manifiesto no existe en la carpeta de disposición final',
					variant: 'warning'
				})
			})
	}

	/**
	 * On download reporte by sbai
	 * @param {*} sbai
	 * @param {*} customerToken
	 */
	const onDownloadReportbySbais = (sbai, customerToken) => {
		setFolioDocumentSap(sbai)
		dispatch(downloadReportbySbais(sbai, customerToken))
			.then((response) => {
				setFolioDocumentSap(null)
			})
			.catch(() => {
				setFolioDocumentSap(null)
				setToasterOptions({
					open: true,
					message: 'Se generó un error al descargar reporte de excel',
					variant: 'error'
				})
			})
	}

	/**
	 * The user change the requests table configuration
	 */
	const onConfigurationChange = (configuration) => {
		onChangeConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	/**
	 * Action to donwload report general
	 * @param {*} requestData
	 */
	const onDownloadReport = (requestData) => {
		dispatch(downloadReportByRangeDate(requestData))
			.then(() => {
				openModalReportClick(false)
			})
			.catch(() => {
				openModalReportClick(false)
				setToasterOptions({
					open: true,
					message: 'Se generó un error al descargar reporte de excel',
					variant: 'error'
				})
			})
	}

	const renderListIndex = () => {
		return (
			<Grid className={classes.contentGridItem} item xs={12} sm={12} md={12} lg={12} xl={12}>
				<div className={classes.indexList}>
					<RequestIndex
						requests={allRequests}
						noDataMessage={getMessage()}
						customers={allCustomers}
						onChangeSelectedCustomers={onChangeSelectedCustomers}
						onChangeSortingCriteria={onRequestsSortingChange}
						onRequestClick={onRequestClick}
						onChangeSelectedStatus={onChangeSelectedStatus}
						onSearchRequest={onSearchBoxChange}
						queryValue={query}
						onAddButtonClick={setOpenRequestPanel}
						isValitToAddRequest={isUserInRole(
							AppRoles.CUSTOMER_SERVICE_APPLICANT,
							loggedUser.get('roles').toJS()
						)}
						isRoleAdmin={isUserInRole(
							AppRoles.ADMINISTRATOR,
							loggedUser.get('roles').toJS()
						)}
						isValidToAddInternal={isUserInRole(
							AppRoles.ROLE_SERVICE_INTERNAL,
							loggedUser.get('roles').toJS()
						)}
						isDisabledSBAI={isDisabledSBAI(loggedUser.toJS())}
						folioDocumentSap={folioDocumentSap}
						switchViewMode={switchViewListMode}
						gridMode={gridMode}
						allSbais={allSbais}
						downloadReportbySbais={onDownloadReportbySbais}
						isLoadingSbais={isLoadingSbais}
						isLoadingRequest={isLoadingRequestList}
						isDownloadingSbai={isDownloadingSbai}
						configuration={
							isDisabledSBAI(loggedUser.toJS())
								? serviceTableConfiguration
								: headerConfigExternal
						}
						setConfigurationAnchorEl={setConfigurationAnchorEl}
						configuratorAnchorEl={configuratorAnchorEl}
						sortConfig={sortConfig}
						identifierRow={identifierRow}
						onDownloadReportClick={openModalReportClick}
						onDownloadStatusReport={openStatusReportModal}
					/>
				</div>

				{gridMode && (
					<MemoryPager
						pageNumber={pagination.get('pageNumber') - 1}
						pageSize={pagination.get('pageSize')}
						count={pagination.get('total')}
						onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
					/>
				)}

				{!gridMode && (
					<MemoryPager
						pageNumber={sbaipagination.get('pageNumber') - 1}
						pageSize={sbaipagination.get('pageSize')}
						count={sbaipagination.get('total')}
						onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
					/>
				)}

				<Configurator
					open={configuratorAnchorEl ? true : false}
					anchorEl={configuratorAnchorEl}
					configuration={serviceTableConfiguration}
					onConfigurationChange={onConfigurationChange}
					onClickAway={() => setConfigurationAnchorEl(null)}
				/>
			</Grid>
		)
	}

	if (hasRequests || openRequestPanel) {
		return (
			<Layout>
				<Paper className={classes.root}>
					{/** Request table */}
					{
						{
							true: <Loader />,
							false: (
								<Grid container spacing={2}>
									{renderListIndex()}
								</Grid>
							)
						}[isLoadingRequests]
					}
				</Paper>

				{/** Toaster */}
				{toasterOptions.open && (
					<Toaster
						{...toasterOptions}
						onClose={() => setToasterOptions({ open: false })}
					/>
				)}

				<RequestPanel
					open={openRequestPanel}
					isSaving={isSaving}
					title={'Agregar solicitud'}
					onClose={onClosePanel}
					customers={allCustomers}
					isValidToAddInternal={isUserInRole(
						AppRoles.ROLE_SERVICE_INTERNAL,
						loggedUser.get('roles').toJS()
					)}
					userManagers={userManagers}
				/>

				{openShowRequestPanel && (
					<Drawer anchor='right' open={true} onClose={onClosePanelShow}>
						<div className={classNames(classes.drawer)} tabIndex={0} role='button'>
							<RequestPanelShow
								open={openShowRequestPanel}
								selectedRequestToken={requestToken}
								isSaving={isSaving}
								isRoleAdmin={isUserInRole(
									AppRoles.ADMINISTRATOR,
									loggedUser.get('roles').toJS()
								)}
								isValitToAddEvidenceCoodinator={isUserInRole(
									AppRoles.CUSTOMER_SERVICE_APPLICANT,
									loggedUser.get('roles').toJS()
								)}
								onOpenModalConfirmRequest={onOpenModalConfirmRequest}
								verifyExistsDocumentSap={verifyExistsDocumentSapDocument}
								isVerifingDocumentSap={isVerifingDocumentSap}
								folioDocumentSap={folioDocumentSap}
								onOpenModalToAddRequest={openAddingManualEntryDialog}
								onClosePanelShow={onClosePanelShow}
							/>
						</div>
					</Drawer>
				)}

				{requestDialogOptions.open && (
					<RequestActionDialog
						open={requestDialogOptions.open}
						isSaving={isSavingRequest}
						onClose={onCloseModalConfirmRequest}
						{...requestDialogOptions}
						requestToken={requestToken}
						showHelperPassword={showHelperPassword}
						verifyExistsDocumentSap={verifyExistsDocumentSapDocument}
						isVerifingDocumentSap={isVerifingDocumentSap}
					/>
				)}

				{openModalReport.open && (
					<DownloadReportDialog
						{...openModalReport}
						isSaving={isDownloadReport}
						onClose={() => openModalReportClick(false)}
					/>
				)}

				{addingManualMaterialEntryConfig.open && (
					<AddingManualMaterialEntry
						{...addingManualMaterialEntryConfig}
						onClose={closeAddingManualEntryDialog}
						onSaveButtonClick={addManualMaterialEntry}
					/>
				)}
			</Layout>
		)
	} else if (isLoadingRequests) {
		return <Loader />
	}
	return (
		<Layout>
			<Welcome
				isLoggedUserAdministrator={isLoggedUserAdministrator}
				onOpenPanelCreateRequest={onOpenPanelCreateRequest}
				isUserCustomerServiceApplicant={isUserInRole(
					AppRoles.CUSTOMER_SERVICE_APPLICANT,
					loggedUser.get('roles').toJS()
				)}
			/>
		</Layout>
	)
}

const useUserStyles = makeStyles({
	indexList: {
		height: `calc(100vh - ${150}px)`,
		overflowY: 'hidden',
		overflowX: 'hidden'
	},
	root: {
		marginTop: 10,
		width: '92%',
		padding: '10px',
		height: `calc(100vh - ${100}px)`
	},
	headerToolbar: {
		paddingBottom: '0px !important'
	},
	contentGridItem: {
		paddingTop: '0px !important'
	},
	drawer: {
		width: '670px',
		display: 'grid',
		height: '100vh',
		overflowY: 'hidden'
	}
})

export default withRouter(Index)
