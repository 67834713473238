import React, { useState, useEffect, memo } from 'react';

/** Material UI import section */
import {
    makeStyles,
    InputBase,
    IconButton,
    Paper
} from '@material-ui/core';
import SearchIconEnabled from '@material-ui/icons/Search';
import SearchIconDisabled from '@material-ui/icons/YoutubeSearchedFor';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: 35,
        width: 33,
    },
    inputHolder: {
        display: 'flex',
        backgroundColor: '#FFFAFA',
        borderRadius: 50
    },
    input: {
        flex: 1,
        transition: theme.transitions.create("width")
    },
    buttonSearch: {
        backgroundColor: 'rgba(0,0,0,.05)',
        padding: 10,
    },
    buttonToggle: {
        width: 35,
        "&:hover": {
            background: "transparent",
        }
    }
}));

const SearchBox = props => {
    const active = props.active || false;

    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');

    const onQueryChange = event => {
        setSearchValue(event.target.value)
    }

    useEffect(() => {
        setSearchValue(props.value)
    }, [props.value])

    const onKeyPress = event => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (props.onChange) {
                props.onChange(searchValue)
            }
        }
    }

    const onSearchButtonClick = () => {
        if (active) {
            if (props.onChange) {
                props.onChange(searchValue)
            }
        }
        else {
            if (props.onToggleStatus) {
                props.onToggleStatus(true);
            }
        }
    }

    const onCloseButtonClick = () => {
        if (props.onToggleStatus) {
            props.onChange(null);
            props.onToggleStatus(false);
        }
    }

    const placeholder = props.placeholder || 'Buscar';
    const rootStyle = active ? { width: 250 } : {};
    const inputStyle = active ? { paddingLeft: 20, } : {};
    return (
        <div className={classes.root} style={rootStyle}>
            <Paper component="form" className={classes.inputHolder}>
                {
                    active &&
                    <InputBase
                        autoFocus={active}
                        className={classes.input}
                        style={inputStyle}
                        placeholder={placeholder}
                        inputProps={{ 'aria-label': `${placeholder}` }}
                        value={searchValue}
                        onChange={onQueryChange}
                        onKeyPress={onKeyPress}
                    />
                }
                <IconButton
                    aria-label="search"
                    size="small"
                    className={classes.buttonSearch}
                    onClick={onSearchButtonClick}
                >
                    {
                        {
                            true: <SearchIconEnabled fontSize="small" />,
                            false: <SearchIconDisabled fontSize="small" />
                        }[active]
                    }
                </IconButton>
            </Paper>
            {
                active &&
                <IconButton
                    aria-label="clse"
                    size="small"
                    className={classes.buttonToggle}
                    onClick={onCloseButtonClick}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        </div>

    );
}

export default memo(SearchBox)