import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

/** Material UI import section */
import {
	ListItem,
	ListItemText,
	Typography,
	ListItemSecondaryAction,
	IconButton,
    makeStyles
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CalendarIcon from '@material-ui/icons/EventNote'
import EditIcon from '@material-ui/icons/Create'

/** Resources import section */
import {
	COLOR_PRIMARY_NORMAL,
	COLOR_INFO_NORMAL
} from '../../../resources/constants/Colors'


const RecollectionRequest = ({
	token,
	recollectionDate,
	realRecollectionDate,
	carrierName,
	branchOffice,
    editionEnabled,
    onEditButtonClick = () => console.warn('[onEditButtonClick] not defined!')
}) => {
    const classes = useStyles();
	return (
		<div>
			<ListItem key={token} divider className={classes.listContent}>
				<ListItemAvatar>
					<ListItemAvatar>
						<Avatar style={{ background: COLOR_INFO_NORMAL }}>
							<CalendarIcon />
						</Avatar>
					</ListItemAvatar>
				</ListItemAvatar>
				<ListItemText
					primary={
						<Typography component='span' variant='subtitle2'>
							<strong>{'RECOLECCIÓN DE MATERIAL'}</strong>
						</Typography>
					}
					secondary={
						<>
							<Typography component='span' variant='subtitle2'>
								<strong>{'Fecha de recolección: '}</strong>
								{recollectionDate
									? recollectionDate.format('DD/MM/YYYY')
									: ''}
							</Typography>
							<br />
							{carrierName && (
								<Typography
									component='span'
									variant='subtitle2'
								>
									<strong>{'Transportista: '}</strong>
									{carrierName}
									<br />
								</Typography>
							)}
							<Typography component='span' variant='subtitle2'>
								<strong>{'Sucursal: '}</strong>
								{branchOffice}
							</Typography>
							<br />
						</>
					}
				/>
				{editionEnabled && (
					<ListItemSecondaryAction>
						<IconButton
							edge='end'
							aria-label='add'
							onClick={() => onEditButtonClick(token)}
						>
							<EditIcon color='primary' />
						</IconButton>
					</ListItemSecondaryAction>
				)}
			</ListItem>
		</div>
	)
}

RecollectionRequest.propTypes = {
	token: PropTypes.string.isRequired,
	recollectionDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(moment)
	]),
	realRecollectionDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(moment)
	]),
	carrierName: PropTypes.string,
	branchOffice: PropTypes.string,
    editionEnabled: PropTypes.bool,
    onEditButtonClick: PropTypes.func
}

const useStyles = makeStyles({
	contentTab: {
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '55vh'
	},
	listContent: {
		borderLeft: `3px solid ${COLOR_PRIMARY_NORMAL}`,
		margin: '10px'
	}
})


export default RecollectionRequest
