/** Oidc import section */
import { createUserManager } from 'redux-oidc';
import { getAbsoluteUrlAddress } from './UrlHelper'

/** Defines the user manager configuration */
const userManagerConfig = {
    client_id: 'apsys.repare.crm.finaldisposition.web',
    redirect_uri: getAbsoluteUrlAddress('callback'),
    post_logout_redirect_uri: getAbsoluteUrlAddress(''),
    response_type: 'id_token token',
    scope: 'openid profile email',
    authority: `${process.env.REACT_APP_IDENTITY_SERVER}/identity`,
    filterProtocolClaims: true,
    loadUserInfo: true,
    clockSkew: 900
};
  
const userManager = createUserManager(userManagerConfig);
export default userManager;