import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

/** Material UI import section */
import { makeStyles } from '@material-ui/core/styles';
import ActionBar from './NavBar';

const useLayoutStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    flex: {
        marginLeft: "auto"
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawer: {
        width: 230,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    content: {
        flexGrow: 1,
        paddingLeft:  theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        height: '97vh',
        justifyContent: "center"
    },
    breadCrum: {
        color: "white"
    },
    avatar: {
        margin: 10,
        width: 20,
        height: 20
    },
    removeBorder:{
        boxShadow: "0px 0px 0px 0px", 
    },
    container: {
        // marginTop: 60,
        display: "flex",
        justifyContent: "center",
    }
}))

const Layout = props => {
    const classes = useLayoutStyle();
    const { searchBoxOptions } = props;

    const documentsReport = useSelector(state => state.documents.get('report'));

    const userProfile = useSelector(state => {
        let user = state.oidc.user;
        if (user) { return state.oidc.user; }
        return null;
    });

    const getNotificationData = () => {
        if (documentsReport) { return documentsReport.toJS() }
        return { enabled: false };
    }

    if (!userProfile) return null;
    return (
        <div className={classes.root}>
            <ActionBar 
                history={props.history}
                user={userProfile}
                searchBoxOptions={searchBoxOptions}
                notificationData={getNotificationData()}
            />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.container} >
                    {props.children}
                </div>
            </main>
        </div>
    )    
}

Layout.propTypes = {
    searchBoxOptions: PropTypes.shape({
            enabled: PropTypes.bool,
            placeholder: PropTypes.string,
            value: PropTypes.string,
            onChange: PropTypes.func
        }).isRequired
}

Layout.defaultProps = {
    searchBoxOptions: { enabled: false }
}


export default withRouter(Layout);