import React from 'react';
import EditAvatarIcon from '@material-ui/icons/Create';
import { Box, Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';
import uuidv4 from 'uuid/v4'


const useAvatarStyles = makeStyles(() => ({
    root: {
        marginTop: "10px"
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        backgroundColor: "rgba(108, 117, 125, 1)",
    },
    subtitle: {
        backgroundColor: '#D0DEED'
    },
    logoFileInput: {
        display: "none",
    },
}))

const ProfileAvatar = props => {
    const classes = useAvatarStyles();
    const { avatarUrl } = props;
    const uploadLogoFileRef = React.createRef();
    const firstLetterName = "S";

    const getAvatarUrl = () => {
        if (!isNullOrEmpty(avatarUrl)) {
            let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuidv4();
            return logoUrl;
        }
        else
            return null
    }

    return (
        <Box>
            <Avatar
                onClick={() => {
                    uploadLogoFileRef.current.click();
                }}
                alt="Avatar"
                src={getAvatarUrl()}
                className="container"
            >

                <span styles={{ fontSize: 40 }}>{isNullOrEmpty(avatarUrl) ? firstLetterName.toUpperCase() : null}</span>
                <div className="overlay">
                    <EditAvatarIcon className="icon" />
                </div>
            </Avatar>
            <input className={classes.logoFileInput}
                type='file'
                accept="image/*"
                ref={uploadLogoFileRef}
                multiple={false}
                onChange={props.uploadLogoFile}
            />
        </Box>
    );
}
export default ProfileAvatar;