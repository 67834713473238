import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

/** Material UI import section */
import {
    makeStyles,
    Typography
} from '@material-ui/core';
/** Custom components import section */
import Layout from '../layout/Layout';
import Toaster from '../common/Toaster';
import Loader from '../common/Loader';
import NoDataMessage from '../common/NoDataMessage';
import { AddBtnFake } from '../common/FakeButtons';

import UsersInsupplier from './components/UsersInsupplier';

/**Import action section */
import { toSafeObject } from '../../store/helpers/ImmutableHelper';
import { loadSupplier, updateSupplierStatus } from '../../store/suppliers/SupplierActions';

/** Helper */
import LogoHelp from '../../resources/images/logoHelp.png';
import { loadUsers, addExternalUser, activeUser, filterUsers } from '../../store/users/UserActions';
//import useSort from '../../hooks/useSort';
import ProfileHeader from '../common/Profile/ProfileHeader';
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
const useSupplierProfileStyles = makeStyles(({
    root: {
        marginTop: 20,
        width: 1100,
    }
}))

const SupplierProfile = props => {
    const dispatch = useDispatch();

    const classes = useSupplierProfileStyles();
    let supplierToken = props.match.params.token;
    const [handleOpenUploadImg, setHandleOpenUploadImg] = useState(false);
    const [isLoadingSupplier, setIsLoadingSupplier] = useState(true);
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [users, setLocalUsers] = useState([]);
    const [isLoadingUsers, setIsLoadingUsersFlag] = useState(true);
    const [sortConfig, setSortConfig] = useState({ by: 'name', criteria: 'desc' })
    const [showAddExternalUserPanel, setShowAddExternalUserPanel] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagination, setPagination] = useState({ pageSize: 10, total: 0 });

    const supplierProfile = useSelector(state => toSafeObject(state.suppliers.get('supplier')));
    const isSaving = useSelector(state => state.suppliers.get('isSavingSupplier'));

    const allUsers = useSelector(state => state.users.get('allUsers'));
    //const allSortedUsers = useSort(sortConfig, allUsers.toJS());

    useEffect(() => {
        dispatch(loadSupplier(supplierToken)).finally(() => setIsLoadingSupplier(false));
    }, [supplierToken, dispatch])

    useEffect(() => {
        setIsLoadingUsersFlag(true);
        dispatch(loadUsers(null, supplierToken)).finally(() => setIsLoadingUsersFlag(false));
    }, [dispatch, props.match.url, supplierToken])

    // /** Load all initial users into Index local state */
    useEffect(() => {
       // setLocalUsers(allSortedUsers);
       setLocalUsers(allUsers.toJS());
    }, [allUsers, sortConfig])

    useEffect(() => {
        if (users.length !== pagination.total) {
            setPagination({ pageSize: 10, total: users.length });
        }
    }, [users, pagination])

    useEffect(() => {
        if (props.history.location.search) {
            const actualParameters = queryString.parse(props.history.location.search);

            let newSortConfig = {}
            if (actualParameters.orderBy) newSortConfig.by = actualParameters.orderBy;
            if (actualParameters.orderType) newSortConfig.criteria = actualParameters.orderType;
            if (sortConfig.by !== newSortConfig.by || sortConfig.criteria !== newSortConfig.criteria) {
                setSortConfig(newSortConfig);
            }
        }
    }, [dispatch, props.history.location.search, sortConfig])

    useEffect(() => {
        if (props.history.location) {
            const actualParameters = queryString.parse(props.history.location.search);
            let newPageNumber = Number(actualParameters.pageNumber ? actualParameters.pageNumber : 1)

            let newSortConfig = {}
            if (actualParameters.orderBy) newSortConfig.by = actualParameters.orderBy;
            if (actualParameters.orderType) newSortConfig.criteria = actualParameters.orderType;

            if (newPageNumber !== pageNumber || sortConfig.by !== newSortConfig.by || sortConfig.criteria !== newSortConfig.criteria) {
                setPageNumber(newPageNumber);
                setSortConfig(newSortConfig);

                dispatch(filterUsers('', newSortConfig))
                    .then(allUsers => {
                        setLocalUsers(allUsers.toJS());
                    })
                    .catch(() => {
                        setToasterOptions({ open: true, message: "Se generó un error durante la carga de los usuarios", variant: 'error' })
                    })
            }
        }
    }, [props.history.location, dispatch, pageNumber, sortConfig])


    /** Open modal for edit user avatar*/
    const onSetHandleOpenUploadImg = () => {
        setHandleOpenUploadImg(true);
    }
    /** Close modal for edit user avatar*/
    const onSetHandleCloseUploadImg = () => {
        setHandleOpenUploadImg(false);
    }

    const onAddUserClick = () => {
        setShowAddExternalUserPanel(true);
    }

    const onCreateExternalUser = user => {
        dispatch(addExternalUser(null, supplierToken, user))
            .then(all => {
                setShowAddExternalUserPanel(false);
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : "Error al agregar el usuario", variant: 'error' })
            })
    }

    const onActiveUser = (activeOrDisable, user) => {
        setSelectedUserId(user.userId)
        dispatch(activeUser(activeOrDisable, user))
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al actualizar la informacion del usuario.", variant: 'error' });
            })
    }

    const onUpdateSupplierStatus = (supplier, status) => {
        return dispatch(updateSupplierStatus(supplier, status))
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al actualizar la informacion del proveedor.", variant: 'error' });
            })
    }


    const getNoDataMessasge = (messageToAdd, iconToAdd, image) => {
        let message = (
            <Typography>
                Para agregar un nuevo {messageToAdd} da clic en el boton "Agregar" {iconToAdd} y captura los datos solicitados.
                </Typography>
        );
        return (
            <NoDataMessage
                message={message}
                image={image}
            />
        );
    }

    return (
        <Layout>
            <div className={classes.root}>
                {
                    {
                        true: <Loader />,
                        false: <ProfileHeader
                            handleOpenUploadImg={handleOpenUploadImg}
                            onSetHandleOpenUploadImg={onSetHandleOpenUploadImg}
                            onSetHandleCloseUploadImg={onSetHandleCloseUploadImg}
                            userProfile={supplierProfile}
                            onUpdateUserStatus={onUpdateSupplierStatus}
                            isSaving={isSaving}
                            fieldsInHeader={headerFields(supplierProfile)}
                        />

                    }[isLoadingSupplier]
                }
                {
                    !isLoadingSupplier && <div>
                        {
                            {
                                true: <Loader />,
                                false:
                                    <UsersInsupplier
                                        onAddUserClick={onAddUserClick}
                                        isLoadingUsers={isLoadingUsers}
                                        onClose={() => setShowAddExternalUserPanel(false)}
                                        users={users}
                                        showAddExternalUserPanel={showAddExternalUserPanel}
                                        onCreateExternalUser={onCreateExternalUser}
                                        onActiveUser={onActiveUser}
                                        selectedUserId={selectedUserId}
                                        pagination={pagination}
                                        sortConfig={sortConfig}
                                        noDataMessage={getNoDataMessasge("usuario", <AddBtnFake />, LogoHelp)}
                                        disabledAdd={supplierProfile && !supplierProfile.active}
                                    />
                            }[isLoadingUsers]
                        }
                    </div>
                }
            </div>
            {
                toasterOptions.open &&
                <Toaster
                    {...toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </Layout>
    );
}

const headerFields = (supplierModel) => {
    let configuration = supplierModel ?
        [
            {
                label: "RFC",
                propertyName: "rfc",
                value: supplierModel.rfc
            },
            {
                label: "Nombre",
                propertyName: "name",
                value: supplierModel.fullName
            },
            {
                label: "Servicios",
                propertyName: "isTransportService",
                value: supplierModel.isTransportService ? "Servicio de Transporte" : ""
            }
        ] : [];
    return configuration;
}


export default withRouter(SupplierProfile);