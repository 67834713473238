import { useState, useEffect } from 'react'

/**
 * Create  the configuration with de visible and order properties
 */
const createConfigurationWithAdditionalProps = (configuration) => {
	return configuration.map((columnConfig) => {
		return { ...columnConfig, visible: true }
	})
}

/**
 * Custom hook for datagrid configuration
 * @param {*} identifier
 * @param {*} defaultConfiguration
 */
export const useConfigurator = (identifier, defaultConfiguration) => {
	let [configuration, setConfiguration] = useState(() =>
		createConfigurationWithAdditionalProps(defaultConfiguration)
	)

	/**
	 * Get the configuration stored in the localstore
	 */
	const getStoredConfiguration = (identifier) =>
		JSON.parse(localStorage.getItem(identifier))

	/**
	 * Save the configuration in the localstore
	 */
	const saveConfiguration = (identifier, config) =>
		localStorage.setItem(identifier, JSON.stringify(config))

	/**
	 * Load the datagrid configuration initial configuration
	 */
	useEffect(() => {
		const defaultConfigurationWithProps = createConfigurationWithAdditionalProps(
			defaultConfiguration
		)

		/**
		 * Determine if there exist a datagrid identifier
		 */
		const hasIdentifier = identifier !== null && identifier !== undefined

		/**
		 * Merge the stored configuration with the default configuration
		 */
		const mergeConfiguration = (
			defaultConfiguration,
			storedConfiguration
		) => {
			const configWithRemovedMissingColumns = storedConfiguration.reduce(
				(result, storedColumn) => {
					const existingConfig = defaultConfiguration.find(
						(x) => x.title === storedColumn.title
					)
					if (existingConfig) {
						result.push({ ...storedColumn, ...existingConfig })
					}
					return result
				},
				[]
			)

			const configWithAddedColumns = defaultConfiguration.reduce(
				(result, defaultColum) => {
					const existingStoredConfig = configWithRemovedMissingColumns.find(
						(x) => x.title === defaultColum.title
					)
					if (!existingStoredConfig)
						result.push({ ...defaultColum, visible: true })
					return result
				},
				configWithRemovedMissingColumns
			)
			return configWithAddedColumns
		}

		if (!hasIdentifier) {
			// No identifier, so don't search for an stored configuration
			setConfiguration(defaultConfigurationWithProps)
		} else {
			const storedConfiguration = getStoredConfiguration(identifier)
			if (storedConfiguration) {
				// Stored configuration. Set as the actual configuration
				const mergerConfiguration = mergeConfiguration(
					defaultConfiguration,
					storedConfiguration
				)
				setConfiguration(mergerConfiguration)
				saveConfiguration(identifier, mergerConfiguration)
			} else {
				// No saved configuration. Save and set as the actual configuration
				saveConfiguration(identifier, defaultConfigurationWithProps)
				setConfiguration(defaultConfigurationWithProps)
			}
		}
	}, [identifier, defaultConfiguration])

	/**
	 * Callback for update the datagrid configuration
	 * @param {*} config
	 */
	const onConfigurationChange = (config) => {
		saveConfiguration(identifier, config)
		setConfiguration(config)
	}

	return [configuration, onConfigurationChange]
}
