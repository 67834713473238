import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

/**Import material UI sections */
import {
    Chip,
    Grid,
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';

/** Custom components import section */
import DataList from '../../common/DataList/DataList';

/** Helpers import section */
import { getStatus } from '../../layout/Notifications';

/** Resources import section */

/**
 * Users table presentional component
 */
const DocumentsTable = props => {
    const onRenderCellItem = (dataSource, item) => {
        switch (dataSource) {
            case "numberDocument":
                return (
                    <div style={{width: "20px"}}>
                        {item.numberDocument}
                    </div>
                )
            case "viewPdf":
                return (
                    <>
                        {item.extensionFile === ".pdf" && <Chip
                            icon={<VisibilityIcon style={{ color: "white" }} />}
                            id={item.token}
                            key={item.token}
                            data-cy='btn-see-pdf'
                            size="small"
                            label="Ver PDF"
                            style={{ backgroundColor: "#83588A", color: "#FFFFFF", textTransform: "uppercase", height: "28px" }}
                            onClick={onShowPdfButtonClick}
                        />}

                        {item.extensionFile !== ".pdf" && <Chip
                            icon={<VisibilityIcon style={{ color: "white" }} />}
                            size="small"
                            label="Ver FILE"
                            clickable
                            component="a"
                            target= "_blank"
                            href={`${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/Documents/eos/${item.token}${item.extensionFile}`}
                            id={item.token}
                            key={item.token}
                            style={{ backgroundColor: "#83588A", color: "#FFFFFF", textTransform: "uppercase", height: "28px" }}
                        />}
                    </> )
            case "creationDate":
                return (moment(item.creationDate).format("DD/MM/YYYY"))
            case "expirationDate":
                if (item.expirationDate) {
                return (<div style={{width: "120px"}}>{moment(item.expirationDate).format("DD/MM/YYYY")}</div>)
                }
                else {
                    return ("")
                }
            case "fileName":
                return (
                    <Grid style={{width: "250px"}} container direction="row" alignItems="center"  >
                        {getStatus(item.status)}  {item.fileName && item.fileName}
                    </Grid>
                )
            case "customerName":
                return (
                    <div style={{width: "120px"}}>
                        {item.customerName}
                    </div>
                )
            case "description":
                return(
                    <div style={{width: "200px"}}>
                        {item.description}
                    </div>
                )
            default:
                return null
        }
    }

    const onRowClick = (item, event) => {
        if(props.isValitToUpdate){
            let tagName = event.target.tagName;
            if (props.onUserRowClick && (tagName === 'TD' || tagName === "DIV")) {
                props.onUserRowClick(item)
            }
        }
    }

    const onSortingCriteriaChange = (dataSource, sortCriteria) => {
        if (props.onSortingCriteriaChange) {
            props.onSortingCriteriaChange(dataSource, sortCriteria);
        }
    }
    const onShowPdfButtonClick = event => {
        if (props.onViewPdfButtonClick)
            props.onViewPdfButtonClick(event);
    }

    return (
        <DataList
            data={props.documents}
            configuration={requestTableConfiguration}
            onRenderCellItem={onRenderCellItem}
            onRowClick={onRowClick}
            onSortingCriteriaChange={onSortingCriteriaChange}
            sortConfig={props.sortConfig}
            noDataMessage={props.noDataMessage}
        />
    )
}

DocumentsTable.propTypes = {
    documents: propTypes.array.isRequired,
    onViewPdfButtonClick: propTypes.func
}

DocumentsTable.defaultProps = {
    documents: []
}

const requestTableConfiguration = {
    columns: [{
        dataSource: "numberDocument",
        header: "NO. DOC",
        isSortable: true
    }, {
        dataSource: "fileName",
        header: "NOMBRE",
        isSortable: true
    }, {
        dataSource: "customerName",
        header: "CLIENTE",
        isSortable: true
    }, {
        dataSource: "expirationDate",
        header: "FECHA DE EXPIRACIÓN",
        isSortable: true
    }, {
        dataSource: "description",
        header: "DESCRIPCIÓN",
        isSortable: false

    }, {
        dataSource: "viewPdf",
        isSortable: false
    }]

}

export default DocumentsTable