import React, { useState } from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import {
	makeStyles,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

/** Custom components import section */
import DateSelector from '../../common/DateSelector'

/** Resources import section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
import {
	COLOR_SUCCESS_NORMAL,
	COLOR_DANGER_NORMAL,
	COLOR_WHITE,
	COLOR_PRIMARY_NORMAL,
	COLOR_SECONDARY_NORMAL
} from '../../../resources/constants/Colors'

/**
 * Presentional component to show a modal confirmation.
 * @param {object} props
 */
const RecollectionDialog = ({
	open = false,
	isSaving = false,
	onSave = () => console.warn('[onSave] not defined!'),
	onClose = () => console.warn('[onClose] not defined!'),
	recollection
}) => {
	/** Define local state */
	const [requestData, setRequestData] = useState(recollection)

	const classes = useStyles()

	/**
	 * Handle change of the form inputs
	 * @param {*} event
	 */
	const onInputDateChange = (event) => {
		const { value, name } = event.target
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	/**
	 * Determine if the accepted button can be pressed
	 */
	const canBeAccepted = () => {
		if (!requestData || isNullOrEmpty(requestData.recollectionDate)) {
			return false
		}
		return true
	}

	/**
	 * Hanlde click event from accept button
	 */
	const onAcceptClick = () => {
		if (onSave) {
			onSave(requestData)
		}
	}
	/**
	 * Render the component content
	 */
	return (
		<Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
			<DialogTitle disableTypography className={classes.root}>
				<Typography variant='button' className={classes.title}>
					<strong>{'ACTUALIZAR FECHA DE RECOLECCIÓN'}</strong>
				</Typography>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div>
					<DateSelector
						dataCy='date-picker-collection-date'
						name={'recollectionDate'}
						label={'Fecha de recolección'}
						onChange={onInputDateChange}
						value={
							requestData ? requestData.recollectionDate : null
						}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<div className={classes.wrapper}>
					<Button
						data-cy='btn-accept'
						className={classes.buttonAccept}
						variant='contained'
						onClick={onAcceptClick}
						disabled={!canBeAccepted() || isSaving}
					>
						{'Actualizar'}
					</Button>
					{isSaving && (
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
							disableShrink
						/>
					)}
				</div>
			</DialogActions>
		</Dialog>
	)
}

RecollectionDialog.propsTypes = {
	open: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isSaving: PropTypes.bool
}

const useStyles = makeStyles((theme) => ({
	title: {
		color: COLOR_PRIMARY_NORMAL
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonAccept: {
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: COLOR_WHITE,
		right: '10px',
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	buttonRejected: {
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_DANGER_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	buttonProgress: {
		color: COLOR_SECONDARY_NORMAL,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	closeButton: {
		position: 'absolute',
		right: '22px',
		top: theme.spacing(1),
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL
		},
		width: '37px',
		height: '36px',
		paddingTop: 8
	},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {}
	},
	cssFocused: {},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: `${COLOR_PRIMARY_NORMAL} !important`,
		color: `${COLOR_PRIMARY_NORMAL} !important`
	},
	colorWhithe: {
		color: 'white'
	}
}))

export default RecollectionDialog
