import React from 'react';
import propTypes from 'prop-types';

/**Import material UI sections */
import {
    Chip,
    Typography,
    Fab,
    makeStyles
} from '@material-ui/core';
import ActiveSupplierIcon from '@material-ui/icons/Check';
import InactiveSupplierIcon from '@material-ui/icons/Block';

/** Custom components import section */
import DataList from '../../common/DataList/DataList';
/** Resources import section */
import { getSupplierTypeDecription } from '../../../store/helpers/SupplierHelper';

/**
 * Suppliers table presentional component
 */
const SuppliersTable = props => {
    const classes = useStyles();

    const onRenderCellItem = (dataSource, item) => {
        switch (dataSource) {
            case "name":
                return <Typography variant="overline">{item.name}</Typography>
            case "userName":
                return <Typography variant="overline">{item.userName}</Typography>
            case "type":
                return <Typography variant="overline">{getSupplierTypeDecription(item.type)}</Typography>
            case "active":
                return <Fab
                    size="small"
                    disabled
                    className={item.active ? classes.button : classes.inactiveButton}
                >
                    {item.active ?
                        <ActiveSupplierIcon className={classes.activeIcon} />
                        : <InactiveSupplierIcon className={classes.activeIcon} />
                    }
                </Fab>
            case "options":
                return (
                    item.active ?
                        <Chip
                            id={item.token}
                            size="small"
                            label="Ver usuarios"
                            style={{ backgroundColor: "#83588A", color: "#FFFFFF", textTransform: "uppercase" }}
                            onClick={onShowUsersButtonClick}
                        />
                        :
                        <Chip
                            disabled
                            size="small"
                            label="Ver usuarios"
                            style={{ color: "#FFFFFF", textTransform: "uppercase" }}
                        />
                );
            default:
                return null
        }
    }

    const onRowClick = (item, event) => {
        let tagName = event.target.tagName;
        if (tagName !== 'INPUT' && props.onSupplierRowClick) {
            props.onSupplierRowClick(item)
        }
    }

    const onShowUsersButtonClick = event => {
        let cusotomerId = event.currentTarget.id;
        if (props.onShowCustomerUsersButtonClick) {
            props.onShowCustomerUsersButtonClick(cusotomerId);
        }
    }

    const onSortingCriteriaChange = (dataSource, sortCriteria) => {
        if (props.onSortingCriteriaChange) {
            props.onSortingCriteriaChange(dataSource, sortCriteria);
        }
    }

    return (
        <DataList
            data={props.suppliers}
            configuration={suppliersTableConfiguration}
            onRenderCellItem={onRenderCellItem}
            onRowClick={onRowClick}
            onSortingCriteriaChange={onSortingCriteriaChange}
            sortConfig={props.sortConfig}
            noDataMessage={props.noDataMessage}
        />
    )
}

SuppliersTable.propTypes = {
    customers: propTypes.array.isRequired,
    onUserClick: propTypes.func
}

SuppliersTable.defaultProps = {
    customers: []
}

const suppliersTableConfiguration = {
    columns: [{
        dataSource: "code",
        header: "Código",
        isSortable: true
    }, {
        dataSource: "name",
        header: "Nombre",
        isSortable: true
    }, {
        dataSource: "active",
        header: "Estatus",
    }
    ]
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 35,
        height: 35,
        backgroundColor: " #3AC47D",
        "&.Mui-disabled": {
            backgroundColor: " #3AC47D",
        }
    },
    inactiveButton: {
        width: 35,
        height: 35,
        backgroundColor: "#D92550",
        "&.Mui-disabled": {
            backgroundColor: "#D92550",
        },
    },
    activeIcon: {
        color: "white"
    },
}))

export default SuppliersTable