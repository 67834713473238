import axios from 'axios';

/**Import helper section */
import { getAuthorizedRequestConfigurationUser } from '../helpers/RequestHelper';

export const IS_LOADING_DOCUMENTS = "IS_LOADING_DOCUMENTS";
export const SET_ALL_DOCUMENTS = "SET_ALL_DOCUMENTS";
export const SET_DOCUMENTS_REPORT = "SET_DOCUMENTS_REPORT";
export const ADD_FILE_DOCUMENT = "ADD_FILE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const IS_SAVING_DOCUMENTS = "IS_SAVING_DOCUMENTS";

export const setAllDocuments = documents => {
    return {
        type: SET_ALL_DOCUMENTS,
        documents
    }
}

const setIsLoadingDocuments = status => {
    return {
        type: IS_LOADING_DOCUMENTS,
        status
    }
}

export const loadAllDocuments = (customerToken) => {
    return function (dispatch, getState) {
        dispatch(setIsLoadingDocuments(true));
        let user = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(user)
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/all`;
        let command = {
            CustomerToken: customerToken,
        }
        return axios.post(endPoint, command, config)
            .then(documentsResponse => {
                let allDocuments = documentsResponse.data;
                dispatch(setAllDocuments(allDocuments));
            })
            .catch((error) => {
                dispatch(setIsLoadingDocuments(false));
                console.error("Error loading all EOS documents", error)
                return Promise.reject();
            });
    }
}

const setIsSavingDocuments = status => {
    return {
        type: IS_SAVING_DOCUMENTS,
        status
    }
}


const addDocument = attahcment => {
    return {
        type: ADD_FILE_DOCUMENT,
        attahcment
    }
}
export const uploadEosDocuments = (files, docsData) => {
    return function (dispatch, getState) {
        dispatch(setIsSavingDocuments(true))
        let loggedUser = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(loggedUser);
        let command = {
            AttachmentType: 'EOS',
            ExpirationDate: docsData.expirationDate,
            NotificationDays: docsData.notificationDays,
            NumberDocument : docsData.numberDocument,
            CustomerToken: docsData.customer ? docsData.customer.value : "",
        };
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents`;
        let data = new FormData()
        data.append('files', files)
        data.append('command', JSON.stringify(command));
        for (var index = 0; index < files.length; index++) {
            data.append('FileNames', files[index].attachment.name);
            data.append('file', files[index].attachment);
        }
        return axios.post(endPoint, data, config)
            .then(response => {
                if(response.status === 200){
                    response.data.forEach(document => {
                        dispatch(addDocument(document))
                    });
                }
                return Promise.resolve();
            })
            .catch(error => {
                dispatch(setIsSavingDocuments(false));
                console.error('Error uploading EOS documents', error);
                return Promise.reject();
            });
    }
}

export function downloadEosPdf(documentToken) {
    return function (dispatch) {
        dispatch(setIsSavingDocuments(true));
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/${documentToken}/download`;
        return axios.post(endPoint, null, { responseType: 'arraybuffer' })
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    dispatch(setIsSavingDocuments(false));
                    var file = new Blob([serverResponse.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            })
            .catch((error) => {
                dispatch(setIsSavingDocuments(false));
                console.error("Error to download EOS document", error);
                return Promise.reject();
            })
    }
}

export function downloadSupplierPdf(documentToken, supplierToken) {
    return function (dispatch, getState) {
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/${documentToken}/download/${supplierToken}`;
        return axios.get(endPoint, null, { responseType: 'arraybuffer' })
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    var file = new Blob([serverResponse.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            })
            .catch((error) => {
                console.error("Error to download template for sales amounts", error);
                return Promise.reject();
            })
    }
}

const updateDocument = doc => {
    return {
        type: UPDATE_DOCUMENT,
        doc
    }
}

export const updateEosDocument = (files, documentModel) => {
    return function (dispatch, getState) {
        dispatch(setIsSavingDocuments(true));
        let loggedUser = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(loggedUser);
        let command = {
            AttachmentToken: documentModel.token,
            AttachmentType: 'EOS',
            Description: documentModel.description,
            FileName: documentModel.fileName,
            ExpirationDate: documentModel.expirationDate,
            NotificationDays: documentModel.notificationDays,
            NumberDocument : documentModel.numberDocument,
            CustomerToken: documentModel.customer ? documentModel.customer.value : "",
        };
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents`;
        let data = new FormData()
        data.append('files', files)
        data.append('command', JSON.stringify(command));
        for (var index = 0; index < files.length; index++) {
            data.append('file', files[index].attachment);
        }
        return axios.put(endPoint, data, config)
            .then(response => {
                let properties = response.data;
                dispatch(updateDocument(properties))
            })
            .catch(error => {
                dispatch(setIsSavingDocuments(false));
                console.error('Error added file for supplier', error);
                return Promise.reject();
            });
    }
}

const setDocumentsReport = documents => {
    return {
        type: SET_DOCUMENTS_REPORT,
        documents
    }
}

export const loadDocumentsReport = () => {
    return function (dispatch, getState) {
        let user = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(user)
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/report`;
        let allDocuments;
        return axios.get(endPoint, config)
            .then(reportResponse => {
                allDocuments = reportResponse.data;
                let allUrls = allDocuments.filter(d => d.supplier !== null).map(d => `${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${d.supplier.token}`);
                return axios.all(allUrls.map(url => axios.get(url)));
            })
            .then((allResponses) => {
                let allSuppliers = allResponses.map(r => r.data);
                allSuppliers.forEach(supplier => {
                    let foundDoc = allDocuments.find(s => s.supplier !== null && s.supplier.token === supplier.token);
                    if (foundDoc) {
                        foundDoc.supplier = supplier;
                    }
                })
                dispatch(setDocumentsReport(allDocuments));
            })
            .catch((error) => {
                console.error("Error loading documents report", error)
                return Promise.reject();
            });
    }
}

const removeDocument = (documentToken) => {
    return {
        type: REMOVE_DOCUMENT,
        documentToken
    }
}

export const removeDocumentInBackend = (documentModel) => {
    return function (dispatch, getState) {
        dispatch(setIsSavingDocuments(true));
        let loggedUser = getState().oidc.user;
        let config = getAuthorizedRequestConfigurationUser(loggedUser);
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}documents/delete/${documentModel.token}`;

        return axios.delete(endPoint, config)
            .then(response => {
                dispatch(removeDocument(documentModel.token));
            })
            .catch(error => {
                dispatch(setIsSavingDocuments(false));
                console.error('Error removing file eos', error);
                return Promise.reject();
            });
    }
}