import React from 'react'
import classnames from 'classnames'

/** Import components */
import { Button, Typography, makeStyles } from '@material-ui/core'
import unauthorized from '../../resources/images/unauthorized.png'
import { executeLogout } from '../../store/session/SessionActions';
import userManager from '../../store/helpers/UserManager'
/** Import resources */
import logo from '../../resources/images/logoEOS.png'
import { useDispatch } from 'react-redux';
import Header from '../common/ApplicationHeader'

/**
 * Unauthorized view
 */
const UnauthorizedView = (props) => {
	const classes = unauthorizedUseStyles()
	const dispatch = useDispatch();

	/** Close session*/
	const onLogout = () => {
		dispatch(executeLogout())
		userManager.signoutRedirect()
	}

	return (
		<div className={classes.rootBackgroundImage}>
			<div className={classes.header}>
				<Header
					leftToolbar={
						<div className={classes.toolBar}>
							<img
								src={logo}
								className={classes.appLogo}
								alt='isp-logo'
							/>
							<Typography
								color='secondary'
								variant='overline'
								className={classes.unauthorizedMessage}
							>
								Unauthorized access
							</Typography>
						</div>
					}
					rightToolbar={
						<Button
							color='secondary'
							variant='outlined'
							onClick={onLogout}
						>
							Logout
						</Button>
					}
				/>
			</div>
			<div className={classnames(classes.content, classes.root)}>
				<div className={classes.leftPanel}>
					<Typography color='secondary' variant='h2' gutterBottom style = {{marginRight:"200px"}}>
						Access denied
					</Typography>
					<Typography color='primary' variant='subtitle1' gutterBottom style = {{marginRight:"200px"}}>
						You don't have permission to access this resource.
					</Typography>
					<Typography color='primary' variant='subtitle1' gutterBottom style = {{marginRight:"200px"}}>
						Please feel free to give us a call if you have any
						questions.
					</Typography>
					<Button color='secondary' variant='contained' onClick={onLogout} style = {{marginRight:"200px"}}>
						Change user's credentials
						</Button>
				</div>
			</div>
		</div>
	)
}

const unauthorizedUseStyles = makeStyles((theme) => ({
	rootBackgroundImage: {
		height: '100vh',
		display: 'grid',
		gridTemplateRows: '64px auto',
		backgroundColor: "white",
		marginRight: "-10px",
		marginLeft: "-10px",
		overflowY: "hidden",
		overflowX: "hidden"
	},
	root: {
		backgroundColor: 'white',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${unauthorized})`,
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		minHeight: `calc(100vh - ${70}px)`,

	},
	toolBar: {
		display: 'flex',
		alignItems: 'start'
	},
	leftPanel: {
		// marginRight: '-80px',
		gridColumn: '1 / 2',
		textAlign: 'center',
		color: theme.palette.common.white,
		alignItems:"center",
		alignSelf:"center",
		padding:"30px"
	},
	unauthorizedMessage: {
		// marginLeft: '20px'
	},
	header: {
		gridRow: '1/2',
	},
	content: {
		gridRow: '2/3',
	},
	appLogo: {
		height: 40
	}
}))
export default UnauthorizedView
