/** Import ReactJS */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
/**select custom */
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

/**Impor actions section */
import { loadAllSuppliers } from '../../../store/suppliers/SupplierActions';
/**Impor material UI section */
const animatedComponents = makeAnimated();

const SelectSuppliers = props => {
    // Define local state
    const [isLoading, setIsLoading] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    // Connect with store
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAllSuppliers())
            .then(allSuppliers => {
                let options = allSuppliers.map(supplier => ({
                    value: supplier.token,
                    label: supplier.name,
                }));
                setSuppliers(options)
                setIsLoading(false);
            })
    }, [dispatch])

    const onSelectSupplier = (supplier) => {
        if (props.onChange) {
            props.onChange(supplier);
        }
    }

    return (
        <Select
            id='sel-carrier'
            placeholder={"Seleccionar transportista"}
            components={animatedComponents}
            options={suppliers}
            isLoading={isLoading}
            onChange={onSelectSupplier}
            value={props.supplier}
            menuPortalTarget={document.parentNode}
            menuPosition="fixed"
            menuPlacement={'bottom'}
            styles={colourStyles}
        />
    );
}

const colourStyles = {
    menu: (base) => ({ ...base, zIndex: 62 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
    menuList: (base) => ({ ...base, paddingTop: 0 }),
};


SelectSuppliers.propTypes = {
    onChange: PropTypes.func,
    supplier: PropTypes.object,
}

export default SelectSuppliers;