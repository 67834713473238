import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'

import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

/** Material UI import section */
import { Typography, makeStyles, Box, Avatar, CardActions } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleAlt';

/** Custom components import section */
import Layout from '../layout/Layout';
import AddItemButton from '../common/AddItemButton';
import Loader from '../common/Loader';
import Toaster from '../common/Toaster';
import AddInternalUserPanel from './components/AddInternalUserPanel';
import AddExternalUserPanel from './components/AddExternalUserPanel';
import UsersTable from './components/UsersTable';
import MemoryPager from '../common/DataList/MemoryPager';
import NoDataMessage from '../common/NoDataMessage';
import { AddBtnFake } from '../common/FakeButtons';

/**Import resources section */
import NoUsersImage from '../../resources/images/noSuppliers.png';
import NoResultsFound from '../../resources/images/searchEmpty.png';
/** Actions import section */
import {
    loadUsers,
    addInternalUser,
    addExternalUser,
    activeUser,
    setInternalQueryParameters
} from '../../store/users/UserActions';

import {
    internalUsersSelector,
} from '../../store/users/UsersSelectors'

/** Helpers impor section */
import { COLOR_SECONDARY_NORMAL, COLOR_LIGHT_GRAY } from '../../resources/constants/Colors';
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import { UserTypes } from '../../resources/constants/AppConstants';

const useUserStyles = makeStyles(({
    root: {
        marginTop: 10,
        minWidth: "90vw",
    }
}))

const Index = props => {
    const classes = useUserStyles();
    let location = useLocation()
    let history = useHistory()
    
    const internalUser = useSelector(internalUsersSelector)
    
    const getCustomerToken = () => props.match.params.token;
    //Configuration pagination table
    const [pageNumber, setPageNumber] = useState(1);
    const [pagination, setPagination] = useState({ pageSize: 10, total: 0 });

    const [isLoadingUsers, setIsLoadingUsersFlag] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [users, setLocarUsers] = useState([]);

    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [showAddInternalUserPanel, setShowAddInternalUserPanel] = useState(false);
    const [showAddExternalUserPanel, setShowAddExternalUserPanel] = useState(false);
    const [sortConfig, setSortConfig] = useState({ by: 'name', criteria: 'desc' })
    const [query, setQuery] = useState(null);
    const [searchBoxActive, setSearchBoxActive] = useState(false);
    const dispatch = useDispatch();
    const isSavingUser = useSelector(state => state.users.get('isSavingUser'));
    const isFindingUser = useSelector(state => state.users.get('isFindingUser'));
    const isLoadingActiveUser = useSelector(state => state.users.get('isLoadingActiveUser'))
    const allUsers= useSelector(state => state.users.get('allUsers'));

    useEffect(() => {
        setIsLoadingUsersFlag(true);
        const queryParameters = queryString.parse(location.search)
        if (!!queryParameters.pageNumber) {
            setPageNumber(queryParameters.pageNumber)
        }
        let newQuery = queryParameters.query ? queryParameters.query : '';
        if (!!newQuery) {
            setQuery(newQuery);
            setSearchBoxActive(true);
        }

        let newSortConfig = {}
        if (!!queryParameters.sortBy) newSortConfig.by = queryParameters.sortBy;
        if (!!queryParameters.sortDirection) newSortConfig.criteria = queryParameters.sortDirection;
        setSortConfig(newSortConfig)
        dispatch(loadUsers(props.match.params.token, null, queryParameters)).finally(() => setIsLoadingUsersFlag(false));
    }, [dispatch, props.match.params.token, location.search])

    useEffect(() => {
        let usersPerPage = 10;
        let allTotal = internalUser.length;
        const indexOfLastUser = pageNumber * usersPerPage;
        let indexOfFirtsUser = indexOfLastUser - usersPerPage;
        let currentUsers = internalUser.slice(indexOfFirtsUser, indexOfLastUser)
        setLocarUsers(currentUsers)
        setPagination({ pageSize: usersPerPage, total: allTotal });
    }, [internalUser, pagination.total, pageNumber])



    const areCustomerUsers = () => !isNullOrEmpty(getCustomerToken());

    const getUsersType = () => isNullOrEmpty(getCustomerToken()) ? UserTypes.ACTIVE_DIRECTORY : UserTypes.NON_ACTIVE_DIRECTORY;

    const onAddUserClick = () => {
        isNullOrEmpty(getCustomerToken()) ? setShowAddInternalUserPanel(true) : setShowAddExternalUserPanel(true);
    }

    const onCreateInternalUser = user => {
        dispatch(addInternalUser(user))
            // .then(() => {
            //     return dispatch(filterUsers(query, sortConfig));
            // })
            .then(allUsers => {
                // setLocalUsers(allUsers.toJS());
                setShowAddInternalUserPanel(false);
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al agregar el usuario", variant: 'error' });
            })
    }

    const onCreateExternalUser = user => {
        let customerToken = getCustomerToken();
        dispatch(addExternalUser(customerToken, null, user))
            // .then(() => {
            //     return dispatch(filterUsers(query, sortConfig));
            // })
            .then(allUsers => {
                // setLocalUsers(allUsers.toJS());
                setShowAddExternalUserPanel(false);
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : "Error al agregar el usuario", variant: 'error' })
            })
    }

    const onActiveUser = (activeOrDisable, user) => {
        setSelectedUserId(user.userId)
        dispatch(activeUser(activeOrDisable, user))
            .then(() => {
                setToasterOptions({ open: true, message: "Información actualizada correctamente", variant: 'success' });
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "Error al actualizar la informacion del usuario.", variant: 'error' });
            })
    }

    const onUserClick = user => {
        props.history.push(`/users/${user.id}`);
    }

    /**
 * Update sourt criteria
 */
    const onSortingCriteriaChange = (sortBy, sortCriteria) => {
        const queryParams = queryString.parse(location.search)
        queryParams.sortBy = sortBy
        queryParams.sortDirection = sortCriteria
        history.push({ search: queryString.stringify(queryParams) })
        dispatch(setInternalQueryParameters(queryParams))
    }

    const onSearchBoxChange = (searchQuery) => {
        const queryParams = queryString.parse(location.search)
        if (searchQuery) {
            queryParams.query = searchQuery
            setQuery(searchQuery);
        }
        else delete queryParams.query
        history.push({ search: queryString.stringify(queryParams) })
        dispatch(setInternalQueryParameters(queryParams))
    }

    /**
 * On change page number
 */
    const onChangePageNumber = (page) => {
        setPageNumber(page)
        const queryParams = queryString.parse(location.search)
        queryParams.pageNumber = page
        history.push({ search: queryString.stringify(queryParams) })
    }

    const onToggleSearchStatus = active => {
        setSearchBoxActive(active);
    }

    const getNoDataMessasge = () => {
        let message, image;
        if (allUsers.size > 0 && internalUser.length === 0) {
            message = (
                <Typography>
                    No se encontraron resultados para esta búsqueda.
                </Typography>);
            image = NoResultsFound;
        }
        else {
            message = (
                <Typography>
                    Para agregar un nuevo usuario da click en el boton "Agregar" <AddBtnFake /> y captura los datos solicitados.
                </Typography>
            );
            image = NoUsersImage;
        }
        return (
            <NoDataMessage
                message={message}
                image={image}
            />
        );
    }


    return (
        <Layout
            searchBoxOptions={{
                enabled: true,
                placeholder: "Buscar usuarios",
                value: query,
                onChange: onSearchBoxChange,
                active: searchBoxActive,
                onToggleStatus: onToggleSearchStatus
            }}
        >
            <div className={classes.root}>
                {/** Users header */}
                <UsersHeader
                    onAddUserButtonClick={onAddUserClick}
                    areCustomerUsers={areCustomerUsers()}
                />
                {/** Users table */}
                {
                    {
                        true: <Loader />,
                        false: <UsersTable
                            users={users}
                            usersType={getUsersType()}
                            selectedUserId={selectedUserId}
                            onActiveUser={onActiveUser}
                            onUserRowClick={onUserClick}
                            isLoadingActiveUser={isLoadingActiveUser}
                            sortConfig={sortConfig}
                            onSortingCriteriaChange={onSortingCriteriaChange}
                            noDataMessage={getNoDataMessasge}
                        />
                    }[isLoadingUsers]
                }
                <CardActions>
                    <MemoryPager
                        pageNumber={pageNumber - 1}
                        pageSize={pagination.pageSize}
                        count={pagination.total}
                        onChangePage={(event, page) => onChangePageNumber(Number(page + 1))}
                    />
                </CardActions>
            </div>
            {/** Add internal user panel */}
            {
                showAddInternalUserPanel &&
                <AddInternalUserPanel
                    open={showAddInternalUserPanel}
                    isSaving={isSavingUser}
                    isFindingUser={isFindingUser}
                    onClose={() => setShowAddInternalUserPanel(false)}
                    onCreateUser={onCreateInternalUser}
                />
            }
            {/** Add external user panel */}
            {
                showAddExternalUserPanel &&
                <AddExternalUserPanel
                    open={showAddExternalUserPanel}
                    isSaving={isSavingUser}
                    isFindingUser={isFindingUser}
                    onClose={() => setShowAddExternalUserPanel(false)}
                    onCreateUser={onCreateExternalUser}
                />
            }


            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    {...toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </Layout>
    );
}

export default withRouter(Index);

const useHeaderStyles = makeStyles(({
    root: {
        marginTop: -20,
        marginBottom: 0
    },
    icon: {
        // marginRight: 10
        color: COLOR_SECONDARY_NORMAL
    },
    avatar: {
        marginTop: 8,
    },
    buttonAddUser: {
        marginTop: 3,
    },
    titleUser: {
        marginTop: 6,
        marginLeft: 8,
        color: COLOR_SECONDARY_NORMAL
    }
}))

const UsersHeader = props => {
    const classes = useHeaderStyles();
    const { areCustomerUsers } = props;
    return (
        <Box
            className={classes.root}
            display="flex"
            p={1}
            alignItems="center"
            justifyContent="flex-start"
        >
            <Box
                className={classes.avatar}
                display="flex"
                width="100%"
            >
                <Avatar style={{ color: COLOR_SECONDARY_NORMAL, backgroundColor: COLOR_LIGHT_GRAY, fontSize: "14px", height: "35px", width: "35px" }}><PeopleIcon className={classes.icon} /></Avatar>
                <Typography variant="button" className={classes.titleUser}>
                    <strong>{areCustomerUsers ? 'CLIENTES /' : ''} USUARIOS</strong>
                </Typography>
            </Box>
            <Box
                className={classes.buttonAddUser}
                p={1}
                flexShrink={0}
            >
                <AddItemButton
                    title={"Agregar usuario"}
                    onClick={props.onAddUserButtonClick}
                />
            </Box>
        </Box>
    );
}