import { 
    AppRoles,
    UserTypes
} from '../../resources/constants/AppConstants';

import {
    COLOR_WARNING_NORMAL, 
    COLOR_INFO_NORMAL
} from '../../resources/constants/Colors';

/**
 * Method to get all available roles for internal users
 */
export const getAllRoles = () => {
    return allRoles;
}

/**
 * Method to get all available roles for internal users
 */
export const getRolesForInternalUsers = () => {
    return allRoles.filter(role => role.type === UserTypes.ACTIVE_DIRECTORY);
}

/**
 * Method to get all available roles for external users
 */
export const getRolesForExternalUsers = () => {
    return allRoles.filter(role => role.type === UserTypes.NON_ACTIVE_DIRECTORY);
}

/**
 * Method to get all available roles by user type
 */
export const getAvailableRolesByUserType = userType => {
    return allRoles.filter(role => role.type === userType);
}

const allRoles = [
    { 
        key: `${AppRoles.ADMINISTRATOR}`, 
        type: UserTypes.ACTIVE_DIRECTORY,
        color: COLOR_WARNING_NORMAL,
        label: "Administrador de la plataforma" ,
        description: "Podrá realizar actividades de administración sobre los usuarios y los clientes que acceden a la plataforma."
    },
    { 
        key: `${AppRoles.SALES_MANAGER}`, 
        type: UserTypes.ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Administrador de ventas" ,
        description: "Podrá recibir las solicitudes de servicio de los clientes, así como realizar su seguimiento hasta la entrega final."
    },
    { 
        key: `${AppRoles.ROLE_SERVICE_INTERNAL}`, 
        type: UserTypes.ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Solicitante de Servicios internos" ,
        description: "Podrá solicitar servicios de otros clientes como usuario interno, así como su seguimiento."
    },
    { 
        key: `${AppRoles.ROLE_DOCUMENTS_OPERATIVES}`, 
        type: UserTypes.ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Documentos operativos" ,
        description: "Podrá administrar los documentos operativos asi como agregar, editar y eliminar."
    },
    { 
        key: `${AppRoles.CUSTOMER_SERVICE_APPLICANT}`, 
        type: UserTypes.NON_ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Solicitante de Servicios" ,
        description: "Podrá realizar actividades de servicios de enfriadores."
    },
    { 
        key: `${AppRoles.CUSTOMER_VISUALIZATOR}`, 
        type: UserTypes.NON_ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Visualizador de Servicios" ,
        description: "Podrá visualizar todos los servicios de enfriadores."
    },
    { 
        key: `${AppRoles.CUSTOMER_VISUALIZATOR_NOTIFICATOR}`, 
        type: UserTypes.NON_ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Visualizador de Servicios / Envio de notificación de recolección" ,
        description: "Recibirá correo informativo con la fecha programada para recolectar y podrá visualizar todos los servicios."
    },
    { 
        key: `${AppRoles.CUSTOMER_GOVERNMENT}`, 
        type: UserTypes.NON_ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: " Gubernamental / Abastecimientos " ,
        description: "Podrá visualizar los listados de documentos oficiales"
    },
    { 
        key: `${AppRoles.CARRIER}`, 
        type: UserTypes.NON_ACTIVE_DIRECTORY,
        color: COLOR_INFO_NORMAL,
        label: "Transportista" ,
        description: "Podrá realizar actividades de transportista"
    },
];
