import React, { Fragment, useState, useEffect } from 'react'
import { v4 as uuid4 } from 'uuid'
import propTypes from 'prop-types'

/** Material UI import section */
import {
	Button,
	Popper,
	ClickAwayListener,
	Paper,
	Checkbox,
	Typography,
	Chip,
	InputBase,
	Box,
	Grid
} from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/ArrowDropDown'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import RequestIcon from '@material-ui/icons/DescriptionOutlined'

/** Custom compoents import section */
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import RequestIndexHeader from './RequestIndexHeader'
import Loader from '../../common/Loader'
import DispositionRequestBudget from '../../common/DispositionRequestBudget'

/** Resources import section */
import headersSbaiConfig from '../resources/headersSbaiConfig.json'
import {
	getDispositionRequestStatusDescription,
	getStatusRequest
} from '../../../store/helpers/StatusHelper'
import { useRequestsToolbar } from '../resources/useStyles'
import { RequestStatus } from '../../../store/helpers/StatusHelper'
import dispositionRequestUtils from '../../../utils/dispositionRequests/requests.utils'

/**
 * The disposition requests index view
 */
const RequestIndex = (props) => {
	const toolbarClasses = useRequestsToolbar()
	const {
		configuration,
		setConfigurationAnchorEl,
		configuratorAnchorEl,
		identifierRow,
		onDownloadStatusReport
	} = props

	/**
	 * config header property
	 */
	const getHeaderConfiguration = () => {
		let statusProperty = configuration.find((x) => x.dataSource === 'estatus')
		statusProperty.onRenderProperty = (item) => {
			return (
				<Grid
					container
					direction='row'
					alignItems='center'
					className={toolbarClasses.iconSize}
				>
					<RequestIcon fontSize='large' className={colorIconEos(item.estatus)} />
					{
						{
							true: (
								<DispositionRequestBudget badgeContent='CRM' color='primary'>
									{getDispositionRequestStatusDescription(item.estatus)}
								</DispositionRequestBudget>
							),
							false: getDispositionRequestStatusDescription(item.estatus)
						}[dispositionRequestUtils.comesFromCrm(item)]
					}
				</Grid>
			)
		}
		return configuration
	}

	const colorIconEos = (status) => {
		switch (status) {
			case RequestStatus.REQUESTED:
				return toolbarClasses.colorIconRequestInit
			case RequestStatus.COMPLETED:
				return toolbarClasses.colorIconRequestCompleted
			case RequestStatus.LIBERATED:
				return toolbarClasses.colorIconRequestLibered
			case RequestStatus.CANCEL:
				return toolbarClasses.colorIconRequestCancel
			default:
				return toolbarClasses.colorIconRequestInProcess
		}
	}

	const getHeaderConfigSbai = () => {
		let optionProperty = headersSbaiConfig.find((x) => x.dataSource === 'options')
		optionProperty.onRenderProperty = (item) => {
			return (
				<Chip
					component='a'
					disabled={props.isDownloadingSbai && props.folioDocumentSap === item.sbai}
					icon={<CloudDownloadIcon className={toolbarClasses.colorWhithe} />}
					id={item.token}
					data-cy='btn-see-doc'
					size='small'
					label='Descargar reporte de sbai'
					className={toolbarClasses.btnDownloadReport}
					target='_blank'
					onClick={() => props.downloadReportbySbais(item.sbai, item.customerToken)}
				/>
			)
		}

		return headersSbaiConfig
	}

	return (
		<Fragment>
			<Box className={toolbarClasses.root} alignItems='center' justifyContent='flex-start'>
				<Typography variant='button'>
					{<strong>SOLICITUDES DE DISPOSICIÓN FINAL</strong>}
				</Typography>
			</Box>

			<div className={toolbarClasses.root}>
				{props.gridMode && (
					<StatusFilter
						status={getStatusRequest()}
						onChangeSelectedStatus={props.onChangeSelectedStatus}
					/>
				)}

				{props.isRoleAdmin && (
					<CustomerFilter
						customers={props.customers}
						onChangeSelectedCustomers={props.onChangeSelectedCustomers}
					/>
				)}

				<div className={toolbarClasses.grow}></div>
				<SearchRequest
					classInput={toolbarClasses.inputSearch}
					onSearchRequest={props.onSearchRequest}
					value={props.queryValue}
				/>

				{props.isDisabledSBAI && (
					<Box className={toolbarClasses.buttonAddRequets} p={1} flexShrink={0}>
						<RequestIndexHeader
							isValitToAddRequest={props.isValitToAddRequest}
							isValidToAddInternal={props.isValidToAddInternal}
							title={'Agregar solicitud'}
							onClick={() => props.onAddButtonClick(true)}
							switchViewMode={props.switchViewMode}
							gridMode={props.gridMode}
							setConfigurationAnchorEl={setConfigurationAnchorEl}
							configuratorAnchorEl={configuratorAnchorEl}
							onDownloadReport={() => props.onDownloadReportClick(true)}
							btnDownloadStatusReport={{
								displayed: props.isRoleAdmin,
								onClick: onDownloadStatusReport
							}}
						/>
					</Box>
				)}
			</div>

			<div className={toolbarClasses.indexList}>
				{props.gridMode && (
					<>
						{
							{
								true: <Loader />,
								false: (
									<DataGrid
										headers={getHeaderConfiguration()}
										data={props.requests}
										onChangeSortingCriteria={props.onChangeSortingCriteria}
										onItemClick={props.onRequestClick}
										sortBy={props.sortConfig ? props.sortConfig.by : ''}
										sortDirection={
											props.sortConfig ? props.sortConfig.criteria : ''
										}
										identifierRow={identifierRow}
									/>
								)
							}[props.isLoadingRequest]
						}
					</>
				)}

				{!props.gridMode && (
					<>
						{
							{
								true: <Loader />,
								false: (
									<DataGrid
										headers={getHeaderConfigSbai()}
										data={props.allSbais}
										onChangeSortingCriteria={props.onChangeSortingCriteria}
										sortBy={props.sortConfig ? props.sortConfig.by : ''}
										sortDirection={
											props.sortConfig ? props.sortConfig.criteria : ''
										}
										identifierRow={identifierRow}
									/>
								)
							}[props.isLoadingSbais]
						}
					</>
				)}
			</div>
		</Fragment>
	)
}

RequestIndex.propTypes = {
	/**
	 * The list of disposition request
	 */
	requests: propTypes.arrayOf(
		propTypes.shape({
			token: propTypes.string.isRequired,
			folio: propTypes.string.isRequired,
			estatus: propTypes.number.isRequired,
			fechaSolicitud: propTypes.string.isRequired,
			sbai: propTypes.string,
			total: propTypes.number.isRequired,
			totalRecibidos: propTypes.number,
			totalDestruidos: propTypes.number,
			codigoSucursal: propTypes.string,
			sucursal: propTypes.string,
			porcetajRecibidos: propTypes.number,
			porcetajeDestruidos: propTypes.number,
			customerCode: propTypes.string,
			customerName: propTypes.string,
			customerToken: propTypes.string
		})
	).isRequired,
	/**
	 * The complete list of customers to be displayed in the filter panel
	 */
	customers: propTypes.arrayOf(
		propTypes.shape({
			token: propTypes.string.isRequired,
			code: propTypes.string.isRequired,
			name: propTypes.string.isRequired
		})
	),
	/**
	 * Callback executed when the user change the customers filter list
	 */
	onChangeSelectedCustomers: propTypes.func.isRequired,
	/**
	 * On change sorting criteria
	 */
	onChangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * Callback executed when the users clicks on a request item
	 */
	onRequestClick: propTypes.func.isRequired,
	/**
	 * Callback executed when the user change the status filter list
	 */
	onChangeSelectedStatus: propTypes.func.isRequired,
	/**
	 *  Callback executed when the user search by folio request filter list
	 */
	onSearchRequest: propTypes.func.isRequired,
	/**
	 * On send value query to search request
	 */
	queryValue: propTypes.string,
	/**
	 * On add request button click
	 */
	onAddButtonClick: propTypes.func.isRequired,
	/**
	 * On validate to create request and show filter customer
	 */
	isValitToAddRequest: propTypes.bool.isRequired,
	/**
	 * On validate is role administrator
	 */
	isRoleAdmin: propTypes.bool.isRequired,

	/**
	 * Callback executed when donwload report by sbai
	 */
	downloadReportbySbais: propTypes.func.isRequired,

	/**
	 * Callback executed to download status report of disposition requests
	 */
	onDownloadStatusReport: propTypes.func,

	identifierRow: propTypes.array
}

RequestIndex.defaultProps = {
	requests: [],
	customers: [],
	onChangeSelectedCustomers: () => {
		console.warn('Callback [onChangeSelectedCustomers] not defined')
	},
	onChangeSortingCriteria: () => console.warn('No [onChangeSortingCriteria] callback defined'),
	onRequestClick: () => console.warn('No [onRequestClick] callback defined'),
	onChangeSelectedStatus: () => {
		console.warn('Callback [onChangeSelectedStatus] not defined')
	},
	onSearchRequest: () => {
		console.warn('Callback [onSearchRequest] not defined')
	},
	queryValue: '',
	onAddButtonClick: () => {
		console.warn('Callback [onAddButtonClick] not defined')
	},
	isValitToAddRequest: false,
	isRoleAdmin: false,
	downloadReportbySbais: () => {
		console.warn('Callback [downloadReportbySbais] not defined')
	},
	onDownloadStatusReport: () => {
		console.warn('Callback [onDownloadStatusReport] not defined')
	}
}

export default RequestIndex

/**
 * Toolbar component customer
 */
const CustomerFilter = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedCustomers, setSelectedCustomers] = useState([])
	const toolbarClasses = useRequestsToolbar()

	const onChangeCustomerCheck = (customer) => {
		let updatesCustomerList = null
		let addedCustomer = selectedCustomers.find((x) => x.token === customer.token)
		if (addedCustomer)
			updatesCustomerList = selectedCustomers.filter(
				(customer) => customer.token !== addedCustomer.token
			)
		else updatesCustomerList = [...selectedCustomers, customer]
		setSelectedCustomers(updatesCustomerList)
		props.onChangeSelectedCustomers(updatesCustomerList)
	}

	const isSelected = (customer) =>
		selectedCustomers.find((x) => x.token === customer.token) ? true : false

	return (
		<div>
			<Button
				endIcon={<DownloadIcon />}
				size='small'
				onClick={(event) => setAnchorEl(event.currentTarget)}
			>
				Clientes
			</Button>
			<Popper
				anchorEl={anchorEl}
				open={anchorEl !== null}
				className={toolbarClasses.positionPopper}
			>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2} className={toolbarClasses.contextualMenu}>
						{props.customers.map((customer) => {
							return (
								<div className={toolbarClasses.menuItem} key={uuid4()}>
									<Checkbox
										size='small'
										onChange={() => onChangeCustomerCheck(customer)}
										checked={isSelected(customer)}
									/>
									<Typography
										variant='caption'
										className={toolbarClasses.menuItemLabel}
									>
										{customer.name}
									</Typography>
								</div>
							)
						})}
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}

CustomerFilter.propTypes = {
	customers: propTypes.arrayOf(
		propTypes.shape({
			token: propTypes.string.isRequired,
			code: propTypes.string.isRequired,
			name: propTypes.string.isRequired
		})
	),
	onChangeSelectedCustomers: propTypes.func.isRequired
}

CustomerFilter.defaultProps = {
	customers: []
}

/**
 * Toolbar component stataus
 * @param {*} props
 */
const StatusFilter = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedStatus, setSelectedStatus] = useState([])
	const toolbarClasses = useRequestsToolbar()

	const onChangeStatusCheck = (status) => {
		let updatesStatusList = null
		let addedStatus = selectedStatus.find((x) => x.key === status.key)
		if (addedStatus) updatesStatusList = selectedStatus.filter((x) => x.key !== status.key)
		else updatesStatusList = [...selectedStatus, status]
		setSelectedStatus(updatesStatusList)
		props.onChangeSelectedStatus(updatesStatusList)
	}

	const isSelected = (status) => (selectedStatus.find((x) => x.key === status.key) ? true : false)

	return (
		<div>
			<Button
				endIcon={<DownloadIcon />}
				size='small'
				onClick={(event) => setAnchorEl(event.currentTarget)}
			>
				Estatus
			</Button>
			<Popper
				anchorEl={anchorEl}
				open={anchorEl !== null}
				className={toolbarClasses.positionPopper}
			>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2} className={toolbarClasses.contextualMenu}>
						{props.status.map((status) => {
							return (
								<div className={toolbarClasses.menuItem} key={uuid4()}>
									<Checkbox
										size='small'
										onChange={() => onChangeStatusCheck(status)}
										checked={isSelected(status)}
									/>
									<Typography
										variant='caption'
										className={toolbarClasses.menuItemLabel}
									>
										{status.label}
									</Typography>
								</div>
							)
						})}
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}

StatusFilter.propTypes = {
	status: propTypes.arrayOf(
		propTypes.shape({
			key: propTypes.number.isRequired,
			label: propTypes.string.isRequired
		})
	),
	onChangeSelectedStatus: propTypes.func.isRequired
}

StatusFilter.defaultProps = {
	status: []
}

/**
 * Component to search request
 * @param {*} props
 */
const SearchRequest = (props) => {
	const [searchValue, setSearchValue] = useState('')

	const onQueryChange = (event) => {
		setSearchValue(event.target.value)
	}

	useEffect(() => {
		setSearchValue(props.value)
	}, [props.value])

	const onKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault()
			if (props.onSearchRequest) {
				props.onSearchRequest(searchValue)
			}
		}
	}

	return (
		<InputBase
			className={props.classInput}
			placeholder={'Buscar solicitud'}
			inputProps={{ 'aria-label': `${'Buscar solicitud'}` }}
			value={searchValue}
			onChange={onQueryChange}
			onKeyPress={onKeyPress}
		/>
	)
}

SearchRequest.propTypes = {
	onSearchRequest: propTypes.func.isRequired,
	value: propTypes.string
}

SearchRequest.defaultProps = {
	value: ''
}
