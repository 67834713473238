import { isNullOrEmpty } from './StringHelper'
import queryString from 'query-string'

/**
 * Get the absolute url address
 *
 * @param {string} url
 */
export const getAbsoluteUrlAddress = (url) => {
	let baseUrl = `${window.location.protocol}//${window.location.hostname}${
		window.location.port ? `:${window.location.port}` : ''
	}`
	if (isNullOrEmpty(url)) {
		return process.env.REACT_APP_ROOT === '/'
			? `${baseUrl}`
			: `${baseUrl}${process.env.REACT_APP_ROOT}/${url}`
	} else {
		return process.env.REACT_APP_ROOT === '/'
			? `${baseUrl}/${url}`
			: `${baseUrl}${process.env.REACT_APP_ROOT}/${url}`
	}
}

/**
 *
 * @param {*} searchString
 * @returns
 */
 export const parseStatusValues = (searchString) => {
	const searchParams = new URLSearchParams(searchString)
	return searchParams.has('status') ? searchParams.get('status').split(',') : []
}

/**
 *
 * @param {*} searchString
 * @returns
 */
export const parseQuickLookupValue = (searchString) => {
	const searchParams = new URLSearchParams(searchString)
	return searchParams.has('query') ? searchParams.get('query').trim() : ''
}

const paginationKeys = ['pageNumber', 'pageSize']

/**
 * Parse input string to pagination object
 * @param {string} queryString
 * @returns {object}
 */
export const parsePaginationFromQueryString = (queryString, defaultPagination) => {
	const pagination = parseObjectFromString(queryString, paginationKeys, defaultPagination)
	Object.entries(pagination).forEach(([key, value]) => {
		pagination[key] = parseInt(value)
	})
	return pagination
}

const sortingKeys = ['sortBy', 'sortDirection']

/**
 * Parse input string to sorting object
 * @param {string} searchString
 * @returns
 */
export const parseSortingFromQueryString = (searchString, defaultPagination) => {
	return parseObjectFromString(searchString, sortingKeys, defaultPagination)
}

const parseObjectFromString = (str, allowedKeys, defaultObject = {}) => {
	const searchParams = new URLSearchParams(str)

	const parsedObj = {}
	allowedKeys.forEach((key) => {
		const paramValue = searchParams.get(key)?.trim()
		if (isValidPropertyValue(paramValue)) {
			parsedObj[key] = paramValue
		} else if (defaultObject.hasOwnProperty(key)) {
			parsedObj[key] = defaultObject[key]
		}
	})
	return parsedObj
}

const isValidPropertyValue = (value) => {
	if (!value) {
		return false
	}
	if (value.toString().trim().length === 0) {
		return false
	}
	return true
}

const notAllowedFilters = [...paginationKeys, ...sortingKeys, 'query']

export const getCustomFilters = (url) => {
	const actualParameters = queryString.parse(url)

	let customFilters = []
	var keys = Object.keys(actualParameters)
	for (let key of keys) {
		if (!notAllowedFilters.includes(key) && !customFilters.some((f) => f.name === key)) {
			customFilters.push({ name: key, values: actualParameters[key].split(',') })
		}
	}

	return customFilters
}
