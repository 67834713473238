import React from 'react'

/** Material-UI import section */
import { makeStyles, Grid, Typography } from '@material-ui/core'
import Loader from 'react-spinners/CircleLoader'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		height: '100vh'
	},
	message: {
		color: theme.palette.text.hint
	}
}))

const defaultColor = 'rgba(0, 0, 0, 0.38)'

/**
 * Component for showing a waiting message when the app is loading permissions
 *
 */
const LoadingPermissions = (props) => {
	const classes = useStyles()

	return (
		<Grid
			container
			spacing={3}
			className={classes.root}
			direction='column'
			justifyContent='center'
			alignItems='center'
		>
			<Grid item>
				<Loader
					sizeUnit={'px'}
					size={100}
					margin={'2px'}
					color={defaultColor}
					loading={true}
				/>
			</Grid>
			<Grid item>
				<Typography
					className={classes.message}
					variant='subtitle1'
					style={{ color: defaultColor }}
					gutterBottom
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant='h5'>{'Verificando permisos...'}</Typography>
						</Grid>
					</Grid>
				</Typography>
			</Grid>
		</Grid>
	)
}

export default LoadingPermissions
