import React from 'react';
import PropTypes from 'prop-types';

/** Material UI import section */
import { 
    makeStyles,
    Box
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
    },
    imageContainer: {
        // minHeight: "28vh",
        // minWidth: "18vw",
        // maxWidth: "28vw",
        display: "block",
        height:"40%",
        minHeight: "50%",
        width:"40%",
        maxWidth: "50%",
        marginLeft:"auto",
        marginRight:"auto",
        marginTop:"-25px"
    },
    image: {
        width: "100%",
        height: "auto"
    },
    message:{
        color: "rgb(84, 92, 216)",
        maxWidth:"45%",
        textAlign:"center"
    }
}));

/**
 * Presentional componet to show the suppliers operational documents 
 * 
 * @param {*} props 
 */
const NoDataMessage = props => {
    const classes = useStyles();
    const { message, image } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            m={1} p={1}
        >
            <Box
                p={1}
                alignSelf="center"
                className={classes.message}
            >
                {message}
            </Box>
            {
                image &&
                <Box 
                    className={classes.imageContainer}
                    p={1}
                    alignSelf="center"
                >
                    <img src={image} className={classes.image} alt="Imagen" />
                </Box>
            }
        </Box>
    );
}
  
NoDataMessage.propTypes = {
    message: PropTypes.node.isRequired
};

export default NoDataMessage;