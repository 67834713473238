import React, { useState } from 'react'
import PropTypes from 'prop-types'

/** Custom componets import section */
import FileContent from '../../common/Dropzone/FileContent'
import DialogView from '../../common/Dialog'
import { TextBoxFieldRequired } from '../../common/TextBox'
import DropDownZone from '../../common/DropDownZone'
import { Button, makeStyles } from '@material-ui/core'
import { COLOR_SUCCESS_NORMAL } from '../../../resources/constants/Colors'

const AddingManualMaterialEntry = (props) => {
    const { open, onClose, isSaving, onSaveButtonClick } = props
    const allowedFileExtensions = ['.doc', '.docx', '.eml', '.pdf', '.pps', '.ppsx', '.ppt', '.pptx', '.xls', '.xlsx'];
    const [attachment, setAttachment] = useState(null)

    const [folio, setFolio] = useState('');

    const onFolioChange = (event) => {
        const { value } = event.target
        setFolio(value)
    }

    const handleSaveButtonClick = () => {
        onSaveButtonClick(folio, attachment)
    }

    const enableSaveButton = () => {
        if(folio.length < 3)
        return true
        return false
    }
    
    const onFilesChange = (files) => {
        if(files.length > 0){
            setAttachment(files[0])
        }else{
            setAttachment(null)
        }

    }
    
  

    const classes = useStyles()

    return (
        <DialogView
            open={open}
            onClose={onClose}
            header={<div>Agregar entrada manual</div>}
            isSaving={isSaving}
            actions={
                <Button 
                    className={classes.buttonAccept}
                    disabled={enableSaveButton() || isSaving}
                    onClick={handleSaveButtonClick}
                >
                    Guardar
                </Button>
            }
        >
            <TextBoxFieldRequired
                active={true}
                fullWidth = {true}
                required = {true}
                label="Folio de la entrada"
                name='Folio'
                onChange={onFolioChange}

            />
            <DropDownZone
					datacy='drop-zone-documents-operating'
					//classes={classes}
					multiple={false}
					accept={allowedFileExtensions}
					message={`Extensiones de archivos permitidas: ${allowedFileExtensions.map(ext => ` [${ext}] `).join(',')}`}
					onFilesReceived={onFilesChange}
				/>
                {attachment && (
					<FileContent
						files={[{ attachment }]}
						onRemoveFile={() => setAttachment(null)}
					/>
				)}

        </DialogView>
    )
}

AddingManualMaterialEntry.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSaveButtonClick: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    buttonEnabled: PropTypes.bool.isRequired,
    
}

AddingManualMaterialEntry.defaultProps = {
    open: false,
    onClose: () => console.warn('[onClose] is not defined'),
    onSaveButtonClick: () => console.warn('[onSaveButtonClick] is not defined'),
    isSaving: false,
    buttonEnabled: false,
 
}

const useStyles = makeStyles((theme) => ({
	buttonAccept: {
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: theme.palette.common.white,
		right: '10px',
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: theme.palette.common.white,
			opacity: 0.75
		}
	}
}))

export default AddingManualMaterialEntry;