import React, { useState } from 'react'
import PropTypes from 'prop-types'

/** Material UI import section */
import {
	makeStyles,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	IconButton,
	Typography,
	InputAdornment,
	Grid,
	Chip,
	Checkbox,
	FormControlLabel
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CloudDownloadIcon from '@material-ui/icons/Check'

/** Custom components import section */
import DateSelector from '../../common/DateSelector'
import TextBox from '../../common/TextBox'
import SelectSuppliers from '../components/SelectSupplier'

/** Helpers import section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'

/** Resources import section */
import {
	COLOR_SUCCESS_NORMAL,
	COLOR_DANGER_NORMAL,
	COLOR_WHITE,
	COLOR_PRIMARY_NORMAL,
	COLOR_SECONDARY_NORMAL
} from '../../../resources/constants/Colors'

/**
 * Presentional component to show a modal confirmation.
 * @param {object} props
 */
const RequestActionDialog = (props) => {
	const {
		isSaving,
		onAccept,
		title,
		requestToken,
		showHelperPassword,
		isOptionToConfirm,
		isOptionToConfirmColection,
		isOptionToLiberated,
		isOptionToCancel,
		type
	} = props
	const classes = useStyles()

	const [requestData, setRequestData] = useState({
		password: '',
		confirmationDate: null,
		collectionDate: null,
		carrier: null,
		markForFinished: false
	})
	const [showPassword, setShowPassword] = useState(false)
	const [existPdf, setExistPdfInSap] = useState(false)

	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	const onChangeConfirmationDate = (event) => {
		let { value } = event.target
		let name = 'confirmationDate'
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	const onChangeCollectionDate = (event) => {
		let { value } = event.target
		let name = 'collectionDate'
		setRequestData({
			...requestData,
			[name]: value
		})
	}

	const onMarkForFinished = (event) => {
		let { checked } = event.target
		setRequestData({
			...requestData,
			markForFinished: checked
		})
	}

	/**
	 * Determine if the accepted button can be pressed
	 */
	const canBeAccepted = () => {
		if (isOptionToConfirm) {
			if (
				isNullOrEmpty(requestData && requestData.password) ||
				(requestData && requestData.password.length < 6)
			) {
				return false
			}
			if (
				isNullOrEmpty(requestData && requestData.confirmationDate) ||
				(requestData && requestData.confirmationDate.length < 1)
			) {
				return false
			}
		} else if (isOptionToConfirmColection) {
			if (
				isNullOrEmpty(requestData && requestData.password) ||
				(requestData && requestData.password.length < 6)
			) {
				return false
			}
			if (
				isNullOrEmpty(requestData && requestData.collectionDate) ||
				(requestData && requestData.collectionDate.length < 1)
			) {
				return false
			}
		} else if (isOptionToCancel) {
			if (
				isNullOrEmpty(requestData && requestData.password) ||
				(requestData && requestData.password.length < 6)
			) {
				return false
			}
			if (
				isNullOrEmpty(requestData && requestData.comments) ||
				(requestData && requestData.comments.length < 10)
			) {
				return false
			}
		} else {
			if (
				isNullOrEmpty(requestData && requestData.password) ||
				(requestData && requestData.password.length < 6)
			) {
				return false
			}
		}
		return true
	}

	/**
	 * Hanlde click event from accept button
	 */
	const onAcceptClick = () => {
		if (onAccept) {
			onAccept(requestData, requestToken)
		}
	}

	/**
	 * Handle view actual password
	 */
	const onToggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	/**
	 * Function to dowload pdf
	 */
	const onDonwloadPdf = () => {
		props.verifyExistsDocumentSap(requestToken).then((response) => {
			if (response) {
				setExistPdfInSap(response.existPdf)
			} else {
				setExistPdfInSap(false)
			}
		})
	}

	/**
	 * Function on add carrier
	 * @param {*} carrier
	 */
	const onChangeCarrier = (selectedCarrier) => {
		setRequestData({
			...requestData,
			carrier: selectedCarrier
		})
	}

	const shouldSbaiBeDisplayed = type === 'REQUEST_AUTHORIZATION'

	/**
	 * Render the component content
	 */
	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth='xs' fullWidth>
			<DialogTitle disableTypography className={classes.root}>
				<Typography variant='button' className={classes.title}>
					<strong>{title}</strong>
				</Typography>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={props.onClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<TextField
						fullWidth
						data-cy='txt-confirm-password'
						autoComplete='off'
						label={'Contraseña'}
						variant='outlined'
						name='password'
						value={requestData ? requestData.password : ''}
						onChange={onPropertyChange}
						placeholder='Ingrese su contraseña para confirmar la operación'
						helperText={
							showHelperPassword ? (
								<strong style={{ color: COLOR_DANGER_NORMAL }}>
									{'Contraseña incorrecta, por favor verifique.'}
								</strong>
							) : (
								''
							)
						}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										edge='end'
										aria-label='toggle password visibility'
										onClick={onToggleShowPassword}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
							classes: {
								root: classes.cssOutlinedInput,
								focused: classes.cssFocused,
								notchedOutline: classes.notchedOutline
							},
							inputMode: 'text',
							autoComplete: 'off'
						}}
					/>
					{isOptionToLiberated && (
						<Grid item xs={12}>
							<br />
							<Chip
								disabled={props.isVerifingDocumentSap}
								icon={<CloudDownloadIcon className={classes.colorWhithe} />}
								id={'itek-foen'}
								data-cy='btn-see-doc'
								label={
									!props.isVerifingDocumentSap
										? 'Validar que existan manifiestos en la carpeta'
										: 'Verificando información...'
								}
								className={classes.btnDownload}
								onClick={() => onDonwloadPdf()}
							/>
						</Grid>
					)}
					{isOptionToConfirm && (
						<Grid item xs={12}>
							<DateSelector
								dataCy='date-picker-cofirn-date'
								active={true}
								label={'Fecha de confirmación'}
								onChange={onChangeConfirmationDate}
								value={requestData ? requestData.confirmationDate : null}
							/>
							<br />
							<br />
							<TextBox
								active={true}
								label='Comentarios'
								fullWidth={true}
								margin='dense'
								multiline={true}
								rows={3}
								name='comments'
								value={requestData.comments}
								onChange={onPropertyChange}
							/>
						</Grid>
					)}
					{isOptionToConfirmColection && (
						<Grid item xs={12}>
							<DateSelector
								dataCy='date-picker-collecction-date'
								active={true}
								label={'Fecha de recolección'}
								onChange={onChangeCollectionDate}
								value={requestData ? requestData.collectionDate : null}
							/>
							<br />
							<br />
							<Typography variant='subtitle2'>Seleccionar transportista</Typography>
							<SelectSuppliers
								onChange={onChangeCarrier}
								supplier={requestData ? requestData.carrier : null}
							/>
							<br />
							<FormControlLabel
								control={
									<Checkbox
										onChange={(event) => onMarkForFinished(event)}
										value={requestData ? requestData.markForFinished : false}
										color='primary'
									/>
								}
								label='Cambiar estatus manual a (Por Terminar). Una vez que cambie de estatus tendrá la opción de terminar solicitud. Verifique que ya no habra entregas de sap de esta solicitud. Ya no se podrá modificar.'
							/>
						</Grid>
					)}
					{isOptionToCancel && (
						<Grid item xs={12}>
							<TextBox
								active={true}
								label='Comentarios de la cancelación'
								fullWidth={true}
								margin='dense'
								multiline={true}
								rows={4}
								name='comments'
								value={requestData.comments}
								onChange={onPropertyChange}
							/>
						</Grid>
					)}
					{shouldSbaiBeDisplayed && (
						<Grid item xs={12}>
							<TextBox
								active={true}
								label='SBAI'
								fullWidth={true}
								margin='dense'
								name='sbai'
								value={requestData.sbai}
								onChange={onPropertyChange}
							/>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<div className={classes.wrapper}>
					<Button
						data-cy='btn-accept'
						className={classes.buttonAccept}
						variant='contained'
						onClick={onAcceptClick}
						disabled={
							!canBeAccepted() || isSaving || (isOptionToLiberated && !existPdf)
						}
					>
						{'Aceptar'}
					</Button>
					{isSaving && (
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
							disableShrink
						/>
					)}
				</div>
			</DialogActions>
		</Dialog>
	)
}

RequestActionDialog.propsTypes = {
	onAccept: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isSaving: PropTypes.bool,
	showHelperPassword: PropTypes.bool,
	isOptionToConfirm: PropTypes.bool,
	isOptionToLiberated: PropTypes.bool,
	isOptionToConfirmColection: PropTypes.bool,
	isOptionToCancel: PropTypes.bool,
	type: PropTypes.string
}

RequestActionDialog.defaultProps = {
	isSaving: false,
	showHelperPassword: false,
	isOptionToConfirm: false,
	isOptionToLiberated: false,
	isOptionToConfirmColection: false,
	isOptionToCancel: false
}

const useStyles = makeStyles((theme) => ({
	title: {
		color: COLOR_PRIMARY_NORMAL
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonAccept: {
		backgroundColor: COLOR_SUCCESS_NORMAL,
		color: COLOR_WHITE,
		right: '10px',
		'&:hover': {
			backgroundColor: COLOR_SUCCESS_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_SUCCESS_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	buttonRejected: {
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL
		},
		'&.Mui-disabled': {
			backgroundColor: COLOR_DANGER_NORMAL,
			color: COLOR_WHITE,
			opacity: 0.75
		}
	},
	buttonProgress: {
		color: COLOR_SECONDARY_NORMAL,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	closeButton: {
		position: 'absolute',
		right: '22px',
		top: theme.spacing(1),
		backgroundColor: COLOR_DANGER_NORMAL,
		color: COLOR_WHITE,
		'&:hover': {
			backgroundColor: COLOR_DANGER_NORMAL
		},
		width: '37px',
		height: '36px',
		paddingTop: 8
	},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {}
	},
	cssFocused: {},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: `${COLOR_PRIMARY_NORMAL} !important`,
		color: `${COLOR_PRIMARY_NORMAL} !important`
	},
	colorWhithe: {
		color: 'white'
	},
	btnDownload: {
		backgroundColor: '#83588A',
		color: '#FFFFFF',
		textTransform: 'uppercase',
		height: '28px !important'
	}
}))

export default RequestActionDialog
