/** Helpers import section */
import axios from 'axios';

import { getAuthorizedRequestConfigurationUser } from '../helpers/RequestHelper';

export const START_LOADING_BRANCHS = "START_LOADING_BRANCHS";
export const SET_BRANCHS_LIST = "SET_BRANCHS_LIST";

/**
 * Action start loading
 * @param {*} isLoading 
 */
function isLoading(isLoading) {
    return {
        type: START_LOADING_BRANCHS,
        isLoading
    }
}

const setBranchs = (items) => {
    return {
        type: SET_BRANCHS_LIST,
        items
    }
}

/**
 * Action geall all brachs officce
 * @param {*} query 
 */
export function getBranchoffices(){
    return function(dispatch, getState){

        let actualBranch = getState().branchoffices ? getState().branchoffices.get('branchoffices').toJS() : [];
        if (actualBranch.length !== 0) {
            return Promise.resolve(actualBranch);
        }

        dispatch(isLoading(true));
        let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user);
        let endPoint = `${process.env.REACT_APP_FINALDISPOSITION_BACKEND_URL}/branchoffice`;

        return axios.get(endPoint, configuration)
        .then((response) => {
            if (response.status === 200) {
                dispatch(isLoading(false));
                dispatch(setBranchs(response.data))
                return Promise.resolve(response.data);
            }
        })
        .catch((error) => {
            dispatch(isLoading(false));
            console.error(error);
        });        
    }
}