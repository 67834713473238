import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Material UI */
import { 
    makeStyles,
    Button,
    Grid,
    Typography
} from '@material-ui/core';

/** Custom component import section */
import LateralActionPanel from '../../common/LateralActionPanel';
import TextBox from '../../common/TextBox';
import {TextBoxFieldRequired} from '../../common/TextBox';

/** Actions import section */
import { findUserByUserName } from '../../../store/users/UserActions';

/** Helpers impor section */
import { toSafeArray } from '../../../store/helpers/ImmutableHelper';
import { UserTypes } from '../../../resources/constants/AppConstants';
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';

const useStyle = makeStyles((theme) => ({
    form: {
        height: "500px",
        width: "350px",
        padding: "10px"
    },
    button: {
        margin: theme.spacing(1),
    }

}))

const AddInternalUserPanel = props => {
    const [userModel, setUserModel] = useState(props.userModel);
    const [userExist, setUserExist] = useState(true);
    const classes = useStyle();
    
    const dispatch = useDispatch();
    const allUsers = useSelector(state => toSafeArray(state.users.get('allUsers')));

    const updateProperty = (property, value) => {
        setUserModel({
            ...userModel,
            [property]: value
        })
    }

    const onUserNameChange = event => {
        let userName = event.target.value;
        updateProperty('userName', userName);
        if (userName.length > 2) {
            setUserExist(true);
            dispatch(findUserByUserName(userName))
            .then(foundUser => {
                if (foundUser) {
                    setUserModel(foundUser);
                    setUserExist(true);
                }
                else {
                    setUserModel({
                        userName: userName,
                        name: '',
                        email: '',
                        type: UserTypes.ACTIVE_DIRECTORY
                    });
                    setUserExist(false);
                }
            })
            .catch(() => {
                setUserExist(false);
            })
        }
    }

    const isUserAlreadyAdded = () => {
        return allUsers.some(u => u.userName === userModel.userName);
    }

    const canBeSaved = () => {
        if (!userModel.userName || userModel.userName.length === 0) return false;
        if (!userModel.name || userModel.name.length === 0) return false;
        if (!userExist) return false;
        if (isUserAlreadyAdded()) return false;
        return true;
    }

    const onSaveButtonClick = () => {
        if (props.onCreateUser) {
            props.onCreateUser(userModel);
        }
        else {
            console.warn("Callback 'onCreateUser' not defined!");
        }
    }

    const getFooter = () => {
        return (
            <Fragment>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onSaveButtonClick}
                    className={classes.button}
                    disabled={!canBeSaved() || props.isSaving || props.isFindingUser}
                >
                    Guardar
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={props.onClose}
                    disabled={props.isSaving}
                >
                    Cancelar
                </Button>
            </Fragment>
        )
    }

    const getUserNameTextHelper = () => {
        if (!userExist) { return (<Typography data-cy='txt-there-no-user' color="error" variant="caption">{"No se encontró usuario con este número de socio"}</Typography>) }
        else if (isUserAlreadyAdded()) { return (<Typography data-cy='txt-user-already-exists' color="error" variant="caption">{"Este usuario ya ha sido agregado"}</Typography>); }
        return null;
    }

    return (
        <LateralActionPanel
            title="Agregar usuario"
            show={props.open}
            footer = {getFooter()}
            onClose={props.onClose}
            isSaving={props.isSaving || props.isFindingUser}
            canBeSaved={!canBeSaved()}
            onSaveItem={onSaveButtonClick}
            onCancel={props.onClose}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} >
                    <TextBoxFieldRequired
                        dataCy="key-user"
                        autoFocus
                        isSuccess={!isNullOrEmpty(userModel.userName)}
                        required
                        active={true}
                        label="Ingresa el número de socio"
                        fullWidth
                        margin={"dense"}
                        onChange={onUserNameChange}
                        value={userModel.userName}
                        helperText={getUserNameTextHelper()}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextBox
                        dataCy="name"
                        disabled
                        active={true}
                        label="Nombre"
                        fullWidth={true}
                        margin="dense"
                        value={userModel.name}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextBox
                        dataCy="email"
                        disabled
                        active={true}
                        label="Email"
                        fullWidth={true}
                        margin="dense"
                        value={userModel.email}
                    />
                </Grid>
            </Grid>
        </LateralActionPanel>
    )
}

AddInternalUserPanel.defaultProps = {
    userModel: {
        userName: '',
        name: '',
        phoneNumber: '', 
        type: UserTypes.ACTIVE_DIRECTORY
    }
}

export default AddInternalUserPanel;  